import { RootState } from 'store';

export interface IReadReceipt {
  id: string
  read_receipt_key: string
  last_changed: string
  last_read: string
}

export const isReceiptUnread = (receipt: IReadReceipt | undefined) => {
  if (!receipt) return false;

  const lastRead = new Date(receipt.last_read).getTime();
  const lastChanged = new Date(receipt.last_changed).getTime();

  if (isNaN(lastRead)) {
    return true;
  }

  if (isNaN(lastChanged)) {
    return false;
  }

  return lastChanged > lastRead;
};

const root = (state: RootState) => state.readReceipts;
export const receiptsByKey = (state: RootState) => root(state).readReceiptsByKey;
const markingAsRead = (state: RootState) => root(state).markingReceiptsAsReadPending;
export const isMarkReadPending = (state: RootState, anyIdsOrKeys: string[]) => {
  const markingSet = markingAsRead(state);
  return anyIdsOrKeys.some(idOrKey => !!markingSet[idOrKey]);
};
export const getReceiptByKey = (state: RootState, key: string): IReadReceipt | undefined => receiptsByKey(state)[key];
const getIsReceiptUnread = (state: RootState, key: string) => isReceiptUnread(getReceiptByKey(state, key));

const getReceiptLastRead = (state: RootState, key: string): number => new Date(getReceiptByKey(state, key)?.last_read || 0).valueOf();
export const getIsCalendarPinsUnread = (state: RootState, evtId: string) => getIsReceiptUnread(state, `calendar_event:${evtId}.pins`);
export const getIsCalendarNotesUnread = (state: RootState, evtId: string) => getIsReceiptUnread(state, `calendar_event:${evtId}.notes`);
export const getIsCalendarActivityUnread = (state: RootState, evtId: string) => getIsReceiptUnread(state, `calendar_event:${evtId}.activity`);
export const getIsCalendarEventUnread = (state: RootState, evtId: string) => getIsCalendarPinsUnread(state, evtId) || getIsCalendarNotesUnread(state, evtId) || getIsCalendarActivityUnread(state, evtId);
export const getLastCalendarNotesRead = (state: RootState, evtId: string) => getReceiptLastRead(state, `calendar_event:${evtId}.notes`) || undefined;
export const getLastCalendarPinsRead = (state: RootState, evtId: string) => getReceiptLastRead(state, `calendar_event:${evtId}.pins`) || undefined;
export const getLastCalendarActivityRead = (state: RootState, evtId: string) => getReceiptLastRead(state, `calendar_event:${evtId}.activity`) || undefined;
export const getIsWorkstreamPinsUnread = (state: RootState, tagId: string) => getIsReceiptUnread(state, `workstream:${tagId}.pins`);
export const getIsWorkstreamNotesUnread = (state: RootState, tagId: string) => getIsReceiptUnread(state, `workstream:${tagId}.notes`);
export const getLastWorkstreamPinsRead = (state: RootState, evtId: string) => getReceiptLastRead(state, `workstream:${evtId}.pins`) || undefined;
export const getLastWorkstreamNotesRead = (state: RootState, evtId: string) => getReceiptLastRead(state, `workstream:${evtId}.notes`) || undefined;