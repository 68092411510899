import cx from 'classnames';
import { CrossIcon, Button as EvergreenButton, Switch, TickIcon } from 'evergreen-ui';
import axios, { AxiosError } from 'axios';

import { ConnectorProviders } from 'constants/app';
import GoogleIcon from 'components/icons/Google';
import JiraIcon from 'components/icons/Jira';
import { NotificationType } from 'store/notifications/selectors';
import { getConnectorName } from 'utils/message_ctx';
import { getGoogleOAuthURI } from 'api/auth';
import { notify } from 'store/notifications/slice';
import { useAppDispatch } from 'hooks';
import { IConnector, receiveConnectors } from 'store/connectors/slice';
import { disableConnector, enableConnector } from 'api/connectors';

import { getProviderIcon } from './providers';
import './style.css';


const openGoogle = async (authuser?: string) => {
  try {
    const {data} = await getGoogleOAuthURI(undefined, authuser);
    window.location.href = data.authorization_url;
  } catch (err) {
    console.warn('Could not get google auth URI', err);
  }
};


const ConnectorStatus = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  SUSPENDED: 'SUSPENDED',
};


const Connector = ({
  connector,
  className = '',
}: {connector: IConnector, className?: string}) => {
  const Icon = getProviderIcon(connector.provider);
  const connectorName = getConnectorName(connector);
  const isSuspended = connector.status === ConnectorStatus.SUSPENDED;
  const apiCall = connector.status === ConnectorStatus.DISABLED ? enableConnector : disableConnector;
  const dispatch = useAppDispatch();

  const callApi = async () => {
    if (isSuspended) return;

    const setTo = connector.status === ConnectorStatus.DISABLED ? 'enabled' : 'disabled';
    try {
      const {data} = await apiCall(connector.id, connector.provider);
      dispatch(receiveConnectors([data]));
      notify({message: `Account was ${setTo}`, type: NotificationType.SUCCESS})(dispatch);
    } catch (e: unknown) {
      const status = axios.isAxiosError(e) ? (e as AxiosError).response?.status : null;
      // 400 on this endpoint means it was already disabled
      if (status !== 400) {
        notify({message: 'Failed to update account, please try again or contact support', type: NotificationType.ERROR})(dispatch);
      } else {
        notify({message: `Account was ${setTo}`, type: NotificationType.SUCCESS})(dispatch);
      }
    }
  };

  return (
    <>
      <div className={cx(className, 'connector-info--container')}>
        <div className="connector-info--title">
          <Icon className="connector-info--icon"/>
          <div>
            {connectorName}
          </div>
        </div>
        <div className="connected-account-actions-status--container">
          <Switch disabled={isSuspended} checked={connector.status !== ConnectorStatus.DISABLED && !isSuspended} onChange={callApi} marginRight={4}></Switch>
          {isSuspended && <span>Suspended</span>}
          {!isSuspended && <span className="">{connector.status === ConnectorStatus.DISABLED ? 'Disabled' : 'Enabled'}</span>}
        </div>
      </div>
    </>
  );
};

export default Connector;

export const ConnectedAccountGroup = ({connectors, name, canSetStatus=false}: {connectors: IConnector[], name: string, canSetStatus?: boolean}) => {
  if (connectors.length === 0) {
    return null;
  }

  const provider = connectors[0].provider;
  const status = connectors[0].status;
  const apiCall = status === ConnectorStatus.DISABLED ? enableConnector : disableConnector;
  const dispatch = useAppDispatch();
  const anyAreSuspended = connectors.some(conn => conn.status === ConnectorStatus.SUSPENDED);

  const callApi = async () => {
    if (anyAreSuspended) return;

    const setTo = status === ConnectorStatus.DISABLED ? 'enabled' : 'disabled';
    try {
      const responses = await Promise.all(connectors.map((conn) => apiCall(conn.id, provider)));
      const allData = responses.map(res => res.data);
      dispatch(receiveConnectors(allData));
      notify({message: `Account was ${setTo}`, type: NotificationType.SUCCESS})(dispatch);
    } catch (e: unknown) {
      const status = axios.isAxiosError(e) ? (e as AxiosError).response?.status : null;
      // 400 on this endpoint means it was already disabled
      if (status !== 400) {
        notify({message: 'Failed to update account, please try again or contact support', type: NotificationType.ERROR})(dispatch);
      } else {
        notify({message: `Account was ${setTo}`, type: NotificationType.SUCCESS})(dispatch);
      }
    }
  };

  const getStatusIcon = (connector?: IConnector) => {
    if (!connector || connector.status !== 'ACTIVE') {
      return <CrossIcon color='var(--color-red-3)' />;
    } else {
      return <TickIcon color='var(--color-green-2-4)' />;
    }
  };
  if (connectors.length === 1 && connectors[0].provider === ConnectorProviders.SALESFORCE) {
    return <Connector connector={connectors[0]} />;
  }

  if (connectors.length === 1 && connectors[0].provider === ConnectorProviders.TRELLO) {
    return <Connector connector={connectors[0]} />;
  }

  if (connectors.length === 1 && connectors[0].provider === ConnectorProviders.SLACK) {
    return <Connector connector={connectors[0]} />;
  }

  if (connectors.length !== 1 && connectors[0].provider === ConnectorProviders.SLACK) {
    return <>{connectors.map(conn => <Connector connector={conn} key={conn.id} />)}</>;
  }

  if (connectors.length === 1 && connectors[0].provider === ConnectorProviders.OUTLOOK) {
    return <Connector connector={connectors[0]} />;
  }
  // TODO: update OUTLOOK provider as next microsoft integrations will be created (change it from OUTLOOK to MICROSOFT)

  if (connectors[0].provider === ConnectorProviders.JIRA) {
    return <div className="connected-account-group--container">
      <div className="connected-account-group--title">
        <JiraIcon className='connected-account-group--icon'/>
        Jira
        <div className='connected-account-actions--group'>
          {canSetStatus &&
          <div className="connected-account-actions-status--container">
            <Switch disabled={anyAreSuspended} checked={status !== ConnectorStatus.DISABLED && !anyAreSuspended} onChange={callApi} marginRight={4}></Switch>
            {anyAreSuspended && <span>Suspended</span>}
            {!anyAreSuspended && <span>{status === ConnectorStatus.DISABLED ? 'Disabled' : 'Enabled'}</span>}
          </div>
          }
        </div>
      </div>
      <div className="connectors-list--container">
        {connectors.map((conn, indx) => 
          <div key={`${indx}-${conn.meta.url?.length}`} className="connectors-list--row">{getStatusIcon(conn)}<a href={conn.meta.url}>{conn.meta.name}</a></div>
        )}
      </div>
    </div>;
  }

  const driveConn = connectors.find(conn => conn.provider === ConnectorProviders.GDRIVE);
  const calConn = connectors.find(conn => conn.provider === ConnectorProviders.GCAL);
  const gmailConn = connectors.find(conn => conn.provider === ConnectorProviders.GMAIL);
  const showReconnect = !driveConn || !calConn || !gmailConn;

  // only case left is that there are multiple connectors and they're all google accounts
  return <div className="connected-account-group--container">
    <div className="connected-account-group--title">
      <GoogleIcon className='connected-account-group--icon'/>
      {name}
      <div className='connected-account-actions--group'>
        {showReconnect && <EvergreenButton className="connected-account-reconnect--button" onClick={() => openGoogle(name)}>Reconnect</EvergreenButton>}
        {canSetStatus &&
          <div className="connected-account-actions-status--container">
            <Switch disabled={anyAreSuspended} checked={status !== ConnectorStatus.DISABLED && !anyAreSuspended} onChange={callApi} marginRight={4}></Switch>
            {anyAreSuspended && <span>Suspended</span>}
            {!anyAreSuspended && <span className="">{status === ConnectorStatus.DISABLED ? 'Disabled' : 'Enabled'}</span>}
          </div>
        }
      </div>
    </div>
    <div className="connectors-list--container">
      <div className="connectors-list--row">{getStatusIcon(gmailConn)}<span>Gmail</span></div>
      <div className="connectors-list--row">{getStatusIcon(calConn)}<span>Calendar</span></div>
      <div className="connectors-list--row">{getStatusIcon(driveConn)}<span>Drive</span></div>
    </div>
  </div>;
};
