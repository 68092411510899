import { API } from 'api';
import { IDocSearchResultV2, ILinkSearchResult, INoteSearchResult } from 'store/search/selectors';
import { ISearchFacet, ISearchPage } from 'store/search/slice';

export const searchDocuments = (query: string, filters: string[]) => {
  return API.get(getSearchApiUrl('/api/v1/search/documents/', query, filters));
};

export const searchDocumentsV2 = (query: string, filters: string[]) => {
  return API.get<{results: IDocSearchResultV2[]}>(getSearchApiUrl('/api/v1/search/documents-from-db/', query, filters));
};

export const searchLinks = (query: string, filters: string[]) => {
  return API.get<{results: ILinkSearchResult[], facets: ISearchFacet[], pagination: ISearchPage }>(getSearchApiUrl('/api/v1/search/links', query, filters));
};

export const searchNotes = (query: string, filters: string[]) => {
  return API.get<{results: INoteSearchResult[], facets: ISearchFacet[], pagination: ISearchPage }>(getSearchApiUrl('/api/v1/search/notes', query, filters));
};

const getSearchApiUrl = (baseUrl: string, query: string, filters: string[]) => {
  let qs = `?query=${encodeURIComponent(query)}&`;
  filters.map(encodeURIComponent).forEach(value => {
    qs += `filter=${value}&`;
  });
  return `${baseUrl}${qs}`;
};