import { API } from 'api';
import { INote, INoteData } from 'store/notes/slice';
import { TrackEventNames, tracker } from 'utils/tracking';


export const createNote = (payload: {
  title?: string,
  content?: string,
  data?: Record<string, unknown>,
}) => API.post<INote>('/api/v1/notes/', payload);

export const fetchNotesPage = (offset: number, namespaces: string[], limit=100) => {
  const params = new URLSearchParams({offset: offset.toString(), limit: limit.toString()});
  namespaces.forEach(ns => {
    params.append('namespaces', ns);
  });
  return API.get<INote[]>(`/api/v1/notes/?${params}`);
};

export const pinNoteToEvent = (eventId: string, noteId: string, is_public=false) => API.post<{note: INote, pin_id: string, pinned_by: string, pinned_at: string, is_shared_namespace: boolean}>(`/api/v1/calendar_events/${eventId}/pin/NOTE/${noteId}/v2?is_public=${is_public}`);

export const unpinNoteToEvent = (eventId: string, noteId: string) => API.post(`/api/v1/calendar_events/${eventId}/unpin/NOTE/${noteId}`);

export const pinNoteToTag = (tagId: string, noteId: string) => API.post(`/api/v1/tags/${tagId}/pin/NOTE/${noteId}`);

export const patchNote = (noteId: string, content: string, title: string, data: INoteData, namespace?: string) => API.patch<INote>(`/api/v1/notes/${noteId}`, {content, title, data, namespace}).then(tracker.trackAfterRequest(TrackEventNames.EN, {noteId}));

export const fetchNote = (noteId: string) => API.get<INote>(`/api/v1/notes/${noteId}`);

export const deleteNote = (noteId: string) => API.delete(`/api/v1/notes/${noteId}`);