import { FunctionComponent, MouseEvent, ReactNode } from 'react';

import ClassifyIcon from 'components/icons/Classify';
import GDriveIcon from 'components/icons/GDrive';
import LoadingDots from 'components/LoadingDots';
import cx from 'classnames';
import { getContactName } from 'utils/strings';
import { getExternalLinkFromActivity } from 'utils/message_ctx';
import { IActivity, IDocument } from 'store/documents/selectors';
import { TrackEventNames, tracker } from 'utils/tracking';
import { useContact, useDocument } from 'hooks';

import { ActivityMessages, DocumentActivityMessages } from './message';

const NewClassifyFileShare = ({
  activity,
  documentId,
  showFileName=false,
  className='',
  trackingCtx='',
}: {activity: IActivity, documentId: string, showFileName?: boolean, className?: string, trackingCtx?: string}) => {
  const contactId = activity.data.actor.contact_id;
  const {
    contact,
  } = useContact(contactId);
  const {document} = useDocument(documentId);
  const externalLink = getExternalLinkFromActivity(activity, document?.mimetype);

  const open = (e: MouseEvent) => {
    e.stopPropagation();
    tracker.track(TrackEventNames.ELC, {documentId: documentId, from: trackingCtx || 'pin activity'});
    window.open(externalLink);
  };

  const dt = new Date(activity.timestamp);

  const date = dt.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  const time = dt.toLocaleTimeString('default', { hour: 'numeric', minute: '2-digit' });

  const createdByText = !contact ? <div className="activity-context-content--loading">loading <LoadingDots /></div> : <span className="activity-context-content--actor">{getContactName(contact)}</span>;

  const titleText = !document && showFileName ? <div className="activity-context-content--loading">{createdByText} created loading <LoadingDots /></div> : <div>{createdByText} created <div className="activity-context-content--link" onClick={open}>{document?.filename}</div></div>;

  return <div className={cx('activity-context-content--container', 'immutable', className)}>
    <ClassifyIcon className='activity-context--icon'/>
    <div className="activity-context-content-title--container">
      <div className="activity-context-content--subtitle">{date} at {time}</div>
      <div className="activity-context-content--title">{showFileName ? titleText : <div>Shared by {createdByText}</div>}</div>
    </div>
  </div>;
};


const NewDriveFileActivity = ({
  activity,
  documentId,
  showFileName=false,
  className='',
  trackingCtx='',
}: {activity: IActivity, documentId: string, showFileName?: boolean, className?: string, trackingCtx?: string}) => {
  const contactId = activity.data.actor.contact_id;
  const {
    contact,
  } = useContact(contactId);
  const {document} = useDocument(documentId);
  const externalLink = getExternalLinkFromActivity(activity, document?.mimetype);

  const open = (e: MouseEvent) => {
    e.stopPropagation();
    tracker.track(TrackEventNames.ELC, {documentId: documentId, from: trackingCtx || 'drive activity'});
    window.open(externalLink);
  };

  const dt = new Date(activity.timestamp);

  const date = dt.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  const time = dt.toLocaleTimeString('default', { hour: 'numeric', minute: '2-digit' });

  const createdByText = !contact ? <div className="activity-context-content--loading">loading <LoadingDots /></div> : <span className="activity-context-content--actor">{getContactName(contact)}</span>;

  const titleText = !document && showFileName ? <div className="activity-context-content--loading">{createdByText} created loading <LoadingDots /></div> : <div>{createdByText} created <div className="activity-context-content--link" onClick={open}>{document?.filename}</div></div>;

  return <div className={cx('activity-context-content--container', 'immutable', className)}>
    <GDriveIcon className='activity-context--icon'/>
    <div className="activity-context-content-title--container">
      <div className="activity-context-content--subtitle">{date} at {time}</div>
      <div className="activity-context-content--title">{showFileName ? titleText : <div>Created by {createdByText}</div>}</div>
    </div>
  </div>;
};

const DocRequiredWrapper = (Component: FunctionComponent<{activity: IActivity, documentId: string, showFileName?: boolean, defaultOpen: boolean, className?: string, trackingCtx?: string}>) => {
  const Wrapped = (props: {activity: IActivity, documentId?: string, showFileName?: boolean, defaultOpen: boolean, className?: string, trackingCtx?: string}): ReactNode => {
    if (!props.documentId) {
      return null;
    } else {
      return <Component documentId={props.documentId} activity={props.activity} defaultOpen={props.defaultOpen} showFileName={props.showFileName} className={props.className} trackingCtx={props.trackingCtx}/>;
    }
  };
  return (Wrapped as FunctionComponent<{activity: IActivity, document?: IDocument, defaultOpen: boolean, className?: string, showFileName?: boolean, trackingCtx?: string}>);
};


const ActivityTypeComponents: {[key: string]: FunctionComponent<{activity: IActivity, documentId?: string, showFileName?: boolean, defaultOpen: boolean, className?: string, trackingCtx?: string}>} = {
  NEW_FILE_SLACK: DocRequiredWrapper(DocumentActivityMessages),
  NEW_FILE_GDRIVE: DocRequiredWrapper(NewDriveFileActivity),
  NEW_FILE_COMMENT_GDRIVE: DocRequiredWrapper(DocumentActivityMessages),
  NEW_FILE_MENTION_GDRIVE: DocRequiredWrapper(DocumentActivityMessages),
  NEW_FILE_GMAIL: DocRequiredWrapper(DocumentActivityMessages),
  NEW_MESSAGE_GMAIL: ActivityMessages,
  NEW_MENTION_SLACK: ActivityMessages,
  CLASSIFY_NEW_FILE_SHARE: DocRequiredWrapper(NewClassifyFileShare),
};


export const Activity = ({
  activity,
  documentId,
  defaultOpen=false,
  showFileName=false,
  className='',
  trackingCtx='',
}: {activity: IActivity, documentId?: string, defaultOpen?: boolean, showFileName?: boolean, className?: string, trackingCtx?: string }) => {
  const Component = ActivityTypeComponents[activity.type];

  if (!Component) {
    console.warn(`Unknown component for activity type: ${activity.type}`);
    return null;
  }

  return <Component activity={activity} documentId={documentId} defaultOpen={defaultOpen} showFileName={showFileName} className={className} trackingCtx={trackingCtx}/>;
};


const ActivityContext = ({
  document,
  defaultOpen=false,
}: {document: IDocument, defaultOpen?: boolean}) => {

  return <div>
    {document.latest_activity.map(act => <Activity className="sidebar" activity={act} defaultOpen={defaultOpen} documentId={document.id} key={act.id} />)}
  </div>;
};

export default ActivityContext;