import { Alert } from 'evergreen-ui';
import { useEffect, useState } from 'react';

import GCalendarIcon from 'components/icons/GCalender';
import GDriveIcon from 'components/icons/GDrive';
import GmailIcon from 'components/icons/Gmail';
import GoogleButton from 'components/GoogleButton';
import OnboardingCardWithHeader from 'components/OnboardingCardWithHeader';
import { getGoogleOAuthURI } from 'api/auth';
import { putSetting } from 'api/user';
import { OnboardingStepNames, UserPropertyNames} from 'store/user/selector';

import './style.css';

const ConnectGoogleAccount = () => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    putSetting(UserPropertyNames.ONBOARDING_STEP, 'STRING', OnboardingStepNames.CONNECT_GOOGLE)
      .catch(() => console.error('Unable to save onboarding step'));
  });

  const openGoogle = async () => {
    setHasError(false);
    try {
      const {data} = await getGoogleOAuthURI();
      window.location.href = data.authorization_url;
    } catch (err) {
      setHasError(true);
    }
  };

  return (
    <OnboardingCardWithHeader headerText="Connect Google to your Classify account" className="connect-google-account-card">
      {hasError && <Alert intent="warning" title="Could not connect Google account. Please try again" marginBottom={24}/>}
      <div className="connect-google-account--google-icons">
        <GmailIcon className="connect-google-account--gmail-icon"/>
        <GCalendarIcon className="connect-google-account--gcal-icon"/>
        <GDriveIcon className="connect-google-account--gdrive-icon"/>
      </div>
      <div className="connect-google-account--description">In order to get the most out of Classify, you’ll need to connect your Google account. Your account information will always remain private.</div>
      <GoogleButton onClick={openGoogle}/>
    </OnboardingCardWithHeader>
  );
};

export default ConnectGoogleAccount;
