import { AddIcon, Button, EmptyState, MapIcon } from 'evergreen-ui';
import { useEffect, useState } from 'react';

import ActionItem from 'components/ActionItem';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import Sidebar from 'components/Sidebar';
import { createActionItem } from 'api/action_items';
import { notify } from 'store/notifications/slice';
import { store } from 'store';
import { fetchNextTaskTimeline, pushTaskToTimeline, receiveActionItems } from 'store/action_items/slice';
import { getAllParentTimelineActionItems, getNoMoreTasksInTimeline, getTaskTimelineIds, getTaskTimelineLoading, getTaskTimelineOffset } from 'store/action_items/selectors';
import { useAppDispatch, useAppSelector, useVisibility } from 'hooks';

import './style.css';

const TasksViewEmpty = ({onClickNew}: {onClickNew: () => void}) => (
  <EmptyState
    title="You have no tasks"
    icon={<MapIcon color="var(--color-yellow-3)" />}
    background="dark"
    iconBgColor="var(--color-purple-2)"
    description="Create your first task to have Classify supercharge your work day."
    primaryCta={<Button onClick={onClickNew}>Create a new rule</Button>}
  />
);

const LoadingIndicator = ({onEmptyClick}: {onEmptyClick: () => void}) => {
  const offset = useAppSelector(getTaskTimelineOffset);
  const loading = useAppSelector(getTaskTimelineLoading);
  const noMoreToLoad = useAppSelector(getNoMoreTasksInTimeline);
  const [isVisible, checkInView, currentElement] = useVisibility<HTMLDivElement>(100);
  const taskIds = useAppSelector(getTaskTimelineIds);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loading && isVisible && !noMoreToLoad) {
      fetchNextTaskTimeline({limit: 20})(dispatch, store.getState, {}).finally(() => {
        setTimeout(() => checkInView(), 100);
      });
    }

  }, [isVisible, loading, dispatch, offset, noMoreToLoad, checkInView]);

  const isEmpty = taskIds.length === 0;

  return <div ref={currentElement} className="document-timeline--loading">
    {loading && <div className="document-timeline--loading-content"><span>Loading {!isEmpty ? 'more' : ''} Tasks</span><LoadingDots /></div>}
    {!loading && noMoreToLoad && isEmpty && <TasksViewEmpty onClickNew={onEmptyClick} />}
  </div>;
};



const TasksView = () => {
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(getAllParentTimelineActionItems);
  const [loadingNewActionItem, setLoadingNewActionItem] = useState(false);

  const createNewActionItem = async (parentActionItemId: string | null=null) => {
    if (loadingNewActionItem) return;
    setLoadingNewActionItem(true);

    try {
      const {data} = await createActionItem({ title: '', description: '', data: { parentActionItemId }, status: 'NOT_DONE' });
      dispatch(receiveActionItems([data]));
      dispatch(pushTaskToTimeline(data));

      setLoadingNewActionItem(false);
    } catch (err) {
      setLoadingNewActionItem(false);
      notify({
        message: 'Could not create new task, please try again.',
        type: NotificationType.WARNING,
      })(dispatch);
    }
  };

  return (
    <div className="sidebar-sibling-content--container tasks-view--container">
      <Sidebar />
      <Button width={80} iconBefore={AddIcon} appearance="minimal" onClick={() => createNewActionItem(null)} isLoading={loadingNewActionItem} disabled={loadingNewActionItem}>Task</Button>
      {tasks?.map((actionItem, idx) => <ActionItem actionItemId={actionItem.id} prevActionItem={tasks[idx-1]?.id} key={actionItem.id} onEnterClick={createNewActionItem} />)}
      <LoadingIndicator onEmptyClick={() => createNewActionItem(null)} />
    </div>
  );
};


export default TasksView;