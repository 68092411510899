import Select from 'react-select';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Dialog, ErrorIcon, Switch, TextInputField } from 'evergreen-ui';

import { NotificationType } from 'store/notifications/selectors';
import { getAllTags } from 'store/tags/selectors';
import { getEnabledGateNames } from 'store/user/selector';
import { notify } from 'store/notifications/slice';
import { receiveRules } from 'store/rules/slice';
import { IRulePayload, patchRule, postRule } from 'api/rules';
import { RuleActions, RuleConditionSet, RuleTriggers, getRuleById } from 'store/rules/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';

import Condition, { DefaultConditionFieldOptions, ICondition } from './condition';
import { EmptyRule, RuleActionToOptions, RuleTriggerActionOptions, RuleTriggerFieldOptions, TriggerOptions } from './constants';


const hashCondition = (condition: ICondition, idx: number) => {
  const condValue = condition.value.join('__&__');
  return `field:${condition.field}_operator:${condition.operator}_value:${condValue || idx}`;
};


const conditionSetToConditionsList = (conditionSet: RuleConditionSet): ICondition[] => {
  return (conditionSet.conditions
    .map(cond => {
      if ('field' in cond) {
        return {field: cond.field, operator: cond.operator, value: cond.value};
      } else {
        // doesn't handle nested conditions for now
        return undefined;
      }
    })
    .filter(cond => !!cond) as ICondition[]);
};


const UNNAMED_TAG = '(unnamed tag)';

const RuleDialog = ({
  isOpen,
  onClose,
  editingRuleId='',
}: {
  isOpen: boolean,
  onClose: () => void,
  editingRuleId?: string,
}) => {
  const editingRule = useAppSelector(s => getRuleById(s, editingRuleId)) || EmptyRule;
  const [nameInput, setNameInput] = useState(editingRule.name);
  const [nameError, setNameError] = useState('');
  const tags = useAppSelector(getAllTags);
  const [triggerInput, setTriggerInput] = useState<{value: RuleTriggers, label: string} | null>(null);
  const workstreamOptions = editingRule.actions.actions.filter(act => act.action === RuleActions.ADD_TAG);
  const workstreamId = workstreamOptions.length !== 0 ? (workstreamOptions[0].action_specifier || null) : null;
  const tag = tags.find(t => t.id === workstreamId);
  const workstreamOption = tag ? {value: tag.id, label: tag.name} : null;
  const [selectedWorkstream, setSelectedWorkstream] = useState(workstreamOption);
  const [showWorkstreamDropdown, setShowWorkstreamDropdown] = useState(!!workstreamOption);
  const [triggerError, setTriggerError] = useState('');
  const [workstreamError, setWorkstreamError] = useState('');
  const [conditions, setConditions] = useState<ICondition[]>(conditionSetToConditionsList(editingRule.conditions));
  const [evalOption, setEvalOption] = useState<'ANY' | 'ALL'>(editingRule.conditions.evaluation);
  const editingActions: readonly {label: string, value: RuleActions}[] = editingRule.actions.actions.map(action => RuleActionToOptions[action.action]);
  const [actions, setActions] = useState(editingActions);
  const [saving, setSaving] = useState(false);
  const dispatch = useAppDispatch();
  const enabledGates = useAppSelector(getEnabledGateNames);

  useEffect(() => {
    if (!editingRuleId) {
      setNameInput('');
      setTriggerInput(null);
      setSelectedWorkstream(null);
      setConditions([]);
      setEvalOption('ALL');
      setActions([]);
      setShowWorkstreamDropdown(false);
    } else {
      setNameInput(editingRule.name);
      const triggerOptions = editingRule.triggers.map(trigger => TriggerOptions.find(({value}) => value === trigger)).filter(x => !!x)[0] || null;
      setTriggerInput(triggerOptions);
      const workstreamOptions = editingRule.actions.actions.filter(act => act.action === RuleActions.ADD_TAG);
      const workstreamId = workstreamOptions.length !== 0 ? (workstreamOptions[0].action_specifier || null) : null;
      const tag = tags.find(t => t.id === workstreamId);
      const workstreamOption = tag ? {value: tag.id, label: tag.name} : null;
      setShowWorkstreamDropdown(!!tag);
      setSelectedWorkstream(workstreamOption);
      setConditions(conditionSetToConditionsList(editingRule.conditions));
      const actions = editingRule.actions.actions.map(action => RuleActionToOptions[action.action]);
      setActions(actions);
    }
  }, [editingRuleId]);

  const onSetActions = (actions: readonly {label: string, value: RuleActions}[]) => {
    if (actions.some(a => a.value === RuleActions.ADD_TAG)) {
      setShowWorkstreamDropdown(true);
    } else {
      setShowWorkstreamDropdown(false);
    }
    setActions(actions);
  };

  const addEmptyCondition = () => {
    setConditions(conditions.concat([{field: 'NAME', operator: 'CONTAINS', value: []}]));
  };

  const validateName = () => {
    const newNameError = nameInput ? '' : 'Name is required';

    setNameError(newNameError);
    return !newNameError;
  };

  const validateTrigger = () => {
    const newTriggerError = triggerInput ? '' : 'Trigger is required';

    setTriggerError(newTriggerError);
    return !newTriggerError;
  };
  const validateWorkstream = () => {
    const newWorkstreamError = !selectedWorkstream || selectedWorkstream.label == UNNAMED_TAG ? 'Workstream is required' : '';

    setWorkstreamError(newWorkstreamError);
    return !newWorkstreamError;
  };


  const validate = () => {
    const nameIsValid = validateName();
    const triggerIsValid = validateTrigger();
    const workstreamIsValid = validateWorkstream();
    if (showWorkstreamDropdown) {
      return nameIsValid && triggerIsValid && workstreamIsValid;
    }
    return nameIsValid && triggerIsValid;
  };

  const removeCondition = (idx: number) => {
    setConditions(conditions.filter((_c, i) => i !== idx));
  };

  const updateCondition = (newCondition: ICondition, idx: number) => {
    setConditions(conditions.map((existingCondition, i) => i === idx ? newCondition : existingCondition));
  };

  const resetAndClose = () => {
    onClose();
    setSaving(false);
    setNameInput('');
    setNameError('');
    setTriggerInput(null);
    setTriggerError('');
    setWorkstreamError('');
    setConditions([]);
    setEvalOption('ALL');
  };

  const submit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!triggerInput) {
      return;
    }

    if (validate()) {
      setSaving(true);

      const apiCall = editingRuleId ? (payload: IRulePayload) => patchRule(editingRuleId, payload) : postRule;

      const ruleActions = (actions.map(option => {
        if (option.value === RuleActions.ADD_TAG) {
          if (selectedWorkstream === null) {
            return undefined;
          } else {
            return {action: option.value, action_specifier: selectedWorkstream.value};
          }
        }

        return {action: option.value};
      }).filter(x => !!x) as {action: string, action_specifier?: string}[]);

      try {
        const {data} = await apiCall({
          name: nameInput,
          place: 1,
          triggers: [(triggerInput as {label: string, value: string}).value],
          conditions: {
            evaluation: evalOption,
            conditions,
          },
          actions: {actions: ruleActions, stop_after_eval: false},
        });
        dispatch(receiveRules([data]));
        resetAndClose();
      } catch (err) {
        console.log(err);
        setSaving(false);
        notify({
          message: `Could not ${editingRuleId ? 'update' : 'create'} rule, please try again`,
          type: NotificationType.ERROR,
        })(dispatch);
      }
    }
  };

  return <Dialog
    isShown={isOpen}
    title="New rule"
    minHeightContent="65vh"
    topOffset={40}
    onCloseComplete={resetAndClose}
    confirmLabel="Save"
    isConfirmLoading={saving}
    onConfirm={() => submit()}
  >
    <form onSubmit={submit}>
      <TextInputField
        value={nameInput}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setNameInput(e.target.value)}
        isInvalid={!!nameError}
        label="Name"
        hint="Name your rule so you can easily find it later"
        placeholder="Rule name..."
        onBlur={validateName}
        validationMessage={nameError ? nameError : undefined}
      />

      <div className="new-rule-form--field-container">
        <div className="new-rule-form--field-title">Trigger</div>
        <div className="new-rule-form--field-description">
          Run this rule when I receive
          <Select
            onBlur={validateTrigger}
            className="new-rule-form--select-trigger"
            value={triggerInput}
            options={TriggerOptions.filter(option => !option.gate_required || enabledGates.has(option.gate_required))}
            onChange={(option: {label: string, value: RuleTriggers} | null) => setTriggerInput(option)}
            autoFocus={false}
          />
        </div>
        {triggerError && <div className="new-rule-form--field-error"><ErrorIcon color="var(--color-red-3)" />{triggerError}</div>}
      </div>

      <div className="new-rule-form--field-container">
        <div className="new-rule-form--field-title">Conditions</div>
        <div className="new-rule-form--field-description">If...</div>
        <div className="new-rule-conditions-eval--container">
          <div>Conditions needed</div>
          <div className="new-rule-conditions-eval--option">
            All <Switch checked={evalOption === 'ANY'} onChange={() => setEvalOption(evalOption === 'ALL' ? 'ANY' : 'ALL')} /> Any
          </div>
        </div>
        {conditions.map((cond: ICondition, idx: number) => (
          <Condition
            condition={cond}
            conjunction={evalOption === 'ALL' ? 'AND' : 'OR'}
            fieldOptions={(triggerInput ? RuleTriggerFieldOptions[triggerInput.value] : DefaultConditionFieldOptions).filter(op => !op.gate_required || enabledGates.has(op.gate_required))}
            place={idx}
            key={hashCondition(cond, idx)}
            onDeleteCondition={() => removeCondition(idx)}
            onChange={(cond: ICondition) => updateCondition(cond, idx)}
          />
        ))}
        <div className="new-rule-form--add-condition" onClick={addEmptyCondition}>Add a{conditions.length ? 'nother' : ''} condition</div>
      </div>

      <div className="new-rule-form--field-container">
        <div className="new-rule-form--field-title">Action</div>
        <Select className="new-rule-form--select" styles={{ menuPortal: (base) => ({ ...base, fontSize: '12px', zIndex: 9999 }) }} menuPortalTarget={document.body} options={triggerInput === null ? [] : (RuleTriggerActionOptions[triggerInput.value] || []).filter(op => !op.gate_required || enabledGates.has(op.gate_required))} value={actions} isMulti onChange={onSetActions} />
      </div>

      {showWorkstreamDropdown &&
      <label>
        <div className="new-rule-form--field-title">Workstream</div>
        <Select 
          onBlur={validateWorkstream}
          className="new-rule-form--select"
          styles={{ menuPortal: (base) => ({ ...base, fontSize: '12px', zIndex: 9999 }) }}
          menuPlacement='top'
          menuPortalTarget={document.body}
          options={tags.map(tag => ({label: tag.name || UNNAMED_TAG, value: tag.id}))}
          value={selectedWorkstream}
          onChange={(option: {label: string | undefined, value: string} | null) => setSelectedWorkstream(option)} />
        {workstreamError && <div className="new-rule-form--field-error"><ErrorIcon color="var(--color-red-3)" />{workstreamError}</div>}
      </label>
      }
    </form>
  </Dialog>;
};


export default RuleDialog;