import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IRule } from './selectors';

export interface RulesState {
  rulesById: {[ruleId: string]: IRule} | null,
}

const initialState: RulesState = {
  rulesById: null,
};

export const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    receiveRules: (state, action: PayloadAction<IRule[]>) => {
      action.payload.forEach(rule => {
        if (state.rulesById === null) {
          state.rulesById = {};
        }

        state.rulesById[rule.id] = rule;
      });
    },
  },
});

export const { receiveRules } = rulesSlice.actions;
export default rulesSlice.reducer;
