export const TrelloIcon = (
  { background_color = '', background_scaled_image = '', className = '', width = 48, height = 48 }: 
  { background_color?: string, background_scaled_image?: string, className?: string, width?: number, height?: number }) => {
  if (background_scaled_image) {
    return <div style={{ width, height, backgroundImage: `url(${background_scaled_image})` }} className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
        <path d={trelloCardsSVGPart} fill="#FFF" fillRule="evenodd"/>
      </svg>
    </div>;
  } 
  
  return <TrelloSvg background_color={background_color || '#0079BF'} className={className} width={width} height={height} />;
};

const trelloCardsSVGPart = 'm26 12.992C26 31.37 31.373 26 37.997 26h37.006C81.63 26 87 31.37 87 37.992v113.516c0 6.623-5.373 11.992-11.997 11.992H37.997C31.37 163.5 26 158.13 26 151.508V37.992zm87 .014C113 31.376 118.373 26 124.997 26h37.006C168.63 26 174 31.375 174 38.006v63.488c0 6.63-5.373 12.006-11.997 12.006h-37.006c-6.626 0-11.997-5.375-11.997-12.006V38.006z';

const TrelloSvg = ({ background_color, className, width, height }: 
  { background_color?: string, className?: string, width?: number, height?: number }) => 
  (<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200" className={className}>
    <path d={`M0 25C0 11.194 11.2 0 25 0h150c13.806 0 25 11.2 25 25v150c0 13.806-11.2 25-25 25H25c-13.807 0-25-11.2-25-25V25${trelloCardsSVGPart}`} fill={background_color} fillRule="evenodd"/>
  </svg>);