import { BrowserRouter } from 'react-router-dom';
import { Integrations } from '@sentry/tracing';
import LogRocket from 'logrocket';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, defaultTheme } from 'evergreen-ui';
import * as Sentry from '@sentry/react';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import App from 'App';
import { store } from 'store';
import { HEAP_APP_ID, LOGROCKET_APP_ID, SENTRY_DSN, SENTRY_SAMPLE_RATE } from 'constants/resources';
import './index.css';

const classify_release = `classify-web@${process.env.npm_package_version}`;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  environment: process.env.REACT_APP_ENV,
  release: classify_release,
});

// eslint-disable-next-line
heap.load(HEAP_APP_ID);

if (LOGROCKET_APP_ID !== null) {
  LogRocket.init(LOGROCKET_APP_ID, {
    release: classify_release,
  });
}

const evergreenTheme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    Spinner: {
      ...defaultTheme.components.Spinner,
      baseStyle: {
        ...defaultTheme.components.Spinner.baseStyle,
        color: 'var(--color-purple-5)',
      },
    },
    Button: {
      ...defaultTheme.components.Button,
      appearances: {
        ...defaultTheme.components.Button.appearances,
        minimal: {
          ...defaultTheme.components.Button.appearances.minimal,
          color: 'var(--color-purple-5)',
        },
        primary: {
          ...defaultTheme.components.Button.appearances.primary,
          color: 'white',
          backgroundColor: 'var(--color-purple-5)',
          _hover: {
            ...defaultTheme.components.Button.appearances.primary._hover,
            backgroundColor: 'var(--color-purple-6)'
          },
          _active: {
            ...defaultTheme.components.Button.appearances.primary._active,
            backgroundColor: 'var(--color-purple-7)'
          },
          _disabled: {
            ...defaultTheme.components.Button.appearances.primary._disabled,
            backgroundColor: 'var(--color-purple-3)'
          },
        },
      },
    },
    Checkbox: {
      ...defaultTheme.components.Checkbox,
      appearances: {
        ...defaultTheme.components.Checkbox.appearances,
        default: {
          ...defaultTheme.components.Checkbox.appearances.default,
          color: 'white',
          background: 'var(--color-purple-5)',
          _hover: {
            ...defaultTheme.components.Checkbox.appearances.default._hover,
            background: 'var(--color-purple-3)',
            color: 'var(--color-purple-3)',
          },
          _checkedHover: {
            ...defaultTheme.components.Checkbox.appearances.default._checkedHover,
            background: 'var(--color-purple-5)',
            borderColor: 'var(--color-purple-4)',
          },
          _checked: {
            ...defaultTheme.components.Checkbox.appearances.default._checked,
            background: 'var(--color-purple-5)',
          },
          _active: {
            ...defaultTheme.components.Checkbox.appearances.default._active,
            background: 'var(--color-purple-6)'
          },
          _disabled: {
            ...defaultTheme.components.Checkbox.appearances.default._disabled,
            background: 'var(--color-purple-3)'
          },
        },
      },
    }
  }
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <ThemeProvider value={evergreenTheme}>
          <App />
        </ThemeProvider>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
);
