import {RootState} from 'store';

const root = (state: RootState) => state.notes;

const getNotesById = (state: RootState) => root(state).notesById;
const getNote = (state: RootState, noteId: string) => getNotesById(state)[noteId];
export const fetchingNoteFailed = (state: RootState, noteId: string) => (getNote(state, noteId) || {}).fetchFailed;
export const isFetchingNote = (state: RootState, noteId: string) => (getNote(state, noteId) || {}).isFetching;
export const getNoteById = (state: RootState, noteId: string) => (getNote(state, noteId) || {}).data;
export const getNoteIsPublic = (state: RootState, noteId: string) => getNoteById(state, noteId)?.namespace.startsWith('tea:'); //TODO: make & use a namespace utility function

const getDirtyNotes = (state: RootState) => root(state).dirtyNoteDataById;
const getDirtyNoteById = (state: RootState, noteId: string) => getDirtyNotes(state)[noteId] || {};
export const getNotePatchTimeoutId = (state: RootState, noteId: string) => getDirtyNoteById(state, noteId).saveTimeoutId;
export const getDirtyNoteContent = (state: RootState, noteId: string) => getDirtyNoteById(state, noteId).content;
export const getDirtyNoteTitle = (state: RootState, noteId: string) => getDirtyNoteById(state, noteId).title;
const getDirtyNoteSaving = (state: RootState, noteId: string) => getDirtyNoteById(state, noteId).saving;
const getDirtyNoteSaveFailed = (state: RootState, noteId: string) => getDirtyNoteById(state, noteId).saveFailed;

export const getEditingNoteTitle = (state: RootState, noteId: string) => {
  const dirtyTitle = getDirtyNoteTitle(state, noteId);
  const note = getNoteById(state, noteId);
  if (typeof dirtyTitle === 'string') {
    return dirtyTitle;
  }
  return note?.title || '';
};

export const getEditingNoteContent = (state: RootState, noteId: string) => {
  const dirtyContent = getDirtyNoteContent(state, noteId);
  const note = getNoteById(state, noteId);
  if (typeof dirtyContent === 'string') {
    return dirtyContent;
  }
  return note?.content || '';
};

export const getSavingNote = (state: RootState, noteId: string) => getDirtyNoteSaving(state, noteId) || false;
export const getNoteSaveFailed = (state: RootState, noteId: string) => getDirtyNoteSaveFailed(state, noteId) || false;


const getNotesTimeline = (state: RootState) => root(state).notesTimeline;
export const getNoteTimelineNeverLoaded = (state: RootState) => getNotesTimeline(state).timelineOffset === 0;
export const getNoteTimelineOffset = (state: RootState) => getNotesTimeline(state).timelineOffset;
export const getNoteTimelineLoading = (state: RootState) => getNotesTimeline(state).isFetching;
export const getNoteTimelineIds = (state: RootState) => getNotesTimeline(state).noteIds;
export const getNoMoreNotesInTimeline = (state: RootState) => getNotesTimeline(state).noMoreScrollback;