import { Link } from 'react-router-dom';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { Alert, Button, TextInput } from 'evergreen-ui';
import { ChangeEvent, FocusEvent, FormEvent, MouseEvent, useEffect, useState } from 'react';

import OnboardingCardWithHeader from 'components/OnboardingCardWithHeader';
import { newPasswordUpdate } from 'api/auth';
import { setToken } from 'utils/auth';

const INVALID_TOKEN_ERROR = {
  title: 'Invalid request',
  children: <div className="onboarding-alert-children">Please try requesting  <Link to="/reset-pw" className="login-link">another reset email</Link>.</div>
};

const RESET_PASSWORD_FAILED_ERROR = {
  title: 'Failed to reset password',
  children: 'Please try again.'
};

const SetNewPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<typeof INVALID_TOKEN_ERROR | typeof RESET_PASSWORD_FAILED_ERROR | null>(null);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const token = new URLSearchParams(window.location.search).get('reset_token');
  const buttonDisabled = !token || !password || isPasswordInvalid || !passwordsMatch;

  useEffect(() => {
    if (!token) {
      setError(INVALID_TOKEN_ERROR);
    }
  }, [token]);

  const onSubmitPasswordUpdate = (e: MouseEvent<HTMLButtonElement> & FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return;
    }
    if (token) {
      setLoading(true);
      newPasswordUpdate(token, password)
        .then(({ data }) => {
          setToken(data.access_token);
          setTimeout(() => window.location.href = '/', 200);
        })
        .catch(() => {
          setError(RESET_PASSWORD_FAILED_ERROR);
        })
        .finally(() => setLoading(false));
    }
  };

  const onCheckPasswordValidity = (e: FocusEvent<HTMLInputElement>) => {
    setIsPasswordInvalid(!e.target.value || e.target.value.length < 6);
  };

  const onCheckPasswordMatch = debounce((password, confirmPassword) => {
    setPasswordsMatch(password === confirmPassword);
  }, 500);


  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (confirmPassword) {
      onCheckPasswordMatch(e.target.value, confirmPassword);
    }
  };

  const onChangeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    onCheckPasswordMatch(password, e.target.value);
  };

  return (
    <OnboardingCardWithHeader headerText="Reset Password">
      {error && <Alert intent="warning" title={error.title} marginBottom={24}>{error.children}</Alert>}
      <form onSubmit={onSubmitPasswordUpdate}>
        <label className="login-input--label" htmlFor="password">New Password</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': isPasswordInvalid })} width="100%" height="40px" onBlur={onCheckPasswordValidity} type="password" id="password" isInvalid={isPasswordInvalid} value={password} onChange={onChangePassword} />
        {isPasswordInvalid && <div className="login-input--error">Password must be at least 6 characters</div>}
        <label className="login-input--label" htmlFor="password">Confirm New Password</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': !passwordsMatch })} width="100%" height="40px" type="password" id="confirm-password" value={confirmPassword} isInvalid={!passwordsMatch} onChange={onChangeConfirmPassword} />
        {!passwordsMatch && <div className="login-input--error">Passwords must match</div>}
        <Button appearance="primary" className="login-submit-button" type="submit" disabled={buttonDisabled} onClick={onSubmitPasswordUpdate} isLoading={loading}>Reset</Button>
      </form>
      <div className="login-card-footer">
        <div>Don’t have an account? <Link className="login-link" to="/signup">Sign up</Link></div>
      </div>
    </OnboardingCardWithHeader>
  );
};

export default SetNewPassword;
