import { ConfluenceIcon } from 'components/icons/Confluence';
import { FigmaIcon } from 'components/icons/Figma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GitHubIcon } from 'components/icons/GitHub';
import JiraIcon from 'components/icons/Jira';
import { LinkSiteName } from 'store/links/slice';
import { MiroIcon } from 'components/icons/Miro';
import { ReactElement } from 'react';
import SalesforceIcon from 'components/icons/SalesForce';
import { TrelloIcon } from 'components/icons/Trello';
import { TwitterIcon } from 'components/icons/Twitter';
import { extractHostName } from 'utils/strings';
import { faLink } from '@fortawesome/free-solid-svg-icons';

interface LinkIconProps {
  url: string | undefined;
  width?: number;
  height?: number;
  className?: string;
  color?: string;
  iconType?: LinkSiteName | string;
  background_color?: string;
  background_scaled_image?: string;
}

export const LinkIcon = ({
  url,
  width = 15,
  height = 18,
  className = 'link-card-pill--link-icon',
  color,
  iconType,
  background_color,
  background_scaled_image,
}: LinkIconProps) => {
  const props = { width, height, className };
  if (iconType === 'Trello Board') {
    const minDimension = Math.min(width, height);
    return <TrelloIcon width={minDimension} height={minDimension} background_color={background_color} background_scaled_image={background_scaled_image} />;
  } else if (iconType === 'Trello') {
    return <TrelloIcon {...props} />;
  }else if (iconType === 'Figma') {
    return <FigmaIcon {...props} />;
  } else if (iconType === 'Salesforce.com') {
    return <SalesforceIcon {...props} />;
  } else if (iconType === 'Twitter') {
    return <TwitterIcon {...props} />;
  } else if (iconType === 'https://miro.com/') {
    return <MiroIcon {...props} />;
  }

  const iconMapping : Record<string, ReactElement> = {
    'confluence' : <ConfluenceIcon {...props} />,
    'atlassian' : <JiraIcon {...props} />,
    'jira' : <JiraIcon {...props} />,
    'github' : <GitHubIcon {...props} />,
  };

  const FallbackIcon = <FontAwesomeIcon {...props} icon={faLink} color={color} />;

  if (!url) {
    return FallbackIcon;
  }
  
  const hostName = extractHostName(url.toLowerCase());
  const domains = Object.keys(iconMapping);
  const match = domains.find(domain => hostName.includes(domain));
  return match ? iconMapping[match] : FallbackIcon;
};
