import cx from 'classnames';
import { ReactNode, useState } from 'react';

import './style.css';


interface ITab {
  title: ReactNode;
  key?: string;
  content: ReactNode;
}

interface ITabProps {
  tabs: ITab[];
}


const TabHeader = ({ title, tabName, active, onClick }: { title: ReactNode, tabName?: string, active: boolean, onClick: (title: ReactNode) => void }) => (
  <div className={cx('entity-tab--option', { active })} onClick={() => onClick(tabName || title)}>
    <div className="entity-tab--title">{title}</div>
    <div className="entity-tab--underline" />
  </div>
);


const EntityTabs = ({ tabs }: ITabProps) => {
  const [activeTab, setActiveTab] = useState(tabs[0].key || tabs[0].title);

  return (
    <>
      <div className="entity-tab--container">
        {tabs.map((tab, idx) => <TabHeader title={tab.title} tabName={tab.key} key={tab.key || tab.title?.toString() || idx} onClick={(t) => setActiveTab(t)} active={tab.title === activeTab || tab.key == activeTab} />)}
      </div>
      {tabs.find(tab => tab.title === activeTab || tab.key === activeTab)?.content}
    </>
  );
};


export default EntityTabs;