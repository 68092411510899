import { Redirect, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { AppQueryParams } from 'constants/app';
import LoadingDots from 'components/LoadingDots';
import { fetchEventFromGcal } from 'api/calendar';
import { useAppDispatch } from 'hooks';
import { TrackEventNames, tracker } from 'utils/tracking';
import { focusOnEvent, receiveEvents } from 'store/calendar/slice';


// this component is a wrapper for redirecting users
// when they enter classiy fromm a "direct link" such
// as the google chrome extension. The primary job
// of this component is to track the link in heap and
// redirect the user
const ExternalLinkView = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(true);
  const [viewDate, setViewDate] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const eventId = params.get('eventId');
  const calendarId = params.get('calendarId');

  useEffect(() => {
    if (!eventId || !calendarId) {
      return;
    }

    tracker.track(TrackEventNames.ECFGC, { eventId });
    fetchEventFromGcal(eventId, calendarId)
      .then(({ data }) => {
        dispatch(receiveEvents([data]));
        dispatch(focusOnEvent(data.id));
        setViewDate(data.start_dt);
      })
      // TODO: capture sentry error?
      .catch((e) => console.warn(e))
      .finally(() => setLoading(false));
  }, []);

  if (loading && eventId && calendarId) {
    return <div className="app-bootstrap--loading">Loading<LoadingDots/></div>;
  }

  const newParams = new URLSearchParams();
  if (viewDate) {
    newParams.set(AppQueryParams.CALENDAR_VIEW_DATE, viewDate);
  }

  return <Redirect to={`/?${newParams}`}/>;
};

export default ExternalLinkView;