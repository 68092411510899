import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from 'store';
import { fetchContact, fetchContactFromHandle } from 'api/contacts';

import { IContact, isFetchingContact, isFetchingContactByHandle } from './selectors';

interface ISidebarState {
  isOpen: boolean;
  contactId: string | null;
}

interface IAsyncContactByHandle {
  contactId: string | null;
  isFetching: boolean;
  fetchFailed: boolean;
}

interface IAsyncContact {
  data: IContact | null;
  isFetching: boolean;
  lastReceived: Date | null;
  fetchFailed: boolean;
}

export interface ContactState {
  contactsById: {[contactId: string]: IAsyncContact} | null,
  contactsFiltered: {[filter: string]: Array<IContact>}, 
  contactsByHandle: {[sourceHandle: string]: IAsyncContactByHandle},
  sidebar: ISidebarState,
  oldestContactFetch?: Date,
}

const initialState: ContactState = {
  contactsByHandle: {},
  contactsById: null,
  contactsFiltered: {},
  sidebar: {
    isOpen: false,
    contactId: null,
  },
  oldestContactFetch: undefined,
};

export const fetchContactById = createAsyncThunk<IContact | undefined, string, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'contacts/fetchById',
  async (contactId: string, {getState, dispatch}) => {
    if (!isFetchingContact(getState(), contactId)) {
      dispatch(setFetchingContact({ contactId, isFetching: true}));
      const {data} = await fetchContact(contactId);
      return data;
    }
  }
);


export const fetchContactByHandle = createAsyncThunk<IContact | undefined, {source: string, handle: string}, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'contacts/fetchByHandle',
  async ({source, handle}, {getState, dispatch}) => {
    if (!isFetchingContactByHandle(getState(), source, handle)) {
      dispatch(setFetchingContactByHandle({ source, handle, isFetching: true}));
      const {data} = await fetchContactFromHandle(source, handle);
      return data;
    }
  }
);


export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setFetchingContact: (state, action: PayloadAction<{contactId: string, isFetching: boolean}>) => {
      if (!state.contactsById) {
        state.contactsById = {};
      }

      if (!state.contactsById[action.payload.contactId]) {
        state.contactsById[action.payload.contactId] = {
          data: null,
          lastReceived: null,
          isFetching: action.payload.isFetching,
          fetchFailed: false,
        };
      } else {
        state.contactsById[action.payload.contactId].isFetching = action.payload.isFetching;
      }
    },

    setFetchingContactByHandle: (state, action: PayloadAction<{source: string, handle: string, isFetching: boolean}>) => {
      const sourceHandle = `${action.payload.source}-${action.payload.handle}`;
      if (!state.contactsByHandle[sourceHandle]) {
        state.contactsByHandle[sourceHandle] = {
          contactId: null,
          isFetching: action.payload.isFetching,
          fetchFailed: false,
        };
      } else {
        state.contactsByHandle[sourceHandle].isFetching = action.payload.isFetching;
      }
    },

    receiveContacts: (state, action: PayloadAction<{data: IContact[], filter?: string}>) => {
      if (action.payload.filter) {
        const contactsFiltered = state.contactsFiltered[action.payload.filter] || [];
        action.payload.data.forEach(contact => {
          if (!contactsFiltered.filter(x => x.id === contact.id).length) {
            contactsFiltered.push(contact);
          }
        });
        state.contactsFiltered[action.payload.filter] = contactsFiltered;
      }
      action.payload.data.forEach(contact => {
        if (!state.contactsById) {
          state.contactsById = {};
        }

        if (!state.contactsById[contact.id]) {
          state.contactsById[contact.id] = {
            data: contact,
            lastReceived: new Date(),
            isFetching: false,
            fetchFailed: false,
          };
        } else {
          state.contactsById[contact.id].data = contact;
          state.contactsById[contact.id].lastReceived = new Date();
        }
      });
    },

    removeAndAddContacts: (state, action: PayloadAction<{removeIds: string[], addContacts: IContact[]}>) => {
      action.payload.removeIds.forEach(id => {
        if (state.contactsById === null) {
          state.contactsById = {};
        }

        delete state.contactsById[id];
      });

      action.payload.addContacts.forEach(contact => {
        if (state.contactsById === null) {
          state.contactsById = {};
        }

        state.contactsById[contact.id] = {
          data: contact,
          lastReceived: new Date(),
          isFetching: false,
          fetchFailed: false,
        };
      });
    },

    openSideBarToContact: (state, action: PayloadAction<string>) => {
      state.sidebar.isOpen = true;
      state.sidebar.contactId = action.payload;
    },

    closeSidebar: (state) => {
      state.sidebar.isOpen = false;
    },

    setOldestContactFetch: (state, action: PayloadAction<Date>) => {
      state.oldestContactFetch = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchContactById.rejected, (state, action) => {
      if (!state.contactsById) {
        state.contactsById = {};
      }

      const contactId = action.meta.arg;

      if (!state.contactsById[contactId]) {
        state.contactsById[contactId] = {
          data: null,
          isFetching: false,
          lastReceived: null,
          fetchFailed: true,
        };
      } else {
        state.contactsById[contactId].fetchFailed = true;
      }
    });

    builder.addCase(fetchContactById.fulfilled, (state, action) => {
      if (!state.contactsById) {
        state.contactsById = {};
      }

      if (!action.payload) {
        // thunk short-circuited because another fetch is in flight
        return;
      }

      state.contactsById[action.payload.id] = {
        data: action.payload,
        isFetching: false,
        lastReceived: new Date(),
        fetchFailed: false,
      };
    });

    builder.addCase(fetchContactByHandle.rejected, (state, action) => {
      const {source, handle} = action.meta.arg;
      const sourceHandle = `${source}-${handle}`;

      if (!state.contactsByHandle[sourceHandle]) {
        state.contactsByHandle[sourceHandle] = {
          contactId: null,
          isFetching: false,
          fetchFailed: true,
        };
      } else {
        state.contactsByHandle[sourceHandle].fetchFailed = true;
      }
    });

    builder.addCase(fetchContactByHandle.fulfilled, (state, action) => {
      const {source, handle} = action.meta.arg;
      const sourceHandle = `${source}-${handle}`;

      if (!state.contactsById) {
        state.contactsById = {};
      }

      if (!action.payload) {
        // thunk short-circuited because another fetch is in flight
        return;
      }

      state.contactsByHandle[sourceHandle].contactId = action.payload.id;
      state.contactsByHandle[sourceHandle].isFetching = false;
      state.contactsByHandle[sourceHandle].fetchFailed = false;

      state.contactsById[action.payload.id] = {
        data: action.payload,
        isFetching: false,
        lastReceived: new Date(),
        fetchFailed: false,
      };
    });

  },
});

export const { receiveContacts, removeAndAddContacts, closeSidebar, openSideBarToContact, setOldestContactFetch, setFetchingContact, setFetchingContactByHandle } = contactsSlice.actions;
export default contactsSlice.reducer;
