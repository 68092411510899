import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IActionItem } from 'store/action_items/selectors';
import { IContact } from 'store/contacts/selectors';
import { receiveActionItems } from 'store/action_items/slice';
import { receiveActivities } from 'store/activities/slice';
import { receiveContacts } from 'store/contacts/slice';
import { receiveDocuments } from 'store/documents/slice';
import { AppDispatch, RootState } from 'store';
import { IActivity, IDocument } from 'store/documents/selectors';
import { ILink, receiveLinks } from 'store/links/slice';
import { IMinimalCalendarEvent, ITag } from 'store/calendar/selectors';
import { INote, receiveNotes } from 'store/notes/slice';
import { RelationTypes, TagRelationEntityTypes, TagTimelineView } from 'constants/app';
import { deleteTag, fetchTagRelation, fetchTagTimeline } from 'api/tags';
import { receiveEvents, removeTagFromAllEvents } from 'store/calendar/slice';

import { ITagEntry, getEarlistTimelineEpochForTag, getTagRelationId, getTimelineForTagLoading, getTimelineForTagNeverFetched } from './selectors';


interface IAsyncTagTimeline {
  tagId: string;
  earliestTimelineEpochFetch: number | null;
  noMoreScrollback: boolean;
  isFetching: boolean;
  entries: ITagEntry[];
}

export interface TagState {
  tagsById: {[tagId: string]: ITag},
  timelineByTagView: {[tagId: string]: IAsyncTagTimeline},
}

export interface ITagRelationWSPayload {
  tag_relation_id: string,
  tag_relation_tag_id: string,
  tag_relation_timestamp: string,
  tag_relation: RelationTypes,
  tag_relation_deleted_at: string | null
}

const initialState: TagState = {
  tagsById: {},
  timelineByTagView: {},
};

export const softDeleteTag = createAsyncThunk<string, string, {
  dispatch: AppDispatch,
  state: RootState
}>(
  'tags/softDeleteTag',
  async(tagId, {dispatch}) => {

    const {data} = await deleteTag(tagId);

    if (data.status == 'OK') {
      dispatch(removeTag({tagId}));  // TODO: does this belong in an extraReducer that handles this thunk?
      dispatch(removeTagFromAllEvents({tagId}));
    }

    return data.status;

  }
);

export const wsFetchTagRelation = createAsyncThunk<ITagEntry | undefined, ITagRelationWSPayload, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'tags/wsFetchTagRelation',
  async ({tag_relation_id: relationId, tag_relation_tag_id: tagId, tag_relation: tagRelation, tag_relation_deleted_at: deletedAt}, {getState, dispatch}) => {
    // we always want to fetch tag relations for documents or links, so they update their tags in the UI
    // when we render them as DocumentCard or LinkCard
    const isLinkOrDoc = relationId.includes('DOCUMENT') || relationId.includes('LINK');

    const activityTimelineNeverFetched = getTimelineForTagNeverFetched(getState(), tagId, TagTimelineView.ACTIVITY);
    const notesTimelineNeverFetched = getTimelineForTagNeverFetched(getState(), tagId, TagTimelineView.NOTES);
    const pinsTimelineNeverFetched = getTimelineForTagNeverFetched(getState(), tagId, TagTimelineView.PINS);
    const tasksTimelineNeverFetched = getTimelineForTagNeverFetched(getState(), tagId, TagTimelineView.ACTION_ITEMS);
    const anyTimelineFetched = [activityTimelineNeverFetched, notesTimelineNeverFetched, pinsTimelineNeverFetched, tasksTimelineNeverFetched].some(x => !x);

    if (tagRelation === RelationTypes.DISASSOCIATED || deletedAt !== null) {
      dispatch(removeTagRelation({ tagId, tagRelationId: relationId, view: TagTimelineView.ACTIVITY }));
      dispatch(removeTagRelation({ tagId, tagRelationId: relationId, view: TagTimelineView.PINS }));
      dispatch(removeTagRelation({ tagId, tagRelationId: relationId, view: TagTimelineView.NOTES }));
      dispatch(removeTagRelation({ tagId, tagRelationId: relationId, view: TagTimelineView.ACTION_ITEMS }));
      return;
    }

    // if any timelines for a tag haven't been loaded
    // AND it's not a link/doc, don't fetch
    if (!anyTimelineFetched && !isLinkOrDoc) return;

    const {data} = await fetchTagRelation(relationId);

    if (data.document) {
      dispatch(receiveDocuments([data.document]));
    } else if (data.link) {
      dispatch(receiveLinks([data.link]));
    } else if (data.activity) {
      dispatch(receiveActivities([data.activity]));
    } else if (data.contact) {
      dispatch(receiveContacts({data: [data.contact]}));
    } else if (data.note) {
      dispatch(receiveNotes([data.note]));
    } else if (data.calendar_event) {
      dispatch(receiveContacts({data: data.calendar_event.attendees}));
      dispatch(receiveEvents([data.calendar_event]));
    } else if (data.action_item) {
      dispatch(receiveActionItems([data.action_item]));
    }
    return data;
  }
);


const timelineViewToEntityTypesFilter = {
  [TagTimelineView.ACTIVITY]: [],
  [TagTimelineView.NOTES]: [TagRelationEntityTypes.NOTE],
  [TagTimelineView.PINS]: [TagRelationEntityTypes.LINK, TagRelationEntityTypes.DOCUMENT],
  [TagTimelineView.ACTION_ITEMS]: [TagRelationEntityTypes.ACTION_ITEM],
};

export const fetchNextTagTimeline = createAsyncThunk<ITagEntry[] | undefined, {tagId: string, view: TagTimelineView, limit?: number}, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'tags/fetchNextTagTimeline',
  async ({tagId, view, limit=10}, {getState, dispatch}) => {
    if (!getTimelineForTagLoading(getState(), tagId, view)) {
      const before = getEarlistTimelineEpochForTag(getState(), tagId, view);
      dispatch(tagsSlice.actions.setTimelineLoading({ isFetching: true, tagId, view }));
      const {data} = await fetchTagTimeline(tagId, before, timelineViewToEntityTypesFilter[view], limit);
      const documents: {[key: string]: IDocument} = {};
      const links: {[key: string]: ILink} = {};
      const calendarEvents: {[key: string]: IMinimalCalendarEvent} = {};
      const activities: {[key: string]: IActivity} = {};
      const notes: {[key: string]: INote} = {};
      const contacts: {[key: string]: IContact} = {};
      const actionItems: {[key: string]: IActionItem} = {};
      data.items.forEach((item: {document?: IDocument, link?: ILink, contact?: IContact, note?: INote, activity?: IActivity, calendar_event?: IMinimalCalendarEvent, action_item?: IActionItem}) => {
        if (item.document) {
          item.document.latest_activity.forEach(act => activities[act.id] = act);
          documents[item.document.id] = item.document;
        } else if (item.link) {
          links[item.link.id] = item.link;
        } else if (item.activity) {
          activities[item.activity.id] = item.activity;
        } else if (item.note) {
          notes[item.note.id] = item.note;
        } else if (item.contact) {
          contacts[item.contact.id] = item.contact;
        } else if (item.calendar_event) {
          item.calendar_event.attendees.forEach(contact => contacts[contact.id] = contact);
          calendarEvents[item.calendar_event.id] = item.calendar_event;
        } else if (item.action_item) {
          actionItems[item.action_item.id] = item.action_item;
        }
      });
      if (Object.values(documents).length) dispatch(receiveDocuments(Object.values(documents)));
      if (Object.values(links).length) dispatch(receiveLinks(Object.values(links)));
      if (Object.values(contacts).length) dispatch(receiveContacts({data: Object.values(contacts)}));
      if (Object.values(activities).length) dispatch(receiveActivities(Object.values(activities)));
      if (Object.values(notes).length) dispatch(receiveNotes(Object.values(notes)));
      if (Object.values(calendarEvents).length) dispatch(receiveEvents(Object.values(calendarEvents)));
      if (Object.values(actionItems).length) dispatch(receiveActionItems(Object.values(actionItems)));
      return data.items;
    }
  }
);

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    receiveTags: (state, action: PayloadAction<ITag[]>) => {
      action.payload.forEach(tag => {
        state.tagsById[tag.id] = tag;
      });
    },

    setTimelineLoading: (state, action: PayloadAction<{tagId: string, isFetching: boolean, view: TagTimelineView}>) => {
      const {tagId, view, isFetching} = action.payload;

      const timeline = `tag:${tagId}.${view}`;

      if (!state.timelineByTagView[timeline]) {
        state.timelineByTagView[timeline] = {
          tagId,
          earliestTimelineEpochFetch: Date.now(),
          isFetching,
          noMoreScrollback: false,
          entries: [],
        };
      } else {
        state.timelineByTagView[timeline].isFetching = isFetching;
      }
    },

    removeTagTimelineItem: (state, action: PayloadAction<{tagRelationId: string, tagId: string, view: TagTimelineView}>) => {
      const {tagRelationId, tagId, view} = action.payload;
      const timeline = `tag:${tagId}.${view}`;
      state.timelineByTagView[timeline].entries = state.timelineByTagView[timeline].entries.filter(({ tag_relation }) => (
        tagRelationId !== getTagRelationId(tag_relation)
      ));
    },

    receiveTagTimelineItems: (state, action: PayloadAction<{timelineItems: ITagEntry[], tagId: string, view: TagTimelineView}>) => {
      const {timelineItems, tagId, view} = action.payload;
      const timeline = `tag:${tagId}.${view}`;
      const existingTagRelationIds = new Set(state.timelineByTagView[timeline].entries.map(({ tag_relation }) => getTagRelationId(tag_relation)));

      timelineItems.forEach(maybeNewItem => {
        const uniqueItemId = getTagRelationId(maybeNewItem.tag_relation);
        const alreadyExists = existingTagRelationIds.has(uniqueItemId);

        if (!alreadyExists) {
          state.timelineByTagView[timeline].entries.push(maybeNewItem);
        }
      });
    },

    removeTagRelation: (state, action: PayloadAction<{tagId: string, view: TagTimelineView, tagRelationId: string}>) => {
      const {tagRelationId, tagId, view} = action.payload;
      const timeline = `tag:${tagId}.${view}`;
      if (state.timelineByTagView[timeline]) {
        state.timelineByTagView[timeline].entries = state.timelineByTagView[timeline].entries.filter(({ tag_relation }) => getTagRelationId(tag_relation) !== tagRelationId);
      }
    },

    removeTag: (state, action: PayloadAction<{tagId: string}>) => {
      const {tagId} = action.payload;

      for(const view in TagTimelineView) {
        const timeline = `tag:${tagId}.${view}`;
        if (state.timelineByTagView[timeline]) {
          delete state.timelineByTagView[timeline];
        }
      }

      if (state.tagsById[tagId]) {
        delete state.tagsById[tagId];
      }

    },

    removeNoteTagRelation: (state, action: PayloadAction<{ tagId: string, noteId: string }>) => {
      const {tagId, noteId} = action.payload;
      const noteTag = state.timelineByTagView[`tag:${tagId}.${TagTimelineView.NOTES}`];
      if (noteTag) {
        noteTag.entries = noteTag.entries.filter(note => note.tag_relation.entity_id !== noteId);
      }
      const activityTag = state.timelineByTagView[`tag:${tagId}.${TagTimelineView.ACTIVITY}`];
      if (activityTag) {
        activityTag.entries = activityTag.entries.filter(entry => entry.tag_relation.entity_type !== 'NOTE' || entry.tag_relation.entity_id !== noteId);
      }
    },
  },

  extraReducers: builder => {

    builder.addCase(fetchNextTagTimeline.fulfilled, (state, action) => {
      const {tagId, view} = action.meta.arg;
      const timeline = `tag:${tagId}.${view}`;

      if (!state.timelineByTagView[timeline]) {
        state.timelineByTagView[timeline] = {
          tagId,
          earliestTimelineEpochFetch: Date.now(),
          noMoreScrollback: false,
          isFetching: false,
          entries: [],
        };
      }

      if (action.payload) {
        state.timelineByTagView[timeline].entries = state.timelineByTagView[timeline].entries.concat(action.payload);
        state.timelineByTagView[timeline].isFetching = false;
        const earliestEpoch = action.payload
          .map((ent: ITagEntry) => new Date(ent.tag_relation.timestamp).valueOf())
          .reduce((a, b) => {
            if (a === null) return b;
            if (b === null) return a;

            return a < b ? a : b;
          }, state.timelineByTagView[timeline].earliestTimelineEpochFetch);
        state.timelineByTagView[timeline].earliestTimelineEpochFetch = earliestEpoch;
        state.timelineByTagView[timeline].noMoreScrollback = action.payload.length === 0;
      }
    });

    builder.addCase(wsFetchTagRelation.fulfilled, (state, action) => {
      const {tag_relation_tag_id: tagId, tag_relation_id: relationId} = action.meta.arg;
      const newEntry = action.payload;

      // short circuit requests that were not dispatched
      if (!newEntry) return;

      const actTimeline = `tag:${tagId}.${TagTimelineView.ACTIVITY}`;
      const pinsTimeline = `tag:${tagId}.${TagTimelineView.PINS}`;
      const notesTimeline = `tag:${tagId}.${TagTimelineView.NOTES}`;

      const timelines = [actTimeline];

      if ([TagRelationEntityTypes.DOCUMENT, TagRelationEntityTypes.LINK].includes(newEntry.tag_relation.entity_type)) {
        timelines.push(pinsTimeline);
      } else if (newEntry.tag_relation.entity_type === TagRelationEntityTypes.NOTE) {
        timelines.push(notesTimeline);
      }

      timelines.forEach(timelineName => {
        const timeline = state.timelineByTagView[timelineName];

        if (!timeline || !timeline.entries) return;

        let wasAlreadyInTimeline = false;

        const newEntries = timeline.entries.map((entry) => {
          if (getTagRelationId(entry.tag_relation) !== relationId) {
            return entry;
          }
          wasAlreadyInTimeline = true;
          return newEntry;
        });

        if (wasAlreadyInTimeline) {
          state.timelineByTagView[timelineName].entries = newEntries;
        } else {
          state.timelineByTagView[timelineName].entries.push(newEntry);
        }
      });
    });
  }
});

export const { receiveTags, receiveTagTimelineItems, removeTagRelation, removeTagTimelineItem, removeNoteTagRelation, removeTag } = tagsSlice.actions;
export default tagsSlice.reducer;