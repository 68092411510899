import { AxiosResponse } from 'axios';

import { API } from 'api';
import { IConnector } from 'store/connectors/slice';
import { IReadReceipt } from 'store/read_receipts/selectors';
import { ITag } from 'store/calendar/selectors';
import { ITeamFromAPI } from 'store/teams/selectors';
import { IUser } from 'store/user/selector';

export interface ISignupParams {
  email: string
  password: string
  full_name: string
  org_name?: string
}

interface IBootstrapResponse {
  user: IUser,
  connectors: IConnector[],
  receipts: IReadReceipt[],
  teams: ITeamFromAPI[],
  tags: ITag[],
}

export const fetchBootstrap = () => API.get<IBootstrapResponse>('/api/v1/users/me/boot');

export const signupViaEmail = (params: ISignupParams) => API.post(
  '/api/v1/users/',
  params,
  {headers: {'Content-Type': 'application/json'}}
);

export const resetPasswordViaEmail = (email: string) => API.post('/api/v1/users/reset-pw', {email});

export const verifyUser = (token: string) => API.post('/api/v1/users/verify', token);

export const resendVerificationEmail = () => API.post('/api/v1/users/resend-verify');

export const patchCurrentUser = (full_name?: string, org_name?: string, password?: string): Promise<AxiosResponse<IUser>> => API.patch('/api/v1/users/me', {full_name, password, org_name});

export const putSetting = (name: string, prop_type: 'BOOLEAN' | 'STRING' | 'JSON' | 'NUMBER', value?: string) => API.put(`/api/v1/users/me/settings/${name}`, {value, prop_type});

export const submitAppFeedback = (feedback: string) => API.post('/api/v1/users/me/submit-feedback', {feedback});