import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent, useEffect, useState } from 'react';
import { faAngleUp, faCalendarDay } from '@fortawesome/free-solid-svg-icons';

import { Activity } from 'components/DocumentSidebar/context/activity';
import DriveIcon from 'components/icons/GDrive';
import GmailIcon from 'components/icons/Gmail';
import LoadingDots from 'components/LoadingDots';
import SlackIcon from 'components/icons/Slack';
import cx from 'classnames';
import { markCalendarActivityAsRead } from 'store/read_receipts/slice';
import { store } from 'store';
import { AllActivity, IActivity, INewFileGmailActivityData, INewFileSlackActivityData } from 'store/documents/selectors';
import { Button, CaretDownIcon, ChatIcon, Checkbox, EmptyState, SelectMenu } from 'evergreen-ui';
import { GoogleDoc, GoogleSheets, GoogleSlides } from 'components/icons/files';
import { IMinimalCalendarEvent, getAllActivitiesForEvent, getAllAttendeesForEvent, getFirstActivitySinceLastRead, getInternalAttendeesForEvent, getNonUserAttendeesForEvent } from 'store/calendar/selectors';
import { intersection, union } from 'utils/set';
import { useActivityIntersection_OLD, useActivityUnion, useAppDispatch, useAppSelector, useDocument } from 'hooks';


const AllActivityTypes = new Set([
  'NEW_FILE_GMAIL',
  'NEW_MESSAGE_GMAIL',
  'NEW_FILE_SLACK',
  'NEW_MENTION_SLACK',
  'NEW_FILE_GDRIVE',
  'NEW_FILE_COMMENT_GDRIVE',
  'NEW_FILE_MENTION_GDRIVE',
]);

const ShowOptions = [
  {label: 'All', value: 'All'},
  {label: '@mentions', value: '@mentions'},
];

const makeAccountOptions = (selected: Set<string>) => ([
  {label: <><Checkbox marginRight={16} checked={selected.has('Gmail')} onClick={(e: MouseEvent) => e.preventDefault()} /><GmailIcon className="calendar-context-filter-option--icon"/> Gmail</>, value: 'Gmail'},
  {label: <><Checkbox marginRight={16} checked={selected.has('Slack')} onClick={(e: MouseEvent) => e.preventDefault()} /><SlackIcon className="calendar-context-filter-option--icon"/> Slack</>, value: 'Slack'},
  {label: <><Checkbox marginRight={16} checked={selected.has('Drive')} onClick={(e: MouseEvent) => e.preventDefault()} /><DriveIcon className="calendar-context-filter-option--icon"/> Drive</>, value: 'Drive'},
]);

const makeFileOptions = (selected: Set<string>) => ([
  {label: <><Checkbox marginRight={16} checked={selected.has('Docs')} onClick={(e: MouseEvent) => e.preventDefault()} /><GoogleDoc height={16} width={24} /> Docs</>, value: 'Docs'},
  {label: <><Checkbox marginRight={16} checked={selected.has('Sheets')} onClick={(e: MouseEvent) => e.preventDefault()} /><GoogleSheets height={16} width={24} /> Sheets</>, value: 'Sheets'},
  {label: <><Checkbox marginRight={16} checked={selected.has('Slides')} onClick={(e: MouseEvent) => e.preventDefault()} /><GoogleSlides height={16} width={24} /> Slides</>, value: 'Slides'},
]);

const TypesForOptions: {[key: string]: Set<string>} = {
  All: AllActivityTypes,
  '@mentions': new Set(['NEW_MENTION_SLACK', 'NEW_FILE_MENTION_GDRIVE']),
  Gmail: new Set(['NEW_FILE_GMAIL', 'NEW_MESSAGE_GMAIL']),
  Slack: new Set(['NEW_MENTION_SLACK', 'NEW_FILE_SLACK']),
  Drive: new Set(['NEW_FILE_GDRIVE', 'NEW_FILE_COMMENT_GDRIVE', 'NEW_FILE_MENTION_GDRIVE']),
  Docs: new Set(['NEW_FILE_SLACK', 'NEW_FILE_GMAIL', 'NEW_FILE_GDRIVE', 'NEW_FILE_COMMENT_GDRIVE', 'NEW_FILE_MENTION_GDRIVE']),
  Sheets: new Set(['NEW_FILE_SLACK', 'NEW_FILE_GMAIL', 'NEW_FILE_GDRIVE', 'NEW_FILE_COMMENT_GDRIVE', 'NEW_FILE_MENTION_GDRIVE']),
  Slides: new Set(['NEW_FILE_SLACK', 'NEW_FILE_GMAIL', 'NEW_FILE_GDRIVE', 'NEW_FILE_COMMENT_GDRIVE', 'NEW_FILE_MENTION_GDRIVE']),
};

const FileTypesForFilter: {[key: string]: Set<string>} = {
  Docs: new Set(['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.google-apps.document']),
  Sheets: new Set(['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.google-apps.spreadsheet']),
  Slides: new Set(['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.google-apps.presentation']),
};


const CalendarActivitiesFilter = ({ onChangeActivityFilters, onChangeFileTypeFilters }: {onChangeActivityFilters: (types: Set<string>) => void, onChangeFileTypeFilters: (types: Set<string>) => void}) => {
  const [show, setShow] = useState<'All' | '@mentions'>();
  const [selectedAccounts, setSelectedAccounts] = useState<Set<string>>(new Set());
  const [selectedFileTypes, setSelectedFileTypes] = useState<Set<string>>(new Set());

  const resetFilters = () => {
    setShow(undefined);
    setSelectedAccounts(new Set());
    setSelectedFileTypes(new Set());
  };

  const onSelectAccount = ({value}: {label: string, value: string}) => {
    const newValue = new Set([...selectedAccounts]);
    if (selectedAccounts.has(value)) {
      newValue.delete(value);
    } else {
      newValue.add(value);
    }
    setSelectedAccounts(newValue);
  };

  const onSelectFileType = ({value}: {label: string, value: string}) => {
    const newValue = new Set([...selectedFileTypes]);
    if (selectedFileTypes.has(value)) {
      newValue.delete(value);
    } else {
      newValue.add(value);
    }
    setSelectedFileTypes(newValue);
  };

  let accounts = '';
  if (selectedAccounts.size === 0) {
    accounts = '';
  } else if (selectedAccounts.size === 1) {
    accounts = [...selectedAccounts].toString();
  } else if (selectedAccounts.size > 1) {
    accounts = selectedAccounts.size.toString() + ' Accounts';
  }

  let files = '';
  if (selectedFileTypes.size === 0) {
    files = '';
  } else if (selectedFileTypes.size === 1) {
    files = [...selectedFileTypes].toString();
  } else if (selectedFileTypes.size > 1) {
    files = selectedFileTypes.size.toString() + ' File types';
  }

  useEffect(() => {
    const showTypes = TypesForOptions[show || ''] || AllActivityTypes;
    const accountTypes = [...selectedAccounts].map(acc => TypesForOptions[acc]).reduce(union, new Set());
    const fileTypes = [...selectedFileTypes].map(file => TypesForOptions[file]).reduce(union, new Set());

    const selectedActivityTypes = [showTypes, accountTypes, fileTypes].reduce((soFar, nextSet) => {
      if (nextSet.size === 0) {
        return intersection(soFar, AllActivityTypes);
      } else {
        return intersection(soFar, nextSet);
      }
    }, AllActivityTypes);

    const selectedMimetypes = [...selectedFileTypes].map(file => FileTypesForFilter[file]).reduce(union, new Set());

    onChangeFileTypeFilters(selectedMimetypes);
    onChangeActivityFilters(selectedActivityTypes);
  }, [show, selectedAccounts, selectedFileTypes]);

  const nonEmptyFilters = show === '@mentions' || selectedAccounts.size !== 0 || selectedFileTypes.size !== 0;

  return <div className="calendar-context-activity-filters--container">
    <SelectMenu
      height={66}
      hasFilter={false}
      hasTitle={false}
      options={ShowOptions}
      selected={show}
      onSelect={({value}) => setShow((value as 'All' | '@mentions'))}
    >
      <Button  className={cx('calendar-context-activity-filter--button', {filtered: !!show})} iconAfter={CaretDownIcon}>{show || 'Show'}</Button>
    </SelectMenu>

    <SelectMenu
      height={99}
      hasFilter={false}
      hasTitle={false}
      isMultiSelect
      // @ts-expect-error: the labels need to be JSX to match designs and it's allowed but not typed that way in Evergreen
      options={makeAccountOptions(selectedAccounts)}
      selected={[...selectedAccounts]}
      onSelect={onSelectAccount}
      onDeselect={onSelectAccount}
    >
      <Button className={cx('calendar-context-activity-filter--button', {filtered: selectedAccounts.size})} iconAfter={CaretDownIcon}>{accounts || 'Account'}</Button>
    </SelectMenu>

    <SelectMenu
      height={99}
      hasFilter={false}
      hasTitle={false}
      isMultiSelect
      // @ts-expect-error: the labels need to be JSX to match designs and it's allowed but not typed that way in Evergreen
      options={makeFileOptions(selectedFileTypes)}
      selected={[...selectedFileTypes]}
      onSelect={onSelectFileType}
      onDeselect={onSelectFileType}
    >
      <Button className={cx('calendar-context-activity-filter--button', {filtered: selectedFileTypes.size})} iconAfter={CaretDownIcon}>{files || 'File type'}</Button>
    </SelectMenu>
    {nonEmptyFilters && <div className="calendar-context-activity-filters--clear" onClick={resetFilters}>Clear filters</div>}
  </div>;
};


const CalendarActivityEvent = ({ event, timestamp }: {event: IMinimalCalendarEvent, timestamp: string}) => {
  const start = new Date(event.start_dt);
  const end = new Date(event.end_dt);
  const previousMeetingDate = new Date(timestamp);
  const fmtTime = (time: Date) => time.toLocaleTimeString(window.navigator.language, { hour: 'numeric', minute: '2-digit' });
  const dateStr = (date: Date) => date.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' });

  return (
    <div className="calendar-context-activity-event--container">
      <FontAwesomeIcon className="calendar-context-activity-event--icon" icon={faCalendarDay} size="lg" />
      <div>
        <div className="calendar-context-activity-event--date-time">
          <div className="calendar-context-activity-event--date">{dateStr(previousMeetingDate)}</div> <span className="calendar-context-activity-event--time">{fmtTime(start)} - {fmtTime(end)}</span>
        </div>
        <div className="calendar-context-activity-event--title">{event.title}</div>
      </div>
    </div>
  );
};


const CalendarActivity = ({ activity, isFirstActivitySinceLastMeeting = false }: { activity: IActivity, isFirstActivitySinceLastMeeting?: boolean }) => {
  const data = activity.data;
  let docId = '';
  if ((data as INewFileGmailActivityData).document_ids) {
    docId = (data as INewFileGmailActivityData).document_ids[0];
  } else {
    docId = (data as INewFileSlackActivityData).document_id;
  }

  useDocument(docId);

  return <>
    <Activity documentId={docId} activity={activity} className="calendar-details-activity--container" showFileName trackingCtx='calendar activity tab' />
    {isFirstActivitySinceLastMeeting && <div className="calendar-context-activity-since-last-time--container">
      <div  className="calendar-context-activity-since-last-time--line"/>
      <div className="calendar-context-activity-since-last-time--text">New activity since last meeting <FontAwesomeIcon icon={faAngleUp} /></div>
    </div>}
  </>;
};


const EmptyActivity = () => (
  <EmptyState
    title="Pin files to this meeting to see more activity related to those files"
    icon={<ChatIcon color="var(--color-purple-4)" />}
    background="dark"
    iconBgColor="var(--color-neutral-6)"
    description="See Google Drive comments, recent email conversations, and Slack threads related to important files for this meeting."
  />
);


const CalendarActivities = ({ event }: { event: IMinimalCalendarEvent}) => {
  const [filteredActivityTypes, setFilteredActivityTypes] = useState(AllActivityTypes);
  const [filteredMimetypes, setFilteredMimetypes] = useState<Set<string>>(new Set());
  const [shouldMarkAsRead, setShouldMarkAsRead] = useState(false);
  const dispatch = useAppDispatch();
  // faslm -> first activity since last meeting
  const faslm = useAppSelector(s => getFirstActivitySinceLastRead(s, event.id, event.entity_reference_id, filteredActivityTypes));

  const allAttendees = useAppSelector(s => getAllAttendeesForEvent(s, event.id)).map(c => c.id);
  const internalAttendees = useAppSelector(s => getInternalAttendeesForEvent(s, event.id)).map(c => c.id);
  const attendeesWithoutMe = useAppSelector(s => getNonUserAttendeesForEvent(s, event.id)).map(c => c.id);

  const {loading: intersectionActivitiesLoading} = useActivityIntersection_OLD(allAttendees);
  const {loading: internalActivitiesLoading} = useActivityIntersection_OLD(internalAttendees);
  const {loading: mentionActivitiesLoading} = useActivityUnion(attendeesWithoutMe, [], ['NEW_MENTION_SLACK', 'NEW_FILE_MENTION_GDRIVE']);
  const activitiesLoading = intersectionActivitiesLoading || internalActivitiesLoading || mentionActivitiesLoading;

  const previousEvents = (event.previous_meeting_starts || []).map(timestamp => ({timestamp}));

  const mostRecentFirst = (act1: AllActivity | {timestamp: string}, act2: AllActivity | {timestamp: string}) => (new Date(act2.timestamp).valueOf()) - (new Date(act1.timestamp).valueOf());

  const eventActivity = useAppSelector(s => getAllActivitiesForEvent(s, event.id, event.entity_reference_id, filteredActivityTypes, filteredMimetypes));

  const orderedActivitiesAndPreviousEvents: Array<AllActivity | {timestamp: string}> = (eventActivity as Array<AllActivity | {timestamp: string}>).concat(previousEvents).sort(mostRecentFirst);

  useEffect(() => {
    if (!activitiesLoading) {
      setShouldMarkAsRead(true);
    }
  }, [activitiesLoading, event]);

  useEffect(() => {
    // mark on unmount so the markers don't go away on render
    return () => {
      if (shouldMarkAsRead) {
        markCalendarActivityAsRead({referenceId: event.entity_reference_id})(dispatch, store.getState, {});
      }
    };
  }, [shouldMarkAsRead, event]);


  return <div className="calendar-context-section--container">
    <div>
      <div className="calendar-context-section--header">
        <CalendarActivitiesFilter onChangeActivityFilters={setFilteredActivityTypes} onChangeFileTypeFilters={setFilteredMimetypes} />
      </div>
      {activitiesLoading && <em className="calendar-context-activity--loading">Loading more activity<LoadingDots /></em>}
      {!activitiesLoading && orderedActivitiesAndPreviousEvents.length === 0 && <EmptyActivity />}
      {orderedActivitiesAndPreviousEvents.map(actOrEvt => 'id' in actOrEvt
        ? <CalendarActivity key={actOrEvt.id} activity={actOrEvt} isFirstActivitySinceLastMeeting={faslm ? faslm.id === actOrEvt.id : false} />
        : <CalendarActivityEvent key={actOrEvt.timestamp} event={event} timestamp={actOrEvt.timestamp}/>)}
    </div>
  </div>;
};

export default CalendarActivities;