import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import DocumentSidebar from 'components/DocumentSidebar';
import LinkSidebar from 'components/LinkSidebar';
import NoteSidebar from 'components/NoteSidebar';
import { NotificationType } from 'store/notifications/selectors';
import SearchBar from 'components/SearchBar';
import SearchFacets from 'components/SearchFacets';
import SearchResults from 'components/SearchResults';
import Sidebar from 'components/Sidebar';
import { notify } from 'store/notifications/slice';
import { TrackEventNames, tracker } from 'utils/tracking';
import { closeSidebar, failedDocumentResponse, failedLinkResponse, failedNoteResponse, receiveDocumentResponse, receiveLinkResponse, receiveNoteResponse } from 'store/search/slice';
import { getSearchFilters, getSearchSidebarDocument, getSearchSidebarLink, getSearchSidebarNote, isSearchSidebarOpen } from 'store/search/selectors';
import { searchDocuments, searchLinks, searchNotes } from 'api/search';
import { useAppDispatch, useAppSelector } from 'hooks';

import './style.css';


const SearchPage = () => {

  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const dispatch = useAppDispatch();
  const searchFilters = useAppSelector(getSearchFilters);
  const hidden = !(useAppSelector(isSearchSidebarOpen));
  const document = useAppSelector(getSearchSidebarDocument);
  const link = useAppSelector(getSearchSidebarLink);
  const note = useAppSelector(getSearchSidebarNote);

  const close = () => {
    dispatch(closeSidebar());
  };


  const query = params.get('q') || '';

  const [loading, setLoading] = useState(false);

  // when filters or query change, push to the history
  useEffect(() => {
    const newParams = new URLSearchParams();
    newParams.append('q', query);
    Object.entries(searchFilters).forEach(([category, values]) => {
      values.forEach(value => {
        newParams.append('f', `${category}:${value}`);
      });
    });

    history.push(`/search?${newParams.toString()}`);
  }, [searchFilters, query, history]);


  // when the URL changes, dispatch a search request
  useEffect(() => {
    const searchQuery = params.get('q') || '';

    const search = async () => {
      setLoading(true);
      try {
        const res = await Promise.allSettled([
          searchDocuments(searchQuery, params.getAll('f')),
          searchLinks(searchQuery, params.getAll('f')),
          searchNotes(searchQuery, params.getAll('f')),
          new Promise(res => setTimeout(res, 400))
        ]);
        const documents = res[0];
        const links = res[1];
        const notes = res[2]; 
        if (documents.status === 'fulfilled') {
          dispatch(receiveDocumentResponse({
            results: documents.value.data.results,
            facets: documents.value.data.facets,
            pagination: documents.value.data.pagination,
          }));
        } else {
          dispatch(failedDocumentResponse());
        }
        if (links.status === 'fulfilled') {
          dispatch(receiveLinkResponse({
            results: links.value.data.results,
            facets: links.value.data.facets,
            pagination: links.value.data.pagination,
          }));
        } else {
          dispatch(failedLinkResponse());
        }
        if (notes.status === 'fulfilled') {
          dispatch(receiveNoteResponse({
            results: notes.value.data.results,
            facets: notes.value.data.facets,
            pagination: notes.value.data.pagination,
          }));
        } else {
          dispatch(failedNoteResponse());
        }
        tracker.track(TrackEventNames.S, {
          query: searchQuery,
          filters: searchFilters,
          numResults: 'value' in documents ? documents.value.data.results.length : '-1',
          numResultsLinks: 'value' in links ? links.value.data.results.length : '-1',
          numResultsNotes: 'value' in notes ? notes.value.data.results.length : '-1',
        });
      } catch (err) {
        notify({
          message: 'Failed to search. Please try again',
          type: NotificationType.ERROR
        })(dispatch);
      } finally {
        setLoading(false);
      }
    };

    search();
  }, [params, dispatch, searchFilters]);

  return (
    <div className="search--container sidebar-sibling-content--container">
      <div className="search-content--container">
        <SearchBar
          startText={query}
          loading={loading}
        />
        <SearchFacets />
        <SearchResults />
      </div>
      {document ? <DocumentSidebar searchSidebar hidden={hidden} document={document} onClose={close} /> : null}
      {link ? <LinkSidebar searchSidebar hidden={hidden} link={link} onClose={close} /> : null}
      {note ? <NoteSidebar note={note} searchSidebar onClose={close} /> : null}
    </div>
  );
};



const SearchView = () => {
  return (
    <div>
      <Sidebar />
      <SearchPage />
    </div>
  );
};

export default SearchView;