import React from 'react';
import { connect } from 'react-redux';
import { setUser } from '@sentry/react';
import { History, Location } from 'history';
import { Route, Switch, withRouter } from 'react-router-dom';

import AppUpdateContainer from 'components/AppUpdateListener';
import ConnectGoogleAccount from 'views/onboarding/signup/connectGoogleAccount';
import ConnectSuccess from 'views/connect_success';
import ContactsView from 'views/contacts';
import ExternalLinkView from 'views/external_link';
import HomeView from 'views/home';
import { IReadReceipt } from 'store/read_receipts/selectors';
import { ITag } from 'store/calendar/selectors';
import { ITeam } from 'store/teams/selectors';
import LoadingDots from 'components/LoadingDots';
import Login from 'views/onboarding/login';
import LoginRequiredRoute from 'components/LoginRequiredRoute';
import NotesView from 'views/notes';
import NotificationContainer from 'components/Notification';
import PasswordResetEmailSent from 'views/onboarding/login/passwordReset/emailSent';
import Remember from 'views/remember';
import ResetPassword from 'views/onboarding/login/passwordReset';
import SearchView from 'views/search';
import SetNewPassword from 'views/onboarding/login/passwordReset/setNewPassword';
import SettingsLayout from 'views/settings';
import Signup from 'views/onboarding/signup';
import SignupComplete from 'views/onboarding/signup/signupComplete';
import SignupSurvey from 'views/onboarding/signup/signupSurvey';
import TasksView from 'views/tasks';
import Timeline from 'views/timeline';
import VerifyView from 'views/verify';
import WorkstreamsView from 'views/workstreams';
import { fetchBootstrap } from 'api/user';
import { getToken } from 'utils/auth';
import { receiveReceipts } from 'store/read_receipts/slice';
import { receiveTags } from 'store/tags/slice';
import { receiveTeams } from 'store/teams/slice';
import { tracker } from 'utils/tracking';
import { AppDispatch, RootState } from 'store';
import { IConnector, receiveConnectors } from 'store/connectors/slice';
import { IJoinChannelPayload, markAllChannelsAsDisconnected, renewAllDisconnectedChannels, subscribe } from 'store/socket/slice';
import { IUser, IUserShallow, OnboardingStepNames } from 'store/user/selector';
import { receiveUser, receiveUsers } from 'store/user/slice';

import './App.css';

interface IAppProps {
  receiveMe: (user: IUser) => void,
  receiveConnectors: (connectors: IConnector[]) => void,
  receiveTags: (tags: ITag[]) => void,
  receiveTeams: (teams: ITeam[]) => void,
  receiveUsers: (users: IUserShallow[]) => void,
  receiveReceipts: (receipts: IReadReceipt[]) => void,
  location: Location,
  history: History,
  subscribe: (params: IJoinChannelPayload) => void,
  unsubscribeAll: () => void,
  resubscribeAll: () => void,
}

class App extends React.Component<IAppProps, {bootstrapping: boolean}> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      bootstrapping: true,
    };
  }

  componentDidMount() {
    this.bootstrap();
  }

  bootstrap = async () => {
    const {
      receiveMe,
      receiveConnectors,
      receiveTags,
      receiveTeams,
      receiveUsers,
      receiveReceipts,
      location,
      history,
    } = this.props;

    await fetchBootstrap()
      .then(({data}) => {
        const {
          user,
          connectors,
          tags,
          receipts,
          teams,
        } = data;
        receiveMe(user);
        receiveConnectors(connectors);
        receiveTags(tags);
        receiveReceipts(receipts);
        const users: IUserShallow[] = [user];
        receiveTeams(teams.map(team => ({...team, users: team.users.map(user => {
          users.push(user.user);
          return {...user, user: user.user.id};
        })})));
        receiveUsers(users);
        tracker.identify(user);

        const params = new URLSearchParams(location.search);
        const next = params.get('next');
        let search = params.get('search') || '';
        if (search) {
          try {
            search = atob(search);
          } catch (err) {
            search = '';
          }
        }
        this.props.subscribe({channelName: `user:${user.id}`, token: getToken(), params: {}});
        this.props.subscribe({channelName: `org:${user.org_id}`, token: getToken(), params: {}});
        window.addEventListener('offline', () => this.props.unsubscribeAll());
        window.addEventListener('online', () => this.props.resubscribeAll());
        setUser(user);
        if (next) {
          history.push(`${next}?${new URLSearchParams(search)}`);
        }
      })
      .catch((e) => {
        const status = e.response ? e.response.status : null;
        if (![401, 403].includes(status)) {
          console.warn('[App.bootstrap] Unexpected error response', e);
        }
      })
      .finally(() => {
        this.setState({ bootstrapping: false });
      });
  };

  render() {
    if (this.state.bootstrapping) {
      return <div className="app-bootstrap--loading">Loading<LoadingDots/></div>;
    }

    return (
      <div className="App">
        <Switch>
          <Route path="/login" component={Login}/>
          <Route path="/signup" component={Signup}/>
          <Route path={'/' + OnboardingStepNames.SIGNUP_SURVEY} component={SignupSurvey}/>
          <Route path={'/' + OnboardingStepNames.CONNECT_GOOGLE}  component={ConnectGoogleAccount}/>
          <Route path={'/' + OnboardingStepNames.SIGNUP_COMPLETE} component={SignupComplete}/>
          <Route path="/reset-pw" component={ResetPassword}/>
          <Route path="/reset-pw-email" component={PasswordResetEmailSent}/>
          <Route path="/set-new-pw" component={SetNewPassword}/>
          <Route path="/connect-success" component={ConnectSuccess} />
          <Route path="/connect-success/microsoft" component={ConnectSuccess} />
          <Route path="/verify" component={VerifyView} />
          <LoginRequiredRoute path="/" exact>
            <HomeView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/cdl-ext">
            <ExternalLinkView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/activity" exact>
            <Timeline />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/remember">
            <Remember />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/settings">
            <SettingsLayout />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/search">
            <SearchView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/contacts">
            <ContactsView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/workstreams/:uuid">
            <WorkstreamsView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/notes">
            <NotesView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/tasks">
            <TasksView />
          </LoginRequiredRoute>
        </Switch>
        <NotificationContainer />
        <AppUpdateContainer />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const user = state.user;
  return {
    isLoggedIn: !!user,
    user,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    receiveMe: (data: IUser) => dispatch(receiveUser(data)),
    receiveConnectors: (data: IConnector[]) => dispatch(receiveConnectors(data)),
    receiveTags: (data: ITag[]) => dispatch(receiveTags(data)),
    receiveTeams: (data: ITeam[]) => dispatch(receiveTeams(data)),
    receiveUsers: (data: IUserShallow[]) => dispatch(receiveUsers(data)),
    receiveReceipts: (data: IReadReceipt[]) => dispatch(receiveReceipts(data)),
    subscribe: (data: IJoinChannelPayload) => subscribe(data)(dispatch),
    unsubscribeAll: () => dispatch(markAllChannelsAsDisconnected()),
    resubscribeAll: () => dispatch(renewAllDisconnectedChannels()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));