import { Socket } from 'phoenix';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { WS_URL } from 'constants/resources';
import { getChannelSubscriptions } from './selectors';
import { getToken } from 'utils/auth';
import { AppDispatch, RootState, store } from 'store';
import { ICalendarPinWSPayload, IUpdatedEventsWSPayload, wsFetchCalendarPin, wsUpdateCalendarEvents } from 'store/calendar/slice';
import { IDocumentWSPayload, wsFetchNewDocument, wsFetchUpdatedDocument } from 'store/documents/slice';
import { IGateWSPayload, wsFetchUpdatedUserGate } from 'store/user/slice';
import { ILinkEnrichWSPayload, wsFetchEnrichedLink } from 'store/links/slice';
import { INoteUpdatedWSPayload, wsFetchNoteById } from 'store/notes/slice';
import { ITagRelationWSPayload, wsFetchTagRelation } from 'store/tags/slice';
import { ITeamUpdatedWSPayload, wsFetchTeam } from 'store/teams/slice';
import { IUpdatedReceiptWSPayload, wsReceiveUpdatedReadReceipt } from 'store/read_receipts/slice';

const FETCH_NEW_DOC_DELAY_IN_MS =  12000; // 12 second delay => p99 for doc creation is <10s and p99 for activity creation is <2s


enum ChannelSubscriptionStatus {
  ACTIVE = 'ACTIVE',
  DISCONNECTED = 'DISCONNECTED',  // lost connection
  PAUSED = 'PAUSED'  // user action
}

export interface SocketState {
  channelSubscriptions: {[channel: string]: {
    status: ChannelSubscriptionStatus,
    lastUpdatedAt: number
  }},
}

export interface IJoinChannelPayload {
  channelName: string;
  token: string | null;
  params: Record<string, unknown>;
}

const initialState: SocketState = {
  channelSubscriptions: {},
};

type wsPayload = IGateWSPayload & IDocumentWSPayload & ILinkEnrichWSPayload & ITagRelationWSPayload & ICalendarPinWSPayload & ITeamUpdatedWSPayload & IUpdatedReceiptWSPayload & INoteUpdatedWSPayload & IUpdatedEventsWSPayload;

const receiveChannelMessage = (dispatch: AppDispatch, eventType: string, payload: wsPayload) => {
  const eventTypeToAction: Record<string, (arg: wsPayload) => (dispatch: AppDispatch, getState: () => RootState, config: unknown) => unknown> = {
    DOCUMENT_UPDATED: wsFetchUpdatedDocument,
    DOCUMENT_CREATED: wsFetchNewDocument,
    USER_GATES_UPDATED: wsFetchUpdatedUserGate,
    LINK_ENRICHED: wsFetchEnrichedLink,
    WORKSTREAM_UPDATE: wsFetchTagRelation,
    CALENDAR_PIN_UPDATED: wsFetchCalendarPin,
    TEAM_UPDATED: wsFetchTeam,
    READ_RECEIPT_UPDATED: wsReceiveUpdatedReadReceipt,
    NOTE_UPDATED: wsFetchNoteById,
    CALENDAR_EVENTS_UPDATED: wsUpdateCalendarEvents,
  };

  const dispatchFunc = eventTypeToAction[eventType];

  if (dispatchFunc) {
    if (eventType === 'DOCUMENT_CREATED') {
      setTimeout(()=> dispatchFunc(payload)(dispatch, store.getState, {}), FETCH_NEW_DOC_DELAY_IN_MS);
      return;
    }
    dispatchFunc(payload)(dispatch, store.getState, {});
  }
};

let savedSocket: null | Socket = null;

const getSocket = (token: string, dispatch: AppDispatch) => {
  if (!savedSocket) {
    savedSocket = new Socket(`${WS_URL}/socket`, { params: { token }});
    savedSocket.connect();
    savedSocket.onError(() => {
      savedSocket?.disconnect();
      savedSocket = null;
      dispatch(socketSlice.actions.markAllChannelsAsDisconnected());
    });
  }
  return savedSocket;
};

export const subscribe = (payload: IJoinChannelPayload) => async (dispatch: AppDispatch) => {
  if (!payload.token) {
    return;
  }

  const socket = getSocket(payload.token, dispatch);
  const channel = socket.channel(payload.channelName, payload.params);

  channel.join()
    .receive('ok', () => dispatch(renewedSubscription({channelName: payload.channelName})))
    .receive('error', () => { // listens to channel error
      channel.leave();
      return dispatch(socketSlice.actions.markChannelAsDisconnected({channelName: payload.channelName, at: -1}));
    });

  channel.onMessage = (event: string, payload: wsPayload) => {
    const [classify, eventName] = event.split(':');
    const isAcceptableEvent = classify === 'classify';
    if (isAcceptableEvent) {
      receiveChannelMessage(dispatch, eventName, payload);
    }
    return payload;
  };
};

export const renewAllDisconnectedChannels = createAsyncThunk<void, undefined, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'socket/offlineConnection',
  async (_, {getState, dispatch}) => {

    const channelNameSubscriptions = getChannelSubscriptions(getState());
    Object.entries(channelNameSubscriptions).forEach(([channelName, {status}]) => {
      if (status === ChannelSubscriptionStatus.DISCONNECTED) {
        subscribe({channelName, token: getToken(), params: {}})(dispatch);
      }
    });
  }
);

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    renewedSubscription: (state, action: PayloadAction<{channelName: string, at?: number}>) => {
      state.channelSubscriptions[action.payload.channelName] = {
        lastUpdatedAt: action.payload.at || (new Date()).valueOf(),
        status: ChannelSubscriptionStatus.ACTIVE,
      };
    },

    markChannelAsDisconnected: (state, action: PayloadAction<{channelName: string, at: number}>) => {
      state.channelSubscriptions[action.payload.channelName] = {
        lastUpdatedAt: action.payload.at,
        status: ChannelSubscriptionStatus.DISCONNECTED,
      };
    },

    markAllChannelsAsDisconnected: (state) => {
      const at = Date.now();
      Object.keys(state.channelSubscriptions).forEach(subscription => {
        state.channelSubscriptions[subscription] = {
          status: ChannelSubscriptionStatus.DISCONNECTED,
          lastUpdatedAt: at,
        };
      });
    },
  },
});


export const { renewedSubscription, markAllChannelsAsDisconnected } = socketSlice.actions;
export default socketSlice.reducer;
