import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IAsyncDataSlice } from 'store/interfaces';
import { AppDispatch, RootState } from 'store';
import { IActionItem, getActionItemPatchTimeoutId, getDirtyActionItemContent, getDirtyActionItemData, getDirtyActionItemStatus, getDirtyActionItemTitle, getFetchingActionItem, getSavingActionItem, getTaskTimelineLoading, getTaskTimelineOffset } from './selectors';
import { fetchActionItem, fetchActionItemsPage, patchActionItem } from 'api/action_items';

export interface IDirtyActionItemData {
  title: string
  content: string
  status: string
  data: Record<string, unknown>
  saving: boolean
  saveTimeoutId: null | ReturnType<typeof setTimeout>
  saveFailed: boolean
}

export interface ActionItemsState {
  actionItemsById: { [noteId: string]: IAsyncDataSlice<IActionItem> },
  dirtyActionItemDataById: { [noteId: string]: IDirtyActionItemData },
  tasksTimeline: {
    timelineOffset: number;
    noMoreScrollback: boolean;
    isFetching: boolean;
    actionItemIds: string[];
  }
}

const initialState: ActionItemsState = {
  actionItemsById: {},
  dirtyActionItemDataById: {},
  tasksTimeline: {
    timelineOffset: 0,
    noMoreScrollback: false,
    isFetching: false,
    actionItemIds: [],
  },
};

export const fetchNextTaskTimeline = createAsyncThunk<{tasks: IActionItem[], limit: number} | undefined, {limit: number}, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'tasks/fetchNextTaskTimeline',
  async ({limit}, {getState, dispatch}) => {
    if (!getTaskTimelineLoading(getState())) {
      const offset = getTaskTimelineOffset(getState());
      dispatch(actionItemsSlice.actions.setTaskTimelineLoading({ loading: true }));
      const {data} = await fetchActionItemsPage(offset, limit);
      dispatch(actionItemsSlice.actions.receiveActionItems(data));
      return {tasks: data, limit};
    }
  }
);


export const fetchActionItemById = createAsyncThunk<IActionItem | undefined, string, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'action_items/fetchActionItemById',
  async (actionItemId: string, {getState, dispatch}) => {
    if (!getFetchingActionItem(getState(), actionItemId)) {
      dispatch(actionItemsSlice.actions.setFetchingActionItem({ actionItemId, isFetching: true }));
      const {data} = await fetchActionItem(actionItemId);
      dispatch(receiveActionItems([data]));
      return data;
    }
  }
);


export const updateActionItem = ({actionItemId, title, status, parentActionItemId}: { actionItemId: string, title?: string, status?: string, parentActionItemId?: string | null }) => async (dispatch: AppDispatch, getState: () => RootState) => {
  if (typeof title === 'string') {
    dispatch(actionItemsSlice.actions.setActionItemTitle({ actionItemId, title }));
  }

  if (typeof status === 'string') {
    dispatch(actionItemsSlice.actions.setActionItemStatus({ actionItemId, status }));
  }

  if (typeof parentActionItemId === 'string' || parentActionItemId === null) {
    dispatch(actionItemsSlice.actions.setActionItemParent({ actionItemId, parentActionItemId }));
  }

  const save = ((typeof title === 'string') || (typeof status === 'string') || (typeof parentActionItemId === 'string' || parentActionItemId === null)) && !getSavingActionItem(getState(), actionItemId);

  const patch = async () => {
    const patchContent = getDirtyActionItemContent(getState(), actionItemId);
    const patchTitle = getDirtyActionItemTitle(getState(), actionItemId);
    const patchStatus = getDirtyActionItemStatus(getState(), actionItemId);
    const patchData = getDirtyActionItemData(getState(), actionItemId);
    dispatch(actionItemsSlice.actions.startedActionItemPatch({ actionItemId }));
    try {
      const {data} = await patchActionItem(actionItemId, patchContent, patchTitle, patchStatus, patchData || {});
      dispatch(receiveActionItems([data]));
      dispatch(actionItemsSlice.actions.finishedActionItemPatch({ actionItemId }));
    } catch (err) {
      dispatch(actionItemsSlice.actions.failedActionItemPatch({ actionItemId }));
      console.warn(err);
    }
  };

  const existingTimeoutId = getActionItemPatchTimeoutId(getState(), actionItemId);

  if (save) {
    if (existingTimeoutId) clearTimeout(existingTimeoutId);

    const timeoutId = setTimeout(patch, 1200);
    dispatch(actionItemsSlice.actions.queueActionItemPatch({ timeoutId, actionItemId }));
  }
};


export const actionItemsSlice = createSlice({
  name: 'action_items',
  initialState,
  reducers: {
    receiveActionItems: (state, action: PayloadAction<IActionItem[]>) => {
      action.payload.forEach(ai => {
        state.actionItemsById[ai.id] = {
          data: ai,
          isFetching: false,
          fetchFailed: false,
          lastReceived: new Date(),
        };
      });
    },

    setTaskTimelineLoading: (state, action: PayloadAction<{loading: boolean}>) => {
      state.tasksTimeline.isFetching = action.payload.loading;
    },

    deleteActionItem: (state, action: PayloadAction<string>) => {
      state.tasksTimeline.actionItemIds = state.tasksTimeline.actionItemIds.filter(id => id !== action.payload);
      delete state.actionItemsById[action.payload];
      delete state.dirtyActionItemDataById[action.payload];
    },

    setFetchingActionItem: (state, action: PayloadAction<{actionItemId: string, isFetching: boolean}>) => {
      const {
        actionItemId,
        isFetching,
      } = action.payload;

      if (!state.actionItemsById[actionItemId]) {
        state.actionItemsById[actionItemId] = {
          data: null,
          isFetching,
          lastReceived: null,
          fetchFailed: false,
        };
      } else {
        state.actionItemsById[actionItemId].isFetching = isFetching;
      }
    },

    setActionItemStatus: (state, action: PayloadAction<{actionItemId: string, status: string}>) => {
      const {
        actionItemId,
        status,
      } = action.payload;

      if (!state.dirtyActionItemDataById[actionItemId]) {
        const actionItem = (state.actionItemsById[actionItemId] || {}).data || {title: '', content: '', status, data: {}};
        state.dirtyActionItemDataById[actionItemId] = {
          title: actionItem.title || '',
          content: actionItem.content || '',
          data: actionItem.data,
          status,
          saving: false,
          saveTimeoutId: null,
          saveFailed: false,
        };
      } else {
        state.dirtyActionItemDataById[actionItemId].status = status;
      }
    },


    setActionItemTitle: (state, action: PayloadAction<{actionItemId: string, title: string}>) => {
      const {
        actionItemId,
        title,
      } = action.payload;

      if (!state.dirtyActionItemDataById[actionItemId]) {
        const actionItem = (state.actionItemsById[actionItemId] || {}).data || {title, content: '', status: 'NOT_DONE', data: {}};
        state.dirtyActionItemDataById[actionItemId] = {
          title: title,
          content: actionItem.content || '',
          status: actionItem.status,
          data: actionItem.data,
          saving: false,
          saveTimeoutId: null,
          saveFailed: false,
        };
      } else {
        state.dirtyActionItemDataById[actionItemId].title = title;
      }
    },

    setActionItemParent: (state, action: PayloadAction<{actionItemId: string, parentActionItemId: string | null}>) => {
      const {
        actionItemId,
        parentActionItemId,
      } = action.payload;

      const saved = state.actionItemsById[actionItemId];

      if (saved && saved.data) {
        saved.data.data.parentActionItemId = parentActionItemId;
      }

      if (!state.dirtyActionItemDataById[actionItemId]) {
        const actionItem = (state.actionItemsById[actionItemId] || {}).data || {title: '', content: '', status: 'NOT_DONE', data: {}};
        state.dirtyActionItemDataById[actionItemId] = {
          title: actionItem.title || '',
          content: actionItem.content || '',
          status: actionItem.status,
          data: {...actionItem.data, parentActionItemId},
          saving: false,
          saveTimeoutId: null,
          saveFailed: false,
        };
      } else {
        state.dirtyActionItemDataById[actionItemId].data.parentActionItemId = parentActionItemId;
      }
    },

    startedActionItemPatch: (state, action: PayloadAction<{actionItemId: string}>) => {
      const {
        actionItemId,
      } = action.payload;

      if (!state.dirtyActionItemDataById[actionItemId]) {
        const actionItem = (state.actionItemsById[actionItemId] || {}).data || {title: '', content: '', status: 'NOT_DONE', data: {}};
        state.dirtyActionItemDataById[actionItemId] = {
          title: actionItem.title || '',
          content: actionItem.content || '',
          status: actionItem.status,
          data: actionItem.data,
          saving: true,
          saveTimeoutId: null,
          saveFailed: false,
        };
      } else {
        state.dirtyActionItemDataById[actionItemId].saving = true;
        state.dirtyActionItemDataById[actionItemId].saveFailed = false;
      }
    },

    finishedActionItemPatch: (state, action: PayloadAction<{actionItemId: string}>) => {
      const {
        actionItemId,
      } = action.payload;

      if (!state.dirtyActionItemDataById[actionItemId]) {
        const actionItem = (state.actionItemsById[actionItemId] || {}).data || {title: '', content: '', status: 'NOT_DONE', data: {}};
        state.dirtyActionItemDataById[actionItemId] = {
          title: actionItem.title || '',
          content: actionItem.content || '',
          status: actionItem.status,
          data: actionItem.data,
          saving: false,
          saveTimeoutId: null,
          saveFailed: false,
        };
      } else {
        state.dirtyActionItemDataById[actionItemId].saving = false;
        state.dirtyActionItemDataById[actionItemId].saveTimeoutId = null;
        state.dirtyActionItemDataById[actionItemId].saveFailed = false;
      }
    },

    failedActionItemPatch: (state, action: PayloadAction<{actionItemId: string}>) => {
      const {
        actionItemId,
      } = action.payload;

      if (!state.dirtyActionItemDataById[actionItemId]) {
        const actionItem = (state.actionItemsById[actionItemId] || {}).data || {title: '', content: '', status: 'NOT_DONE', data: {}};
        state.dirtyActionItemDataById[actionItemId] = {
          title: actionItem.title || '',
          content: actionItem.content || '',
          status: actionItem.status,
          data: actionItem.data,
          saving: false,
          saveTimeoutId: null,
          saveFailed: true,
        };
      } else {
        state.dirtyActionItemDataById[actionItemId].saving = false;
        state.dirtyActionItemDataById[actionItemId].saveTimeoutId = null;
        state.dirtyActionItemDataById[actionItemId].saveFailed = true;
      }
    },

    queueActionItemPatch: (state, action: PayloadAction<{actionItemId: string, timeoutId: ReturnType<typeof setTimeout>}>) => {
      const {
        actionItemId,
        timeoutId,
      } = action.payload;

      if (!state.dirtyActionItemDataById[actionItemId]) {
        const actionItem = (state.actionItemsById[actionItemId] || {}).data || {title: '', content: '', status: 'NOT_DONE', data: {}};
        state.dirtyActionItemDataById[actionItemId] = {
          title: actionItem.title || '',
          content: actionItem.content || '',
          status: actionItem.status,
          data: actionItem.data,
          saving: false,
          saveTimeoutId: timeoutId,
          saveFailed: false,
        };
      } else {
        state.dirtyActionItemDataById[actionItemId].saveTimeoutId = timeoutId;
        state.dirtyActionItemDataById[actionItemId].saveFailed = false;
      }
    },

    pushTaskToTimeline: (state, action: PayloadAction<IActionItem>) => {
      state.tasksTimeline.actionItemIds.unshift(action.payload.id);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchNextTaskTimeline.fulfilled, (state, action) => {
      if (!action.payload) {
        // short circuit if request is already in flight
        return;
      }

      const {
        tasks,
        limit,
      } = action.payload;

      state.tasksTimeline = {
        isFetching: false,
        timelineOffset: state.tasksTimeline.timelineOffset + limit,
        noMoreScrollback: tasks.length < limit,
        actionItemIds: [...state.tasksTimeline.actionItemIds, ...tasks.map(t => t.id)],
      };
    });
  }
});

export const { receiveActionItems, deleteActionItem, pushTaskToTimeline } = actionItemsSlice.actions;
export default actionItemsSlice.reducer;