import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { Button, ChevronDownIcon, LockIcon, Popover, Spinner, TextInput } from 'evergreen-ui';
import { ChangeEvent, KeyboardEvent, MouseEvent, ReactNode, useEffect, useState } from 'react';
import { faAngleLeft, faCheck, faExclamationTriangle, faGlobe, faLock, faTrash } from '@fortawesome/free-solid-svg-icons';

import { NotificationType } from 'store/notifications/selectors';
import { PinSpaces } from 'constants/app';
import { deleteNote } from 'api/notes';
import { deleteNotePin } from 'store/calendar/slice';
import { notify } from 'store/notifications/slice';
import { removeNoteTagRelation } from 'store/tags/slice';
import { store } from 'store';
import { getCurrentUser, getUserNameById } from 'store/user/selector';
import { getEditingNoteContent, getEditingNoteTitle, getNoteById, getNoteIsPublic, getNoteSaveFailed, getSavingNote } from 'store/notes/selectors';
import { removeNote, updateNote } from 'store/notes/slice';
import { useAppDispatch, useAppSelector, usePrevious } from 'hooks';

import NoteEditor, { CustomElement, CustomText, getNoteEditorElement } from './noteEditor';

const PinSpaceToButtonLabel = {
  [PinSpaces.PUBLIC]: <div><FontAwesomeIcon icon={faGlobe} /> Public</div>,
  [PinSpaces.PRIVATE]: <div><FontAwesomeIcon icon={faLock} /> Private</div>,
};

const renderNotePreview = (content: string) => {
  try {
    const parsed: CustomElement[] = JSON.parse(content);
    let contentSoFar = '';
    const unparsedBlocks: Array<CustomElement | CustomText> = [];
    parsed.forEach(elt => {
      unparsedBlocks.push(elt);
    });

    while (unparsedBlocks.length !== 0) {
      const elt = unparsedBlocks.shift();

      if (!elt) {
        break;
      }

      if ('text' in elt) {
        contentSoFar = contentSoFar + `${elt.text} `;
      }

      if ('children' in elt) {
        elt.children.reverse().forEach(child => unparsedBlocks.unshift(child));
      }
    }

    return contentSoFar;
  } catch (err) {
    return content;
  }
};


interface INoteProps {
  noteId: string,
  showAsEditor?: boolean,
  showBackArrow?: boolean,
  onViewNote: (noteId?: string | undefined) => void,
  onTogglePinSpace?: (noteId: string | '') => Promise<void>,
  notesLastRead?: number,
  showNoteMadePrivate?: boolean,
  footerLeftContent?: ReactNode,
  footerRightContent?: string,
  disablePopover?: boolean,
  eventId?: string,
  tagId?: string,
}

const Note = ({
  noteId,
  showBackArrow=false,
  showAsEditor=false,
  onViewNote,
  onTogglePinSpace,
  notesLastRead,
  showNoteMadePrivate,
  footerLeftContent,
  footerRightContent,
  disablePopover=false,
  eventId,
  tagId,
}: INoteProps) => {
  const dispatch = useAppDispatch();
  const note = useAppSelector(s => getNoteById(s, noteId));
  const title = useAppSelector(s => getEditingNoteTitle(s, noteId));
  const content = useAppSelector(s => getEditingNoteContent(s, noteId));
  const saving = useAppSelector(s => getSavingNote(s, noteId));
  const saveFailed = useAppSelector(s => getNoteSaveFailed(s, noteId));
  const currentUserId = useAppSelector(getCurrentUser)?.id;
  const [saved, setSaved] = useState(false);
  const prevSaving = usePrevious(saving);
  const creatorName = useAppSelector(s => getUserNameById(s, note?.created_by || ''));
  const isPublic = useAppSelector(s => getNoteIsPublic(s, noteId));
  const noteSpace = isPublic ? PinSpaces.PUBLIC : PinSpaces.PRIVATE;
  const [pinLoading, setPinLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const createdStr = new Date((note?.data.calendarEventStart as undefined | string) || note?.created_at || '').toLocaleDateString('default', {year: '2-digit', month: 'numeric', day: 'numeric'});
  const isViewOnly = note?.created_by !== currentUserId;

  useEffect(() => {
    if (!saving && prevSaving) {
      setSaved(true);
      setTimeout(() => setSaved(false), 1500);
    }
  }, [saving, prevSaving]);

  const onView = () => {
    if (!showAsEditor) {
      onViewNote(noteId);
    }
  };

  const showSaving = saving;
  const showSaved = !saving && saved;
  const showSaveFailed = !saving && !saved && saveFailed;
  const showDate = !showSaving && !prevSaving && !showSaved && !showSaveFailed;
  const dateStr = `Last updated ${new Date(note?.updated_at || '').toLocaleString('default', { year: '2-digit', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}`;
  // TODO: figure out better default than "othermeeting attendee"
  const noteFrom = <div><FontAwesomeIcon icon={faStickyNote} /> Note from {creatorName || 'another meeting attendee'}</div>;
  const noteSpaceText = <div><FontAwesomeIcon icon={isPublic ? faGlobe : faLock} /> {isPublic ? 'Public' : 'Private'}</div>;
  const noteIsUnread = (isViewOnly && notesLastRead && new Date(note?.updated_at || 0).valueOf() > notesLastRead) || false;

  if (!note) return null;

  const togglePinSpace = async (e?: MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();
    if (pinLoading) return;

    setPinLoading(true);
    if (onTogglePinSpace) {
      onTogglePinSpace(noteId).finally(() => setPinLoading(false));
    }
  };

  if (showNoteMadePrivate && showAsEditor) {
    // the user un-shared this note
    return <div className="calendar-notes-note--container disabled">
      <div className="calendar-notes-note-title--container">
        <h4 className="calendar-notes-note--title">{createdStr} - {note.title}</h4>
        <div className='calendar-notes-note-title--shared'>{isViewOnly ? noteFrom : noteSpaceText}</div>
      </div>
      <div className="calendar-notes-note-locked--container">
        <div className="calendar-notes-note-locked--icon">
          <LockIcon color="var(--color-red-4)" size={24}/>
        </div>
        <div className="calendar-notes-note-locked--content">
          <p>{creatorName} made their note private, you no longer have access</p>
          <Button onClick={() => onViewNote()}>Back to notes list</Button>
        </div>
      </div>
    </div>;
  }

  const onDelete = async () => {
    setDeleteLoading(true);
    try {
      await deleteNote(noteId);
      setDeleteLoading(false);
      if (showAsEditor) {
        onViewNote();
      }
      if (eventId) {
        dispatch(deleteNotePin({ eventId, noteId }));
      }
      if (tagId) {
        dispatch(removeNoteTagRelation({ tagId, noteId }));
      }
      dispatch(removeNote({ noteId }));
    } catch (err) {
      notify({
        message: 'Failed to delete note, please try again.',
        type: NotificationType.WARNING,
      })(dispatch);
      setDeleteLoading(false);
    }
  };

  return (
    <>
      {!showAsEditor && <div className={cx('calendar-notes-note--container', {disabled: isViewOnly, unread: noteIsUnread, 'with-footer': !!footerLeftContent})} onClick={onView}>
        <div className={cx('calendar-notes-note-title--container', {'with-footer': !!footerLeftContent})}>
          <h4 className="calendar-notes-note--title">{createdStr} - {note.title}</h4>
          <div className='calendar-notes-note-title--shared'>{isViewOnly ? noteFrom : noteSpaceText}</div>
        </div>
        <div className={cx('calendar-notes-note--content-preview', {'with-footer': !!footerLeftContent})}>{renderNotePreview(note.content)}</div>
        {footerLeftContent && footerRightContent && <div className="calendar-notes-note-preview--footer"><span>{footerLeftContent}</span><span>{footerRightContent}</span></div>}
        {noteIsUnread && <div className="note-container-unread--indicator"/>}
      </div>}
      {showAsEditor &&
        <div className={cx('calendar-notes-note-edit--container', {error: showSaveFailed, disabled: isViewOnly})}>
          <div className="calendar-notes-note-header--container">
            <div className="calendar-notes-note-edit--header">
              {showBackArrow && <Button size="small" className="calendar-notes-note-edit-back--button" onClick={() => onViewNote()}><FontAwesomeIcon icon={faAngleLeft} /></Button>}
              <span className='calendar-notes-note-edit--datestr'>{createdStr} - </span>
              <TextInput
                value={title}
                disabled={isViewOnly}
                className="calendar-notes-editor--title"
                onChange={isViewOnly ? undefined : (e: ChangeEvent<HTMLInputElement>) => updateNote({ noteId, title: e.target.value })(dispatch, store.getState)}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => (e.code === 'Enter' ? (e.preventDefault(), getNoteEditorElement()?.focus()) : void 0)}
              />
            </div>
            {isViewOnly ?
              <div className='calendar-notes-note-title--shared'>{noteFrom}</div>
              :
              disablePopover ? <div className='calendar-notes-note-right-content--container'> <div className="calendar-notes-note-title--shared">{PinSpaceToButtonLabel[noteSpace || PinSpaces.PRIVATE]}
              </div>
              <Button size="small" className='workstream-note-actions-delete--button' onClick={onDelete} isLoading={deleteLoading}><FontAwesomeIcon icon={faTrash} size={'sm'}/></Button>
              </div>
                :
                <div className='note-actions--container'>
                  <Popover minWidth={100} content={() => (
                    <div>
                      <div
                        onClick={(e: MouseEvent<HTMLDivElement>) => !pinLoading && noteSpace === PinSpaces.PUBLIC && togglePinSpace(e)}
                        className={cx('note-actions-pin-space--button', { disabled: noteSpace === PinSpaces.PRIVATE })}
                      >{PinSpaceToButtonLabel[PinSpaces.PRIVATE]}</div>
                      <div
                        onClick={(e: MouseEvent<HTMLDivElement>) => !pinLoading && noteSpace === PinSpaces.PRIVATE && togglePinSpace(e)}
                        className={cx('note-actions-pin-space--button', { disabled: noteSpace === PinSpaces.PUBLIC })}
                      >{PinSpaceToButtonLabel[PinSpaces.PUBLIC]}</div>
                    </div>
                  )}>
                    <Button className="note-pin-space--button" isLoading={pinLoading}>{PinSpaceToButtonLabel[noteSpace || PinSpaces.PRIVATE]}<ChevronDownIcon marginLeft={4} /></Button>
                  </Popover>
                  <Button size="small" className='calendar-note-actions-delete--button' onClick={onDelete} isLoading={deleteLoading}><FontAwesomeIcon icon={faTrash}/></Button>
                </div>
            }
          </div>
          <NoteEditor disabled={isViewOnly} noteContent={content} onChange={(content: string) => updateNote({ noteId, content })(dispatch, store.getState)} noteId={note.id} />
          <div className="calendar-notes-editor--footer">
            {footerLeftContent ? footerLeftContent : <div />}
            <div>
              {showSaving && <div className="calendar-notes-footer--saving"><Spinner marginRight={4} height={12} width={12} /> saving</div>}
              {showSaved && <div className="calendar-notes-footer--saved"><FontAwesomeIcon icon={faCheck} /> saved</div>}
              {showSaveFailed && <div className="calendar-notes-footer--failed"><FontAwesomeIcon icon={faExclamationTriangle} /> could not save note</div>}
              {showDate && dateStr}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Note;