import { sort } from 'ramda';

import { RootState } from 'store';
import { IActionItem, getActionItem, getDirtyActionItemContent, getDirtyActionItemData, getDirtyActionItemParentId, getDirtyActionItemStatus, getDirtyActionItemTitle } from 'store/action_items/selectors';
import { RelationTypes, TagRelationEntityTypes, TagTimelineView } from 'constants/app';

interface ITagRelationData {
  tagged_by?: string // user ID
  tagged_reason?: 'CALENDAR_PIN' // TODO: use an app constant for this
  calendar_event_id?: string // the calendar event ID (or entity_reference_id) the item was pinned to
  calendar_event_instance_id?: string // the calendar event ID of the specific instance of a recurring meeting
}

export interface ITagRelation {
  entity_id: string
  entity_subtype: string | null
  entity_type: TagRelationEntityTypes
  relation: RelationTypes
  tag_id: string
  timestamp: string
  data?: ITagRelationData
  namespace: string | null
  updated_by: string
  updated_at?: string
}

export interface ITagEntry {
  tag_relation: ITagRelation
}


export const getTagRelationId = (relation: ITagRelation) => `tag:${relation.tag_id}.entity:${relation.entity_type}.${relation.entity_id}`;


const root = (state: RootState) => state.tags;

export const getTags = (state: RootState) => root(state).tagsById;
export const getTagById = (state: RootState, tagId: string) => getTags(state)[tagId];
export const getAllTags = (state: RootState) => Object.values(getTags(state));
const getAllNamedTags = (state: RootState) => getAllTags(state).filter(t => !!t.name);
export const getOrderedNamedTags = (state: RootState) => {
  const tags = getAllNamedTags(state);
  return sort((t1, t2) => new Date(t1.created_at).valueOf() - new Date(t2.created_at).valueOf(), tags);
};

const getTagTimeline = (state: RootState) => root(state).timelineByTagView;
const getTimelineForTag = (state: RootState, tagId: string, view: TagTimelineView) => getTagTimeline(state)[`tag:${tagId}.${view}`] || {};
export const getTimelineForTagLoading = (state: RootState, tagId: string, view: TagTimelineView) => getTimelineForTag(state, tagId, view).isFetching;
export const getTimelineForTagNeverFetched = (state: RootState, tagId: string, view: TagTimelineView) => getTagTimeline(state)[`tag:${tagId}.${view}`] === undefined;
export const getTimelineForTagHasNoMoreItems = (state: RootState, tagId: string, view: TagTimelineView) => getTimelineForTag(state, tagId, view).noMoreScrollback;
export const getEarlistTimelineEpochForTag = (state: RootState, tagId: string, view: TagTimelineView) => getTimelineForTag(state, tagId, view).earliestTimelineEpochFetch;
const getTimelineEntriesForTag = (state: RootState, tagId: string, view: TagTimelineView) => getTimelineForTag(state, tagId, view).entries || [];
export const getTimelineForTagItems = (state: RootState, tagId: string, view: TagTimelineView) => {
  const entries = getTimelineEntriesForTag(state, tagId, view);
  return sort((a, b) => new Date(b.tag_relation.timestamp).valueOf() - new Date(a.tag_relation.timestamp).valueOf(), entries);
};
export const getTagTimelineEntryForDocument = (state: RootState, tagId: string, documentId: string) => {
  return getTimelineEntriesForTag(state, tagId, TagTimelineView.PINS).find(({ tag_relation }) => tag_relation.entity_id === documentId);
};

export const getAllParentTagActionItems = (state: RootState, tagId: string) => {
  const allActionItems = (getTimelineForTagItems(state, tagId, TagTimelineView.ACTION_ITEMS)
    .map(d => getActionItem(state, d.tag_relation.entity_id))
    .filter(d => d !== null) as IActionItem[]);
  const actionItemsById: {[key: string]: IActionItem} = {};
  allActionItems.forEach(ai => {
    actionItemsById[ai.id] = ai;
  });

  const actionItemsWithNoParent = allActionItems
    .filter(ai => !ai.data.parentActionItemId || !actionItemsById[ai.data.parentActionItemId as string]);

  const withDirtyData = actionItemsWithNoParent.map(ai => {
    return {
      ...ai,
      content: getDirtyActionItemContent(state, ai.id) || ai.content,
      title: getDirtyActionItemTitle(state, ai.id) || ai.title,
      data: {...ai.data, ...getDirtyActionItemData(state, ai.id), parentActionItemId: getDirtyActionItemParentId(state, ai.id)},
      status: getDirtyActionItemStatus(state, ai.id) || ai.status,
    };
  });
  return sort(
    (ai1, ai2) => new Date(ai1.created_at).valueOf() - new Date(ai2.created_at).valueOf(),
    withDirtyData,
  );
};

