import cx from 'classnames';
import { Avatar, SearchInput } from 'evergreen-ui';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';

import Button from 'components/Button';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import { getContactName } from 'utils/strings';
import { getOldestContactUpdated } from 'store/contacts/selectors';
import { notify } from 'store/notifications/slice';
import { IContact, getContactsOrderedByUpdatedAt, getFilteredContactsOrderedByUpdatedAt, getHasNoContacts, getSidebarContactId, getSidebarIsOpen } from 'store/contacts/selectors';
import { fetchContactsList, mergeContacts } from 'api/contacts';
import { openSideBarToContact, receiveContacts, removeAndAddContacts, setOldestContactFetch } from 'store/contacts/slice';
import { useAppDispatch, useAppSelector, useVisibility } from 'hooks';

import './style.css';

const shortDateFormat = (datestring: string) => {
  const date = new Date(datestring);
  return date.toLocaleDateString(window.navigator.language, {month: 'short', day: 'numeric', year: 'numeric'});
};

const ContactRow = ({contact, onToggleSelect, isSelected=false}: {contact: IContact, onToggleSelect: (e: ChangeEvent) => void, isSelected?: boolean}) => {
  const dispatch = useAppDispatch();
  const isSidebarOpen = useAppSelector(getSidebarIsOpen);
  const selectedContactId = useAppSelector(getSidebarContactId);
  const selected = isSidebarOpen && selectedContactId === contact.id;
  const name = getContactName(contact);

  const openSidebar = () => {
    dispatch(openSideBarToContact(contact.id));
  };

  return <tr className={cx('classify--tr', {selected})} onClick={openSidebar}>
    <td className="classify--td"><input type="checkbox" checked={isSelected} onChange={onToggleSelect}/></td>
    <td className="classify--td"><Avatar name={name} size={32} shape="square" /></td>
    <td className="classify--td">{name}</td>
    <td className="classify--td">{shortDateFormat(contact.updated_at)}</td>
    <td className="classify--td">{shortDateFormat(contact.created_at)}</td>
  </tr>;
};

const LoadingIndicator = ({ loading, noMoreToLoad }: { loading: boolean, noMoreToLoad: boolean }) => {
  return <div className="contact-list--loading">
    {loading && <div className="contact-list--loading-content"><span>Loading more contacts</span><LoadingDots /></div>}
    {noMoreToLoad && <div>No more contacts.</div>}
  </div>;
};

const ContactsList = () => {
  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState('');
  const [textInput, setTextInput] = useState('');
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [mergeLoading, setMergeLoading] = useState(false);
  const [isVisible, checkInView, currentElement] = useVisibility<HTMLDivElement>(100);
  const [loading, setLoading] = useState(false);
  const [noMoreToLoad, setNoMoreToLoad] = useState(false);
  const previousFilter = useRef<string>('');

  const contacts = useAppSelector(getContactsOrderedByUpdatedAt);
  const filteredContacts = useAppSelector(state => getFilteredContactsOrderedByUpdatedAt(state, filter));
  const hasNoContacts = useAppSelector(getHasNoContacts);
  const selectedContactId = useAppSelector(getSidebarContactId);
  const oldestContactTs = useAppSelector(getOldestContactUpdated);

  useEffect(() => {
    const loadMore = async () => {
      setLoading(true);
      try {
        const {data} = await fetchContactsList(previousFilter.current !== filter ? undefined : oldestContactTs, filter);
        if (data.length === 0) {
          setNoMoreToLoad(true);
        } else {
          dispatch(setOldestContactFetch(new Date(data[data.length - 1].updated_at)));
        }
        dispatch(receiveContacts({data, filter}));
      } catch (err) {
        notify({
          message: 'Failed to load more contacts',
          type: NotificationType.ERROR
        })(dispatch);
      } finally {
        setTimeout(() => checkInView(), 0);
        setTimeout(() => setLoading(false), 250);
      }
    };
    if (!loading && ((!noMoreToLoad && isVisible) || previousFilter.current !== filter)) {
      loadMore();
      previousFilter.current = filter;
    }

  }, [isVisible, filter, loading, noMoreToLoad, oldestContactTs, dispatch]);

  if (hasNoContacts) {
    return null;
  }

  const makeToggleSelect = (id: string) => {
    return () => {
      if (selectedContacts.includes(id)) {
        setSelectedContacts(selectedContacts.filter(nid => nid !== id));
      } else {
        setSelectedContacts(selectedContacts.concat([id]));
      }
    };
  };

  const merge = async () => {
    setMergeLoading(true);
    try {
      const {data} = await mergeContacts(selectedContacts);
      if (selectedContacts.includes(selectedContactId || 'NONE')) {
        dispatch(openSideBarToContact(data.id));
      }
      dispatch(removeAndAddContacts({
        removeIds: selectedContacts,
        addContacts: [data],
      }));
      setSelectedContacts([]);
    } catch (err) {
      console.warn('failed to merge contacts', err);
      notify({
        message: 'Failed to merge contacts',
        type: NotificationType.ERROR,
      })(dispatch);
    } finally {
      setMergeLoading(false);
    }
  };

  const maybeSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setFilter(textInput);
      setNoMoreToLoad(false);
    }
  };

  return <div className="contacts-list--container">
    <h2 className="contact-list--header">Contacts</h2>
    <div className="contact-list--actions">
      <Button className="contact-list-actions--button" loading={mergeLoading} disabled={selectedContacts.length < 2} onClick={merge}>Merge</Button>
      <SearchInput
        placeholder='Search contact...'
        onKeyDown={maybeSubmit}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setTextInput(e.target.value)}
        className="calendar-pin-select-file-search--input"
      />
    </div>
    <div className="contact-list--section">
      <table className="classify--table">
        <thead className="classify--thead">
          <tr className="classify--tr">
            <th className="classify--th"></th>
            <th className="classify--th">Avatar</th>
            <th className="classify--th">Name</th>
            <th className="classify--th">last update</th>
            <th className="classify--th">first seen</th>
          </tr>
        </thead>
        <tbody className="classify--tbody">
          {(filter ? filteredContacts : contacts).map(contact => <ContactRow onToggleSelect={makeToggleSelect(contact.id)} isSelected={selectedContacts.includes(contact.id)} contact={contact} key={contact.id} />)}
        </tbody>
      </table>
      <div ref={currentElement} ></div>
      <LoadingIndicator loading={loading} noMoreToLoad={noMoreToLoad}/>
    </div>
  </div>;
};

export default ContactsList;