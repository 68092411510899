import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IDocSearchResult, ILinkSearchResult, INoteSearchResult } from './selectors';


interface ISearchState {
  query: string;
  filters: {[category: string]: string[]};
}

interface ISidebarState {
  isOpen: boolean;
  documentId: string | null;
  linkId: string | null;
  noteId: string | null;
}

export interface ISearchFacet {
  category: string;
  buckets: {
    value: string;
    count: number;
  }[]
}

export interface ISearchPage {
  total_results: number;
}

export const SearchTypes = ['documents', 'notes', 'links'] as const;
export type SearchType = typeof SearchTypes[number];

export const FailedResponseResultsCount = -1;

export interface ISearchSlice {
  activeSearchType: SearchType;
  searchResultsDocuments: IDocSearchResult[];
  searchResultsLinks: ILinkSearchResult[];
  searchResultsNotes: INoteSearchResult[];
  searchFacetsDocuments: ISearchFacet[];
  searchFacetsLinks: ISearchFacet[];
  searchFacetsNotes: ISearchFacet[];
  searchState: ISearchState;
  searchPageDocuments: null | ISearchPage;
  searchPageLinks: null | ISearchPage;
  searchPageNotes: null | ISearchPage;
  sidebar: ISidebarState;
}

const initialState: ISearchSlice = {
  activeSearchType: 'documents',
  searchResultsDocuments: [],
  searchResultsLinks: [],
  searchResultsNotes: [],
  searchFacetsDocuments: [],
  searchFacetsLinks: [],
  searchFacetsNotes: [],
  searchState: { query: '', filters: {} },
  searchPageDocuments: null,
  searchPageLinks: null,
  searchPageNotes: null,
  sidebar: { isOpen: false, documentId: null, linkId: null, noteId: null }
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    receiveDocumentResponse: (state, action: PayloadAction<{results: IDocSearchResult[], facets: ISearchFacet[], pagination: ISearchPage}>) => {
      state.searchFacetsDocuments = action.payload.facets;
      state.searchResultsDocuments = action.payload.results;
      state.searchPageDocuments = action.payload.pagination;
    },

    failedDocumentResponse: (state) => {
      state.searchPageDocuments = { total_results: FailedResponseResultsCount };
    },

    receiveLinkResponse: (state, action: PayloadAction<{results: ILinkSearchResult[], facets: ISearchFacet[], pagination: ISearchPage}>) => {
      state.searchFacetsLinks = action.payload.facets;
      state.searchResultsLinks = action.payload.results;
      state.searchPageLinks = action.payload.pagination;
    },

    failedLinkResponse: (state) => {
      state.searchPageLinks = { total_results: FailedResponseResultsCount };
    },

    receiveNoteResponse: (state, action: PayloadAction<{results: INoteSearchResult[], facets: ISearchFacet[], pagination: ISearchPage}>) => {
      state.searchFacetsNotes = action.payload.facets;
      state.searchResultsNotes = action.payload.results;
      state.searchPageNotes = action.payload.pagination;
    },

    failedNoteResponse: (state) => {
      state.searchPageNotes = { total_results: FailedResponseResultsCount };
    },

    updateSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchState.query = action.payload;
    },

    setSearchFilter: (state, action: PayloadAction<{category: string, values: string[]}>) => {
      state.searchState.filters[action.payload.category] = action.payload.values;
    },

    setSearchType: (state, action: PayloadAction<SearchType>) => {
      state.activeSearchType = action.payload;
    },

    openSidebarToDocument: (state, action: PayloadAction<string>) => {
      state.sidebar.isOpen = true;
      state.sidebar.documentId = action.payload;
      state.sidebar.linkId = null;
      state.sidebar.noteId = null;
    },

    openSidebarToLink: (state, action: PayloadAction<string>) => {
      state.sidebar.isOpen = true;
      state.sidebar.documentId = null;
      state.sidebar.linkId = action.payload;
      state.sidebar.noteId = null;
    },

    openSidebarToNote: (state, action: PayloadAction<string>) => {
      state.sidebar.isOpen = true;
      state.sidebar.documentId = null;
      state.sidebar.linkId = null;
      state.sidebar.noteId = action.payload;
    },

    closeSidebar: (state) => {
      state.sidebar.isOpen = false;
      state.sidebar.documentId = null;
      state.sidebar.linkId = null;
      state.sidebar.noteId = null;
    }
  },
});

export const { receiveDocumentResponse, receiveLinkResponse, receiveNoteResponse, failedDocumentResponse, failedLinkResponse, failedNoteResponse, updateSearchQuery, setSearchFilter, setSearchType, openSidebarToDocument, openSidebarToLink, openSidebarToNote, closeSidebar } = searchSlice.actions;
export default searchSlice.reducer;
