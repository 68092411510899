import { API } from 'api';
import { IActionItem } from 'store/action_items/selectors';
import { IDocument } from 'store/documents/selectors';
import { ILink } from 'store/links/slice';
import { INote } from 'store/notes/slice';
import { ICalEventSuggestions, IMinimalCalendarEvent } from 'store/calendar/selectors';


export interface IFetchPinResponse {
  document: IDocument | null,
  link: ILink | null,
  note: INote | null,
  pin_id: string,
  pinned_by: string,
  pinned_at: string,
  is_shared_namespace: boolean,
  calendar_event_id: string,
  pin_relation: 'DISASSOCIATED' | 'MANUAL' | 'AUTOMATIC',
}



export const removeTagFromEvent = (entRefId: string) => API.post(`/api/v1/calendar_events/${entRefId}/remove-tag`);
export const fetchEventFromGcal = (eventId: string, calendarId: string) => API.get<IMinimalCalendarEvent>(`/api/v1/calendar_events/${eventId}/fetch-from-gcal?calendar_id=${calendarId}`);
export const fetchTodayEvents = (start: Date, end: Date) => {
  return API.get<{events: IMinimalCalendarEvent[]}>(`/api/v1/calendar_events/today?start=${start.toISOString()}&end=${end.toISOString()}`);
};

export const fetchEventPinsV2 = (entityReferenceId: string) => API.get<{
  documents: {document: IDocument, pin_id: string, pinned_by: string, pinned_at: string}[],
  links: {link: ILink, pin_id: string, pinned_by: string, pinned_at: string}[],
  notes: {note: INote, pin_id: string, pinned_by: string, pinned_at: string}[],
  action_items: {action_item: IActionItem, pin_id: string, pinned_by: string, pinned_at: string}[],
  namespace: string, is_shared_namespace: boolean
}[]>(`/api/v1/calendar_events/${entityReferenceId}/pins_v3`);

export const fetchEventSuggestions = (eventId: string) => API.get<ICalEventSuggestions>(`/api/v1/calendar_events/${eventId}/suggestions`);

export const createAndPinLinkToEvent = (eventId: string, url: string, title?: string, is_public=false) => API.post<{link: ILink, pin_id: string, pinned_by: string, pinned_at: string, is_shared_namespace: boolean}>(`/api/v1/calendar_events/${eventId}/create_and_pin_link/v2?is_public=${is_public}`, {url, title});
export const pinDocumentToEvent = (eventId: string, documentId: string, is_public=false) => API.post<{document: IDocument, pin_id: string, pinned_by: string, pinned_at: string, is_shared_namespace: boolean}>(`/api/v1/calendar_events/${eventId}/pin/DOCUMENT/${documentId}/v2?is_public=${is_public}`);
export const pinLinkToEvent = (eventId: string, linkId: string, is_public=false) => API.post<{link: ILink, pin_id: string, pinned_by: string, pinned_at: string, is_shared_namespace: boolean}>(`/api/v1/calendar_events/${eventId}/pin/LINK/${linkId}/v2?is_public=${is_public}`);
export const postTogglePinSpace = (pinId: string) => API.post<{document: IDocument, pin_id: string, pinned_by: string, pinned_at: string, is_shared_namespace: boolean} | {link: ILink, pin_id: string, pinned_by: string, pinned_at: string, is_shared_namespace: boolean} | {note: INote, pin_id: string, pinned_by: string, pinned_at: string, is_shared_namespace: boolean}>(`/api/v1/calendar_events/pins/${pinId}/toggle-space`);
export const deletePin = (pinId: string) => API.delete(`/api/v1/calendar_events/pins/${pinId}`);
export const fetchPin = (pinId: string) => API.get<IFetchPinResponse>(`/api/v1/calendar_events/pins/${pinId}`);
export const pinActionItemToEvent = (eventId: string, actionItemId: string, is_public=false) => API.post<{action_item: IActionItem, pin_id: string, pinned_by: string, pinned_at: string, is_shared_namespace: boolean}>(`/api/v1/calendar_events/${eventId}/pin/ACTION_ITEM/${actionItemId}/v2?is_public=${is_public}`);