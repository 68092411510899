import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'evergreen-ui';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

const FakeNewNote = ({
  defaultTitle,
  onClick,
  loading
}: {
  defaultTitle: string,
  onClick: () => void,
  loading: boolean
}) => (
  // TODO: update classnames not specific to calendar
  <div onClick={onClick} className="calendar-notes-note--container template">
    <h4 className="calendar-notes-note--title">{defaultTitle}</h4>
    <div className="calendar-notes-note--content-preview">
      <FontAwesomeIcon icon={faEdit} className="calendar-notes-note-preview--icon" />
      {!loading ? 'Start typing...' : <div className="calendar-note-fake-note--loading"><Spinner width={12} height={12} marginRight={4} />Creating new note</div>}
    </div>
  </div>
);

export default FakeNewNote;