import { API } from 'api';
import { IActionItem } from 'store/action_items/selectors';
import { TrackEventNames, tracker } from 'utils/tracking';


export const createActionItem = (payload: {
  title: string,
  description: string,
  data?: Record<string, unknown>,
  status: string,
}) => API.post<IActionItem>('/api/v1/action_items/', payload);

export const fetchActionItemsPage = (offset: number, limit=100) => {
  const params = new URLSearchParams({offset: offset.toString(), limit: limit.toString()});
  return API.get<IActionItem[]>(`/api/v1/action_items/?${params}`);
};

export const fetchActionItem = (actionItemId: string) => API.get<IActionItem>(`/api/v1/action_items/${actionItemId}`);
export const patchActionItem = (actionItemId: string, description: string, title: string, status: string, data?: Record<string, unknown>) => API.patch<IActionItem>(`/api/v1/action_items/${actionItemId}`, {description, status, title, data}).then(tracker.trackAfterRequest(TrackEventNames.ET, {actionItemId}));
export const deleteActionItem = (actionItemId: string) => API.delete(`/api/v1/action_items/${actionItemId}`);
