import { Button } from 'evergreen-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { formatDistance } from 'date-fns';
import { rrulestr } from 'rrule';
import { faAlignJustify, faAtom, faCheckCircle, faChevronDown, faRetweet, faThumbtack, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import CalendarEventColorPicker from 'components/CalendarEventColorPicker';
import EntityTabs from 'components/EntityTabs';
import { getContactById } from 'store/contacts/selectors';
import { getCurrentUserEmail } from 'store/user/selector';
import { openSidebarToContact } from 'store/calendar/slice';
import { usePrefetch } from 'api/activities';
import { IMinimalCalendarEvent, getAllAttendeesForEvent, getEventById, getEventHasNewActivitySinceLastRead, getFocusEventId, getInternalAttendeesForEvent, getNonUserAttendeesForEvent, isContactSelected } from 'store/calendar/selectors';
import { TrackEventNames, tracker } from 'utils/tracking';
import { getContactName, wrapURLsWithAnchorTags } from 'utils/strings';
import { getIsCalendarNotesUnread, getIsCalendarPinsUnread } from 'store/read_receipts/selectors';
import { useActivityIntersection_OLD ,useActivityUnion, useAppDispatch, useAppSelector, useContactByHandle, useEventSuggestions, usePrevious, usePrivateEventPins } from 'hooks';

import CalendarActivity from './activity';
import CalendarNotes from './notes';
import CalendarPinnedAndSuggested from './pinned';
import CalendarTasks from './action_items';
import './style.css';

const RelatedActivityTypes = new Set([
  'NEW_FILE_GMAIL',
  'NEW_MESSAGE_GMAIL',
  'NEW_FILE_SLACK',
  'NEW_MENTION_SLACK',
  'NEW_FILE_COMMENT_GDRIVE',
  'NEW_FILE_MENTION_GDRIVE',
]);


const ActivityTitle = ({showDot}: {showDot: boolean}) => (
  <div className="calendar-event-details-activity--tabname">
    <FontAwesomeIcon icon={faAtom} className="calendar-event-details-activity--tabicon"/>
    Activity{showDot && <div className="calendar-event-details-activity--dot"/>}
  </div>
);

const CalendarGuest = ({
  label,
  id,
  onClick,
}: { label: string, id: string, onClick: () => void }) => {
  const selected = useAppSelector((s) => isContactSelected(s, id));
  const contact = useAppSelector((s) => getContactById(s, id));
  const loggedUserEmail = useAppSelector(s => getCurrentUserEmail(s));
  const { contact: userAsContact } = useContactByHandle(loggedUserEmail || '');
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
  twoMonthsAgo.setHours(0, 0, 0, 0);
  const prefetchIntersection = usePrefetch('activityIntersection');
  const prefetchEmails = usePrefetch('activitiesForContact');

  const onMouseOver = () => {
    if (userAsContact && contact) {
      prefetchIntersection({
        contactIds: [userAsContact.id, contact.id],
        documentIds: [],
        after: twoMonthsAgo.toISOString().replace('+00:00', 'Z'),
        activityTypes: ['NEW_FILE_GMAIL', 'NEW_MESSAGE_GMAIL'],
      });
      prefetchEmails({
        contactId: id,
        types: ['NEW_FILE_GMAIL', 'NEW_MESSAGE_GMAIL'],
      });
    }
  };

  const name = contact ? getContactName(contact) : label;

  return <div onMouseEnter={onMouseOver} className={cx('calendar-detail-guests--guest', {selected})} onClick={onClick}>{name}</div>;
};

const CalendarGuests = ({ event }: { event: IMinimalCalendarEvent }) => {
  const previousEvent = usePrevious(event);
  const [expanded, setExpanded] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (expanded && previousEvent && previousEvent.id !== event.id) {
      setExpanded(false);
    }
  }, [event, previousEvent, expanded]);

  const numGuests = event.attendees.length;
  let guests = null;

  const openSidebar = (id: string) => {
    tracker.track(TrackEventNames.FOMG, {
      contactId: id,
      eventId: event.id,
    });
    dispatch(openSidebarToContact({ id }));
  };

  if (numGuests >= 4 && !expanded) {
    const organizer = event.attendees.find(attendee => attendee.id === event.organizer_id);
    const others = event.attendees.filter(a => a.id !== event.organizer_id);
    const other = others[0];
    const oneOther = !organizer ? others[1] : null;
    guests = <div className="calendar-detail-guests--container">
      {organizer && <CalendarGuest id={organizer.id} label={getContactName(organizer)} onClick={() => openSidebar(organizer.id)} />}
      <CalendarGuest id={other.id} label={getContactName(other)} onClick={() => openSidebar(other.id)} />
      {oneOther && <CalendarGuest id={oneOther.id} label={getContactName(oneOther)} onClick={() => openSidebar(oneOther.id)} />}
      <CalendarGuest id="none" label={`+ ${numGuests - 2} more`} onClick={() => setExpanded(true)} />
    </div>;
  } else {
    const organizer = event.attendees.find(attendee => attendee.id === event.organizer_id);
    const others = event.attendees.filter(a => a.id !== event.organizer_id);
    const organizerGuest = organizer ? <CalendarGuest id={organizer.id} label={getContactName(organizer)} onClick={() => openSidebar(organizer.id)} /> : null;
    guests = <div className="calendar-detail-guests--container">
      {organizerGuest}
      {others.map(o => <CalendarGuest key={o.id} id={o.id} label={getContactName(o)} onClick={() => openSidebar(o.id)} />)}
    </div>;
  }

  return <div>
    <div className="calendar-detail-section--header">
      <div>
        <FontAwesomeIcon icon={faUserFriends} />
        <span className="calendar-detail-section--title">{numGuests} Guest{numGuests === 1 ? '' : 's'}</span>
      </div>
    </div>
    {guests}
  </div>;
};

const getRecurrenceText = (recurrence?: string): string | null => {
  if (!recurrence) return null;

  try {
    return rrulestr(recurrence).toText().replace('week on', '').replace('month on', '').replace('year on', '');
  } catch (err) {
    console.warn('Failed to parse recurrence rule', recurrence, err);
    return null;
  }
};

const CalendarHeader = ({ event }: { event: IMinimalCalendarEvent }) => {
  const start = new Date(event.start_dt);
  const end = new Date(event.end_dt);

  const fmtTime = (time: Date) => time.toLocaleTimeString(window.navigator.language, { hour: 'numeric', minute: '2-digit' });

  const join = () => {
    if (event.join_link) {
      tracker.track(TrackEventNames.JM, {
        eventId: event.id,
      });
      window.open(event.join_link);
    }
  };

  const recurrenceText = getRecurrenceText(event.recurrence);

  return (
    <div>
      <div className="calendar-event-details--header">
        <div className="calendar-event-details--title">{event.title || <em>Unnamed event</em>}</div>
        <div className="calendar-event-details-header--actions">
          <CalendarEventColorPicker calendarEventId={event.id} entityReferenceId={event.entity_reference_id} />
          <Button appearance="primary" onClick={join} disabled={!event.join_link}>Join meeting</Button>
        </div>
      </div>
      <div className="calendar-event-details--duration">
        {fmtTime(start)} - {fmtTime(end)}
        <span className="calendar-event-details--reltime">({formatDistance(new Date(event.start_dt), new Date(), { addSuffix: true })})</span>
        {!!recurrenceText && <span className="calendar-event-details--recurrence"><FontAwesomeIcon icon={faRetweet} /> {recurrenceText}</span>}
      </div>
    </div>
  );
};

const CalendarDescription = ({ event }: { event: IMinimalCalendarEvent }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <div className={cx('calendar-detail-section--header', 'collapsible')} onClick={() => setIsOpen(!isOpen)}>
        <div>
          <FontAwesomeIcon icon={faAlignJustify} />
          <span className="calendar-detail-section--title">Description</span>
          <FontAwesomeIcon icon={faChevronDown} className={cx('calendar-detail-section--caret', { closed: !isOpen })} />
        </div>
      </div>
      {isOpen && <div className='calendar-detail-description--container'>
        {event.description
          ? <p dangerouslySetInnerHTML={{ __html: wrapURLsWithAnchorTags(event.description.replace(/\n/g, '<br />')) }} />
          : <em className={cx('calendar-detail-documents--empty', 'collapsible')}>There is no description for this event.</em>
        }
      </div>}
    </div>
  );
};


export const CalendarEventDetails = ({
  small,
}: { small: boolean }) => {
  const eventId = useAppSelector(getFocusEventId);
  const event = useAppSelector((s) => getEventById(s, eventId || ''));
  const hasNewActivity = useAppSelector(s => getEventHasNewActivitySinceLastRead(s, eventId || '', event?.entity_reference_id || '', RelatedActivityTypes));
  usePrivateEventPins(event);
  useEventSuggestions(event?.id || '');

  const allAttendees = useAppSelector(s => getAllAttendeesForEvent(s, eventId || '')).map(c => c.id);
  const internalAttendees = useAppSelector(s => getInternalAttendeesForEvent(s, eventId || '')).map(c => c.id);
  const attendeesWithoutMe = useAppSelector(s => getNonUserAttendeesForEvent(s, eventId || '')).map(c => c.id);

  const hasUnreadPins = useAppSelector(s => getIsCalendarPinsUnread(s, event?.entity_reference_id || ''));
  const hasUnreadNotes = useAppSelector(s => getIsCalendarNotesUnread(s, event?.entity_reference_id || ''));

  useActivityIntersection_OLD(allAttendees);
  useActivityIntersection_OLD(internalAttendees);
  useActivityUnion(attendeesWithoutMe, [], ['NEW_MENTION_SLACK', 'NEW_FILE_MENTION_GDRIVE']);

  if (!event) {
    return null;
  }

  const tabs = [
    {
      key: 'Pins',
      title: <div className="calendar-event-details-activity--tabname"><FontAwesomeIcon className="calendar-event-details-activity--tabicon" icon={faThumbtack}/> Pinned content {hasUnreadPins && <span className="calendar-event-details-activity--dot"/>}</div>,
      content: <CalendarPinnedAndSuggested event={event} />
    },
    {
      key: 'Activity',
      title: <ActivityTitle showDot={hasNewActivity} />,
      content: <CalendarActivity event={event} />
    },
    {
      key: 'Notes',
      title: <div className="calendar-event-details-activity--tabname"><FontAwesomeIcon className="calendar-event-details-activity--tabicon" icon={faStickyNote} />Notes  {hasUnreadNotes && <span className="calendar-event-details-activity--dot"/>}</div>,
      content: <CalendarNotes event={event} />
    },
    {
      key: 'Tasks',
      title: <div className="calendar-event-details-activity--tabname"><FontAwesomeIcon className="calendar-event-details-activity--tabicon" icon={faCheckCircle}/> Tasks</div>,
      content: <CalendarTasks event={event} />
    }
  ];

  return <div className={cx('calendar-event-details--container', { small })}>
    <div className="calendar-event-details--section">
      <CalendarHeader event={event} />
    </div>
    <div className="calendar-event-details--section">
      <CalendarGuests event={event} />
    </div>
    <div className="calendar-event-details--section">
      <CalendarDescription event={event} />
    </div>
    <EntityTabs tabs={tabs} />
  </div>;
};