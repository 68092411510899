import { RootState } from 'store';
import { sort } from 'ramda';

import { IDirtyActionItemData } from './slice';

export interface IActionItem {
  id: string
  org_id: string
  title?: string
  namespace: string
  content?: string
  status: string
  data: Record<string, unknown>
  created_by: string
  created_at: string
  updated_by: string
  updated_at: string
  deleted_by?: string
  deleted_at?: string
}

const root = (state: RootState) => state.actionItems;

const actionItemsById = (state: RootState) => root(state).actionItemsById;
const actionItemById = (state: RootState, actionItemId: string) => actionItemsById(state)[actionItemId] || {};
export const getFetchingActionItem = (state: RootState, actionItemId: string) => actionItemById(state, actionItemId).isFetching;
export const getFetchActionItemFailed = (state: RootState, actionItemId: string) => actionItemById(state, actionItemId).fetchFailed;
export const getActionItem = (state: RootState, actionItemId: string) => actionItemById(state, actionItemId).data;


const getDirtyActionItems = (state: RootState) => root(state).dirtyActionItemDataById;
const getDirtyActionItemById = (state: RootState, actionItemId: string) => getDirtyActionItems(state)[actionItemId] || {};
export const getActionItemPatchTimeoutId = (state: RootState, actionItemId: string) => getDirtyActionItemById(state, actionItemId).saveTimeoutId;
export const getDirtyActionItemContent = (state: RootState, actionItemId: string) => getDirtyActionItemById(state, actionItemId).content;
export const getDirtyActionItemTitle = (state: RootState, actionItemId: string) => getDirtyActionItemById(state, actionItemId).title;
export const getDirtyActionItemStatus = (state: RootState, actionItemId: string) => getDirtyActionItemById(state, actionItemId).status;
export const getDirtyActionItemData = (state: RootState, actionItemId: string) => getDirtyActionItemById(state, actionItemId).data;
export const getDirtyActionItemParentId = (state: RootState, actionItemId: string) => getDirtyActionItemData(state, actionItemId)?.parentActionItemId;
const getDirtyActionItemSaving = (state: RootState, actionItemId: string) => getDirtyActionItemById(state, actionItemId).saving;
const getDirtyActionItemSaveFailed = (state: RootState, actionItemId: string) => getDirtyActionItemById(state, actionItemId).saveFailed;

const getChildActionItems = (state: RootState, actionItemId: string) => {
  const byId: {[id: string]: IActionItem | IDirtyActionItemData} = {};
  Object.entries(actionItemsById(state)).forEach(([id, ai]) => {
    if (ai.data) {
      byId[id] = ai.data;
    }
  });
  Object.entries(getDirtyActionItems(state)).forEach(([id, ai]) => {
    byId[id] = ai;
  });

  return Object.entries(byId).filter((d) => d[1].data.parentActionItemId === actionItemId);
};
export const getChildActionItemIds = (state: RootState, actionItemId: string) => getChildActionItems(state, actionItemId).map(([id]) => id);
export const getHasChildActionItems = (state: RootState, actionItemId: string) => getChildActionItemIds(state, actionItemId).length !== 0;

export const getParentIdOfActionItemById = (state: RootState, actionItemId: string): string | null => {
  const byId: {[id: string]: IActionItem | IDirtyActionItemData} = {};
  Object.entries(actionItemsById(state)).forEach(([id, ai]) => {
    if (ai.data) {
      byId[id] = ai.data;
    }
  });
  Object.entries(getDirtyActionItems(state)).forEach(([id, ai]) => {
    byId[id] = ai;
  });

  const actionItem = byId[actionItemId];

  if (!actionItem) {
    return null;
  }

  if (actionItem.data.parentActionItemId) {
    return (actionItem.data.parentActionItemId as string);
  }

  return null;
};

export const getEditingActionItemStatus = (state: RootState, actionItemId: string) => {
  const dirtyStatus = getDirtyActionItemStatus(state, actionItemId);
  const actionItem = getActionItem(state, actionItemId);
  if (typeof dirtyStatus === 'string') {
    return dirtyStatus;
  }
  return actionItem?.status || 'NOT_DONE';
};

export const getEditingActionItemTitle = (state: RootState, actionItemId: string) => {
  const dirtyTitle = getDirtyActionItemTitle(state, actionItemId);
  const actionItem = getActionItem(state, actionItemId);
  if (typeof dirtyTitle === 'string') {
    return dirtyTitle;
  }
  return actionItem?.title || '';
};

export const getEditingActionItemContent = (state: RootState, actionItemId: string) => {
  const dirtyContent = getDirtyActionItemContent(state, actionItemId);
  const actionItem = getActionItem(state, actionItemId);
  if (typeof dirtyContent === 'string') {
    return dirtyContent;
  }
  return actionItem?.content || '';
};

export const getSavingActionItem = (state: RootState, actionItemId: string) => getDirtyActionItemSaving(state, actionItemId) || false;
export const getActionItemSaveFailed = (state: RootState, actionItemId: string) => getDirtyActionItemSaveFailed(state, actionItemId) || false;


const getTasksTimeline = (state: RootState) => root(state).tasksTimeline;
export const getTaskTimelineNeverLoaded = (state: RootState) => getTasksTimeline(state).timelineOffset === 0;
export const getTaskTimelineOffset = (state: RootState) => getTasksTimeline(state).timelineOffset;
export const getTaskTimelineLoading = (state: RootState) => getTasksTimeline(state).isFetching;
export const getTaskTimelineIds = (state: RootState) => getTasksTimeline(state).actionItemIds;
export const getNoMoreTasksInTimeline = (state: RootState) => getTasksTimeline(state).noMoreScrollback;

export const getAllParentTimelineActionItems = (state: RootState) => {
  const allActionItems = (getTaskTimelineIds(state)
    .map(d => getActionItem(state, d))
    .filter(d => d !== null) as IActionItem[]);
  const actionItemsById: {[key: string]: IActionItem} = {};
  allActionItems.forEach(ai => {
    actionItemsById[ai.id] = ai;
  });

  const actionItemsWithNoParent = allActionItems
    .filter(ai => !ai.data.parentActionItemId || !actionItemsById[ai.data.parentActionItemId as string]);

  const withDirtyData = actionItemsWithNoParent.map(ai => {
    return {
      ...ai,
      content: getDirtyActionItemContent(state, ai.id) || ai.content,
      title: getDirtyActionItemTitle(state, ai.id) || ai.title,
      data: {...ai.data, ...getDirtyActionItemData(state, ai.id), parentActionItemId: getDirtyActionItemParentId(state, ai.id)},
      status: getDirtyActionItemStatus(state, ai.id) || ai.status,
    };
  });
  return sort(
    (ai1, ai2) => new Date(ai1.created_at).valueOf() - new Date(ai2.created_at).valueOf(),
    withDirtyData,
  );
};
