import { ConnectorProviders } from 'constants/app';
import { IConnector } from 'store/connectors/slice';
import { IContact } from 'store/contacts/selectors';
import { IActivity, IActivityMessage, INewCommentGdriveActivityData, INewFileGdriveActivityData, INewFileGmailActivityData, INewFileSlackActivityData, INewMentionSlackActivityData } from 'store/documents/selectors';
import { extractHostName, getContactName } from './strings';

const addUTCTzIfMissingTz = (timestring: string): string => {
  // where the + or - would be for a timezone indicator
  const tzPlacement = -6;
  const tzIndicator = timestring.slice(tzPlacement)[0];
  const endsWithZ = timestring.endsWith('Z');
  if (endsWithZ || ['-', '+'].includes(tzIndicator)) {
    return timestring;
  }
  return timestring + 'Z';
};

// if from slack: return the message that had the file attachment
export const activityMessageThatSentFile = (activity: IActivity) => {
  // could be either the gmail or the slack activity data, both have messages
  const messages = (activity.data as INewFileSlackActivityData).messages;

  if (messages.length === 0) {
    return null;
  }

  const timestamp = new Date(activity.timestamp).valueOf();
  const exactTsCtx = messages.find(msg => timestamp === new Date(addUTCTzIfMissingTz(msg.sent_at)).valueOf());
  if (exactTsCtx) {
    return exactTsCtx;
  }

  let messageWithFile = messages[0];
  let smallestDiffSoFar = Math.abs(new Date(addUTCTzIfMissingTz(messages[0].sent_at)).valueOf() - timestamp);

  messages.forEach((message) => {
    const msgTs = new Date(addUTCTzIfMissingTz(message.sent_at)).valueOf();
    const diff = Math.abs(msgTs - timestamp);
    if (diff < smallestDiffSoFar) {
      smallestDiffSoFar = diff;
      messageWithFile = message;
    }
  });

  return messageWithFile;
};


export const getConnectorName = (connector: IConnector) => {
  switch (connector.provider) {
    case ConnectorProviders.SALESFORCE:
      return (connector.meta.instance_url !== undefined) ? extractHostName(connector.meta.instance_url) : ' ';
    case ConnectorProviders.JIRA:
      return connector.meta.user_id || ' ';
    case ConnectorProviders.SLACK:
      return connector.meta.workspace || ' ';
    case ConnectorProviders.GMAIL:
    case ConnectorProviders.GDRIVE:
    case ConnectorProviders.GCAL:
      return connector.meta.email || ' ';
    case ConnectorProviders.OUTLOOK:
      return connector.meta.principal_name || ' ';
    case ConnectorProviders.TRELLO:
      return connector.meta.username || ' ';
    default:
      return ' ';
  }
};

const gsuiteMimetypeToPathFragment: {[key: string]: string} = {
  'application/vnd.google-apps.presentation': 'presentation',
  'application/vnd.google-apps.spreadsheet': 'spreadsheets',
  'application/vnd.google-apps.document': 'document',
  'application/vnd.google-apps.form': 'forms',
};


const getGdriveFileUrl = (gdriveFileId: string, authuser: string, mimetype?: string, commentId?: string) => {
  const pathFragment = gsuiteMimetypeToPathFragment[mimetype || ''];
  const params = new URLSearchParams({ authuser });
  if (pathFragment) {
    if (commentId) {
      params.set('disco', commentId);
    }

    return `https://docs.google.com/${pathFragment}/d/${gdriveFileId}?${params}`;
  } else {
    return `https://drive.google.com/file/d/${gdriveFileId}?${params}`;
  }
};


const EXTERNAL_LINK_TYPES_BY_PRIORITY = Object.freeze({
  'NEW_FILE_MENTION_GDRIVE': 0,
  'NEW_FILE_COMMENT_GDRIVE': 1,
  'NEW_FILE_GDRIVE': 2,
  'NEW_FILE_GMAIL': 3,
  'NEW_FILE_SLACK': 4,
  'NEW_MENTION_SLACK': 5,
  'CLASSIFY_NEW_FILE_SHARE': 6,
});


export const getExternalLinkFromActivity = (activity: IActivity, docMimetype?: string, useSlackChannel=false) => {
  if (!activity) {
    return '';
  }

  if (activity.type === 'CLASSIFY_NEW_FILE_SHARE') {
    const data = activity.data;
    if ('slack_file_share' in data) {
      const teamId = data.slack_file_share.slack_user_id.split('.')[0];
      const channelId = data.slack_file_share.channel_id;
      if (useSlackChannel) {
        return `slack://channel?id=${channelId}&team=${teamId}`;
      } else {
        return `slack://file?team=${teamId}&id=${data.slack_file_share.file_id}`;
      }
    } else if ('gdrive_file' in data) {
      const driveData = (activity.data as INewFileGdriveActivityData).gdrive_file;
      return getGdriveFileUrl(driveData.file_id, driveData.authuser, docMimetype);
    } else if ('slack_message' in data) {
      const slackMentionData = (activity.data as INewMentionSlackActivityData).slack_message;
      const mentionTeamId = slackMentionData.slack_user_id.split('.')[0];
      const mentionChannelId = slackMentionData.channel_id;
      return `slack://channel?id=${mentionChannelId}&team=${mentionTeamId}`;
    } else {
      return '';
    }
  }

  switch (activity.type) {
    case 'NEW_MESSAGE_GMAIL':
    case 'NEW_FILE_GMAIL':
      const activityData = (activity.data as INewFileGmailActivityData);
      const gmailData = activityData.gmail_message || activityData.gmail_file_share;
      return `https://mail.google.com/mail/?authuser=${gmailData.authuser}#inbox/${gmailData.message_id}`;
    case 'NEW_MENTION_SLACK':
      const slackMentionData = (activity.data as INewMentionSlackActivityData).slack_message;
      const mentionTeamId = slackMentionData.slack_user_id.split('.')[0];
      const mentionChannelId = slackMentionData.channel_id;
      return `slack://channel?id=${mentionChannelId}&team=${mentionTeamId}`;
    case 'NEW_FILE_SLACK':
      const slackData = (activity.data as INewFileSlackActivityData).slack_file_share;
      const teamId = slackData.slack_user_id.split('.')[0];
      const channelId = slackData.channel_id;
      if (useSlackChannel) {
        return `slack://channel?id=${channelId}&team=${teamId}`;
      } else {
        return `slack://file?team=${teamId}&id=${slackData.file_id}`;
      }
    case 'NEW_FILE_GDRIVE':
      const driveData = (activity.data as INewFileGdriveActivityData).gdrive_file;
      return getGdriveFileUrl(driveData.file_id, driveData.authuser, docMimetype);
    case 'NEW_FILE_MENTION_GDRIVE':
    case 'NEW_FILE_COMMENT_GDRIVE':
      const commentData = (activity.data as INewCommentGdriveActivityData).gdrive_file;
      return getGdriveFileUrl(commentData.file_id, commentData.authuser, docMimetype, commentData.comment_id);
    default:
      return '';
  }
};

export const getExternalLinkFromActivities = (activities: IActivity[], docMimetype?: string) => {
  if (!activities) {
    return '';
  }
  for (const activity_type in EXTERNAL_LINK_TYPES_BY_PRIORITY) {
    const matchingActivity = activities.find((activity) => activity_type === activity.type);
    if (matchingActivity) {
      return getExternalLinkFromActivity(matchingActivity, docMimetype);
    }
  }
  return '';
};


export const humanizeActivityParticipants = (msgs: IActivityMessage[], getContactByHandleId: (handleId: string) => IContact | null | undefined): string => {
  const contactNames: Set<string> = new Set();

  msgs.forEach(msg => {
    if (msg.sender.name) {
      contactNames.add(msg.sender.name);
    } else if (msg.sender.contact_handle_id){
      const contact = getContactByHandleId(msg.sender.contact_handle_id);
      if (contact) {
        contactNames.add(getContactName(contact));
      }
    }
  });

  const names: string[] = [];
  contactNames.forEach(name => names.push(name));

  if (names.length === 0) {
    // super weird but worth catching
    return '(nobody)';
  } else if (names.length === 1) {
    return names[0];
  } else if (names.length < 4) {
    return names.map(name => name.split(' ')[0]).join(', ');
  } else {
    const firstTwoNames = names.slice(0, 3).map(name => name.split(' ')[0]).join(', ');
    const numOthers = names.length - 2;
    return `${firstTwoNames}, and ${numOthers} others`;
  }
};
