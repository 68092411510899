import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import FakeNewNote from 'components/Note/fakeNote';
import { NotificationType } from 'store/notifications/selectors';
import { TagTimelineView } from 'constants/app';
import WorkstreamNote from 'components/WorkstreamNote';
import { getIsWorkstreamNotesUnread } from 'store/read_receipts/selectors';
import { markWorkstreamNotesAsRead } from 'store/read_receipts/slice';
import { notify } from 'store/notifications/slice';
import { receiveTagTimelineItems } from 'store/tags/slice';
import { store } from 'store';
import { INote, receiveNotes } from 'store/notes/slice';
import { ITagRelation, getTagRelationId, getTimelineForTagItems, getTimelineForTagLoading } from 'store/tags/selectors';
import { createNote, pinNoteToTag } from 'api/notes';
import { useAppDispatch, useAppSelector, useNote } from 'hooks';

import { LoadingIndicator } from './activity';
import './style.css';


export const WorkStreamNoteEditView = ({ tagId }: { tagId: string }) => {
  const {noteId} = useParams<{noteId: string}>();
  const {loading} = useNote(noteId);
  const history = useHistory();

  if (loading) {
    return <div>Loading note...</div>;
  }

  return <div className="workstream-notes-note-edit--container">
    <WorkstreamNote
      noteId={noteId}
      onViewNote={() => history.push(`/workstreams/${tagId}/notes`)}
      showAsEditor={true}
      tagId={tagId}
    />
  </div>;
};

const WorkStreamNotesView = ({ tagId }: { tagId: string }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const notes = useAppSelector(s => getTimelineForTagItems(s, tagId, TagTimelineView.NOTES));
  const loading = useAppSelector(s => getTimelineForTagLoading(s, tagId, TagTimelineView.NOTES));
  const [loadingNewNote, setLoadingNewNote] = useState(false);
  const [shouldMarkAsRead, setShouldMarkAsRead] = useState(false);
  const notesUnread = useAppSelector(s => getIsWorkstreamNotesUnread(s, tagId));

  const onViewNote = (noteId?: string) => {
    if (noteId) {
      history.push(`/workstreams/${tagId}/notes/${noteId}`);
    }
  };

  const createAndPinToTag = (title: string, content: string, data: { tagId: string }): Promise<{note: INote, tag_relation: ITagRelation}> => {
    return createNote({ title, content, data }).then(({ data: newNote }) => pinNoteToTag(tagId, newNote.id ).then(({ data }) => data));
  };

  const createAndViewNote = async () => {
    if (loadingNewNote) return;
    setLoadingNewNote(true);

    try {
      const tagPin = await createAndPinToTag('New note', '', { tagId: tagId });
      dispatch(receiveNotes([tagPin.note]));
      dispatch(receiveTagTimelineItems({
        timelineItems: [tagPin],
        tagId,
        view: TagTimelineView.NOTES,
      }));

      onViewNote(tagPin.note.id);
      setLoadingNewNote(false);
    } catch (err) {
      setLoadingNewNote(false);
      notify({
        message: 'Could not create new note, please try again.',
        type: NotificationType.WARNING,
      })(dispatch);
    }
  };

  useEffect(() => {
    if (!loading && notesUnread) {
      setShouldMarkAsRead(true);
    }
  }, [loading, tagId, notesUnread]);

  useEffect(() => {
    // mark on unmount so the markers don't go away on render
    return () => {
      if (shouldMarkAsRead) {
        markWorkstreamNotesAsRead({tagId})(dispatch, store.getState, {});
      }
    };
  }, [shouldMarkAsRead, tagId]);

  return (
    <div className='workstream-notes--container'>
      <FakeNewNote onClick={createAndViewNote} defaultTitle={`${new Date().toLocaleDateString('default', { month: 'numeric', day: 'numeric', year: 'numeric' })} New note`} loading={loadingNewNote} />
      {notes.map(note => <WorkstreamNote
        key={getTagRelationId(note.tag_relation)}
        noteId={note.tag_relation.entity_id}
        onViewNote={onViewNote}
        showAsEditor={false}
        tagId={tagId}
      />)}
      <LoadingIndicator tagId={tagId} view={TagTimelineView.NOTES} />
    </div>
  );
};

export default WorkStreamNotesView;