import { API } from 'api';
import { IActionItem } from 'store/action_items/selectors';
import { ITag } from 'store/calendar/selectors';
import { ITagRelation } from 'store/tags/selectors';

export const postCreateAndAssociateTag = (payload: {
  name?: string,
  config: Record<string, unknown>,
  entities: {
    entity_type: 'ACTIVITY' | 'CALENDAR_EVENT',
    entity_id: string,
  }[],
  existing_tag_id?: string,
  tag_relation_data?: Record<string, unknown>,
}) => API.post<ITag>('/api/v1/tags/create-and-or-associate', payload);


export const fetchTagTimeline = (tagId: string, before?: number | null, entityTypes?: string[], limit?: number) => {
  const params = new URLSearchParams();
  if (before) {
    const date = new Date(before).toISOString().replace('+00:00', 'Z');
    params.set('before', date);
  }

  (entityTypes || []).forEach(type => {
    params.append('entity_type', type);
  });

  if (limit) {
    params.set('limit', limit.toString());
  }

  return API.get(`/api/v1/tags/${tagId}/timeline?${params}`);
};


export const fetchTagRelation = (tagRelationId: string) => API.get(`/api/v1/tags/relations/${tagRelationId}`);
export const deleteTagRelation = (tagRelationId: string) => API.delete(`/api/v1/tags/relations/${tagRelationId}`);
export const deleteTag = (tagId: string) => API.delete<{status:string}>(`/api/v1/tags/${tagId}`);

export const postPinDocumentToTag = (tagId: string, documentId: string) => API.post(`/api/v1/tags/${tagId}/pin/DOCUMENT/${documentId}`);
export const postCreateAndPinLinkToTag = (tagId: string, url: string, title?: string) => API.post(`/api/v1/tags/${tagId}/create_and_pin_link`, {url, title});
export const postPinActionItemToTag = (tagId: string, actionItemId: string) => API.post<{action_item: IActionItem, tag_relation: ITagRelation}>(`/api/v1/tags/${tagId}/pin/ACTION_ITEM/${actionItemId}`);