import { API } from 'api';
import { IRule } from 'store/rules/selectors';


export const fetchAllRules = () => API.get<IRule[]>('/api/v1/rules/');


export interface IRulePayload {
  name: string,
  place: number,
  triggers: string[],
  conditions: {
    evaluation: 'ANY' | 'ALL',
    conditions: {field: string, operator: string, value: string[]}[],
  },
  actions: {actions: {action: string, action_specifier?: string}[], stop_after_eval: boolean},
}


export const postRule = (payload: IRulePayload) => API.post<IRule>('/api/v1/rules/', payload);


export const deleteRule = (ruleId: string) => API.delete<IRule>(`/api/v1/rules/${ruleId}`);


export const patchRule = (ruleId: string, payload: IRulePayload) => API.patch<IRule>(`/api/v1/rules/${ruleId}`, payload);
