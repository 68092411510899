const GCalendarIcon = ({ className = '' }: { className?: string }) => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_286_2601)">
      <path d="M26.7101 8.28906H8.28906V26.7101H26.7101V8.28906Z" fill="white" />
      <path d="M26.7109 35.0004L35.0004 26.7109H26.7109V35.0004Z" fill="#EA4335" />
      <path d="M35.0004 8.28906H26.7109V26.7101H35.0004V8.28906Z" fill="#FBBC04" />
      <path d="M26.7101 26.7109H8.28906V35.0004H26.7101V26.7109Z" fill="#34A853" />
      <path d="M0 26.7109V32.2373C0 33.7639 1.23651 35.0004 2.76316 35.0004H8.28947V26.7109H0Z" fill="#188038" />
      <path d="M35.0004 8.28947V2.76316C35.0004 1.23651 33.7639 0 32.2373 0H26.7109V8.28947H35.0004Z" fill="#1967D2" />
      <path d="M26.7105 0H2.76316C1.23651 0 0 1.23651 0 2.76316V26.7105H8.28947V8.28947H26.7105V0Z" fill="#4285F4" />
      <path d="M12.0679 22.5802C11.3794 22.1151 10.9028 21.4358 10.6426 20.5378L12.2406 19.8792C12.3857 20.4319 12.639 20.8602 13.0005 21.1641C13.3597 21.4681 13.7972 21.6177 14.3084 21.6177C14.8311 21.6177 15.2801 21.4588 15.6554 21.1411C16.0307 20.8233 16.2196 20.4181 16.2196 19.9276C16.2196 19.4256 16.0215 19.0158 15.6255 18.698C15.2294 18.3802 14.732 18.2213 14.138 18.2213H13.2146V16.6394H14.0436C14.5547 16.6394 14.9853 16.5013 15.3353 16.225C15.6853 15.9486 15.8603 15.571 15.8603 15.0898C15.8603 14.6615 15.7038 14.3207 15.3906 14.0651C15.0774 13.8095 14.6814 13.6806 14.2001 13.6806C13.7304 13.6806 13.3574 13.8049 13.0811 14.0559C12.8049 14.3075 12.5973 14.6253 12.4778 14.9792L10.8959 14.3207C11.1054 13.7266 11.4899 13.2016 12.0541 12.748C12.6182 12.2944 13.339 12.0664 14.214 12.0664C14.861 12.0664 15.4436 12.1907 15.9594 12.4417C16.4751 12.6927 16.8804 13.0404 17.1728 13.4825C17.4653 13.9269 17.6103 14.4243 17.6103 14.9769C17.6103 15.5411 17.4745 16.0177 17.2028 16.4092C16.9311 16.8006 16.5972 17.1 16.2011 17.3095V17.4039C16.7125 17.6148 17.1568 17.9611 17.486 18.4056C17.8199 18.8546 17.988 19.3911 17.988 20.0174C17.988 20.6437 17.8291 21.2033 17.5113 21.6937C17.1936 22.1842 16.7538 22.571 16.1965 22.8519C15.637 23.1329 15.0084 23.2756 14.3107 23.2756C13.5024 23.2779 12.7564 23.0454 12.0679 22.5802ZM21.884 14.65L20.1294 15.9187L19.2521 14.5878L22.3998 12.3174H23.6064V23.0269H21.884V14.65Z" fill="#4285F4" />
    </g>
    <defs>
      <clipPath id="clip0_286_2601">
        <rect width="35" height="35" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GCalendarIcon;
