import { AppDispatch, RootState } from 'store';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { lclId } from 'utils/uuid';
import { markAsRead } from 'api/read_receipts';

import { IReadReceipt, getReceiptByKey, isMarkReadPending, isReceiptUnread } from './selectors';


export interface ReceiptState {
  readReceiptsByKey: {[readReceiptKey: string]: IReadReceipt},
  markingReceiptsAsReadPending: {[keyOrId: string]: unknown},
}

const initialState: ReceiptState = {
  readReceiptsByKey: {},
  markingReceiptsAsReadPending: {},
};


export interface IUpdatedReceiptWSPayload {
  receipt: {
    id: string
    read_receipt_key: string
    namespace: string
    last_changed: string
    last_read: string
  }
}


export const wsReceiveUpdatedReadReceipt = createAsyncThunk<
  IReadReceipt,
  IUpdatedReceiptWSPayload
>(
  'readReceipts/wsReceiveUpdatedReadReceipt',
  async ({receipt}) => receipt,
);


export const markCalendarActivityAsRead = createAsyncThunk<
  IReadReceipt | undefined,
  { referenceId: string },
  { state: RootState, dispatch: AppDispatch}
>(
  'readReceipts/markCalendarActivityAsRead',
  async ({referenceId}, {getState, dispatch}) => {
    const key = `calendar_event:${referenceId}.activity`;
    const receipt = getReceiptByKey(getState(), key);
    if (!isMarkReadPending(getState(), [receipt?.id || '', key])) {
      dispatch(receiptSlice.actions.setMarkingAsRead([receipt?.id || key, key]));
      await markAsRead([key], new Date().toISOString());
      const lastRead = new Date().toISOString();
      return {
        id: lclId(),
        read_receipt_key: key,
        last_changed: new Date().toISOString(),
        ...(receipt || {}),
        last_read: lastRead,
      };
    } else {
      return undefined;
    }
  },
);



export const markCalendarNotesAsRead = createAsyncThunk<
  IReadReceipt | undefined,
  { referenceId: string },
  { state: RootState, dispatch: AppDispatch}
>(
  'readReceipts/markCalendarNotesAsRead',
  async ({referenceId}, {getState, dispatch}) => {
    const key = `calendar_event:${referenceId}.notes`;
    const receipt = getReceiptByKey(getState(), key);
    if (receipt && isReceiptUnread(receipt) && !isMarkReadPending(getState(), [receipt.id, receipt.read_receipt_key])) {
      dispatch(receiptSlice.actions.setMarkingAsRead([receipt.id, receipt.read_receipt_key]));
      await markAsRead([receipt.read_receipt_key], new Date().toISOString());
      const lastRead = new Date().toISOString();
      return {
        ...receipt,
        last_read: lastRead,
      };
    } else {
      return undefined;
    }
  },
);


export const markCalendarPinsAsRead = createAsyncThunk<
  IReadReceipt | undefined,
  { referenceId: string },
  { state: RootState, dispatch: AppDispatch}
>(
  'readReceipts/markCalendarPinsAsRead',
  async ({referenceId}, {getState, dispatch}) => {
    const key = `calendar_event:${referenceId}.pins`;
    const receipt = getReceiptByKey(getState(), key);
    if (receipt && isReceiptUnread(receipt) && !isMarkReadPending(getState(), [receipt.id, receipt.read_receipt_key])) {
      dispatch(receiptSlice.actions.setMarkingAsRead([receipt.id, receipt.read_receipt_key]));
      await markAsRead([receipt.read_receipt_key], new Date().toISOString());
      const lastRead = new Date().toISOString();
      return {
        ...receipt,
        last_read: lastRead,
      };
    } else {
      return undefined;
    }
  },
);


export const markWorkstreamNotesAsRead = createAsyncThunk<
  IReadReceipt | undefined,
  { tagId: string },
  { state: RootState, dispatch: AppDispatch}
>(
  'readReceipts/markWorkstreamNotesAsRead',
  async ({tagId}, {getState, dispatch}) => {
    const key = `workstream:${tagId}.notes`;
    const receipt = getReceiptByKey(getState(), key);
    if (receipt && isReceiptUnread(receipt) && !isMarkReadPending(getState(), [receipt.id, receipt.read_receipt_key])) {
      dispatch(receiptSlice.actions.setMarkingAsRead([receipt.id, receipt.read_receipt_key]));
      await markAsRead([receipt.read_receipt_key], new Date().toISOString());
      const lastRead = new Date().toISOString();
      return {
        ...receipt,
        last_read: lastRead,
      };
    } else {
      return undefined;
    }
  },
);


export const markWorkstreamPinsAsRead = createAsyncThunk<
  IReadReceipt | undefined,
  { tagId: string },
  { state: RootState, dispatch: AppDispatch}
>(
  'readReceipts/markWorkstreamPinsAsRead',
  async ({tagId}, {getState, dispatch}) => {
    const key = `workstream:${tagId}.pins`;
    const receipt = getReceiptByKey(getState(), key);
    if (receipt && isReceiptUnread(receipt) && !isMarkReadPending(getState(), [receipt.id, receipt.read_receipt_key])) {
      dispatch(receiptSlice.actions.setMarkingAsRead([receipt.id, receipt.read_receipt_key]));
      await markAsRead([receipt.read_receipt_key], new Date().toISOString());
      const lastRead = new Date().toISOString();
      return {
        ...receipt,
        last_read: lastRead,
      };
    } else {
      return undefined;
    }
  },
);


export const receiptSlice = createSlice({
  name: 'read_receipts',
  initialState,
  reducers: {
    receiveReceipts: (state, action: PayloadAction<IReadReceipt[]>) => {
      action.payload.forEach(receipt => {
        state.readReceiptsByKey[receipt.read_receipt_key] = receipt;
      });
    },

    setMarkingAsRead: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach(keyOrId => {
        state.markingReceiptsAsReadPending[keyOrId] = keyOrId;
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(wsReceiveUpdatedReadReceipt.fulfilled, (state, action) => {
      state.readReceiptsByKey[action.payload.read_receipt_key] = action.payload;
    });

    builder.addCase(markCalendarPinsAsRead.fulfilled, (state, action) => {
      // short circuit because the receipt is already read
      if (!action.payload) return;

      delete state.markingReceiptsAsReadPending[action.payload.id];
      delete state.markingReceiptsAsReadPending[action.payload.read_receipt_key];
      state.readReceiptsByKey[action.payload.read_receipt_key] = action.payload;
    });

    builder.addCase(markCalendarNotesAsRead.fulfilled, (state, action) => {
      // short circuit because the receipt is already read
      if (!action.payload) return;

      delete state.markingReceiptsAsReadPending[action.payload.id];
      delete state.markingReceiptsAsReadPending[action.payload.read_receipt_key];
      state.readReceiptsByKey[action.payload.read_receipt_key] = action.payload;
    });

    builder.addCase(markCalendarActivityAsRead.fulfilled, (state, action) => {
      // short circuit because the receipt is already read
      if (!action.payload) return;

      delete state.markingReceiptsAsReadPending[action.payload.id];
      delete state.markingReceiptsAsReadPending[action.payload.read_receipt_key];
      state.readReceiptsByKey[action.payload.read_receipt_key] = action.payload;
    });

  }
});

export const { receiveReceipts } = receiptSlice.actions;
export default receiptSlice.reducer;
