import { AppDispatch, RootState } from 'store';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IAsyncDataSlice } from 'store/interfaces';
import { PinSpaces } from 'constants/app';
import { lclId } from 'utils/uuid';
import { receiveActionItems } from 'store/action_items/slice';
import { receiveContacts } from 'store/contacts/slice';
import { receiveDocuments } from 'store/documents/slice';
import { receiveLinks } from 'store/links/slice';
import { receiveNotes } from 'store/notes/slice';
import { fetchEventFromGcal, fetchEventPinsV2, fetchEventSuggestions, fetchPin } from 'api/calendar';

import { ICalEventSuggestions, IMinimalCalendarEvent, ITag, getEventPinsData, hasRequestedTime, isFetchingEvent, isFetchingEventPins, isFetchingEventSuggestions } from './selectors';


interface IAsyncEventSuggestions {
  data: null | ICalEventSuggestions,
  fetchFailed: boolean,
  isFetching: boolean,
  lastReceived: null | Date,
}

export interface ICalendarPinWSPayload {
  pin_id: string;
  calendar_event_id: string;
  pin_relation: 'RelationTypes.MANUAL' | 'RelationTypes.AUTOMATIC' | 'RelationTypes.DISASSOCIATED';
}

export interface IUpdatedEventsWSPayload {
  events: {
    id: string;
    status: string;
    start_date?: string;
  }[];
}

export interface INamespacedPins {
  namespace: string,
  is_shared_namespace: boolean,
  action_items: {action_item: string, pin_id: string, pinned_by: string, pinned_at: string}[],
  documents: {document: string, pin_id: string, pinned_by: string, pinned_at: string}[],
  links: {link: string, pin_id: string, pinned_by: string, pinned_at: string}[],
  notes: {note: string, pin_id: string, pinned_by: string, pinned_at: string}[],
}

export interface CalendarState {
  eventsById: {[eventId: string]: IAsyncDataSlice<IMinimalCalendarEvent>},
  pinsByRefId: {[eventId: string]: IAsyncDataSlice<INamespacedPins[]>},
  // TODO: normalize suggestions
  suggestionsByEventId: {
    [eventId: string]: IAsyncEventSuggestions,
  },
  requestedEventEpochs: {
    start: null | number,
    end: null | number,
  },
  view: {
    sidebarStack: {
      type: 'document' | 'contact' | 'link',
      entityId: string,
    }[],
    sidebarIsOpen: boolean,
    focusCalendarEventId?: string,
  },
}

const initialState: CalendarState = {
  eventsById: {},
  pinsByRefId: {},
  suggestionsByEventId: {},
  view: {
    sidebarStack: [],
    sidebarIsOpen: false,
    focusCalendarEventId: undefined,
  },
  requestedEventEpochs: {
    start: null,
    end: null,
  },
};

export const wsUpdateCalendarEvents = createAsyncThunk<
  void,
  IUpdatedEventsWSPayload,
  {dispatch: AppDispatch, state: RootState}
>(
  'calendar/wsUpdateCalendarEvents',
  async ({events}, {getState, dispatch}) => {
    const cancelledEventIds = events.filter(evt => evt.status === 'cancelled').map(({id}) => id);

    dispatch(calendarSlice.actions.removeEvents(cancelledEventIds));

    const calEvents = await Promise.all(
      events
        .filter(({start_date}) => !!start_date && hasRequestedTime(getState(), new Date(start_date).valueOf()))
        .map(event => fetchEventFromGcal(event.id, 'primary').then(({data}) => data))
    );
    dispatch(receiveEvents(calEvents));
  }
);

export const wsFetchCalendarPin = createAsyncThunk<
  void,
  ICalendarPinWSPayload,
  {dispatch: AppDispatch, state: RootState}
>(
  'calendar/wsFetchCalendarPin',
  async ({pin_id: pinId, calendar_event_id: calendarEventId, pin_relation: pinRelation}, {getState, dispatch}) => {

    if (getEventPinsData(getState(), calendarEventId) === undefined) return;

    if (pinRelation === 'RelationTypes.DISASSOCIATED') {
      dispatch(deletePin({ referenceId: calendarEventId, pinId }));
      return;
    }

    const {data} = await fetchPin(pinId);

    if ('link' in data && !!data.link) {
      dispatch(receiveLinks([data.link]));
      dispatch(pinLink({
        referenceId: calendarEventId,
        linkId: data.link.id,
        pinnedBy: data.pinned_by,
        isPublicPin: data.is_shared_namespace,
        pinId,
      }));
    } else if ('document' in data && !!data.document) {
      dispatch(receiveDocuments([data.document]));
      dispatch(pinDocument({
        referenceId: calendarEventId,
        docId: data.document.id,
        pinnedBy: data.pinned_by,
        isPublicPin: data.is_shared_namespace,
        pinId,
      }));
    } else if ('note' in data && !!data.note) {
      dispatch(receiveNotes([data.note]));
      dispatch(pinNote({
        referenceId: calendarEventId,
        noteId: data.note.id,
        pinnedBy: data.pinned_by,
        isPublicPin: data.is_shared_namespace,
        pinId,
      }));
    }
  }
);


export const fetchPinsByEvent = createAsyncThunk<INamespacedPins[] | undefined, IMinimalCalendarEvent, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'calendar/fetchPinsByEvent',
  async (event: IMinimalCalendarEvent, {getState, dispatch}) => {
    if (!isFetchingEventPins(getState(), event.entity_reference_id)) {
      dispatch(calendarSlice.actions.setFetchingEventPins({ refId: event.entity_reference_id, isFetching: true}));
      const {data} = await fetchEventPinsV2(event.entity_reference_id);
      data.forEach(({ documents, links, notes, action_items }) => {
        dispatch(receiveLinks(links.map(({ link }) => link)));
        dispatch(receiveDocuments(documents.map(({ document }) => document)));
        dispatch(receiveNotes(notes.map(({ note }) => note)));
        dispatch(receiveActionItems(action_items.map(({ action_item }) => action_item)));
      });
      return data.map(namespacedPins => {
        return {
          ...namespacedPins,
          links: namespacedPins.links.map(pinnedLink => ({
            ...pinnedLink,
            link: pinnedLink.link.id,
          })),
          documents: namespacedPins.documents.map(pinnedDoc => ({
            ...pinnedDoc,
            document: pinnedDoc.document.id,
          })),
          notes: namespacedPins.notes.map(pinnedNote => ({
            ...pinnedNote,
            note: pinnedNote.note.id,
          })),
          action_items: namespacedPins.action_items.map(pinnedActItem => ({
            ...pinnedActItem,
            action_item: pinnedActItem.action_item.id,
          })),
        };
      });
    }
  }
);


export const fetchSuggestionsByEvent = createAsyncThunk<ICalEventSuggestions | undefined, string, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'calendar/fetchSuggestionsByEvent',
  async (eventId: string, {getState, dispatch}) => {
    if (!isFetchingEventSuggestions(getState(), eventId)) {
      dispatch(setFetchingEventSuggestions({ eventId, isFetching: true}));
      const {data} = await fetchEventSuggestions(eventId);
      dispatch(receiveDocuments(data.documents.map(d => d.document)));
      return data;
    }
  }
);


export const fetchCalendarEventById = createAsyncThunk<IMinimalCalendarEvent | undefined, string, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'calendar/fetchEventById',
  async (eventId: string, {getState, dispatch}) => {
    if (!isFetchingEvent(getState(), eventId)) {
      dispatch(calendarSlice.actions.setFetchingEvent({ eventId, isFetching: true }));
      const {data} = await fetchEventFromGcal(eventId, 'primary');
      dispatch(receiveContacts({data: data.attendees}));
      return data;
    }
  }
);


export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {

    removeEvents: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach(eventId => {
        if (!state.eventsById) {
          state.eventsById = {};
        }

        delete state.eventsById[eventId];
      });
    },
    receiveEvents: (state, action: PayloadAction<IMinimalCalendarEvent[]>) => {
      action.payload.forEach(event => {
        if (!state.eventsById) {
          state.eventsById = {};
        }

        state.eventsById[event.id] = {
          data: event,
          lastReceived: new Date(),
          fetchFailed: false,
          isFetching: false,
        };
      });
    },

    setFetchingEvent: (state, action: PayloadAction<{eventId: string, isFetching: boolean}>) => {
      const {
        eventId,
        isFetching,
      } = action.payload;

      if (state.eventsById[eventId]) {
        state.eventsById[eventId].isFetching = isFetching;
      } else {
        state.eventsById[eventId] = {
          data: null,
          lastReceived: null,
          fetchFailed: false,
          isFetching: isFetching,
        };
      }
    },

    setFetchingEventPins: (state, action: PayloadAction<{refId: string, isFetching: boolean}>) => {
      const refId = action.payload.refId;
      const isFetching = action.payload.isFetching;
      if (state.pinsByRefId[refId]) {
        state.pinsByRefId[refId].isFetching = isFetching;
      } else {
        state.pinsByRefId[refId] = {
          data: null,
          lastReceived: null,
          fetchFailed: false,
          isFetching: isFetching,
        };
      }
    },

    setFetchingEventSuggestions: (state, action: PayloadAction<{eventId: string, isFetching: boolean}>) => {
      const eventId = action.payload.eventId;
      const isFetching = action.payload.isFetching;
      if (state.suggestionsByEventId[eventId]) {
        state.suggestionsByEventId[eventId].isFetching = isFetching;
      } else {
        state.suggestionsByEventId[eventId] = {
          data: null,
          lastReceived: null,
          fetchFailed: false,
          isFetching: isFetching,
        };
      }
    },

    focusOnEvent: (state, action: PayloadAction<string>) => {
      state.view.focusCalendarEventId = action.payload;
    },

    openSidebarToContact: (state, action: PayloadAction<{id: string, pushState?: boolean}>) => {
      if (action.payload.pushState) {
        state.view.sidebarStack.unshift({
          type: 'contact',
          entityId: action.payload.id,
        });
      } else {
        state.view.sidebarStack = [{type: 'contact', entityId: action.payload.id}];
      }
      state.view.sidebarIsOpen = true;
    },

    openSidebarToDocument: (state, action: PayloadAction<{id: string, pushState?: boolean}>) => {
      if (action.payload.pushState) {
        state.view.sidebarStack.unshift({
          type: 'document',
          entityId: action.payload.id,
        });
      } else {
        state.view.sidebarStack = [{type: 'document', entityId: action.payload.id}];
      }
      state.view.sidebarIsOpen = true;
    },

    openSidebarToLink: (state, action: PayloadAction<{id: string, pushState?: boolean}>) => {
      if (action.payload.pushState) {
        state.view.sidebarStack.unshift({
          type: 'link',
          entityId: action.payload.id,
        });
      } else {
        state.view.sidebarStack = [{type: 'link', entityId: action.payload.id}];
      }
      state.view.sidebarIsOpen = true;
    },

    closeSidebar: (state) => {
      state.view.sidebarStack = [];
      state.view.sidebarIsOpen = false;
    },

    popSidebarHistory: (state) => {
      state.view.sidebarStack.shift();
    },

    pinDocument: (state, action: PayloadAction<{referenceId: string, docId: string, isPublicPin: boolean, pinId: string, pinnedBy: string}>) => {
      const {
        referenceId,
        isPublicPin,
        docId,
        pinId,
        pinnedBy,
      } = action.payload;
      const pins = state.pinsByRefId[referenceId];
      if (pins && pins.data) {
        let namespacePins = pins.data.find(p => p.is_shared_namespace === isPublicPin);
        if (!namespacePins) {
          namespacePins = {
            is_shared_namespace: isPublicPin,
            namespace: `lcl:${lclId()}`,
            documents: [],
            links: [],
            notes: [],
            action_items: [],
          };
          pins.data.push(namespacePins);
        }
        const isAlreadyInDocs = namespacePins.documents.find(({ document })=> document === docId);
        if (!isAlreadyInDocs) {
          namespacePins.documents.push({
            document: docId,
            pin_id: pinId,
            pinned_by: pinnedBy,
            pinned_at: (new Date()).toISOString(),
          });
        } else {
          namespacePins.documents = namespacePins.documents.map(pin => {
            if (pin.pin_id === pinId) {
              return {
                ...pin,
                pinned_by: pinnedBy,
              };
            } else {
              return pin;
            }
          });
        }
      }
    },

    pinLink: (state, action: PayloadAction<{referenceId: string, linkId: string, isPublicPin: boolean, pinId: string, pinnedBy: string}>) => {
      const {
        referenceId,
        isPublicPin,
        linkId,
        pinId,
        pinnedBy,
      } = action.payload;
      const pins = state.pinsByRefId[referenceId];
      if (pins && pins.data) {
        let namespacePins = pins.data.find(p => p.is_shared_namespace === isPublicPin);
        if (!namespacePins) {
          namespacePins = {
            is_shared_namespace: isPublicPin,
            namespace: `lcl:${lclId()}`,
            documents: [],
            links: [],
            notes: [],
            action_items: [],
          };
          pins.data.push(namespacePins);
        }

        const isAlreadyInLinks = namespacePins.links.find(({ link })=> link === linkId);
        if (!isAlreadyInLinks) {
          namespacePins.links.push({
            link: linkId,
            pin_id: pinId,
            pinned_by: pinnedBy,
            pinned_at: (new Date()).toISOString(),
          });
        } else {
          namespacePins.links = namespacePins.links.map(pin => {
            if (pin.pin_id === pinId) {
              return {
                ...pin,
                pinned_by: pinnedBy,
              };
            } else {
              return pin;
            }
          });
        }
      }
    },

    pinActionItem: (state, action: PayloadAction<{referenceId: string, actionItemId: string, isPublicPin: boolean, pinId: string, pinnedBy: string}>) => {
      const {
        referenceId,
        isPublicPin,
        actionItemId,
        pinId,
        pinnedBy,
      } = action.payload;
      const pins = state.pinsByRefId[referenceId];
      if (pins && pins.data) {
        let namespacePins = pins.data.find(p => p.is_shared_namespace === isPublicPin);
        if (!namespacePins) {
          namespacePins = {
            is_shared_namespace: isPublicPin,
            namespace: `lcl:${lclId()}`,
            documents: [],
            links: [],
            notes: [],
            action_items: [],
          };
          pins.data.push(namespacePins);
        }
        const isAlreadyInActionItems = namespacePins.action_items.find(({ action_item }) => action_item === actionItemId);
        if (!isAlreadyInActionItems) {
          namespacePins.action_items.push({
            action_item: actionItemId,
            pin_id: pinId,
            pinned_by: pinnedBy,
            pinned_at: (new Date()).toISOString(),
          });
        } else {
          namespacePins.action_items = namespacePins.action_items.map(pin => {
            if (pin.pin_id === pinId) {
              return {
                ...pin,
                pinned_by: pinnedBy,
              };
            } else {
              return pin;
            }
          });
        }
      }
    },

    pinNote: (state, action: PayloadAction<{referenceId: string, noteId: string, isPublicPin: boolean, pinId: string, pinnedBy: string}>) => {
      const {
        referenceId,
        isPublicPin,
        noteId,
        pinId,
        pinnedBy,
      } = action.payload;
      const pins = state.pinsByRefId[referenceId];
      if (pins && pins.data) {
        let namespacePins = pins.data.find(p => p.is_shared_namespace === isPublicPin);
        if (!namespacePins) {
          namespacePins = {
            is_shared_namespace: isPublicPin,
            namespace: `lcl:${lclId()}`,
            documents: [],
            links: [],
            notes: [],
            action_items: [],
          };
          pins.data.push(namespacePins);
        }
        const isAlreadyInNotes = namespacePins.notes.find(({ note }) => note === noteId);
        if (!isAlreadyInNotes) {
          namespacePins.notes.push({
            note: noteId,
            pin_id: pinId,
            pinned_by: pinnedBy,
            pinned_at: (new Date()).toISOString(),
          });
        } else {
          namespacePins.notes = namespacePins.notes.map(pin => {
            if (pin.pin_id === pinId) {
              return {
                ...pin,
                pinned_by: pinnedBy,
              };
            } else {
              return pin;
            }
          });
        }
      }
    },

    // TODO: remove this action once collab is ungated
    unpinDocument: (state, action: PayloadAction<{referenceId: string, documentId: string}>) => {
      const pins = state.pinsByRefId[action.payload.referenceId];
      if (pins && pins.data) {
        const privatePins = pins.data.find(p => !p.is_shared_namespace);
        if (!privatePins) return;
        privatePins.documents = privatePins.documents.filter(({document})=> document !== action.payload.documentId);
      }
    },

    deletePin: (state, action: PayloadAction<{referenceId: string, pinId: string}>) => {
      const {
        referenceId,
        pinId,
      } = action.payload;
      const pins = state.pinsByRefId[referenceId];
      if (pins && pins.data) {
        const newData = pins.data.map(namespacedPins => {
          return {
            ...namespacedPins,
            links: namespacedPins.links.filter(({ pin_id }) => pin_id !== pinId),
            documents: namespacedPins.documents.filter(({ pin_id }) => pin_id !== pinId),
            notes: namespacedPins.notes.filter(({ pin_id }) => pin_id !== pinId),
          };
        });
        pins.data = newData;
      }
    },

    deleteNotePin: (state, action: PayloadAction<{ eventId: string, noteId: string }>) => {
      const { eventId, noteId } = action.payload;
      const pins = state.pinsByRefId[eventId].data;
      if (!pins) return;
      state.pinsByRefId[eventId].data = pins.map(({ notes, ...rest}) => {
        const newNotes = notes.filter(note => note.note !== noteId);
        return {...rest, notes: newNotes};
      });
    },

    unpinActionItem: (state, action: PayloadAction<{actionItemId: string}>) => {
      const {actionItemId} = action.payload;

      Object.entries(state.pinsByRefId).map(([refId, pins]) => {
        const newPins = pins.data?.map(namespacedPins => ({
          ...namespacedPins,
          action_items: namespacedPins.action_items.filter(pin => pin.action_item !== actionItemId)
        }));

        return {refId, pins: newPins};
      }).forEach(({refId, pins}) => {
        state.pinsByRefId[refId].data = pins || null;
      });
    },

    unpinLink: (state, action: PayloadAction<{referenceId: string, linkId: string}>) => {
      const pins = state.pinsByRefId[action.payload.referenceId];
      if (pins && pins.data) {
        const privatePins = pins.data.find(p => !p.is_shared_namespace);
        if (!privatePins) return;
        privatePins.links = privatePins.links.filter(({link})=> link !== action.payload.linkId);
      }
    },

    unpinNote: (state, action: PayloadAction<{referenceId: string, noteId: string}> ) => {
      const pins = state.pinsByRefId[action.payload.referenceId];
      if (pins && pins.data) {
        const privatePins = pins.data.find(p => !p.is_shared_namespace);
        if (!privatePins) return;
        privatePins.notes = privatePins.notes.filter(({note})=> note !== action.payload.noteId);
      }
    },

    removeAndAddPinnedNote: (state, action: PayloadAction<{referenceId: string, removePinId: string, pinnedNote: {pin_id: string, pinned_by: string, pinned_at: string, note: string}, addToSpace: PinSpaces}>) => {
      const {
        referenceId,
        removePinId,
        pinnedNote,
        addToSpace,
      } = action.payload;
      const pins = state.pinsByRefId[referenceId];
      if (pins && pins.data) {
        const newData = pins.data.map(namespacedPins => {
          const notes = namespacedPins.notes.filter(({ pin_id }) => pin_id !== removePinId);

          if (
            addToSpace === PinSpaces.PUBLIC && namespacedPins.is_shared_namespace ||
            addToSpace === PinSpaces.PRIVATE && !namespacedPins.is_shared_namespace
          ) {
            const pinAlreadyInNotes = notes.some(({ pin_id }) => pin_id === pinnedNote.pin_id);
            if (!pinAlreadyInNotes) {
              notes.push(pinnedNote);
            }
          }

          return {
            ...namespacedPins,
            notes,
          };
        });

        if (newData.length === 1 && !newData[0].is_shared_namespace) {
          newData.push({
            notes: [pinnedNote],
            documents: [],
            links: [],
            action_items: [],
            is_shared_namespace: true,
            namespace: `tea:${lclId()}`, // TODO
          });
        }

        pins.data = newData;
      }
    },

    removeAndAddPinnedLink: (state, action: PayloadAction<{referenceId: string, removePinId: string, pinnedLink: {pin_id: string, pinned_by: string, pinned_at: string, link: string}, addToSpace: PinSpaces}>) => {
      const {
        referenceId,
        removePinId,
        pinnedLink,
        addToSpace,
      } = action.payload;
      const pins = state.pinsByRefId[referenceId];
      if (pins && pins.data) {
        const newData = pins.data.map(namespacedPins => {
          const links = namespacedPins.links.filter(({ pin_id }) => pin_id !== removePinId);

          if (
            addToSpace === PinSpaces.PUBLIC && namespacedPins.is_shared_namespace ||
            addToSpace === PinSpaces.PRIVATE && !namespacedPins.is_shared_namespace
          ) {
            const pinAlreadyInLinks = links.some(({ pin_id }) => pin_id === pinnedLink.pin_id);
            if (!pinAlreadyInLinks) {
              links.push(pinnedLink);
            }
          }

          return {
            ...namespacedPins,
            links,
            documents: namespacedPins.documents.filter(({ pin_id }) => pin_id !== removePinId),
          };
        });

        if (newData.length === 1 && !newData[0].is_shared_namespace) {
          newData.push({
            links: [pinnedLink],
            documents: [],
            notes: [],
            action_items: [],
            is_shared_namespace: true,
            namespace: `tea:${lclId()}`, // TODO
          });
        }

        pins.data = newData;
      }
    },

    removeAndAddPinnedDocument: (state, action: PayloadAction<{referenceId: string, removePinId: string, pinnedDoc: {pin_id: string, pinned_by: string, pinned_at: string, document: string}, addToSpace: PinSpaces}>) => {
      const {
        referenceId,
        removePinId,
        pinnedDoc,
        addToSpace,
      } = action.payload;
      const pins = state.pinsByRefId[referenceId];
      if (pins && pins.data) {
        const newData = pins.data.map(namespacedPins => {
          const docs = namespacedPins.documents.filter(({ pin_id }) => pin_id !== removePinId);

          if (
            addToSpace === PinSpaces.PUBLIC && namespacedPins.is_shared_namespace ||
            addToSpace === PinSpaces.PRIVATE && !namespacedPins.is_shared_namespace
          ) {
            const pinAlreadyInDocs = docs.some(({ pin_id }) => pin_id === pinnedDoc.pin_id);
            if (!pinAlreadyInDocs) {
              docs.push(pinnedDoc);
            }
          }

          return {
            ...namespacedPins,
            links: namespacedPins.links.filter(({ pin_id }) => pin_id !== removePinId),
            documents: docs,
          };
        });

        if (newData.length === 1 && !newData[0].is_shared_namespace) {
          newData.push({
            links: [],
            documents: [pinnedDoc],
            notes: [],
            action_items: [],
            is_shared_namespace: true,
            namespace: `tea:${lclId()}`,
          });
        }

        pins.data = newData;
      }
    },

    setEarliestRequestedDate: (state, action: PayloadAction<Date>) => {
      state.requestedEventEpochs.start = Math.min(action.payload.valueOf(), state.requestedEventEpochs.start || Infinity);
    },

    setLatestRequestedDate: (state, action: PayloadAction<Date>) => {
      state.requestedEventEpochs.end = Math.max(action.payload.valueOf(), state.requestedEventEpochs.end || 0);
    },

    removeTagFromAllEvents: (state, action: PayloadAction<{tagId: string}>) => {
      const {tagId} = action.payload;
      const events = Object.values(state.eventsById || {}).map(d => d.data).filter(event => !!event && event.tags.some(x => x.id === tagId));
      events.forEach(event => {
        if (event && state.eventsById) {
          const data = state.eventsById[event.id].data;
          if (data) {
            data.tags = event.tags.filter(({id}) => id !== tagId);
          }
        }
      });
    },

    removeTagFromEvents: (state, action: PayloadAction<{entityReferenceId: string}>) => {
      const events = Object.values(state.eventsById || {}).map(d => d.data).filter(event => !!event && event.entity_reference_id === action.payload.entityReferenceId);
      events.forEach(event => {
        if (event && state.eventsById) {
          const data = state.eventsById[event.id].data;
          if (data) {
            data.tags = [];
          }
        }
      });
    },

    addTagToEvents: (state, action: PayloadAction<{tag: ITag, entityReferenceId: string}>) => {
      const events = Object.values(state.eventsById || {}).map(d => d.data).filter(event => !!event && event.entity_reference_id === action.payload.entityReferenceId);
      events.forEach(event => {
        if (event && state.eventsById) {
          const data = state.eventsById[event.id].data;
          if (data) {
            data.tags = [action.payload.tag];
          }
        }
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchPinsByEvent.rejected, (state, action) => {
      const refId = action.meta.arg.entity_reference_id;

      if (!state.pinsByRefId[refId]) {
        state.pinsByRefId[refId] = {
          data: null,
          isFetching: false,
          lastReceived: null,
          fetchFailed: true,
        };
      } else {
        state.pinsByRefId[refId].fetchFailed = true;
      }
    });

    builder.addCase(fetchPinsByEvent.fulfilled, (state, action) => {
      if (!action.payload) {
        // thunk short-circuited because another fetch is in flight
        return;
      }
      const refId = action.meta.arg.entity_reference_id;

      state.pinsByRefId[refId] = {
        data: action.payload,
        isFetching: false,
        lastReceived: new Date(),
        fetchFailed: false,
      };
    });

    builder.addCase(fetchSuggestionsByEvent.rejected, (state, action) => {
      const eventId = action.meta.arg;

      if (!state.suggestionsByEventId[eventId]) {
        state.suggestionsByEventId[eventId] = {
          data: null,
          isFetching: false,
          lastReceived: null,
          fetchFailed: true,
        };
      } else {
        state.suggestionsByEventId[eventId].fetchFailed = true;
      }
    });

    builder.addCase(fetchSuggestionsByEvent.fulfilled, (state, action) => {
      if (!action.payload) {
        // thunk short-circuited because another fetch is in flight
        return;
      }
      const eventId = action.meta.arg;

      state.suggestionsByEventId[eventId] = {
        data: action.payload,
        isFetching: false,
        lastReceived: new Date(),
        fetchFailed: false,
      };
    });

    builder.addCase(fetchCalendarEventById.rejected, (state, action) => {
      const eventId = action.meta.arg;

      if (!state.eventsById[eventId]) {
        state.eventsById[eventId] = {
          data: null,
          isFetching: false,
          lastReceived: null,
          fetchFailed: true,
        };
      } else {
        state.eventsById[eventId].fetchFailed = true;
      }
    });

    builder.addCase(fetchCalendarEventById.fulfilled, (state, action) => {
      if (!action.payload) {
        // thunk short-circuited because another fetch is in flight
        return;
      }

      const eventId = action.meta.arg;

      state.eventsById[eventId] = {
        data: action.payload,
        isFetching: false,
        lastReceived: new Date(),
        fetchFailed: false,
      };
    });
  },

});

export const {
  receiveEvents,
  focusOnEvent,
  closeSidebar,
  openSidebarToContact,
  openSidebarToDocument,
  openSidebarToLink,
  pinDocument,
  unpinDocument,
  pinLink,
  pinActionItem,
  unpinActionItem,
  unpinLink,
  pinNote,
  setEarliestRequestedDate,
  setLatestRequestedDate,
  popSidebarHistory,
  addTagToEvents,
  removeTagFromEvents,
  removeTagFromAllEvents,
  setFetchingEventSuggestions,
  removeAndAddPinnedDocument,
  removeAndAddPinnedLink,
  removeAndAddPinnedNote,
  deletePin,
  deleteNotePin,
} = calendarSlice.actions;
export default calendarSlice.reducer;
