import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ITagRelation } from 'store/tags/selectors';
import { fetchLink } from 'api/links';
import { isFetchingLink } from './selectors';
import { AppDispatch, RootState } from 'store';

export interface LinksState {
  linksByContactId: {[contactId: string]: string[]} | null,
  linksById: {[linkId: string]: IAsyncLink},
}

export type LinkSiteName = 'Figma' | 'https://miro.com/' | 'Salesforce.com' | 'Trello' | 'Trello Board' | 'Twitter';

export interface ILink {
  id: string,
  connector_id: string | null
  url: string
  title: string | null
  description: string | null
  created_at: string
  updated_at: string
  data: {
    description?: string
    image?: string
    site_name?: LinkSiteName | string
    title?: string
    type?: string
    background_color?: string;
    background_scaled_image?: string;
  }
  tags: ITagRelation[]
}

interface IAsyncLink {
  data: ILink | null;
  isFetching: boolean;
  lastReceived: Date | null;
  fetchFailed: boolean;
}


export interface ILinkFromContact {
  id: string,
  connector: {
    id: string
    provider: string
  }
  title: string | null
  description: string | null
  url: string
  created_at: string
  data: {
    description?: string
    image?: string
    site_name?: string
    title?: string
  }
}

const initialState: LinksState = {
  linksByContactId: null,
  linksById: {},
};

interface ILinksFromContactPayloadAction {
  contactId: string,
  links: ILink[]
}

export interface ILinkEnrichWSPayload {
  id: string;
}


export const fetchLinkById = createAsyncThunk<ILink | undefined, string, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'links/fetchById',
  async (linkId: string, {getState, dispatch}) => {
    if (!isFetchingLink(getState(), linkId)) {
      dispatch(setFetchingLink({ linkId, isFetching: true}));
      const {data} = await fetchLink(linkId);
      return data;
    }
  }
);


export const wsFetchEnrichedLink = createAsyncThunk<ILink, ILinkEnrichWSPayload, { state: RootState }>(
  'links/wsFetchEnrichedLink',
  async ({id}: ILinkEnrichWSPayload, { dispatch }) => {
    dispatch(setFetchingLink({ linkId: id, isFetching: true}));
    const {data} = await fetchLink(id);
    return data;
  }
);



export const linksSlice = createSlice({
  name: 'links',
  initialState,
  reducers: {
    receiveLinksFromContact: (state, action: PayloadAction<ILinksFromContactPayloadAction>) => {
      if (state.linksByContactId === null) {
        state.linksByContactId = {};
      }

      const sortedLinks = action.payload.links.sort((a, b) => new Date(b.updated_at).valueOf() - new Date(a.updated_at).valueOf());

      state.linksByContactId[action.payload.contactId] = sortedLinks.map(link => link.id);
      action.payload.links.forEach(link => {
        if (!state.linksById[link.id]) {
          state.linksById[link.id] = {
            data: link,
            lastReceived: new Date(),
            isFetching: false,
            fetchFailed: false,
          };
        } else {
          state.linksById[link.id].data = link;
          state.linksById[link.id].lastReceived = new Date();
        }
      });
    },

    setFetchingLink: (state, action: PayloadAction<{linkId: string, isFetching: boolean}>) => {
      if (!state.linksById[action.payload.linkId]) {
        state.linksById[action.payload.linkId] = {
          data: null,
          lastReceived: null,
          isFetching: action.payload.isFetching,
          fetchFailed: false,
        };
      } else {
        state.linksById[action.payload.linkId].isFetching = action.payload.isFetching;
      }
    },

    receiveLinks: (state, action: PayloadAction<ILink[]>) => {
      action.payload.forEach(link => {
        if (!state.linksById[link.id]) {
          state.linksById[link.id] = {
            data: link,
            lastReceived: new Date(),
            isFetching: false,
            fetchFailed: false,
          };
        } else {
          state.linksById[link.id].data = link;
          state.linksById[link.id].lastReceived = new Date();
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLinkById.rejected, (state, action) => {
      const linkId = action.meta.arg;

      if (!state.linksById[linkId]) {
        state.linksById[linkId] = {
          data: null,
          isFetching: false,
          lastReceived: null,
          fetchFailed: true,
        };
      } else {
        state.linksById[linkId].fetchFailed = true;
      }
    });

    builder.addCase(fetchLinkById.fulfilled, (state, action) => {
      if (!action.payload) {
        // thunk short-circuited because another fetch is in flight
        return;
      }

      state.linksById[action.payload.id] = {
        data: action.payload,
        isFetching: false,
        lastReceived: new Date(),
        fetchFailed: false,
      };
    });

    builder.addCase(wsFetchEnrichedLink.rejected, (state, action) => {
      const {id} = action.meta.arg;

      if (!state.linksById[id]) {
        state.linksById[id] = {
          data: null,
          isFetching: false,
          lastReceived: null,
          fetchFailed: true,
        };
      } else {
        state.linksById[id].fetchFailed = true;
      }
    });

    builder.addCase(wsFetchEnrichedLink.fulfilled, (state, action) => {
      state.linksById[action.payload.id] = {
        data: action.payload,
        isFetching: false,
        lastReceived: new Date(),
        fetchFailed: false,
      };
    });
  },

});

export const { receiveLinksFromContact, receiveLinks, setFetchingLink } = linksSlice.actions;
export default linksSlice.reducer;
