import { IRule, RuleActions, RuleTriggers } from 'store/rules/selectors';


export const EmptyRule: IRule = {
  id: '',
  name: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  deleted_at: null,
  place: 1,
  triggers: [],
  actions: {
    actions: [],
    stop_after_eval: false,
  },
  conditions: {
    conditions: [],
    evaluation: 'ALL',
  }
};


// in order to go from ACTION data to selected dropdown option
export const RuleActionToOptions = {
  [RuleActions.STAR_DOCUMENT]: {label: 'Add file to "remember"', value: RuleActions.STAR_DOCUMENT},
  [RuleActions.ADD_TAG]: {label: 'Add to workstream', value: RuleActions.ADD_TAG},
  [RuleActions.NOTIFY_EMAIL]: {label: 'Send me an email', value: RuleActions.NOTIFY_EMAIL, gate_required: 'has_notify_email_rule_action'},
};

// in order to decide which condition field options to show based on the trigger
export const RuleTriggerFieldOptions: Record<RuleTriggers, {label: string, value: string, gate_required?: string}[]> = {
  [RuleTriggers.RECEIVE_DOCUMENT]: [
    {label: 'File type', value: 'MIMETYPE'},
    {label: 'Name', value: 'NAME'},
  ],
  [RuleTriggers.RECEIVE_MEETING]: [
    {label: 'Name', value: 'NAME'},
    {label: 'Organinzer email', value: 'ORGANIZER_EMAIL', gate_required: 'has_organizer_email_rule_condition'},
  ],
  [RuleTriggers.RECEIVE_SLACK_MSG]: [],
};

export const TriggerOptions: {label: string, value: RuleTriggers, gate_required?: string}[] = [
  {value: RuleTriggers.RECEIVE_DOCUMENT, label: 'a new file'},
  {value: RuleTriggers.RECEIVE_SLACK_MSG, label: 'a Slack message', gate_required: 'has_slack_msg_rule_trigger'},
  {value: RuleTriggers.RECEIVE_MEETING, label: 'a meeting update'},
];

// in order to decide what options to render in the action dropdown based on the selected trigger
export const RuleTriggerActionOptions: Record<RuleTriggers, {label: string, value: RuleActions, gate_required?: string}[]> = {
  [RuleTriggers.RECEIVE_DOCUMENT]: [
    RuleActionToOptions[RuleActions.STAR_DOCUMENT],
  ],
  [RuleTriggers.RECEIVE_MEETING]: [
    RuleActionToOptions[RuleActions.ADD_TAG],
    RuleActionToOptions[RuleActions.NOTIFY_EMAIL],
  ],
  [RuleTriggers.RECEIVE_SLACK_MSG]: [],
};

