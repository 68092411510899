import { ITagEntry } from 'store/tags/selectors';
import { TagRelationEntityTypes } from 'constants/app';

import TaggedActivity from './activity';
import TaggedCalEvent from './cal_event';
import TaggedDocument from './document';
import TaggedLink from './link';
import TaggedNote from './note';
import './style.css';


interface ITagRelationProps {
  tagTimelineEntry: ITagEntry;
}


const TagRelation = ({
  tagTimelineEntry,
}: ITagRelationProps) => {
  switch (tagTimelineEntry.tag_relation.entity_type) {
    case TagRelationEntityTypes.ACTIVITY:
      return <TaggedActivity tagTimelineEntry={tagTimelineEntry}/>;
    case TagRelationEntityTypes.DOCUMENT:
      return <TaggedDocument tagTimelineEntry={tagTimelineEntry}/>;
    // this indicates when the user tagged the calendar event, we don't have this designed yet
    case TagRelationEntityTypes.CALENDAR_EVENT:
      return <TaggedCalEvent tagTimelineEntry={tagTimelineEntry}/>;
    case TagRelationEntityTypes.LINK:
      return <TaggedLink tagTimelineEntry={tagTimelineEntry}/>;
    case TagRelationEntityTypes.NOTE:
      return <TaggedNote tagTimelineEntry={tagTimelineEntry}/>;
    default:
      return null;
  }
};

export default TagRelation;