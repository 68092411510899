import { useEffect, useState } from 'react';

import Rule from 'components/Rule';
import RuleDialog from 'components/Rule/edit';
import { fetchAllRules } from 'api/rules';
import { receiveRules } from 'store/rules/slice';
import { Button, EmptyState, MapIcon } from 'evergreen-ui';
import { getRulesInOrder, rulesNeverLoaded } from 'store/rules/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';


const RulesList = ({
  onClickNew,
  onClickEdit,
}: {onClickNew: () => void, onClickEdit: (ruleId: string) => void}) => {
  const rules = useAppSelector(getRulesInOrder);

  return <div className="rules-list--container">
    <div className="rules-list-title--container">
      <h2>Rules</h2>
      <div>
        <Button className="rules-list-title--button" onClick={onClickNew}>Create a new rule</Button>
        or
        <Button className="rules-list-title--button" onClick={onClickNew}>Pick from a template</Button>
      </div>
    </div>
    {rules.map(rule => <Rule rule={rule} key={rule.id} onEditClick={onClickEdit} />)}
  </div>;
};


const EmptyRules = ({
  onClickNew,
}: {onClickNew: () => void}) => {
  return <EmptyState
    title="You have no rules"
    icon={<MapIcon color="var(--color-yellow-3)" />}
    background="dark"
    iconBgColor="var(--color-purple-2)"
    description="Create your first rule to have Classify supercharge your work day."
    primaryCta={<div><Button onClick={onClickNew}>Create a new rule</Button> or <Button onClick={onClickNew}>Pick from a template</Button></div>}
  />;
};


const RuleSettings = () => {
  const neverLoaded = useAppSelector(rulesNeverLoaded);
  const rules = useAppSelector(getRulesInOrder);
  const [loading, setLoading] = useState(false);
  const [isCreateRuleOpen, setIsCreateRuleOpen] = useState(false);
  const [editingRuleId, setEditingRuleId] = useState<undefined | string>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadRules = async () => {
      setLoading(true);
      try {
        const {data} = await fetchAllRules();
        dispatch(receiveRules(data));
      } catch (err) {
        dispatch(receiveRules([]));
        console.warn('Failed to load rules');
        console.warn(err);
      } finally {
        setLoading(false);
      }
    };

    if (neverLoaded && !loading) {
      loadRules();
    }
  }, [neverLoaded, loading, dispatch]);

  const onClickNew = () => {
    setIsCreateRuleOpen(true);
    setEditingRuleId(undefined);
  };

  const onClickEdit = (ruleId: string) => {
    setIsCreateRuleOpen(true);
    setEditingRuleId(ruleId);
  };

  const onClose = () => {
    setIsCreateRuleOpen(false);
    setEditingRuleId(undefined);
  };

  const hasNoRules = rules.length === 0 && !loading && !neverLoaded;

  return <div>
    {(neverLoaded || loading) && <div>Loading your rules</div>}
    {hasNoRules && <EmptyRules onClickNew={() => setIsCreateRuleOpen(true)} />}
    <RulesList onClickNew={onClickNew} onClickEdit={onClickEdit}/>
    <RuleDialog
      onClose={onClose}
      isOpen={isCreateRuleOpen}
      editingRuleId={editingRuleId}
    />
  </div>;
};

export default RuleSettings;