import { API } from 'api';
import { IActivity } from 'store/documents/selectors';
import { queries } from './query';


export const fetchActivityFromIntersection = (contactIds: string[], documentIds: string[], after: Date, activity_types?: string[]) => {
  const payload = {
    after: after.toISOString().replace('+00:00', 'Z'),
    entities: [
      ...contactIds.map(cid => ({ entity_type: 'CONTACT', entity_id: cid })),
      ...documentIds.map(did => ({ entity_type: 'DOCUMENT', entity_id: did })),
    ],
    activity_types: activity_types?.length ? activity_types : undefined,
  };
  const path = '/api/v1/activities/for/intersection';
  return API.post<IActivity[]>(path, payload);
};

export const fetchActivityFromUnion = (contactIds: string[], documentIds: string[], after: Date, activity_types?: string[]) => {
  const payload = {
    after: after.toISOString().replace('+00:00', 'Z'),
    entities: [
      ...contactIds.map(cid => ({ entity_type: 'CONTACT', entity_id: cid })),
      ...documentIds.map(did => ({ entity_type: 'DOCUMENT', entity_id: did })),
    ],
    activity_types: activity_types?.length ? activity_types : undefined,
  };
  const path = '/api/v1/activities/for/union';
  return API.post<IActivity[]>(path, payload);
};


interface IActivitySetQueryArgs {
  contactIds: string[],
  documentIds: string[],
  after: string,
  activityTypes?: string[],
}


const activityQueries = queries.injectEndpoints({
  endpoints: (build) => ({

    activityIntersection: build.query<IActivity[], IActivitySetQueryArgs>({
      query: ({contactIds, documentIds, after, activityTypes}) => {
        return {
          url: '/activities/for/intersection',
          method: 'POST',
          body: {
            after,
            entities: [
              ...contactIds.map(cid => ({entity_type: 'CONTACT', entity_id: cid})),
              ...documentIds.map(did => ({entity_type: 'DOCUMENT', entity_id: did})),
            ],
            activity_types: activityTypes?.length ? activityTypes : undefined,
          },
        };
      },

      providesTags: (result) => result?.map(({id}) => ({ type: 'Activity', id })) || [],
    }),

    activitiesForContact: build.query<IActivity[], {contactId: string, types: string[]}>({
      query: ({contactId, types}) => {
        const params = new URLSearchParams();
        types.forEach(type => params.append('types', type));
        return `/activities/for/CONTACT/${contactId}?${params.toString()}`;
      },

      providesTags: (result) => result?.map(({id}) => ({ type: 'Activity', id })) || [],
    }),

  }),

  overrideExisting: false,
});

export const { useActivityIntersectionQuery, useActivitiesForContactQuery, usePrefetch } = activityQueries;