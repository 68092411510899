import { Link } from 'react-router-dom';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import { Alert, Button, TextInput } from 'evergreen-ui';
import { ChangeEvent, FocusEvent, FormEvent, MouseEvent, useState } from 'react';

import OnboardingCardWithHeader from 'components/OnboardingCardWithHeader';
import { getCurrentUser } from 'store/user/selector';
import { setToken } from 'utils/auth';
import { signupViaEmail } from 'api/user';
import { tracker } from 'utils/tracking';
import { validateEmail } from 'utils/strings';

import './style.css';

const SIGNUP_ERROR = {
  title: 'Failed to create account',
  children: 'Please try again.'
};

const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [isFirstNameInvalid, setIsFirstNameInvalid] = useState(false);
  const [lastName, setLastName] = useState('');
  const [isLastNameInvalid, setLastNameInvalid] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailInvalid, setEmailInvalid] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState<typeof SIGNUP_ERROR | null>(null);
  const currentUser = useSelector(getCurrentUser);

  const buttonDisabled = !firstName || !lastName || !email || !password ||
  isFirstNameInvalid || isLastNameInvalid || isEmailInvalid || isPasswordInvalid;

  if (currentUser) {
    window.location.href = '/';
  }

  const onCheckFirstName = (e: FocusEvent<HTMLInputElement>) => {
    setIsFirstNameInvalid(!e.target.value);
  };

  const onCheckLastName = (e: FocusEvent<HTMLInputElement>) => {
    setLastNameInvalid(!e.target.value);
  };

  const onCheckEmail = (e: FocusEvent<HTMLInputElement>) => {
    setEmailInvalid(!validateEmail(e.target.value));
  };

  const onCheckPassword = debounce((newPassword) => {
    setIsPasswordInvalid(newPassword.length < 6);
  }, 500);

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    onCheckPassword(e.target.value);
  };

  const onSubmitEmail = (e: MouseEvent<HTMLButtonElement> & FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const fullName = `${firstName} ${lastName}`;
    signupViaEmail({ email, password, full_name: fullName })
      .then(({ data })=> {
        setToken(data.token.access_token);
        tracker.setSignupVersion();
        setTimeout(() => window.location.href = '/signup-survey', 200);
      })
      .catch(() => {
        setError(SIGNUP_ERROR);
      })
      .finally(() => { setIsLoading(false); });
  };

  return (
    <OnboardingCardWithHeader headerText="Welcome to Classify">
      {error && <Alert intent="warning" className="login-alert" title={error.title} marginBottom={24}>{error.children}</Alert>}
      <form onSubmit={onSubmitEmail}>
        <label className="login-input--label">First Name</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': isFirstNameInvalid })} width="100%" height="40px" onBlur={onCheckFirstName} isInvalid={isFirstNameInvalid} value={firstName} onChange={(e: ChangeEvent<HTMLInputElement>) => { setFirstName(e.target.value); }} />
        {isFirstNameInvalid && <div className="login-input--error">Please fill out this field</div>}
        <label className="login-input--label" htmlFor="email">Last Name</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': isLastNameInvalid })} width="100%" height="40px" onBlur={onCheckLastName} isInvalid={isLastNameInvalid} value={lastName} onChange={(e: ChangeEvent<HTMLInputElement>) => { setLastName(e.target.value); }} />
        {isLastNameInvalid && <div className="login-input--error">Please fill out this field</div>}
        <label className="login-input--label">Email</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': isEmailInvalid })} width="100%" height="40px" onBlur={onCheckEmail} isInvalid={isEmailInvalid} value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value); }} />
        {isEmailInvalid && <div className="login-input--error">Please enter a valid email address</div>}
        <label className="login-input--label">Password</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': isPasswordInvalid })} width="100%" height="40px" isInvalid={isPasswordInvalid} type="password" value={password} onChange={onChangePassword} />
        {isPasswordInvalid && <div className="login-input--error">Password must be at least 6 characters</div>}
        <div className="signup-card-helper-text">
          By creating an account, you agree to Classify’s <a className="signup-card-link" href="https://www.classify.ltd/terms-of-service" target="_blank" rel="noopener noreferrer">Conditions of Use</a>.
        </div>
        <Button appearance="primary" disabled={buttonDisabled} type="submit" className="login-submit-button" isLoading={loading} onClick={onSubmitEmail}>Sign up</Button>
        <div className="signup-card-helper-text signup-card-footer">
          Already have an account? <Link className="login-link" to="/login">Log in</Link>
        </div>
      </form>
    </OnboardingCardWithHeader>
  );
};

export default Signup;
