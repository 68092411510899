export const DEFAULT_IGNORE_MIMETYPES = [
  'text/plain',
  'text/html',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/gif',
  'image/x-photoshop',
  'image/x-123',
  'image/svg',
  'image/svg+xml',
  'video/webm',
  'video/x-msvideo',
  'video/ogg',
  'video/mp4',
  'video/mpeg',
  'video/mpg',
  'video/mp2',
  'video/quicktime',
  'audio/x-m4a',
  'audio/mp4',
  'audio/wav',
  'audio/x-wav',
  'audio/mpeg',
  'audio/webm',
  'application/octet-stream',
  'application/vnd.rar',
  'application/x-tar',
  'application/x-tar',
  'application/x-zip-compressed',
  'application/vnd.microsoft.portable-executable',
  'application/pkcs7-signature',
  'application/vnd.google-apps.folder',
  'application/vnd.google-apps.shortcut',
];
