import { sort } from 'ramda';

import { IContact } from 'store/contacts/selectors';
import { ILink } from 'store/links/slice';
import { INote } from 'store/notes/slice';
import { RootState } from 'store';
import { getLastCalendarActivityRead } from 'store/read_receipts/selectors';
import { getLinkById } from 'store/links/selectors';
import { getNoteById } from 'store/notes/selectors';
import { ActivitySubtitles, PinSpaces } from 'constants/app';
import { AllActivity, IActivityRender, IDocument, getDocumentById } from 'store/documents/selectors';
import {
  IActionItem,
  getActionItem,
  getDirtyActionItemContent,
  getDirtyActionItemData,
  getDirtyActionItemParentId,
  getDirtyActionItemStatus,
  getDirtyActionItemTitle,
} from 'store/action_items/selectors';
import { getActivityById, getActivityIntersectionByHash, getActivityUnionByHash } from 'store/activities/slice';
import { getCurrentUserEmail, getCurrentUserEmailDomain } from 'store/user/selector';


export interface ITag {
  id: string;
  name?: string;
  config: {
    colorHash: string;
  };
  created_at: string;
  updated_at: string;
}

export interface IMinimalCalendarEvent {
  id: string;
  user_id: string;
  start_dt: string;
  end_dt: string;
  created_at: string;
  updated_at: string;
  title?: string;
  description?: string;
  attendees: IContact[];
  organizer_id: string;
  creator_id: string;
  join_link?: string;
  tags: ITag[];
  user_self_attendance: 'ACCEPTED' | 'DECLINED' | 'TENTATIVE' | 'NO_RESPONSE';
  entity_reference_id: string;
  previous_meeting_start: string;
  previous_meeting_starts?: string[];
  recurrence?: string;
}

export interface ICalEventSuggestions {
  documents: {
    document: IDocument;
    score: number;
    reason: string
  }[];
}

export interface ICalendarEvent extends IMinimalCalendarEvent {
  documents: IDocument[];
  action_items: IActionItem[];
  links: ILink[];
  notes: INote[];
  suggestions: ICalEventSuggestions;
}


const root = (state: RootState) => state.calendar;

const getEventsById = (state: RootState) => root(state).eventsById;
const getEvent = (state: RootState, eventId: string) => (getEventsById(state) || {})[eventId] || {};
export const getEventById = (state: RootState, eventId: string): IMinimalCalendarEvent | undefined | null => getEvent(state, eventId).data;
export const isFetchingEvent = (state: RootState, id: string) => getEvent(state, id).isFetching;
export const fetchEventFailed = (state: RootState, id: string) => getEvent(state, id).fetchFailed;
const view = (state: RootState) => root(state).view;
const sidebarStack = (state: RootState) => view(state).sidebarStack;
const getSidebarView = (state: RootState) => sidebarStack(state)[0];

const getEventSuggestions = (state: RootState) => root(state).suggestionsByEventId;
const getEventSuggestionsByEventId = (state: RootState, eventId: string) => getEventSuggestions(state)[eventId];
export const getEventSuggestionsData = (state: RootState, eventId: string) => (getEventSuggestionsByEventId(state, eventId) || {}).data;
export const isFetchingEventSuggestions = (state: RootState, eventId: string) => (getEventSuggestionsByEventId(state, eventId) || {}).isFetching;
export const fetchEventSuggestionsFailed = (state: RootState, eventId: string) => (getEventSuggestionsByEventId(state, eventId) || {}).fetchFailed;

const getEventPins = (state: RootState) => root(state).pinsByRefId;
const getEventPinsByRefId = (state: RootState, refId: string) => getEventPins(state)[refId];
export const getEventPinsData = (state: RootState, refId: string) => (getEventPinsByRefId(state, refId) || {}).data;
export const isFetchingEventPins = (state: RootState, refId: string) => (getEventPinsByRefId(state, refId) || {}).isFetching;
export const fetchEventPinsFailed = (state: RootState, refId: string) => (getEventPinsByRefId(state, refId) || {}).fetchFailed;
export const getPrivatePins = (state: RootState, eventId: string) => (getEventPinsData(state, eventId) || []).find(d => !d.is_shared_namespace);
export const getPublicPins = (state: RootState, eventId: string) => (getEventPinsData(state, eventId) || []).find(d => d.is_shared_namespace);
const getPrivateEventDocumentPins = (state: RootState, eventId: string) => (getPrivatePins(state, eventId) || {}).documents || [];
const getPublicEventDocumentPins = (state: RootState, eventId: string) => (getPublicPins(state, eventId) || {}).documents || [];
const getPrivateEventLinkPins = (state: RootState, eventId: string) => (getPrivatePins(state, eventId) || {}).links || [];
const getPublicEventLinkPins = (state: RootState, eventId: string) => (getPublicPins(state, eventId) || {}).links || [];
const getPrivateNotePins = (state: RootState, eventId: string) => (getPrivatePins(state, eventId) || {}).notes || [];
const getPrivateActionItemPins = (state: RootState, eventId: string) => (getPrivatePins(state, eventId) || {}).action_items || [];
export const getPublicNotePins = (state: RootState, eventId: string) => (getPublicPins(state, eventId) || {}).notes || [];
export const getPublicActionItemPins = (state: RootState, eventId: string) => (getPublicPins(state, eventId) || {}).action_items || [];

export const getAllEventDocumentPins = (state: RootState, eventId: string) => getPrivateEventDocumentPins(state, eventId).concat(getPublicEventDocumentPins(state, eventId));
const getAllEventActionItemPins = (state: RootState, eventId: string) => {
  const actionItemPins: Array<{ action_item: string, pin_id: string, pinned_by: string, pinned_at: string }> = [];
  const pinnedActionItemIds = new Set<string>();
  getPublicActionItemPins(state, eventId).forEach((pin) => {
    actionItemPins.push(pin);
    pinnedActionItemIds.add(pin.action_item);
  });
  getPrivateActionItemPins(state, eventId).filter(({ action_item }) => !pinnedActionItemIds.has(action_item)).forEach((pin) => {
    actionItemPins.push(pin);
  });
  return actionItemPins;
};

const getAllEventNotePins = (state: RootState, eventId: string) => {
  const notePins: Array<{ note: string, pin_id: string, pinned_by: string, pinned_at: string }> = [];
  const pinnedNoteIds = new Set<string>();
  getPublicNotePins(state, eventId).forEach((pin) => {
    notePins.push(pin);
    pinnedNoteIds.add(pin.note);
  });
  getPrivateNotePins(state, eventId).filter(({ note }) => !pinnedNoteIds.has(note)).forEach((pin) => {
    notePins.push(pin);
  });
  return notePins;
};

export const getAllParentEventActionItemPins = (state: RootState, eventId: string) => {
  const allActionItems = (getAllEventActionItemPins(state, eventId)
    .map(d => getActionItem(state, d.action_item))
    .filter(d => d !== null) as IActionItem[]);
  const actionItemsById: {[key: string]: IActionItem} = {};
  allActionItems.forEach(ai => {
    actionItemsById[ai.id] = ai;
  });

  const actionItemsWithNoParent = allActionItems
    .filter(ai => !ai.data.parentActionItemId || !actionItemsById[ai.data.parentActionItemId as string]);

  const withDirtyData = actionItemsWithNoParent.map(ai => {
    return {
      ...ai,
      content: getDirtyActionItemContent(state, ai.id) || ai.content,
      title: getDirtyActionItemTitle(state, ai.id) || ai.title,
      data: {...ai.data, ...getDirtyActionItemData(state, ai.id), parentActionItemId: getDirtyActionItemParentId(state, ai.id)},
      status: getDirtyActionItemStatus(state, ai.id) || ai.status,
    };
  });
  return sort(
    (ai1, ai2) => new Date(ai1.created_at).valueOf() - new Date(ai2.created_at).valueOf(),
    withDirtyData,
  );
};

export const getAllSortedEventNotePins = (state: RootState, eventId: string) => {
  return sort(
    (note1, note2) => new Date(note2.note.created_at).valueOf() - new Date(note1.note.created_at).valueOf(),
    (getAllEventNotePins(state, eventId).map(d => ({ ...d, note: getNoteById(state, d.note) })).filter(d => !!d.note)) as Array<{ note: INote, pin_id: string, pinned_by: string, pinned_at: string }>
  ).map(pin => ({ ...pin, note: pin.note.id }));
};

export const getSortedPublicPins = (state: RootState, eventId: string): Array<{ pin_id: string, pinned_at: string, pinned_by: string, document: string } | { pin_id: string, pinned_at: string, pinned_by: string, link: string }> => {
  const linkPins = getPublicEventLinkPins(state, eventId);
  const docPins = getPublicEventDocumentPins(state, eventId);
  const allPins = [...linkPins, ...docPins];
  return sort((pin1, pin2) => new Date(pin2.pinned_at).valueOf() - new Date(pin1.pinned_at).valueOf(), allPins);
};
export const getSortedPrivatePins = (state: RootState, eventId: string): Array<{ pin_id: string, pinned_at: string, pinned_by: string, document: string } | { pin_id: string, pinned_at: string, pinned_by: string, link: string }> => {
  const linkPins = getPrivateEventLinkPins(state, eventId);
  const docPins = getPrivateEventDocumentPins(state, eventId);
  const allPins = [...linkPins, ...docPins];
  return sort((pin1, pin2) => new Date(pin2.pinned_at).valueOf() - new Date(pin1.pinned_at).valueOf(), allPins);
};

export const getEventLinks = (state: RootState, eventId: string): ILink[] => {
  const linkIds = getPrivateEventLinkPins(state, eventId).map(l => l.link);
  const links = linkIds.map(linkId => getLinkById(state, linkId));
  return (links.filter(x => !!x) as ILink[]);
};
const getEventDocumentIds = (state: RootState, eventId: string) => getAllEventDocumentPins(state, eventId).map(({ document }) => document);
export const getEventDocuments = (state: RootState, eventId: string): IDocument[] => {
  const docIds = getEventDocumentIds(state, eventId);
  const docs = docIds.map(docId => getDocumentById(state, docId));
  return (docs.filter(x => !!x) as IDocument[]);
};
export const isSimilarDocInPublicPins = (state: RootState, eventId: string, docId: string) => {
  const publicDocPins = getPublicEventDocumentPins(state, eventId);
  const doc = getDocumentById(state, docId);

  if (!doc) {
    return false;
  }

  return !!(publicDocPins.find(publicDocPin => {
    const publicDoc = getDocumentById(state, publicDocPin.document);
    return publicDoc && publicDoc.filename === doc.filename && publicDoc.size_bytes === doc.size_bytes && publicDoc.mimetype === doc.mimetype;
  }));
};
export const getPinForDocument = (state: RootState, eventId: string, documentId: string): { document: string; pin_id: string; pinned_by: string; pinned_at: string; pinSpace: PinSpaces } | undefined => {
  const privateDocumentPin = getPrivateEventDocumentPins(state, eventId).find(({ document }) => document === documentId);
  if (privateDocumentPin) {
    return {
      ...privateDocumentPin,
      pinSpace: PinSpaces.PRIVATE,
    };
  }
  const doc = getDocumentById(state, documentId);
  if (!doc) {
    return undefined;
  }

  const publicDocPins = getPublicEventDocumentPins(state, eventId);
  const publicPin = publicDocPins.find(publicDocPin => {
    const publicDoc = getDocumentById(state, publicDocPin.document);
    return publicDoc && publicDoc.filename === doc.filename && publicDoc.size_bytes === doc.size_bytes && publicDoc.mimetype === doc.mimetype;
  });
  if (publicPin) {
    return {
      ...publicPin,
      pinSpace: PinSpaces.PUBLIC,
    };
  }
  return undefined;
};
export const getPinForLink = (state: RootState, eventId: string, linkId: string): { link: string; pin_id: string; pinned_by: string; pinned_at: string; pinSpace: PinSpaces } | undefined => {
  const privateLinkPin = getPrivateEventLinkPins(state, eventId).find(({ link }) => link === linkId);
  if (privateLinkPin) {
    return {
      ...privateLinkPin,
      pinSpace: PinSpaces.PRIVATE,
    };
  }

  const link = getLinkById(state, linkId);
  if (!link) {
    return undefined;
  }

  const publicLinkPins = getPublicEventLinkPins(state, eventId);
  const publicPin = publicLinkPins.find(publicLinkPin => {
    const publicLink = getLinkById(state, publicLinkPin.link);
    return publicLink && publicLink.title === link.title;
  });
  if (publicPin) {
    return {
      ...publicPin,
      pinSpace: PinSpaces.PUBLIC,
    };
  }
  return undefined;
};
export const getPinByNoteId = (state: RootState, eventId: string, noteId: string): { note: string; pin_id: string; pinned_by: string; pinned_at: string; pinSpace: PinSpaces } | undefined => {
  const publicNotePin = getPublicNotePins(state, eventId).find(({ note }) => note === noteId);
  if (publicNotePin) {
    return {
      ...publicNotePin,
      pinSpace: PinSpaces.PUBLIC,
    };
  }

  const privateNotePin = getPrivateNotePins(state, eventId).find(({ note }) => note === noteId);
  if (privateNotePin) {
    return {
      ...privateNotePin,
      pinSpace: PinSpaces.PRIVATE,
    };
  }
  return undefined;
};


export const getSidebarType = (state: RootState) => getSidebarView(state)?.type;
export const getFocusEventId = (state: RootState) => view(state).focusCalendarEventId;
export const getFocusEvent = (state: RootState) => getEventById(state, getFocusEventId(state) || '');

export const getEventsList = (state: RootState): IMinimalCalendarEvent[] => (Object.values(getEventsById(state) || {}).map(evt => evt.data).filter(d => d !== null) as IMinimalCalendarEvent[]);
export const getEventByReferenceId = (state: RootState, entityReferenceId: string) => getEventsList(state).find(oneEvent => oneEvent.entity_reference_id === entityReferenceId);
export const getWorkstreamTagForEvent = (state: RootState, entityReferenceId: string) => getEventByReferenceId(state, entityReferenceId)?.tags.filter(tag => !!tag.config.colorHash).reduce((t1: ITag | undefined, t2: ITag) => {
  if (t1 === undefined) {
    return t2;
  }

  const t1Dt = new Date(t1.updated_at || t1.created_at).valueOf();
  const t2Dt = new Date(t2.updated_at || t2.created_at).valueOf();

  return t1Dt > t2Dt ? t1 : t2;
}, undefined);

export const getAllAttendeesForEvent = (state: RootState, eventId: string) => getEventById(state, eventId)?.attendees || [];
export const getInternalAttendeesForEvent = (state: RootState, eventId: string) => {
  const allAttendees = getAllAttendeesForEvent(state, eventId);
  const userEmailDomain = getCurrentUserEmailDomain(state);
  return allAttendees.filter(c => c.handles.some(ch => ch.handle.endsWith(userEmailDomain)));
};
export const getNonUserAttendeesForEvent = (state: RootState, eventId: string) => {
  // get all the attendees for an event but don't include the current user
  const allAttendees = getAllAttendeesForEvent(state, eventId);
  const userEmail = getCurrentUserEmail(state);
  return allAttendees.filter(c => c.handles.every(ch => ch.handle !== userEmail));
};


export const isHomeSidebarOpen = (state: RootState) => view(state).sidebarIsOpen;
export const isDocumentSidebarOpen = (state: RootState) => getSidebarType(state) === 'document' && isHomeSidebarOpen(state);
export const isContactSidebarOpen = (state: RootState) => getSidebarType(state) === 'contact' && isHomeSidebarOpen(state);
export const isLinkSidebarOpen = (state: RootState) => getSidebarType(state) === 'link' && isHomeSidebarOpen(state);
export const getSidebarEntityId = (state: RootState) => getSidebarView(state)?.entityId;
export const getSidebarHasHistory = (state: RootState) => sidebarStack(state).length > 1;
export const isContactSelected = (state: RootState, id: string) => isContactSidebarOpen(state) && getSidebarEntityId(state) === id;
export const isDocumentSelected = (state: RootState, id: string) => isDocumentSidebarOpen(state) && getSidebarEntityId(state) === id;
export const isLinkSelected = (state: RootState, id: string) => isLinkSidebarOpen(state) && getSidebarEntityId(state) === id;

const requestedTimes = (state: RootState) => root(state).requestedEventEpochs;
export const getEarliestRequestedTime = (state: RootState) => requestedTimes(state).start;
export const getLatestRequestedTime = (state: RootState) => requestedTimes(state).end;
export const hasRequestedTime = (state: RootState, time: number) => {
  const earliest = getEarliestRequestedTime(state);
  const latest = getLatestRequestedTime(state);
  return earliest && latest && time > earliest && time < latest;
};

export const getPinnedActivityForEvent = (state: RootState, eventId: string): AllActivity[] => {
  const pinnedDocs = getEventDocuments(state, eventId);

  const pinnedActivity: AllActivity[] = [];
  const seenActivityIds = new Set();

  pinnedDocs.forEach(doc => {
    doc.latest_activity.forEach((act) => {
      if (!seenActivityIds.has(act.id)) {
        pinnedActivity.push(act);
        seenActivityIds.add(act.id);
      }
    });
  });

  return pinnedActivity;
};

export const getAllActivitiesForEvent = (state: RootState, eventId: string, refId: string, relevantActivityTypes: Set<string>, relevantMimetypes?: Set<string>): AllActivity[] => {
  const event = getEventById(state, eventId);

  // defensive
  if (!event) return [];

  const eventActivitiesById: { [activityId: string]: AllActivity } = {};

  const allAttendeesIntersectionHash = getAllAttendeesForEvent(state, event.id)
    .map(c => c.id)
    .map(cid => `ctc.${cid.replace(/-/g, '')}`)
    .sort()
    .join('_');
  // an "internal" attendee is one that has a slack handle or
  // has an email domain that matches the logged in user's email domain
  const internalAttendeesIntersectionHash = getInternalAttendeesForEvent(state, event.id)
    .map(contact => contact.id)
    .map(cid => `ctc.${cid.replace(/-/g, '')}`)
    .sort()
    .join('_');

  const nonMeAttendeesUnionHash = getNonUserAttendeesForEvent(state, event.id)
    .map(contact => contact.id)
    .map(cid => `ctc.${cid.replace(/-/g, '')}`)
    .sort()
    .join('_');

  // factory function
  const makeConvertToRenderActivity = (subtitle: ActivitySubtitles) => {
    return (act: AllActivity): IActivityRender => ({ ...act, subtitle });
  };

  const internalAttendeesIntersectionActivityIds = getActivityIntersectionByHash(state, internalAttendeesIntersectionHash)?.map(actId => getActivityById(state, actId)).filter(act => !!act).map(makeConvertToRenderActivity(ActivitySubtitles.internalIntersectionQuery));
  const allAttendeesIntersectionActivityIds = getActivityIntersectionByHash(state, allAttendeesIntersectionHash)?.map(actId => getActivityById(state, actId)).filter(act => !!act).map(makeConvertToRenderActivity(ActivitySubtitles.intersectionQuery));
  const nonMeAttendeesUnionActivityIds = getActivityUnionByHash(state, nonMeAttendeesUnionHash)?.map(actId => getActivityById(state, actId)).filter(act => !!act).map(makeConvertToRenderActivity(ActivitySubtitles.unionQuery));

  internalAttendeesIntersectionActivityIds?.forEach(act => eventActivitiesById[act.id] = act);
  allAttendeesIntersectionActivityIds?.forEach(act => eventActivitiesById[act.id] = act);
  nonMeAttendeesUnionActivityIds?.forEach(act => eventActivitiesById[act.id] = act);

  const pinnedActivity = getPinnedActivityForEvent(state, refId).map(makeConvertToRenderActivity(ActivitySubtitles.fileActivity));

  pinnedActivity.forEach(act => eventActivitiesById[act.id] = act);

  const activitiesOfRelevantType = Object.values(eventActivitiesById).filter(act => relevantActivityTypes.has(act.type));

  const activityHasCorrectDocMimetype = (activity: AllActivity) => {
    if (!relevantMimetypes || relevantMimetypes.size === 0) return true;

    if ('document_id' in activity.data) {
      const doc = getDocumentById(state, activity.data.document_id);
      return doc && relevantMimetypes.has(doc.mimetype);
    } else if ('document_ids' in activity.data) {
      const docs = activity.data.document_ids.map(docId => getDocumentById(state, docId));
      return docs.some(doc => doc && relevantMimetypes.has(doc.mimetype));
    }
    return false;
  };

  return activitiesOfRelevantType.filter(activityHasCorrectDocMimetype);
};

export const getFirstActivitySinceLastRead = (state: RootState, eventId: string, refId: string, relevantActivityTypes: Set<string>): AllActivity | null => {
  const lastRead = getLastCalendarActivityRead(state, refId);

  if (!lastRead) return null;

  const after = new Date(lastRead);

  const findFirstActivitySinceLastTimeReduce = (soFar: AllActivity | null, act: AllActivity) => {
    const ts = new Date(act.timestamp);

    if (ts > after) {
      if (soFar === null) {
        return act;
      }
      const soFarTs = new Date(soFar.timestamp);
      return soFarTs > ts ? act : soFar;
    }

    return soFar;
  };

  const activity = getAllActivitiesForEvent(state, eventId, refId, relevantActivityTypes);
  return activity.reduce(findFirstActivitySinceLastTimeReduce, null);
};

export const getEventHasNewActivitySinceLastRead = (state: RootState, eventId: string, refId: string, relevantActivityTypes: Set<string>): boolean => !!(getFirstActivitySinceLastRead(state, eventId, refId, relevantActivityTypes));
