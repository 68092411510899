import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { AddIcon, Button, CaretDownIcon, ChevronLeftIcon, IconButton, Popover, Spinner, TextInput, TickIcon } from 'evergreen-ui';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';

import { NotificationType } from 'store/notifications/selectors';
import { getAllTags } from 'store/tags/selectors';
import { notify } from 'store/notifications/slice';
import { postCreateAndAssociateTag } from 'api/tags';
import { receiveTags } from 'store/tags/slice';
import { removeTagFromEvent } from 'api/calendar';
import { ITag, getWorkstreamTagForEvent } from 'store/calendar/selectors';
import { TrackEventNames, tracker } from 'utils/tracking';
import { addTagToEvents, removeTagFromEvents } from 'store/calendar/slice';
import { useAppDispatch, useAppSelector } from 'hooks';

import './style.css';


const ColorSquare = ({ colorHash, hasCheck=false, small=false }: { colorHash: string, hasCheck?: boolean, small?: boolean }) => <div style={{ background: colorHash }} className={cx('cal-event-color-picker--color-square', {small})}>{hasCheck && <TickIcon size={10} color='white'/>}</div>;


const ColorOptions = [
  {
    label: <ColorSquare colorHash="#7649FD" />,
    value: '#7649FD',
  },
  {
    label: <ColorSquare colorHash="#ED55C2" />,
    value: '#ED55C2',
  },
  {
    label: <ColorSquare colorHash="#D14343" />,
    value: '#D14343',
  },
  {
    label: <ColorSquare colorHash="#429777" />,
    value: '#429777',
  },
  {
    label: <ColorSquare colorHash="#FFB020" />,
    value: '#FFB020',
  },
  {
    label: <ColorSquare colorHash="#DE7548" />,
    value: '#DE7548',
  },
  {
    label: <ColorSquare colorHash="#3366FF" />,
    value: '#3366FF',
  },
  {
    label: <ColorSquare colorHash="#330EA1" />,
    value: '#330EA1',
  },
];

export const TagCreator = ({
  onBackClick,
  entityReferenceId,
  close,
  defaultTagColor=ColorOptions[0].value,
  defaultTagName='',
  existingTagId,
  calendarEventId,
}: {onBackClick?: (e: MouseEvent) => void, entityReferenceId?: string, close: () => void, defaultTagColor?: string, defaultTagName?: string, existingTagId?: string, calendarEventId?: string}) => {
  const [newTagName, setNewTagName] = useState(defaultTagName);
  const [newTagColor, setNewTagColor] = useState(defaultTagColor);
  const [saving, setSaving] = useState(false);
  const dispatch = useAppDispatch();

  const onSave = async (e: MouseEvent) => {
    e.stopPropagation();
    if (saving) return;

    const entities: {entity_type: 'CALENDAR_EVENT' | 'ACTIVITY', entity_id: string}[] = [];
    if (entityReferenceId) {
      entities.push({
        entity_type: 'CALENDAR_EVENT',
        entity_id: entityReferenceId,
      });
    }

    setSaving(true);

    try {
      const {data: tag} = await postCreateAndAssociateTag({
        name: newTagName,
        existing_tag_id: existingTagId,
        config: {colorHash: newTagColor},
        entities,
        tag_relation_data: calendarEventId ? {calendar_event_instance_id: calendarEventId} : {},
      });
      dispatch(receiveTags([tag]));
      if (entityReferenceId) {
        dispatch(addTagToEvents({ tag, entityReferenceId }));
        tracker.track(TrackEventNames.CETAG, { entityReferenceId });
      }
      setSaving(false);
      close();
    } catch (err) {
      const message = entityReferenceId ? 'Unable to set meeting color, please try again.' : 'Failed to create a label, please try again.';
      if (entityReferenceId) {
        notify({
          message,
          type: NotificationType.WARNING,
        })(dispatch);
      }
      setSaving(false);
    }
  };


  return <div className="cal-tag-popover-edit--container">
    {onBackClick && <IconButton size="small" onClick={onBackClick} icon={ChevronLeftIcon}/>}
    <TextInput
      value={newTagName}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setNewTagName(e.target.value)}
      className="cal-tag-popover-edit--name"
      placeholder="Label name..."
    />
    <div className="cal-tag-popover-color-options--label">Label color:</div>
    <div className="cal-tag-popover-color-options--container">
      {ColorOptions.map(({ value }) =>
        <span key={value} onClick={() => setNewTagColor(value)} className="cal-tag-popover-color--option">
          <ColorSquare colorHash={value} hasCheck={value === newTagColor} small/>
        </span>
      )}
    </div>
    <Button onClick={onSave} isLoading={saving} appearance="primary">Save</Button>
  </div>;
};


const CalendarTagPopover = ({
  close,
  onSetTag,
  entityReferenceId,
  calendarEventId,
}: {close: () => void, onSetTag: (tag: ITag) => void, entityReferenceId: string, calendarEventId: string}) => {
  const [editingTagId, setEditingTagId] = useState<null | string>(null);
  const workstreamTag = useAppSelector(s => getWorkstreamTagForEvent(s, entityReferenceId));
  const [creatingNewTag, setCreatingNewTag] = useState(false);
  const tags = useAppSelector(getAllTags);
  const dispatch = useAppDispatch();
  const editingTag = tags.find(t => t.id === editingTagId);

  useEffect(() => {
    if (editingTag) {
      setCreatingNewTag(false);
    }
  }, [editingTag]);

  const makeOnEditTag = (tagId: string | null) => (e: MouseEvent) => {
    e.stopPropagation();
    setCreatingNewTag(false);
    setEditingTagId(tagId);
  };

  const makeOnSetTag = (tag: ITag) => () => {
    if (workstreamTag?.id === tag.id) return;

    onSetTag(tag);
    close();
  };

  const onRemoveTag = async (e: MouseEvent) => {
    e.stopPropagation();

    try {
      await removeTagFromEvent(entityReferenceId);
      dispatch(removeTagFromEvents({ entityReferenceId }));
      close();
    } catch (err) {
      console.warn('failed to remove tag');
    }
  };

  const onCreateNewClick = (e: MouseEvent) => {
    e.stopPropagation();
    setCreatingNewTag(true);
  };

  const onBackClick = (e: MouseEvent) => {
    e.stopPropagation();
    setCreatingNewTag(false);
  };

  if (creatingNewTag) {
    return <TagCreator calendarEventId={calendarEventId} close={close} onBackClick={onBackClick} entityReferenceId={entityReferenceId} />;
  }

  if (!editingTag) {
    return <div>
      <div className="cal-tag-popover--option inline" onClick={onRemoveTag}><ColorSquare colorHash={ColorOptions[0].value}/>Default</div>
      {tags.map(tag => (
        <div className="cal-tag-popover--option" key={tag.id} onClick={makeOnSetTag(tag)}>
          <div className="cal-tag-popover--option">
            <ColorSquare colorHash={tag.config.colorHash} hasCheck={workstreamTag?.id === tag.id}/>
            {tag.name || '(unnamed tag)'}
          </div>
          <FontAwesomeIcon icon={faPencilAlt} className="cal-event-color--edit" onClick={makeOnEditTag(tag.id)} />
        </div>
      ))}
      <div onClick={onCreateNewClick} className="cal-event-color--create-new"><Button iconBefore={AddIcon} appearance="minimal" height={20} width={16} className="cal-event-color-create--icon"/><span>Create a new label</span></div>
    </div>;
  }

  return <TagCreator
    onBackClick={makeOnEditTag(null)}
    close={close}
    calendarEventId={calendarEventId}
    entityReferenceId={entityReferenceId}
    defaultTagColor={editingTag.config.colorHash}
    defaultTagName={editingTag.name}
    existingTagId={editingTag.id}
  />;
};


const CalendarEventColorPicker = ({
  entityReferenceId,
  calendarEventId,
}: { entityReferenceId: string, calendarEventId: string }) => {
  const workstreamTag = useAppSelector(s => getWorkstreamTagForEvent(s, entityReferenceId));

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const setTag = async (tag: ITag) => {
    setLoading(true);
    try {
      const { data: updatedTag } = await postCreateAndAssociateTag({
        name: tag.name,
        config: {
          colorHash: tag.config.colorHash,
        },
        entities: [{
          entity_type: 'CALENDAR_EVENT',
          entity_id: entityReferenceId,
        }],
        existing_tag_id: tag.id,
        tag_relation_data: {
          calendar_event_instance_id: calendarEventId,
        }
      });
      dispatch(addTagToEvents({ tag: updatedTag, entityReferenceId }));
      dispatch(receiveTags([updatedTag]));
      tracker.track(TrackEventNames.CETAG, { entityReferenceId });
    } catch (err) {
      // TODO: reset the color to the previous color
      notify({
        message: 'Unable to set meeting color, please try again.',
        type: NotificationType.WARNING,
      })(dispatch);
    } finally {
      setLoading(false);
    }
  };

  const workstreamLabel = workstreamTag ? <div className="cal-tag-popover--option selected"><ColorSquare colorHash={workstreamTag.config.colorHash}/>{workstreamTag.name || ''}</div> : ColorOptions[0].label;

  return <div className="cal-event-color-picker--container">
    <Popover minWidth="64" content={({close}) => <CalendarTagPopover calendarEventId={calendarEventId} onSetTag={(tag) => setTag(tag)} close={close} entityReferenceId={entityReferenceId} />} position={'bottom-left'}>
      <Button disabled={loading} paddingLeft="0" paddingRight="12" iconAfter={CaretDownIcon}>
        {loading ? <Spinner marginLeft="8px" size={16} /> : workstreamLabel}
      </Button>
    </Popover>
  </div>;
};


export default CalendarEventColorPicker;