import { RootState } from 'store';


export interface ITeamFromAPI {
  id: string,
  name: string | null,
  type: string,
  created_by: string,
  created_at: string,
  updated_by: string,
  updated_at: string,
  deleted_by: null | string,
  deleted_at: null | string,
  users: {
    relation: string,
    data: Record<string, unknown>,
    created_by: string,
    created_at: string,
    updated_by: string,
    updated_at: string,
    user: {
      email: string,
      status: string
      is_superuser: boolean,
      full_name: string,
      org_id: string,
      id: string,
      created_at: string,
    }
  }[]
}

export interface ITeam {
  id: string,
  name: string | null,
  type: string,
  created_by: string,
  created_at: string,
  updated_by: string,
  updated_at: string,
  deleted_by: null | string,
  deleted_at: null | string,
  users: {
    relation: string,
    data: Record<string, unknown>,
    created_by: string,
    created_at: string,
    updated_by: string,
    updated_at: string,
    user: string,
  }[]
}

const root = (state: RootState) => state.teams;
const teamsById = (state: RootState) => root(state).teamsById;
export const getTeamIds = (state: RootState) => Object.keys(teamsById(state));