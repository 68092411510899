import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkIcon } from 'components/LinkCard/LinkIcon';
import LoadingDots from 'components/LoadingDots';
import NoteEditor from 'components/Note/noteEditor';
import { NotificationType } from 'store/notifications/selectors';
import cx from 'classnames';
import { faBookmark as faFilledBookmark } from '@fortawesome/free-solid-svg-icons';
import { fetchLink } from 'api/links';
import { fetchNote } from 'api/notes';
import { getConnector } from 'store/connectors/selectors';
import { getDocumentById } from 'store/documents/selectors';
import { getIconForMimetype } from 'components/icons/files';
import { getLinkById } from 'store/links/selectors';
import { getNoteById } from 'store/notes/selectors';
import { getProvidersIcon } from 'components/Connector/providers';
import { neverGuardDefault } from 'utils/neverguard';
import { notify } from 'store/notifications/slice';
import { receiveDocuments } from 'store/documents/slice';
import { receiveLinks } from 'store/links/slice';
import { receiveNotes } from 'store/notes/slice';
import { store } from 'store';
import { updateNote } from 'store/notes/slice';
import { FailedResponseResultsCount, openSidebarToDocument, openSidebarToLink, openSidebarToNote } from 'store/search/slice';
import { IDocSearchResult, ILinkSearchResult, INoteSearchResult, getTotalSearchResults, isSearchBarSelectedDoc, isSearchBarSelectedLink, isSearchBarSelectedNote, isSearchSidebarOpen, searchResults } from 'store/search/selectors';
import { MouseEvent, useState } from 'react';
import { faCalendarAlt, faBookmark as faEmptyBookmark, faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { fetchDocument, rememberDocument } from 'api/documents';
import { useAppDispatch, useAppSelector } from 'hooks';

import './style.css';


const SearchResultDoc = ({
  result
}: {result: IDocSearchResult}) => {
  const [isLoading, setIsLoading] = useState(false);
  const documentNotStored = useAppSelector(s => getDocumentById(s, result.search_doc.id)) === undefined;
  const isSelectedDoc = useAppSelector(s => isSearchBarSelectedDoc(s, result.search_doc.id));
  const isSidebarOpen = useAppSelector(isSearchSidebarOpen);
  const selected = isSelectedDoc && isSidebarOpen;

  const dispatch = useAppDispatch();
  const connectors = Array.isArray(result.search_doc.connector) ? result.search_doc.connector : [result.search_doc.connector];
  if (!connectors.length) {
    return null;
  }
  const Icon = getProvidersIcon(connectors.map(c => c.provider));

  const timeString = (new Date(result.search_doc.created_at)).toLocaleTimeString(navigator.language, {month: 'numeric', day: 'numeric', year: '2-digit', hour: '2-digit', minute: '2-digit'});

  const rememberDoc = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      const {data} = await rememberDocument(result.search_doc.id);
      dispatch(receiveDocuments([data]));
    } catch (err) {
      notify({ type: NotificationType.WARNING, message: 'Failed to add document to remember'})(dispatch);
      console.warn('Failed to bookmark', err);
    } finally {
      setIsLoading(false);
    }
  };

  const openSidebar = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (documentNotStored) {
      try {
        dispatch(openSidebarToDocument(result.search_doc.id));
        const {data} = await fetchDocument(result.search_doc.id);
        dispatch(receiveDocuments([data]));
      } catch (err) {
        notify({
          message: 'Could not find document',
          type: NotificationType.ERROR,
        })(dispatch);
      }
    } else {
      dispatch(openSidebarToDocument(result.search_doc.id));
    }
  };

  const FileIcon = getIconForMimetype(result.search_doc.mimetype);

  return (
    <div className="activity-document--row">
      <div className="document-row--time">{timeString}</div>
      <div className={cx('document-row--container', {selected})} onClick={openSidebar}>
        <div className="document-row--inner-container">
          <div className="document-row-icons--container">
            <FileIcon />
            <Icon className="document-row--icon"/>
          </div>
          <div className="document-row--filename">
            <div className="document-row-filename--content">{result.search_doc.filename}</div>
          </div>
        </div>
        <div className="document-row--actions">
          {isLoading && <LoadingDots className="document-row--loading" />}
          <Button size="small" buttonType="subtle" onClick={rememberDoc} active={result.search_doc.star}><FontAwesomeIcon icon={result.search_doc.star ? faFilledBookmark : faEmptyBookmark} className="document-row--ext-link"/></Button>
        </div>
      </div>
    </div>
  );
};

const SearchResultLink = ({
  result
}: {result: ILinkSearchResult}) => {
  const linkNotStored = useAppSelector(s => getLinkById(s, result.link.id)) === undefined;
  const isSelectedLink = useAppSelector(s => isSearchBarSelectedLink(s, result.link.id));
  const isSidebarOpen = useAppSelector(isSearchSidebarOpen);
  const selected = isSelectedLink && isSidebarOpen;

  const dispatch = useAppDispatch();
  const connectors = Array.isArray(result.link.connector_id) ? result.link.connector_id : [result.link.connector_id];
  if (!connectors.length) {
    return null;
  }

  const connector = useAppSelector(s => getConnector(s, result.link.connector_id || '')) || {};

  const timeString = (new Date(result.link.created_at)).toLocaleTimeString(navigator.language, {month: 'numeric', day: 'numeric', year: '2-digit', hour: '2-digit', minute: '2-digit'});

  const openSidebar = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (linkNotStored) {
      try {
        dispatch(openSidebarToLink(result.link.id));
        const {data} = await fetchLink(result.link.id);
        dispatch(receiveLinks([data]));
      } catch (err) {
        notify({
          message: 'Could not find link',
          type: NotificationType.ERROR,
        })(dispatch);
      }
    } else {
      dispatch(openSidebarToLink(result.link.id));
    }
  };

  return (
    <div className="activity-document--row">
      <div className="document-row--time">{timeString}</div>
      <div className={cx('link-row--container', {selected})} onClick={openSidebar}>
        <div className="document-row-icons--container">
          <LinkIcon url={result.link.normalized_url} height={49} width={34} className="link-row--icon" iconType={result.link.data.type} background_color={result.link.data.background_color} background_scaled_image={result.link.data.background_scaled_image} />
        </div>
        <div className="link-row--inner-container">
          <div className="link-row--name">
            {result.link.data.title || result.link.normalized_url}
          </div>
          <div className="link-row--description">
            {result.link.data.description}
          </div>
          <div className="link-row--details">
            {`${connector?.provider || ''} from ${result.link.sender || ''}`}
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchResultNote = ({
  result
}: {result: INoteSearchResult}) => {
  const noteNotStored = useAppSelector(s => getNoteById(s, result.note.id)) === undefined;
  const isSelectedNote = useAppSelector(s => isSearchBarSelectedNote(s, result.note.id));
  const isSidebarOpen = useAppSelector(isSearchSidebarOpen);
  const selected = isSelectedNote && isSidebarOpen;

  const dispatch = useAppDispatch();
  const timeString = (new Date(result.note.created_at)).toLocaleTimeString(navigator.language, {month: 'numeric', day: 'numeric', year: '2-digit', hour: '2-digit', minute: '2-digit'});


  const previewNote = async () => {
    if (noteNotStored) {
      try {
        dispatch(openSidebarToNote(result.note.id));
        const {data} = await fetchNote(result.note.id);
        dispatch(receiveNotes([data]));
      } catch (err) {
        notify({
          message: 'Could not find note',
          type: NotificationType.ERROR,
        })(dispatch);
      }
    } else {
      dispatch(openSidebarToNote(result.note.id));
    }
  };

  const openSidebar = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    previewNote();
  };

  return (
    <div className="activity-document--row">
      <div className={cx('link-row--container', {selected})} onClick={openSidebar}>
        <div className="document-row-icons--container">
          <FontAwesomeIcon className="classify-sidebar-link--icon note-row--icon" size="sm" icon={faStickyNote}/>
        </div>
        <div className="link-row--inner-container">
          <div className="note-row--description">
            <NoteEditor disabled={true} hideControls noteContent={result.note.content} onChange={(content: string) => updateNote({ noteId: result.note.id, content })(dispatch, store.getState)} noteId={result.note.id} />
          </div>
          <div className="note-row--name">
            <div className="note-row--date">
              {timeString}
            </div>
            <div className="note-row--title">
              {result.note.title}
            </div>
            {result.note.data.calendarEventId || result.note.data.calendarEventStart ? 
              <FontAwesomeIcon icon={faCalendarAlt} className="note-row--calendar-icon"/> : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchResult = ({
  result
}: {result: IDocSearchResult | ILinkSearchResult  | INoteSearchResult}) => {
  if ('link' in result) {
    return <SearchResultLink result={result} />;
  }

  if ('note' in result) {
    return <SearchResultNote result={result} />;
  }

  if ('search_doc' in result) {
    return <SearchResultDoc result={result} />;
  }

  return neverGuardDefault(result, null);
};

const getKey = (result: IDocSearchResult | ILinkSearchResult | INoteSearchResult) => {
  if ('link' in result) {
    return result.link.id;
  }

  if ('search_doc' in result) {
    return result.search_doc.id;
  }

  if ('note' in result) {
    return result.note.id;
  }

  return neverGuardDefault(result, '');
};

const SearchResults = () => {
  const results = useAppSelector(searchResults);
  const totalResults = useAppSelector(getTotalSearchResults);

  let resultCount = null;
  if (Number.isInteger(totalResults)) {
    if (totalResults === 0) {
      resultCount = 'No results found';
    } else if (totalResults === FailedResponseResultsCount) {
      resultCount = 'Failed to get results';
    } else if (!totalResults) {
      resultCount = 'Showing all results';
    } else if (totalResults === 1) {
      resultCount = 'One result found';
    } else if (totalResults > 20) {
      resultCount = `Showing first 20 of ${totalResults} results`;
    } else {
      resultCount = `Showing all ${totalResults} results`;
    }
  }

  return <div className="search-results--container">
    <div className="search-results--count">{resultCount}</div>
    <div>{results.map(result => <SearchResult result={result} key={getKey(result)} />)}</div>
  </div>;
};

export default SearchResults;