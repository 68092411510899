import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Dialog, Popover, TextInputField } from 'evergreen-ui';
import { faAddressBook, faChevronDown, faClipboardList, faCloudDownloadAlt, faHistory, faHome, faPlus, faSignOutAlt, faSlidersH, faStream, faUser } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faStickyNote } from '@fortawesome/free-regular-svg-icons';

import { NotificationType } from 'store/notifications/selectors';
import { TagCreator } from 'components/CalendarEventColorPicker';
import { expireToken } from 'utils/auth';
import { getOrderedNamedTags } from 'store/tags/selectors';
import { notify } from 'store/notifications/slice';
import { submitAppFeedback } from 'api/user';
import { GateNames, getCurrentUser, userHasGate } from 'store/user/selector';
import { useAppDispatch, useAppSelector } from 'hooks';

import './style.css';


const signOut = () => {
  expireToken();
  window.location.href = '/';
};


const PopoverContent = () => {
  const hasRules = useAppSelector(s => userHasGate(s, GateNames.RULES));

  return (
    <div>
      <NavLink to="/settings" className="classify-sidebar-link" exact>
        <FontAwesomeIcon className="classify-sidebar-link--icon" icon={faSlidersH}/>
        Settings
      </NavLink>
      <NavLink to="/settings/accounts" className="classify-sidebar-link">
        <FontAwesomeIcon className="classify-sidebar-link--icon" icon={faCloudDownloadAlt}/>
        Accounts
      </NavLink>
      {hasRules &&
        <NavLink to="/settings/rules" className="classify-sidebar-link">
          <FontAwesomeIcon className="classify-sidebar-link--icon" icon={faClipboardList}/>
          Rules
        </NavLink>
      }
      <div className="classify-sidebar-link" onClick={signOut}>
        <FontAwesomeIcon className="classify-sidebar-link--icon" icon={faSignOutAlt}/>
        Logout
      </div>
    </div>
  );
};


const messages = [
  'We appreciate your feedback 🎉',
  'Our team thrives on this 🙏',
  'We couldn\'t build this without you 🤗',
  'Sending this straight to our engineers 🤓',
  'Thank you for helping us think deeper on this 🤔',
];


const Sidebar = () => {
  const user = useAppSelector(getCurrentUser);
  const tags = useAppSelector(getOrderedNamedTags);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [submittingFeedback, setSubmittingFeedback] = useState(false);
  const [feedbackInput, setFeedbackInput] = useState('');
  const dispatch = useAppDispatch();

  const submit = async () => {
    setSubmittingFeedback(true);
    try {
      await submitAppFeedback(feedbackInput);
      setSubmittingFeedback(false);
      setFeedbackOpen(false);
      setFeedbackInput('');
      notify({
        message: messages[Math.floor(Math.random() * messages.length)],
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (e) {
      console.warn('Errors submitting feedback', e);
    } finally {
      setSubmittingFeedback(false);
    }
  };

  const openDrift = () => {
    window.drift.load('y8gfib5v5byv');
    window.drift.on('chatClose', api => {
      api.widget.hide();
    });
    window.drift.on('ready', api => {
      api.openChat();
    });
  };

  const maybeSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submit();
    }
  };

  const hasContacts = useAppSelector(s => userHasGate(s, GateNames.CONTACTS));


  return (
    <>
      <div className="classify-sidebar--container">
        <div className="classify-sidebar-header--container">
          <div className="classify-sidebar-user-info--container">
            <div className="classify-sidebar--avatar"><FontAwesomeIcon icon={faUser} size="lg" /></div>
            {/* sometimes (e.g. on the /verify page) we don't require auth and still show the sidebar
                so the user might be undefined
            */}
            {user && <div className="classify-sidebar-user-info--name">{user.full_name}</div>}
          </div>
          <Popover content={<PopoverContent />}>
            <div className="classify-sidebar-open--icon"><FontAwesomeIcon icon={faChevronDown} /></div>
          </Popover>
        </div>
        <div className="classify-sidebar-links--container">
          <NavLink to="/" activeClassName="active" exact className="classify-sidebar-link purple">
            <FontAwesomeIcon className="classify-sidebar-link--icon" size="sm" icon={faHome}/>
            Home
          </NavLink>
          <NavLink to="/activity" activeClassName="active" className="classify-sidebar-link yellow">
            <FontAwesomeIcon className="classify-sidebar-link--icon" size="sm" icon={faStream}/>
            Activity
          </NavLink>
          <NavLink to="/remember" activeClassName="active" className="classify-sidebar-link red">
            <FontAwesomeIcon className="classify-sidebar-link--icon" size="sm" icon={faHistory}/>
            Remember
          </NavLink>
          <NavLink to="/tasks" activeClassName="active" className="classify-sidebar-link green">
            <FontAwesomeIcon className="classify-sidebar-link--icon" size="sm" icon={faCheckCircle}/>
            Tasks
          </NavLink>
          <NavLink to="/notes" activeClassName="active" className="classify-sidebar-link blue">
            <FontAwesomeIcon className="classify-sidebar-link--icon" size="sm" icon={faStickyNote}/>
            Notes
          </NavLink>
          {hasContacts && <NavLink to="/contacts" activeClassName="active" className="classify-sidebar-link violet">
            <FontAwesomeIcon className="classify-sidebar-link--icon" size="sm" icon={faAddressBook}/>
            Contacts
          </NavLink>}
          <div className="classify-sidebar-workstreams--container">
            <div className="classify-sidebar-workstreams--header">
              <div>Workstreams</div>
              <Popover content={({close}) => <TagCreator close={close} />}>
                <div className="classify-sidebar-workstreams-header--icon"><FontAwesomeIcon icon={faPlus}/></div>
              </Popover>
            </div>
            <div>
              {tags.length === 0 && <div className="classify-sidebar-workstreams--empty">No workstreams yet</div>}
              {tags.map(tag => <NavLink className="classify-sidebar-workstreams--link" activeStyle={{backgroundColor: tag.config.colorHash, color: 'white'}} style={{borderColor: tag.config.colorHash, color: tag.config.colorHash}} key={tag.id} to={`/workstreams/${tag.id}`}>{tag.name || tag.id}</NavLink>)}
            </div>
          </div>
        </div>
        <div className="classify-sidebar-footer--container">
          <div className="classify-sidebar-footer--feedback" onClick={openDrift}>chat with us</div>
          <div className="classify-sidebar-footer--feedback" onClick={() => setFeedbackOpen(true)}>feedback</div>
          <div className="classify-sidebar--version">{process.env.REACT_APP_VERSION}</div>
        </div>
      </div>
      <Dialog
        isShown={feedbackOpen}
        title="Give the Classify team your thoughts"
        onCloseComplete={() => setFeedbackOpen(false)}
        onConfirm={submit}
        isConfirmLoading={submittingFeedback}
        confirmLabel="Submit"
        isConfirmDisabled={!feedbackInput}
      >
        <TextInputField
          label="Your feedback"
          placeholder="I think ... could be better if ..."
          hint="Press enter to submit"
          value={feedbackInput}
          autoFocus
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFeedbackInput(e.target.value)}
          onKeyDown={maybeSubmit}
        />
      </Dialog>
    </>
  );
};

export default Sidebar;