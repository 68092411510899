import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { formatDistance } from 'date-fns';

import { ITagEntry } from 'store/tags/selectors';
import { getCurrentUser, getUserNameById } from 'store/user/selector';
import { useAppSelector, useNote } from 'hooks';

import './style.css';

interface ITaggedNoteActivityProps {
  tagTimelineEntry: ITagEntry;
  onClick: () => void;
}

const WorkstreamActivityNotePreview = ({
  tagTimelineEntry,
  onClick,
}: ITaggedNoteActivityProps) => {

  const relation = tagTimelineEntry.tag_relation;
  const { note } = useNote(relation.entity_id);
  const creatorName = useAppSelector(s => getUserNameById(s, note?.created_by || ''));
  const currentUserId = useAppSelector(getCurrentUser)?.id;
  const dateStr = new Date(note?.created_at || relation.timestamp).toLocaleString('default', { month: '2-digit', day: '2-digit', year: '2-digit' });
  const relTime = formatDistance(new Date(relation.timestamp), new Date());

  const calEvtId = (note?.data.calendarEventId as string | undefined);
  const hasCalEvt = !!calEvtId;
  const calEvtIcon = <div className='workstream-activity-note--footer'><FontAwesomeIcon icon={faCalendarDay} /></div>;

  return (
    <div className='workstream-activity-note--container' onClick={onClick}>
      <div className='workstream-activity-note-content--container'>
        <div className='workstream-activity-note-icon--container'>
          <FontAwesomeIcon icon={faStickyNote} />
        </div>
        <div className='workstream-activity-note-text--container'>
          <div className='workstream-activity-note--header'>
            {dateStr} - {note?.title}
          </div>
          <div className='workstream-activity-note--content'>
            From <strong>{currentUserId === note?.created_by ? 'You' : creatorName}</strong>
            <span> - Pinned {relTime} ago.</span>
          </div>
        </div>
      </div>
      <div>
        {hasCalEvt ? calEvtIcon : <div className='workstream-activity-note-footer--empty'></div>}
      </div>
    </div>
  );
};

export default WorkstreamActivityNotePreview;