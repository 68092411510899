import cx from 'classnames';

import { hideTransientNotification } from 'store/notifications/slice';
import { INotification, getAllNotifications } from 'store/notifications/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';

import './style.css';


const Notification = ({
  id,
  message,
  type,
  showDismiss=true,
}: INotification) => {
  const dispatch = useAppDispatch();

  const dismiss = () => dispatch(hideTransientNotification(id));

  return (
    <div className={cx('notification-message--container', type)}>
      <div>{message}</div>
      {showDismiss && <div className="notification-message--dismiss" onClick={dismiss}>&times;</div>}
    </div>
  );
};


const NotificationContainer = () => {
  const notifications = useAppSelector(getAllNotifications);

  return (
    <div className="notifications--container">
      {notifications.map((notif) => <Notification key={notif.id} {...notif} />)}
    </div>
  );
};


export default NotificationContainer;