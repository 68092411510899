import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { pathOr } from 'ramda';

import CalendarEventPill from 'components/CalendarEventPill';
import LoadingDots from 'components/LoadingDots';
import NotePill from 'components/NotePill';
import { ITagEntry, getTagById } from 'store/tags/selectors';
import { getCurrentUser, getUserNameById } from 'store/user/selector';
import { useAppSelector, useCalendarEvent, useNote } from 'hooks';

import './style.css';


interface ITaggedNoteActivityProps {
  tagTimelineEntry: ITagEntry;
}


const TaggedNote = ({
  tagTimelineEntry,
}: ITaggedNoteActivityProps) => {
  const relation = tagTimelineEntry.tag_relation;
  const {loading: noteLoading, error, note} = useNote(relation.entity_id);
  const tag = useAppSelector(s => getTagById(s, relation.tag_id));

  const calEvtId = pathOr(
    pathOr('', ['data', 'calendarEventId'], note || {}),
    ['data', 'calendar_event_id'],
    relation
  );
  const {loading: calEvtLoading} = useCalendarEvent(calEvtId);
  const dateStr = new Date(relation.timestamp).toLocaleString('default', {month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'});

  const actionText = note?.created_at !== note?.updated_at ? 'updated note' : 'added new note';
  const prepositionText = note?.created_at !== note?.updated_at ? 'in' : 'to';

  const notePill = noteLoading ? <div className="tagged-document-activity--loading">Loading<LoadingDots /></div> : <NotePill noteId={relation.entity_id} tagId={relation.tag_id} />;
  const eventPill = calEvtLoading ? <div className="tagged-document-activity--loading">Loading<LoadingDots /></div> : <CalendarEventPill calendarEventId={calEvtId} navigateDate={(note?.data.calendarEventStart as string | undefined) || relation.timestamp} />;

  const currentUserId = useAppSelector(getCurrentUser)?.id;
  const userId = relation.data?.tagged_by;
  const fullName = useAppSelector(s => getUserNameById(s, userId || '') || 'You');
  const usersName = (currentUserId === userId || userId === undefined) ? 'You' : `${fullName}`;

  return (
    <div className="tagged-document-activity--container">
      <div className="tagged-document-activity-content-container">
        <div className="tagged-document-activity--content">
          <FontAwesomeIcon width="20px" className="tagged-document-activity--icon" icon={faThumbtack} />
          <span>{usersName ? usersName : 'You'} {actionText}</span>
          {notePill}
          <span>{prepositionText}</span>
          {calEvtId ? eventPill : <span className="tagged-note-workstream--text">{tag.name || 'this workstream'}</span>}
        </div>
        <div className="tagged-document-activity--time">{dateStr}</div>
      </div>
      {!!error && <div className="tagged-document-activity--error">{error || 'Failed to load note'}</div>}
    </div>
  );
};


export default TaggedNote;