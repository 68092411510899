import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faOtter, faSatellite } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import LoadingDots from 'components/LoadingDots';
import Note from 'components/Note';
import TagRelation from 'components/TagRelation';
import WorkstreamActivityNotePreview from 'components/WorkstreamActivityNotePreview';
import { fetchNextTagTimeline } from 'store/tags/slice';
import { store } from 'store';
import { PinSpaces, TagRelationEntityTypes, TagTimelineView } from 'constants/app';
import { getEarlistTimelineEpochForTag, getTagRelationId, getTimelineForTagHasNoMoreItems, getTimelineForTagItems, getTimelineForTagLoading } from 'store/tags/selectors';
import { getIsWorkstreamNotesUnread, getIsWorkstreamPinsUnread } from 'store/read_receipts/selectors';
import { markWorkstreamNotesAsRead, markWorkstreamPinsAsRead } from 'store/read_receipts/slice';
import { useAppDispatch, useAppSelector, useVisibility } from 'hooks';

import { Pin } from './pins';
import './style.css';


const EmptyPreviewState = ({ text, icon }: { text: string, icon: JSX.Element }) => {
  return (
    <div className='workstreams-activity-preview-empty--container'>
      {`Recent shared ${text}:`}
      <div className='workstreams-activity-preview-empty--text'>
        <div className='workstreams-activity-preview-empty--icon'>{icon}</div>
        <div>{`No shared ${text} yet`}</div>
      </div>
    </div>
  );
};

const viewToSubtitle: Record<TagTimelineView, string> = {
  [TagTimelineView.ACTIVITY]: 'See Google Drive comments, recent email conversations, and Slack threads related to important files for this meeting.',
  [TagTimelineView.NOTES]: 'View notes made in meetings by you or your teammates. Create new notes directly in this workstream.',
  [TagTimelineView.PINS]: 'See the content that you or your team has pinned to meetings relevant to this workstream. Or pin content directly to the workstream.',
  [TagTimelineView.ACTION_ITEMS]: 'See the tasks that you made for meetings relevant to this workstream. Or add new tasks directly to the workstream.',
};

const viewToText: Record<TagTimelineView, string> = {
  [TagTimelineView.ACTIVITY]: 'activity',
  [TagTimelineView.NOTES]: 'notes',
  [TagTimelineView.PINS]: 'pins',
  [TagTimelineView.ACTION_ITEMS]: 'tasks',
};

export const LoadingIndicator = ({ tagId, view, showNoMore=true }: { tagId: string, view: TagTimelineView, showNoMore?: boolean }) => {
  const earliestTimelinEpoch = useAppSelector(s => getEarlistTimelineEpochForTag(s, tagId, view));
  const loading = useAppSelector(s => getTimelineForTagLoading(s, tagId, view));
  const noMoreToLoad = useAppSelector(s => getTimelineForTagHasNoMoreItems(s, tagId, view));
  const [isVisible, checkInView, currentElement] = useVisibility<HTMLDivElement>(100);
  const items = useAppSelector(s => getTimelineForTagItems(s, tagId, view));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loading && isVisible && !noMoreToLoad) {
      dispatch(fetchNextTagTimeline({ tagId, view })).finally(() => {
        setTimeout(() => checkInView(), 100);
      });
    }

  }, [isVisible, loading, dispatch, earliestTimelinEpoch, noMoreToLoad, checkInView]);

  const isEmpty = items.length === 0;

  return <div ref={currentElement} className="document-timeline--loading">
    {loading && <div className="document-timeline--loading-content"><span>Loading more {viewToText[view]}</span><LoadingDots /></div>}
    {!loading && noMoreToLoad && !isEmpty && showNoMore && <div>No older {viewToText[view]} found.</div>}
    {!loading && noMoreToLoad && isEmpty &&
      <div className="workstream-timeline--empty-container">
        <div className="workstream-timeline--empty-header">Tag events to this workstream to track {viewToText[view]}.</div>
        <div className="workstream-timeline--empty-subtitle">{viewToSubtitle[view]}</div>
      </div>
    }
  </div>;
};


const WorkstreamActivityView = ({ tagId }: { tagId: string }) => {
  const items = useAppSelector(s => getTimelineForTagItems(s, tagId, TagTimelineView.ACTIVITY));
  const notes = items.filter(item => item.tag_relation.entity_type === TagRelationEntityTypes.NOTE);
  const pinnedEntityTypes = new Set([TagRelationEntityTypes.DOCUMENT, TagRelationEntityTypes.LINK]);
  const pins = items.filter(item => pinnedEntityTypes.has(item.tag_relation.entity_type));
  const loading = useAppSelector(s => getTimelineForTagLoading(s, tagId, TagTimelineView.ACTIVITY));
  const [shouldMarkAsRead, setShouldMarkAsRead] = useState(false);
  const pinsUnread = useAppSelector(s => getIsWorkstreamPinsUnread(s, tagId));
  const notesUnread = useAppSelector(s => getIsWorkstreamNotesUnread(s, tagId));
  const [viewNoteId, setViewNoteId] = useState<undefined | string>(undefined);
  const dispatch = useAppDispatch();

  const notesEmpty = notes.length === 0 && !loading;
  const pinsEmpty = pins.length === 0 && !loading;

  useEffect(() => {
    if (!loading && (pinsUnread || notesUnread)) {
      setShouldMarkAsRead(true);
    }
  }, [loading, tagId, pinsUnread, notesUnread]);

  useEffect(() => {
    // mark on unmount so the markers don't go away on render
    return () => {
      if (shouldMarkAsRead) {
        markWorkstreamNotesAsRead({ tagId })(dispatch, store.getState, {});
        markWorkstreamPinsAsRead({ tagId })(dispatch, store.getState, {});
      }
    };
  }, [shouldMarkAsRead, tagId]);

  const onViewNote = (noteId?: string | undefined) => {
    setViewNoteId(noteId);
  };

  return (
    <div className="workstreams-tagged-activity-content-split--container">
      <div className='workstreams-tagged-activity-events--container'>
        <h3 className="workstreams-tagged-activity--title">Activity</h3>
        {items.map(item => <TagRelation tagTimelineEntry={item} key={getTagRelationId(item.tag_relation)} />)}
        <LoadingIndicator tagId={tagId} view={TagTimelineView.ACTIVITY} />
      </div>

      <div className="workstreams-tagged-activity-related-content--container">
        {viewNoteId &&
          <div className='workstream-tagged-activity-notes-viewing--container'>
            <Note
              noteId={viewNoteId}
              showBackArrow
              onViewNote={onViewNote}
              showAsEditor
              disablePopover
            />
          </div>
        }
        {!viewNoteId &&
          <>
            {!notesEmpty &&
              <div className="workstreams-tagged-activity-recent--container">
                Recent notes:
                {notes.slice(0, 5).map(note => <WorkstreamActivityNotePreview tagTimelineEntry={note} key={getTagRelationId(note.tag_relation)} onClick={() => setViewNoteId(note.tag_relation.entity_id)} />)}
                {loading && <div className="workstream-tagged-activity-recent--loading">Loading recent notes<LoadingDots /></div>}
                {notes.length !== 0 && <Link className="workstreams-show-more--link" to={`/workstreams/${tagId}/notes`}>View {notes.length >= 5 ? 'more' : 'all'}</Link>}
              </div>
            }
            {notesEmpty && <EmptyPreviewState text={'notes'} icon={<FontAwesomeIcon icon={faOtter} size={'lg'} />} />}
            {!pinsEmpty &&
              <div className="workstreams-tagged-activity-recent--container">
                Recent pins:
                {pins.slice(0, 5).map(pin => <Pin
                  className="workstreams-activity--pin"
                  key={getTagRelationId(pin.tag_relation)}
                  tagRelationId={getTagRelationId(pin.tag_relation)}
                  eventId={pin.tag_relation.data?.calendar_event_id}
                  pinnedBy={pin.tag_relation.data?.tagged_by || pin.tag_relation.updated_by || ''}
                  pinnedAt={pin.tag_relation.updated_at || pin.tag_relation.timestamp}
                  pinnedRelation={pin.tag_relation.relation}
                  link={pin.tag_relation.entity_type === TagRelationEntityTypes.LINK ? pin.tag_relation.entity_id : undefined}
                  document={pin.tag_relation.entity_type === TagRelationEntityTypes.DOCUMENT ? pin.tag_relation.entity_id : undefined}
                  pinSpace={pin.tag_relation.namespace?.startsWith('tea:') ? PinSpaces.PUBLIC : PinSpaces.PRIVATE}
                  tagId={pin.tag_relation.tag_id}
                />)}
                {loading && <div className="workstream-tagged-activity-recent--loading">Loding recent pins<LoadingDots /></div>}
                {pins.length !== 0 && <Link className="workstreams-show-more--link" to={`/workstreams/${tagId}/pins`}>View {pins.length >= 5 ? 'more' : 'all'}</Link>}
              </div>
            }
            {pinsEmpty && <EmptyPreviewState text={'shared contents'} icon={<FontAwesomeIcon icon={faSatellite} size={'lg'} />} />}
          </>
        }
      </div>
    </div>
  );
};

export default WorkstreamActivityView;