import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import XRegExp from 'xregexp';
import cx from 'classnames';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { sort } from 'ramda';
import { Avatar, ChatIcon, IconButton } from 'evergreen-ui';
import { MouseEvent, ReactNode, useState } from 'react';

import { ActivitySubtitles } from 'constants/app';
import DocumentCard from 'components/DocumentCard';
import LoadingDots from 'components/LoadingDots';
import { URLCard } from 'components/LinkCard';
import { fetchContactByHandle } from 'store/contacts/slice';
import { getIconForMimetype } from 'components/icons/files';
import { getProviderIcon } from 'components/Connector/providers';
import { getSlackMessageLink } from 'api/connectors';
import { ActivityTitleTemplateVariables, extractUrls, getActivityTitleTemplate, getContactName, wrapURLsWithAnchorTags } from 'utils/strings';
import { AllActivity, IActivityMessage, INewCommentGdriveActivityData, INewFileSlackActivityData, INewMentionSlackActivityData } from 'store/documents/selectors';
import { AppDispatch, store } from 'store';
import DocumentSenderContext, { MessageSenderContext, RenderType } from 'components/DocumentSenderContext';
import { TrackEventNames, tracker } from 'utils/tracking';
import { activityMessageThatSentFile, getExternalLinkFromActivity, humanizeActivityParticipants } from 'utils/message_ctx';
import { escapeForSlack, userMentionRegExp } from 'utils/slack-render';
import { getAllSlackHandlesToNameMap, getContactsByHandleId } from 'store/contacts/selectors';
import { useAppDispatch, useAppSelector, useContact, useDocument } from 'hooks';


const timeFmt: Record<string, string> = { month: 'numeric', day: 'numeric', year: '2-digit', hour: 'numeric', minute: '2-digit' };

const renderableMessageContent = (message: { text: string, html?: string }) => {
  const messageText = message.text;
  const htmlMessage = message.html;
  // handle special cases
  if (messageText === '__MARKED_GDRIVE_COMMENT_RESOLVED__') {
    return <em>Marked as resolved.</em>;
  }

  if (htmlMessage) {
    return <div dangerouslySetInnerHTML={{ __html: htmlMessage }} />;
  }
  return <>{messageText.split('\n\n').map((line, i) => <div className="message-context-content--line" key={i} dangerouslySetInnerHTML={{__html: wrapURLsWithAnchorTags(line)}} />)}</>;
};


const getSlackActivityMessageContent = (message: IActivityMessage, fullSlackHandlesToNames: { [key: string]: string }, dispatch: AppDispatch) => {
  const team: string | undefined = Object.keys(fullSlackHandlesToNames).map(handle => handle.split('.')[0])[0];
  const slackHandleNamesWithoutTeam: { [key: string]: string } = {};
  const slackHandlesWithoutTeam: { [key: string]: string } = {};
  Object.entries(fullSlackHandlesToNames).forEach(([withTeam, name]) => {
    const withoutTeam = withTeam.split('.')[1];
    slackHandlesWithoutTeam[withoutTeam] = withTeam;
    slackHandleNamesWithoutTeam[withoutTeam] = name;
  });

  if (team) {
    const unknownHandleMentions = XRegExp
      .match(message.content.text, userMentionRegExp, 'all')
      .map(mention => mention.replace('<@', '').replace('>', ''))
      .filter(mention => !slackHandlesWithoutTeam[mention])
      .map(mention => `${team}.${mention}`);

    unknownHandleMentions.forEach(handle => {
      fetchContactByHandle({ source: 'slack', handle })(dispatch, store.getState, {});
    });
  }

  return <div dangerouslySetInnerHTML={{ __html: escapeForSlack(message.content.text, { users: slackHandleNamesWithoutTeam }) }} />;
};

const getSubtitle = (activity: AllActivity, fallback: ReactNode) => {
  if ('subtitle' in activity && activity.subtitle) {
    switch (activity.subtitle) {
      case ActivitySubtitles.intersectionQuery:
        return 'Sent to all event guests';
      case ActivitySubtitles.internalIntersectionQuery:
        return 'Sent to all event guests within your organization';
      default:
        return fallback;
    }
  } else {
    return fallback;
  }
};


export const DocumentActivityMessages = ({
  activity,
  documentId,
  defaultOpen = false,
  showFileName = false,
  className='',
  trackingCtx='',
}: { activity: AllActivity, documentId: string, defaultOpen?: boolean, showFileName?: boolean, className?: string, trackingCtx?: string }) => {
  const provider = activity.data.connector.provider;
  const isSlack = provider === 'SLACK';
  const isGdrive = provider === 'GDRIVE';
  const contactId = activity.data.actor?.contact_id || '';
  const { loading: contactLoading, contact } = useContact(contactId);
  const { loading: documentLoading, document } = useDocument(documentId);
  const contactsByHandleId = useAppSelector(getContactsByHandleId);

  const loading = contactLoading || documentLoading;

  const [contextContentVisible, setContextContentVisible] = useState(defaultOpen);
  const [showAllGroups, setShowAllGroups] = useState(false);

  const useMimetypeIcon = isGdrive && showFileName && document;

  const Icon = useMimetypeIcon ? getIconForMimetype(document.mimetype) : getProviderIcon(provider);

  const externalLink = getExternalLinkFromActivity(activity, document?.mimetype);
  const navigateToFile = (e: MouseEvent, track = true) => {
    e.stopPropagation();
    if (track) {
      tracker.track(TrackEventNames.ELC, { documentId, integration: provider, from: trackingCtx || 'document sidebar message context' });
    }
    const openNewTab = externalLink?.startsWith('http');
    if (openNewTab) {
      window.open(externalLink);
    } else {
      window.location.href = externalLink as string;
    }
  };

  const msgWithFile = activityMessageThatSentFile(activity);
  // use the GDrive interface because it forces defensive logic
  const messages = (activity.data as INewCommentGdriveActivityData).messages;
  const sortedMsgs = sort((a, b) => new Date(a.sent_at).valueOf() - new Date(b.sent_at).valueOf(), messages);
  const { contact: latestSender } = useContact(sortedMsgs[sortedMsgs.length - 1].sender.contact_id || '');

  const navigateToMsg = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    tracker.track(TrackEventNames.ELC, { documentId, integration: provider, from: trackingCtx || 'document message context button' });

    if (!isSlack || !msgWithFile) {
      return navigateToFile(e, false);
    }
    const slackFileData = (activity.data as INewFileSlackActivityData).slack_file_share;
    getSlackMessageLink(activity.data.connector?.id, slackFileData.channel_id, msgWithFile.sent_at)
      .then(({ data }) => window.open(data.permalink))
      .catch(() => navigateToFile(e, false));
  };

  const makeMessageGroups = (messages: IActivityMessage[]) => {
    const groups: IActivityMessage[][] = [];
    let currentGroup: IActivityMessage[] = [];

    messages.forEach(message => {
      if (currentGroup.length === 0) {
        currentGroup.push(message);
        return;
      }
      const lastMsg = currentGroup.slice(-1)[0];

      const sameSender = lastMsg.sender.contact_handle_id === message.sender.contact_handle_id;
      const withinOneMinute = Math.abs(new Date(lastMsg.sent_at).valueOf() - new Date(message.sent_at).valueOf()) <= 1000 * 60;

      if (sameSender && withinOneMinute) {
        currentGroup.push(message);
        return;
      }

      groups.push(currentGroup);
      currentGroup = [message];
    });

    if (currentGroup.length) {
      groups.push(currentGroup);
    }

    return groups;
  };

  // Google drive "messages" are just comments, which aren't grouped
  const groupedCtx = isGdrive ? sortedMsgs.map(msg => [msg]) : makeMessageGroups(sortedMsgs);

  const toggleContextContent = () => setContextContentVisible(!contextContentVisible);

  const participants = humanizeActivityParticipants(messages, (handleId: string) => contactsByHandleId[handleId]);

  const participantsText = !loading ? participants : <div className="activity-context-content--loading">loading <LoadingDots /></div>;

  const subtitle = getSubtitle(activity, participantsText);

  const linksFromMessages: Set<string> = new Set();
  sortedMsgs.forEach(({ content: { text }}) => extractUrls(text).forEach(url => linksFromMessages.add(url)));
  const limitedLinks = [...linksFromMessages].slice(0, 5);
  const renderLinks = limitedLinks.length !== 0 && contextContentVisible;
  const latestMessage = sortedMsgs[sortedMsgs.length - 1];
  let actorName = getContactName(contact);
  if (!contact) {
    if (latestSender) {
      actorName = getContactName(latestSender);
    } else if (latestMessage.sender.name) {
      actorName = latestMessage.sender.name;
    } else {
      actorName = 'Somebody';
    }
  }
  const title = getActivityTitleTemplate(activity)
    .replace(ActivityTitleTemplateVariables.ACTOR_NAME, actorName)
    .replace(ActivityTitleTemplateVariables.FILE_NAME, document?.filename || '');

  return <div className={cx('message-context-content--container', className, { open: contextContentVisible })}>
    <div className={cx('message-context-message--header', { open: contextContentVisible })} onClick={toggleContextContent}>
      <div className="message-context-message-header--container">
        <FontAwesomeIcon icon={faAngleDown} className={cx('message-context-expanded--icon', { open: contextContentVisible })} />
        <Icon className="message-context--icon" height={34} width={16} />
        <div className="message-context-subject--container">
          <div className="message-context-subject-link--container">
            {title || <DocumentSenderContext showFileName={showFileName} renderType={RenderType.SUBJECT_ONLY} activity={activity} activities={document?.latest_activity || []} docId={documentId} />}
          </div>
          <div className="message-context-subject--recipients">
            {subtitle}
          </div>
        </div>
      </div>
      <IconButton icon={ChatIcon} onClick={navigateToMsg} />
    </div>
    {!showAllGroups && groupedCtx.length > 1 && contextContentVisible && <div className="message-context-message--collapse" onClick={() => setShowAllGroups(true)}>{`View ${groupedCtx.length - 1} more`}</div>}
    {contextContentVisible && groupedCtx.map((ctxGroup, index) => {
      const timeStr = new Date(ctxGroup[0].sent_at).toLocaleString(navigator.language, timeFmt);
      const anyHasFile = ctxGroup.some(ctx => msgWithFile?.sent_at === ctx.sent_at);
      return !showAllGroups && index !== groupedCtx.length - 1 ?
        (null) :
        (<MessageGroupFromActivity
          key={ctxGroup[0].sent_at}
          timeStr={timeStr}
          msgWithFile={msgWithFile}
          anyHasFile={anyHasFile}
          group={ctxGroup}
          documentId={documentId}
          isSlack={isSlack}
          isGdrive={isGdrive}
          trackingCtx={trackingCtx}
        />);
    })}
    {renderLinks && <div className="activity-messages-links--container">{limitedLinks.map(url => <URLCard url={url} key={url} />)}</div>}
  </div>;
};


export const ActivityMessages = ({
  activity,
  defaultOpen,
  className='',
  trackingCtx='',
}: { activity: AllActivity, defaultOpen: boolean, className?: string, trackingCtx?: string }) => {
  const contactId = activity.data.actor?.contact_id || '';
  const { loading, contact } = useContact(contactId);
  const contactsByHandleId = useAppSelector(getContactsByHandleId);

  const [contextContentVisible, setContextContentVisible] = useState(defaultOpen);
  const [showAllGroups, setShowAllGroups] = useState(false);
  const provider = activity.data.connector.provider;

  const Icon = getProviderIcon(provider);

  // use the GDrive interface because it forces defensive logic
  const messages = (activity.data as INewCommentGdriveActivityData).messages;
  const sortedMsgs = sort((a, b) => new Date(a.sent_at).valueOf() - new Date(b.sent_at).valueOf(), messages);
  const latestMsg = sortedMsgs[0];
  const { contact: latestSender } = useContact(sortedMsgs[sortedMsgs.length - 1].sender.contact_id || '');
  // fallback to the slack channel
  const externalLink = getExternalLinkFromActivity(activity, undefined, true);
  const isSlack = provider === 'SLACK';
  const isGdrive = provider === 'GDRIVE';

  const navToFallback = () => {
    const openNewTab = externalLink?.startsWith('http');
    if (openNewTab) {
      window.open(externalLink);
    } else {
      window.location.href = externalLink as string;
    }
  };

  const navigateToMsg = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    tracker.track(TrackEventNames.ELC, { integration: provider, from: trackingCtx || 'activity messages' });

    if (!isSlack || !latestMsg) {
      return navToFallback();
    }
    const slackData = (activity.data as INewMentionSlackActivityData).slack_message;
    getSlackMessageLink(activity.data.connector?.id, slackData.channel_id, latestMsg.sent_at)
      .then(({ data }) => window.open(data.permalink))
      .catch(navToFallback);
  };

  const makeMessageGroups = (messages: IActivityMessage[]) => {
    const groups: IActivityMessage[][] = [];
    let currentGroup: IActivityMessage[] = [];

    messages.forEach(message => {
      if (currentGroup.length === 0) {
        currentGroup.push(message);
        return;
      }
      const lastMsg = currentGroup.slice(-1)[0];

      const sameSender = lastMsg.sender.contact_handle_id === message.sender.contact_handle_id;
      const withinOneMinute = Math.abs(new Date(lastMsg.sent_at).valueOf() - new Date(message.sent_at).valueOf()) <= 1000 * 60;

      if (sameSender && withinOneMinute) {
        currentGroup.push(message);
        return;
      }

      groups.push(currentGroup);
      currentGroup = [message];
    });

    if (currentGroup.length) {
      groups.push(currentGroup);
    }

    return groups;
  };

  // Google drive "messages" are just comments, which aren't grouped
  const groupedCtx = isGdrive ? sortedMsgs.map(msg => [msg]) : makeMessageGroups(sortedMsgs);

  const toggleContextContent = () => setContextContentVisible(!contextContentVisible);

  const participants = humanizeActivityParticipants(messages, (handleId: string) => contactsByHandleId[handleId]);

  const participantsText = !loading ? participants : <div className="activity-context-content--loading">loading <LoadingDots /></div>;

  const subtitle = getSubtitle(activity, participantsText);

  const linksFromMessages: Set<string> = new Set();
  sortedMsgs.forEach(({ content: { text }}) => extractUrls(text).forEach(url => linksFromMessages.add(url)));
  const latestMessage = sortedMsgs[sortedMsgs.length - 1];
  const limitedLinks = [...linksFromMessages].slice(0, 5);
  const renderLinks = limitedLinks.length !== 0 && contextContentVisible;
  let actorName = getContactName(contact);
  if (!contact) {
    if (latestSender) {
      actorName = getContactName(latestSender);
    } else if (latestMessage.sender.name) {
      actorName = latestMessage.sender.name;
    } else {
      actorName = 'Somebody';
    }
  }
  const title = getActivityTitleTemplate(activity)
    .replace(ActivityTitleTemplateVariables.ACTOR_NAME, actorName);

  return <div className={cx('message-context-content--container', className, { open: contextContentVisible })}>
    <div className={cx('message-context-message--header', { open: contextContentVisible })} onClick={toggleContextContent}>
      <div className="message-context-message-header--container">
        <FontAwesomeIcon icon={faAngleDown} className={cx('message-context-expanded--icon', { open: contextContentVisible })} />
        <Icon className="message-context--icon" />
        <div className="message-context-subject--container">
          <div className="message-context-subject-link--container">
            {title || <MessageSenderContext renderType={RenderType.SUBJECT_ONLY} activity={activity} />}
          </div>
          <div className="message-context-subject--recipients">
            {subtitle}
          </div>
        </div>
      </div>
      <IconButton icon={ChatIcon} onClick={navigateToMsg} />
    </div>
    {!showAllGroups && groupedCtx.length > 1 && contextContentVisible && <div className="message-context-message--collapse" onClick={() => setShowAllGroups(true)}>{`View ${groupedCtx.length - 1} more`}</div>}
    {contextContentVisible && groupedCtx.map((ctxGroup, index) => {
      const timeStr = new Date(ctxGroup[0].sent_at).toLocaleString(navigator.language, timeFmt);
      return !showAllGroups && index !== groupedCtx.length - 1 ?
        (null) :
        (<MessageGroupFromActivity
          key={ctxGroup[0].sent_at}
          timeStr={timeStr}
          msgWithFile={null}
          group={ctxGroup}
          isSlack={isSlack}
          isGdrive={isGdrive}
        />);
    })}
    {renderLinks && <div className="activity-messages-links--container">{limitedLinks.map(url => <URLCard url={url} key={url} />)}</div>}
  </div>;
};

const MessageFromActivity = ({
  hasFile = false,
  message,
  isSlack,
  documentId,
  trackingCtx='',
}: { hasFile?: boolean, message: IActivityMessage, isSlack: boolean, documentId?: string, trackingCtx?: string }) => {
  const dispatch = useAppDispatch();
  const slackHandles = useAppSelector(getAllSlackHandlesToNameMap);
  const quoteContent = message.content.quoted_html;

  const content = isSlack ? getSlackActivityMessageContent(message, slackHandles, dispatch) : renderableMessageContent(message.content);
  return (
    <div className="message-context--content" key={message.sent_at}>
      {content}
      {hasFile && documentId && <DocumentCard documentId={documentId} trackingCtx={trackingCtx} />}
      {quoteContent && <div className="message-context--quote" dangerouslySetInnerHTML={{ __html: quoteContent }} />}
    </div>
  );
};

const MessageGroupFromActivity = ({
  timeStr,
  anyHasFile = false,
  group,
  msgWithFile = null,
  isSlack,
  isGdrive,
  documentId,
  trackingCtx='',
}: { timeStr: string, anyHasFile?: boolean, group: IActivityMessage[], msgWithFile: IActivityMessage | null, isSlack: boolean, isGdrive: boolean, documentId?: string, trackingCtx?: string }) => {
  const { contact } = useContact(group[0].sender.contact_id || '');

  const senderName = contact ? getContactName(contact) : group[0].sender.name;

  return (
    <div className={cx('message-context-message-group--container', { hightlight: anyHasFile && !isGdrive })}>
      <div className="message-context--row">
        <Avatar className="message-context--avatar" name={senderName} size={40} shape="square" />
        <div className="message-context-row--content">
          <div className="message-context-sender--container">
            <div className="message-context--sender">{senderName}</div>
            <div className="message-context--sent-at">{timeStr}</div>
          </div>
          {group.map((ctx, i) => {
            const hasFile = msgWithFile?.sent_at === ctx.sent_at;
            return <MessageFromActivity key={`${i}-${ctx.sent_at}`} documentId={documentId} hasFile={hasFile && !isGdrive} message={ctx} isSlack={isSlack} trackingCtx={trackingCtx} />;
          })}
        </div>
      </div>
    </div>
  );
};

