import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Button, ChevronDownIcon, Dialog, Popover } from 'evergreen-ui';
import { MouseEvent, ReactNode, useState } from 'react';
import { faExternalLinkAlt, faGlobe, faLock, faThumbtack } from '@fortawesome/free-solid-svg-icons';

import { ITag } from 'store/calendar/selectors';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import { PinSpaces } from 'constants/app';
import { fetchDocumentLocation } from 'api/documents';
import { getContactName } from 'utils/strings';
import { getExternalLinkFromActivities } from 'utils/message_ctx';
import { getIconForMimetype } from 'components/icons/files';
import { notify } from 'store/notifications/slice';
import { IDocument, getContactIdOfMostRecentDocActivity, getMostRecentDocActivityType, getMostRecentDocTimestamp, getSenderNameOfMostRecentActivityMessages } from 'store/documents/selectors';
import { ITagRelation, getTags } from 'store/tags/selectors';
import { TrackEventNames, tracker } from 'utils/tracking';
import { getCurrentUser, getUserNameById } from 'store/user/selector';
import { useAppDispatch, useAppSelector, useContact, useDocument } from 'hooks';

import './style.css';


interface IDocumentCardProps {
  documentId: string;
  onClick?: (documentId: string) => void;
  selected?: boolean;
  onPin?: (e: MouseEvent<HTMLDivElement> | undefined, document: IDocument) => void,
  onUnpin?: (e: MouseEvent<HTMLDivElement> | undefined, document: IDocument) => void,
  onPrivatePin?: (e: MouseEvent<HTMLDivElement>, document: IDocument) => void,
  onPublicPin?: (e: MouseEvent<HTMLDivElement>, document: IDocument) => void,
  currentPinSpace?: PinSpaces,
  subtitle?: ReactNode,
  pinLoading?: boolean,
  className?: string,
  trackingCtx?: string,
  unread?: boolean,
  pinnedBy?: string,
}

const PinSpaceToButtonLabel = {
  [PinSpaces.PUBLIC]: <div><FontAwesomeIcon icon={faGlobe} /> Public</div>,
  [PinSpaces.PRIVATE]: <div><FontAwesomeIcon icon={faLock} /> Private</div>,
};


const TagPill = ({
  tag
}: { tag: ITag }) => (
  <Link
    className="classify-sidebar-workstreams--link small"
    to={`/workstreams/${tag.id}`}
    style={{
      backgroundColor: tag.config.colorHash,
      color: 'white',
      borderColor: tag.config.colorHash
    }}
  >{tag.name || '(unnamed label)'}</Link>
);

export const TagPills = ({
  tagRelations,
}: { tagRelations: ITagRelation[] }) => {
  const tagsById = useAppSelector(getTags);
  const tags = tagRelations.filter(rel => rel.relation !== 'DISASSOCIATED').map(({ tag_id }) => tagsById[tag_id]).filter(t => !!t);

  if (tags.length === 0) {
    return null;
  }

  return (
    <Popover
      content={() => <div className="document-card-tags-popover--container">
        <div className="document-card-tags-popover--title">Related to:</div>
        {tags.map(tag => <TagPill tag={tag} key={tag.id} />)}
      </div>}
      trigger='hover'
      shouldCloseOnExternalClick={true}
      minWidth="24px"
    >
      <div className="document-card-tags--container">
        {tags.length < 3 && tags.map(tag => <div className="document-card-tags--tag" key={tag.id} style={{ backgroundColor: tag.config.colorHash }} />)}
        {tags.length >= 3 && <div className="document-card-tags--tag" style={{ background: 'linear-gradient(270deg, rgba(88, 133, 201, 0.87) 0%, #BB48A9 47.4%, #FF6B2C 100%)' }} />}
      </div>
    </Popover>
  );
};


const DocumentCard = ({
  documentId,
  onClick,
  onPin,
  onUnpin,
  onPrivatePin,
  onPublicPin,
  selected=false,
  pinLoading=false,
  className='',
  currentPinSpace=PinSpaces.PRIVATE,
  trackingCtx='',
  unread=false,
  pinnedBy,
}: IDocumentCardProps) => {
  const { document } = useDocument(documentId);
  const FileIcon = getIconForMimetype(document?.mimetype || '');
  const fallbackExternalLink = getExternalLinkFromActivities(document?.latest_activity || [], document?.mimetype);
  const [showRemovePinModal, setShowRemovePinModal] = useState(false);
  const dispatch = useAppDispatch();

  const currentUserId = useAppSelector(getCurrentUser)?.id;
  const currentUserPinnedDocument = !pinnedBy || pinnedBy === currentUserId;
  const pinnerName = useAppSelector(s => getUserNameById(s, pinnedBy || ''));

  const documentActivityType = useAppSelector((s) => getMostRecentDocActivityType(s, documentId));
  const contactId = useAppSelector((s) => getContactIdOfMostRecentDocActivity(s, documentId));
  const mostRecentSenderName = useAppSelector(s => getSenderNameOfMostRecentActivityMessages(s, documentId));
  const timestamp = useAppSelector(s => getMostRecentDocTimestamp(s, documentId));
  const {
    contact,
    loading: loadingContact,
  } = useContact(contactId || '');
  const contactName = contact ? getContactName(contact) : mostRecentSenderName;

  const ActivityTypeSubtitleActions: Record<string, string> = {
    NEW_FILE_SLACK: 'Last shared:',
    NEW_FILE_COMMENT_GDRIVE: 'Last shared:',
    NEW_FILE_MENTION_GDRIVE: 'Last shared:',
    NEW_FILE_GMAIL: 'Last shared:',
    NEW_FILE_GDRIVE: 'Created',
  };

  const subtitleAction = ActivityTypeSubtitleActions[documentActivityType || 'NEW_FILE_GDRIVE'] || 'Created:';

  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  const showOpenErr = () => {
    notify({
      message: 'Unable to open file, owner did not share access.',
      type: NotificationType.WARNING,
    })(dispatch);
  };

  const open = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    tracker.track(TrackEventNames.ELC, { documentId: documentId, from: trackingCtx || 'document card' });
    try {
      const { data } = await fetchDocumentLocation(documentId);
      if (!data.location && !fallbackExternalLink) {
        showOpenErr();
        return;
      }
      window.open(data.location || fallbackExternalLink);
    } catch (err) {
      const openNewTab = fallbackExternalLink?.startsWith('http');
      if (!fallbackExternalLink) {
        showOpenErr();
        return;
      }
      if (openNewTab) {
        window.open(fallbackExternalLink);
      } else {
        window.location.href = fallbackExternalLink as string;
      }
    }
  };

  if (!document) return null;

  const shouldShowPinIcon = (!!onPin) || (!!onUnpin);

  const onPinClick = (e: undefined | MouseEvent<HTMLDivElement>) => {
    if (!shouldShowPinIcon) return;
    e?.stopPropagation();

    // unpin when: user is unpinning a doc that they pinned OR they confirmed to unpin another's pinned public doc
    const shouldUnpin = (!!onUnpin && shouldShowPinIcon && currentUserPinnedDocument) || (!!onUnpin && showRemovePinModal);
    if (shouldUnpin) {
      return onUnpin(e, document);
    }

    const shouldShowRemovePinModal = !onPin && !!onUnpin && shouldShowPinIcon && !currentUserPinnedDocument;
    if (shouldShowRemovePinModal) {
      return setShowRemovePinModal(true);
    }

    const shouldPin = !!onPin && shouldShowPinIcon;
    if (shouldPin) {
      return onPin(e, document);
    }
  };

  const showPinSpaces = shouldShowPinIcon && (onPrivatePin || onPublicPin) && currentUserPinnedDocument;

  const contactNameText = loadingContact ? <span className="document-card-date--author"> by <LoadingDots /></span> : (contactName ? <span className="document-card-date--author"> by {contactName}</span> : '');

  const dateStr = new Date(timestamp || document.created_at).toLocaleString('default', { month: 'numeric', day: 'numeric', year: '2-digit', hour: 'numeric', minute: '2-digit' });

  return <div className={cx('document-card--container', className, { hover: popoverIsOpen, unread })}>
    <div className={cx('document-card-file--container', { selected, clickable: !!onClick })} onClick={(e: MouseEvent<HTMLDivElement>) => onClick && (document.latest_activity.length ? onClick(documentId) : open(e))}>
      <FileIcon width={24} height={24} size="2x" className="document-card-file--icon" />
      <div className="document-card-file--content">
        <div className="document-card-file--name">{document.filename}</div>
        <div className="document-card--date">{subtitleAction} {dateStr}<span>{contactNameText}</span></div>
      </div>
    </div>
    {unread && <div className="document-card-unread--indicator"/>}
    <div className="document-card--actions">
      <div className="document-card--link" onClick={open}><FontAwesomeIcon icon={faExternalLinkAlt} size="sm" /></div>
      <div className="document-card-actions-pin--container">
        {showPinSpaces &&
          <Popover minWidth={100} onOpen={() => setPopoverIsOpen(true)} onClose={() => setPopoverIsOpen(false)} content={() => (
            <div>
              <div
                onClick={(e: MouseEvent<HTMLDivElement>) => !pinLoading && onPrivatePin && onPrivatePin(e, document)}
                className={cx('document-card-actions-pin-space--button', { disabled: !onPrivatePin })}
              >{PinSpaceToButtonLabel[PinSpaces.PRIVATE]}</div>
              <div
                onClick={(e: MouseEvent<HTMLDivElement>) => !pinLoading && onPublicPin && onPublicPin(e, document)}
                className={cx('document-card-actions-pin-space--button', { disabled: !onPublicPin })}
              >{PinSpaceToButtonLabel[PinSpaces.PUBLIC]}</div>
            </div>
          )}>
            <Button className="document-card-pin-space--button" isLoading={pinLoading}>{PinSpaceToButtonLabel[currentPinSpace]}<ChevronDownIcon marginLeft={4} /></Button>
          </Popover>}
        {shouldShowPinIcon && <div className={cx('document-card--pin ', { unpin: !!onUnpin, pinning: pinLoading, 'with-space': showPinSpaces })} onClick={onPinClick}><FontAwesomeIcon className="document-card--pin-icon" icon={faThumbtack} size="sm" /></div>}
      </div>
      {onPinClick &&
        <Dialog
          isShown={showRemovePinModal}
          title="Remove pinned content"
          intent="danger"
          onCloseComplete={() => setShowRemovePinModal(false)}
          onConfirm={() => onPinClick(undefined)}
          confirmLabel="Remove pinned content"
        >Are you sure you want to remove {pinnerName}&rsquo;s pinned content from event?
        </Dialog>
      }
    </div>
    <TagPills tagRelations={document.tags} />
  </div>;
};


export default DocumentCard;