import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { MouseEvent, useEffect, useState } from 'react';
import { faChevronDown, faGlobe, faLock, faThumbtack } from '@fortawesome/free-solid-svg-icons';

import CalendarPinPopover from 'components/CalendarPinPopover';
import DocumentCard from 'components/DocumentCard';
import { IDocument } from 'store/documents/selectors';
import LinkCard from 'components/LinkCard';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import { PinSpaces } from 'constants/app';
import { markCalendarPinsAsRead } from 'store/read_receipts/slice';
import { notify } from 'store/notifications/slice';
import { receiveDocuments } from 'store/documents/slice';
import { receiveLinks } from 'store/links/slice';
import { store } from 'store';
import { IMinimalCalendarEvent, getEventDocuments, getSortedPrivatePins, getSortedPublicPins, isDocumentSelected, isSimilarDocInPublicPins } from 'store/calendar/selectors';
import { TrackEventNames, tracker } from 'utils/tracking';
import { deletePin, openSidebarToDocument, openSidebarToLink, pinDocument, removeAndAddPinnedDocument, removeAndAddPinnedLink } from 'store/calendar/slice';
import { deletePin as deletePinAPI, pinDocumentToEvent, postTogglePinSpace } from 'api/calendar';
import { getCurrentUser, getUserById } from 'store/user/selector';
import { getIsCalendarPinsUnread, getLastCalendarPinsRead } from 'store/read_receipts/selectors';
import { useAppDispatch, useAppSelector, useEventSuggestions, usePrivateEventPins, usePublicEventPins } from 'hooks';


const SuggestedDocument = ({
  document,
  reason,
  eventId,
  index,
}: { document: IDocument; reason: string; eventId: string; index: number; }) => {
  const dispatch = useAppDispatch();
  const selected = useAppSelector((s) => isDocumentSelected(s, document.id));
  const [pinLoading, setPinLoading] = useState(false);

  const trackingCtx = {
    documentId: document.id,
    eventId,
    suggestionReason: reason,
    suggestionPlace: index + 1,
  };

  const focus = () => {
    dispatch(openSidebarToDocument({ id: document.id }));
    tracker.track(TrackEventNames.FOSD, trackingCtx);
  };

  const pin = async (e?: MouseEvent<HTMLDivElement>, pinSpace: PinSpaces=PinSpaces.PRIVATE) => {
    e?.stopPropagation();
    setPinLoading(true);
    try {
      const { data } = await pinDocumentToEvent(eventId, document.id, pinSpace === PinSpaces.PUBLIC);
      tracker.track(TrackEventNames.PSD, trackingCtx);
      setPinLoading(false);
      dispatch(receiveDocuments([data.document]));
      dispatch(pinDocument({
        referenceId: eventId,
        docId: data.document.id,
        pinId: data.pin_id,
        isPublicPin: data.is_shared_namespace,
        pinnedBy: data.pinned_by,
      }));
    } catch (err) {
      setPinLoading(false);
      notify({
        type: NotificationType.WARNING,
        message: 'Failed to pin file, please try again'
      })(dispatch);
    }
  };

  return <DocumentCard
    documentId={document.id}
    selected={selected}
    onClick={focus}
    onPin={(e: MouseEvent<HTMLDivElement> | undefined) => pin(e)}
    onPublicPin={(e: MouseEvent<HTMLDivElement>) => pin(e, PinSpaces.PUBLIC)}
    onPrivatePin={(e: MouseEvent<HTMLDivElement>) => pin(e, PinSpaces.PRIVATE)}
    className="calendar-context-document--container"
    pinLoading={pinLoading}
    trackingCtx="Calendar event suggestion"
  />;
};

const Pin = ({
  pin_id,
  pinned_by,
  pinned_at,
  link,
  document,
  pinSpace,
  referenceId,
  context,
}: {
  pin_id: string,
  pinned_by: string,
  pinned_at: string,
  pinSpace: PinSpaces,
  referenceId: string,
  link?: string,
  document?: string,
  context?: {
    event_start_dt: string;
  },
}) => {

  const pinner = useAppSelector(s => getUserById(s, pinned_by));
  const pinnerIsUser = useAppSelector(getCurrentUser)?.id === pinner?.id;
  const [isPinning, setIsPinning] = useState(false);
  const lastPinsRead = useAppSelector(s => getLastCalendarPinsRead(s, referenceId));
  const hasDocBeenPubliclyPinned = useAppSelector(s => isSimilarDocInPublicPins(s, referenceId, document || ''));
  const dispatch = useAppDispatch();

  const focusOnLink = (linkId: string) => {
    dispatch(openSidebarToLink({ id: linkId }));
    tracker.track(TrackEventNames.FOPL, { linkId, eventId: referenceId });
  };

  const focusOnDoc = (docId: string) => {
    dispatch(openSidebarToDocument({ id: docId }));
    tracker.track(TrackEventNames.FOPD, { documentId: docId, eventId: referenceId });
  };
  const pinIsUnread = (!pinnerIsUser && lastPinsRead && new Date(pinned_at).valueOf() > lastPinsRead) || false;

  let component = null;

  const togglePinSpace = async () => {
    try {
      setIsPinning(true);
      const {data} = await postTogglePinSpace(pin_id);
      const dispatchPayload = {
        referenceId,
        removePinId: pin_id,
        addToSpace: pinSpace === PinSpaces.PRIVATE ? PinSpaces.PUBLIC : PinSpaces.PRIVATE,
      };
      if ('link' in data && data.link) {
        dispatch(receiveLinks([data.link]));
        dispatch(removeAndAddPinnedLink({
          ...dispatchPayload,
          pinnedLink: {...data, link: data.link.id},
        }));
      } else if ('document' in data && data.document) {
        dispatch(receiveDocuments([data.document]));
        dispatch(removeAndAddPinnedDocument({
          ...dispatchPayload,
          pinnedDoc: {...data, document: data.document.id},
        }));
      }
    } catch (e) {
      console.warn(e);
      setIsPinning(false);
      notify({
        message: 'Failed to move pin, please try again',
        type: NotificationType.WARNING,
      })(dispatch);
    }
  };

  const unpin = () => {
    dispatch(deletePin({ referenceId, pinId: pin_id }));
    deletePinAPI(pin_id).catch(e => console.warn(e));
  };

  const onPinSpaceProps = {
    onPublicPin: pinSpace === PinSpaces.PRIVATE && !hasDocBeenPubliclyPinned ? togglePinSpace : undefined,
    onPrivatePin: pinSpace === PinSpaces.PUBLIC ? togglePinSpace : undefined,
  };

  if (link) {
    component = <LinkCard unread={pinIsUnread} pinnedBy={pinned_by} onClick={focusOnLink} currentPinSpace={pinSpace} pinLoading={isPinning} onUnpin={unpin} {...onPinSpaceProps} linkId={link} className="calendar-context-pinned--container" context={context} />;
  } else if (document) {
    component = <DocumentCard unread={pinIsUnread} pinnedBy={pinned_by} onClick={focusOnDoc} currentPinSpace={pinSpace} pinLoading={isPinning} onUnpin={unpin} {...onPinSpaceProps} documentId={document} className="calendar-context-pinned--container" trackingCtx='Calendar pins'/>;
  }

  if (component === null) return null;

  return (
    <div className="public-pin--container">
      {pinner && <div className="calendar-pin-context--pinner"><FontAwesomeIcon size="xs" icon={faThumbtack}/> by <strong>{pinnerIsUser ? 'You' : pinner.full_name}</strong>{pinSpace === PinSpaces.PRIVATE && hasDocBeenPubliclyPinned && ' in this event already'}</div>}
      {component}
    </div>
  );
};

const PublicPins = ({ event }: { event: IMinimalCalendarEvent }) => {
  const {
    loading,
    pins,
    error,
  } = usePublicEventPins(event);

  const [isPublicOpen, setIsPublicOpen] = useState(true);
  const pinsLength = (pins?.documents.length || 0) + (pins?.links.length || 0);
  const isEmpty = pinsLength === 0;
  const sortedPins = useAppSelector(s => getSortedPublicPins(s, event.entity_reference_id));

  return (
    <div>
      <div className="calendar-context-section--header collapsible" onClick={() => setIsPublicOpen(!isPublicOpen)}>
        <div className="calendar-context-section--collapsible">
          <FontAwesomeIcon icon={faGlobe}/>
          <label>Public Pins {!loading && `(${pinsLength})`}</label>
          <FontAwesomeIcon icon={faChevronDown} className={cx('calendar-context-section--collapsible-caret', {closed: !isPublicOpen})} />
        </div>
        <CalendarPinPopover defaultSpace={PinSpaces.PUBLIC} />
      </div>
      {isPublicOpen &&
        <div className="calendar-context-related-content--container">
          {error && <div>Failed to load pins</div>}
          {loading && <div className="calendar-detail-documents--loading">Loading pinned content<LoadingDots /></div>}
          {!loading && isEmpty && <em className="calendar-pins--empty">There is no publicly pinned content for this event</em>}
          {sortedPins.map(pin => <Pin {...pin} referenceId={event.entity_reference_id} key={pin.pin_id} pinSpace={PinSpaces.PUBLIC} context={{ event_start_dt: event.start_dt }} />)}
        </div>
      }
    </div>
  );
};


const PrivatePins = ({ event }: { event: IMinimalCalendarEvent }) => {
  const {
    loading,
    pins,
    error,
  } = usePrivateEventPins(event);

  const [isPrivateOpen, setIsPrivateOpen] = useState(true);
  const pinsLength = (pins?.documents.length || 0) + (pins?.links.length || 0);
  const isEmpty = pinsLength === 0;
  const sortedPins = useAppSelector(s => getSortedPrivatePins(s, event.entity_reference_id));

  return (
    <div>
      <div className="calendar-context-section--header collapsible" onClick={() => setIsPrivateOpen(!isPrivateOpen)}>
        <div className="calendar-context-section--collapsible">
          <FontAwesomeIcon icon={faLock} />
          <label>Private Pins {!loading && `(${pinsLength})`}</label>
          <FontAwesomeIcon icon={faChevronDown} className={cx('calendar-context-section--collapsible-caret', {closed: !isPrivateOpen})} />
        </div>
        <CalendarPinPopover defaultSpace={PinSpaces.PRIVATE} />
      </div>
      {isPrivateOpen &&
        <div className="calendar-context-related-content--container">
          {error && <div>Failed to load pins</div>}
          {loading && <div className="calendar-detail-documents--loading">Loading pinned content<LoadingDots /></div>}
          {!loading && isEmpty && <em className="calendar-pins--empty">You have not pinned anything to this event yet</em>}
          {sortedPins.map(pin => <Pin {...pin} referenceId={event.entity_reference_id} key={pin.pin_id} pinSpace={PinSpaces.PRIVATE} context={{ event_start_dt: event.start_dt }} />)}
        </div>
      }
    </div>
  );
};


const CalendarPinnedAndSuggested = ({ event }: { event: IMinimalCalendarEvent }) => {
  const docs = useAppSelector(s => getEventDocuments(s, event.entity_reference_id));
  const pinsUnread = useAppSelector((s) => getIsCalendarPinsUnread(s, event.entity_reference_id));
  const [shouldMarkAsRead, setShouldMarkAsRead] = useState(false);
  const dispatch = useAppDispatch();
  const [isSuggestedOpen, setIsSuggestedOpen] = useState(true);
  const { loading } = usePrivateEventPins(event);
  const {
    loading: suggestionsLoading,
    suggestions,
  } = useEventSuggestions(event.id);

  const pinnedDocIds = new Set(docs.map(d => d.id));

  const filteredSuggestions = suggestions?.documents.filter(({ document }) => !pinnedDocIds.has(document.id));

  useEffect(() => {
    if (!loading && pinsUnread) {
      setShouldMarkAsRead(true);
    }
  }, [loading, event, pinsUnread]);

  useEffect(() => {
    // mark on unmount so the markers don't go away on render
    return () => {
      if (shouldMarkAsRead) {
        markCalendarPinsAsRead({referenceId: event.entity_reference_id})(dispatch, store.getState, {});
      }
    };
  }, [shouldMarkAsRead, event]);

  return <div className="calendar-context-section--container">
    <div className="calendar-context-pinned-content--container">
      <PublicPins event={event} />
      <PrivatePins event={event} />
    </div>
    <div>
      <div className="calendar-context-section--header collapsible" onClick={() => setIsSuggestedOpen(!isSuggestedOpen)}>
        <div className="calendar-context-section--collapsible">
          <label>Related content</label>
          <FontAwesomeIcon icon={faChevronDown} className={cx('calendar-context-section--collapsible-caret', {closed: !isSuggestedOpen})} />
        </div>
      </div>
      {isSuggestedOpen &&
        <div className="calendar-context-related-content--container">
          {suggestionsLoading && <div className="calendar-detail-documents--loading">Loading related content<LoadingDots /></div>}
          {filteredSuggestions?.length === 0 && <em className="calendar-detail-documents--empty">No related content for this event</em>}
          {filteredSuggestions?.map((sugg, idx) => <SuggestedDocument key={sugg.document.id} document={sugg.document} reason={sugg.reason} index={idx} eventId={event.entity_reference_id} />)}
        </div>
      }
    </div>
  </div>;
};

export default CalendarPinnedAndSuggested;