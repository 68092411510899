import { API } from 'api';
import { APP_URL } from 'constants/resources';
import { TrackEventNames, tracker } from 'utils/tracking';

export const fetchAllConnectors = () => API.get('/api/v1/connectors/');

export const connectJira = (data: {code: string | null, user_id?: string, redirect_uri: string}) => API.post('/api/v1/connectors/integrate/jira', data)
  .then(tracker.trackAfterRequest(TrackEventNames.CI, {integration: 'JIRA'}));

export const connectSalesForce = (data: {code: string | null, user_id?: string, redirect_uri: string}) => API.post('/api/v1/connectors/integrate/salesforce', data)
  .then(tracker.trackAfterRequest(TrackEventNames.CI, {integration: 'SALESFORCE'}));

export const connectMicrosoft = (data: {code: string | null, user_id?: string, redirect_uri: string}) => API.post('/api/v1/connectors/integrate/microsoft', data)
  .then(tracker.trackAfterRequest(TrackEventNames.CI, {integration: 'MICROSOFT'}));

export const connectTrello = (data: {code: string | null, user_id?: string, redirect_uri: string}) => API.post('/api/v1/connectors/integrate/trello', 
  {token: data.code, redirect_uri: data.redirect_uri})
  .then(tracker.trackAfterRequest(TrackEventNames.CI, {integration: 'MICROSOFT'}));

export const connectSlack = (data: {code: string | null, user_id?: string, redirect_uri: string}) => API.post('/api/v1/connectors/integrate/slack', data)
  .then(tracker.trackAfterRequest(TrackEventNames.CI, {integration: 'SLACK'}));

export const connectGmail = () => API.post('/api/v1/connectors/integrate/gmail', 
  {authorization_response: window.location.href.replace('http:', 'https:'), app_host: APP_URL})
  .then(tracker.trackAfterRequest(TrackEventNames.CI, {integration: 'GMAIL'}));

export const disableConnector = (connectorId: string, provider: string) => API.post(`/api/v1/connectors/${connectorId}/disable`)
  .then(tracker.trackAfterRequest(TrackEventNames.DI, {connectorId, integration: provider}));

export const enableConnector = (connectorId: string, provider: string) => API.post(`/api/v1/connectors/${connectorId}/enable`)
  .then(tracker.trackAfterRequest(TrackEventNames.RI, {connectorId, integration: provider}));

export const getSlackMessageLink = (connId: string, channelId: string, messageTs: string) => {
  const params = new URLSearchParams();
  params.append('slack_channel_id', channelId);
  params.append('message_context_sent_at', messageTs);
  const path = `/api/v1/connectors/${connId}/slack-link?${params.toString()}`;
  return API.get(path);
};