import PinPopover from 'components/PinPopover';
import { PinSpaces } from 'constants/app';
import { getFocusEvent } from 'store/calendar/selectors';
import { useAppSelector } from 'hooks';

const CalendarPinPopover = ({
  defaultSpace,
  buttonClassName='',
}: {defaultSpace: PinSpaces, buttonClassName?: string}) => {
  const event = useAppSelector(getFocusEvent);

  if (!event) return null;

  return <PinPopover defaultSpace={defaultSpace} eventId={event.entity_reference_id} buttonClassName={buttonClassName} />;
};


export default CalendarPinPopover;