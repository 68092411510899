import cx from 'classnames';
import debounce from 'lodash.debounce';
import { AddIcon, Button, Checkbox, TextInput } from 'evergreen-ui';
import { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react';

import { NotificationType } from 'store/notifications/selectors';
import { deleteActionItem as deleteActionItemApi } from 'api/action_items';
import { notify } from 'store/notifications/slice';
import { store } from 'store';
import { unpinActionItem } from 'store/calendar/slice';
import { deleteActionItem, updateActionItem } from 'store/action_items/slice';
import {
  getChildActionItemIds,
  getDirtyActionItemData,
  getDirtyActionItemParentId,
  getEditingActionItemStatus,
  getEditingActionItemTitle,
  getParentIdOfActionItemById,
} from 'store/action_items/selectors';
import { useActionItem, useAppDispatch, useAppSelector } from 'hooks';

import './style.css';


const ActionItem = ({
  actionItemId,
  prevActionItem,
  onEnterClick,
}: {actionItemId: string, prevActionItem: string | undefined, onEnterClick: (parentId?: string | null) => void}) => {
  const {actionItem, loading, error} = useActionItem(actionItemId);
  const dispatch = useAppDispatch();
  const title = useAppSelector(s => getEditingActionItemTitle(s, actionItemId));
  const status = useAppSelector(s => getEditingActionItemStatus(s, actionItemId));
  const dirtyData = useAppSelector(s => getDirtyActionItemData(s, actionItemId));
  const dirtyParentId = useAppSelector(s => getDirtyActionItemParentId(s, actionItemId));
  const childActionItemIds = useAppSelector(s => getChildActionItemIds(s, actionItemId));
  const created = actionItem ? new Date(actionItem.created_at) : new Date();
  const createdStr = created.toLocaleTimeString('default', {hour: '2-digit', minute: '2-digit'});
  const [showMakeSubtask, setShowMakeSubtask] = useState(false);

  const savedParentId = (actionItem ? actionItem.data.parentActionItemId : undefined);
  const currentParentId = dirtyData ? dirtyParentId : savedParentId;
  const parentIdOfParent = useAppSelector(state => getParentIdOfActionItemById(state, (currentParentId as string) || ''));

  const update = useMemo(() => debounce(({status, title, parentActionItemId }: {status?: string, title?: string, parentActionItemId?: string | null}) => {
    updateActionItem({ actionItemId, status, title, parentActionItemId })(dispatch, store.getState);
  }, 100), [actionItemId]);

  if (error) {
    console.warn(`failed to load action item ${actionItemId}`);
    return null;
  }

  const onDeleteActionItem = async () => {
    try {
      await deleteActionItemApi(actionItemId);
      dispatch(unpinActionItem({ actionItemId }));
      dispatch(deleteActionItem(actionItemId));
    } catch (err) {
      notify({
        message: 'Failed to delete task, please try again',
        type: NotificationType.WARNING,
      })(dispatch);
    }
  };

  const handleEnterOrBackspace = (e: KeyboardEvent<HTMLInputElement>) => {
    const removeParentAI = currentParentId && (e.key === 'Enter' || e.key === 'Backspace') && title === '';
    if (removeParentAI) {
      e.preventDefault();
      update({ parentActionItemId: parentIdOfParent });
    } else if (e.key === 'Enter') {
      e.preventDefault();
      onEnterClick((currentParentId as string | undefined) || null);
    } else if (e.code === 'Backspace' && title === '') {
      e.preventDefault();
      onDeleteActionItem();
    } else if (e.code === 'Tab' && prevActionItem) {
      e.preventDefault();
      update({ parentActionItemId: prevActionItem });
    }
  };

  if (loading || !actionItem) return null;

  const subtaskButton = <Button width={80} iconBefore={AddIcon} appearance="minimal" onClick={() => onEnterClick(actionItemId)}>Subtask</Button>;
  const hasChildActionItems = childActionItemIds.length !== 0;

  return <>
    <div className={cx('action-item--container', { 'has-child': hasChildActionItems })} onMouseEnter={() => setShowMakeSubtask(true)} onMouseLeave={() => setShowMakeSubtask(false)}>
      <Checkbox className="action-item--status" defaultChecked={status === 'DONE'} checked={status === 'DONE'} onChange={() => update({ status: status === 'DONE' ? 'NOT_DONE' : 'DONE'})}/>
      <TextInput
        className={cx('action-item-title--input', {done: status === 'DONE'})}
        placeholder='Start typing...'
        disabled={status === 'DONE'}
        defaultValue={title}
        onChange={(e: ChangeEvent<HTMLInputElement>) => update({ title: e.target.value })}
        onKeyDown={handleEnterOrBackspace}
        autoFocus
      />
      <div className="action-item--created">{status !== 'DONE' && showMakeSubtask ? subtaskButton : createdStr}</div>
    </div>
    {childActionItemIds.length !== 0 &&
      <div className="action-item-children--container">{childActionItemIds.map((ai, idx) => <ActionItem key={ai} actionItemId={ai} prevActionItem={childActionItemIds[idx-1]} onEnterClick={onEnterClick} />)}</div>
    }
  </>;
};

export default ActionItem;