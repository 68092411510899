
export const union = <T, K>(setA: Set<T>, setB: Set<K>): Set<T | K> => {
  const newSet = new Set<T | K>();

  setA.forEach(elt => newSet.add(elt));
  setB.forEach(elt => newSet.add(elt));

  return newSet;
};

export const intersection = <T>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const newSet = new Set<T>([...setA, ...setB]);

  setB.forEach(elt => {
    if (!setA.has(elt)) {
      newSet.delete(elt);
    }
  });

  setA.forEach(elt => {
    if (!setB.has(elt)) {
      newSet.delete(elt);
    }
  });

  return newSet;
};

export const difference = <T, K>(setA: Set<T>, setB: Set<K>): Set<T | K> => {
  const newSet = new Set<T | K>(setA);

  setB.forEach(elt => {
    if (newSet.has(elt)) {
      newSet.delete(elt);
    }
  });

  return newSet;
};
