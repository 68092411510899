import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from 'store';
import { useState } from 'react';
import { Dialog, Popover } from 'evergreen-ui';
import { NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { faCheckCircle, faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { faPen, faThumbtack, faTrash } from '@fortawesome/free-solid-svg-icons';

import Sidebar from 'components/Sidebar';
import { TagCreator } from 'components/CalendarEventColorPicker';
import { getTagById } from 'store/tags/selectors';
import { softDeleteTag } from 'store/tags/slice';
import { useAppDispatch, useAppSelector } from 'hooks';

import WorkstreamActivityView from './activity';
import WorkstreamPinsView from './pins';
import WorkstreamTasksView from './tasks';
import WorkStreamNotesView, { WorkStreamNoteEditView } from './note';


const WorkstreamsView = () => {
  const {uuid} = useParams<{uuid: string}>();
  const history = useHistory();
  const tag = useAppSelector(s => getTagById(s, uuid));
  const [deletDialogIsShown, setDeleteDialogIsShown] = useState(false);

  const dispatch = useAppDispatch();

  if (!tag) {
    // tags are loaded on bootstrap, so if we don't have the tag, boot the user to home
    history.replace('/');
  }

  const onDeleteConfirm = () => {

    softDeleteTag(tag.id)(dispatch, store.getState, {}).then(() => {
      setDeleteDialogIsShown(false);
      history.replace('/');
    });
  };

  const tabs = [
    {
      title: <div>Summary</div>,
      key: 'activity',
      path: `/workstreams/${uuid}`,
      Component: WorkstreamActivityView,
      exact: true,
      navLinkExact: true,
    },
    {
      title: <div><FontAwesomeIcon className='workstream-view-tabs--icon' icon={faStickyNote}/>Notes</div>,
      key: 'notes',
      path: `/workstreams/${uuid}/notes`,
      Component: WorkStreamNotesView,
      exact: true,
    },
    {
      title: <div><FontAwesomeIcon className='workstream-view-tabs--icon' icon={faThumbtack}/>Pinned Content</div>,
      key: 'pins',
      path: `/workstreams/${uuid}/pins`,
      Component: WorkstreamPinsView,
    },
    {
      title: <div><FontAwesomeIcon className='workstream-view-tabs--icon' icon={faCheckCircle}/>Tasks</div>,
      key: 'tasks',
      path: `/workstreams/${uuid}/tasks`,
      Component: WorkstreamTasksView,
    },
  ];

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div>
        <div className="workstream-view-name--header">
          <div className="workstream-view-name-header--title" style={{backgroundColor: tag.config.colorHash}}>{tag.name || '(unnamed workstream)'}</div>
          <Popover content={({close}) => <TagCreator close={close} existingTagId={tag.id} defaultTagName={tag.name} defaultTagColor={tag.config.colorHash} />}>
            <div className="workstream-view-name-header--edit"><FontAwesomeIcon icon={faPen} /></div>
          </Popover>
          <div className="workstream-view-name-header--edit"><FontAwesomeIcon icon={faTrash} onClick={() => setDeleteDialogIsShown(true)} /></div>
          <Dialog isShown={deletDialogIsShown} title="Delete Workstream" intent="danger" onCloseComplete={onDeleteConfirm} confirmLabel="Delete" >
            Are you sure you want to delete the &quot;{tag.name}&quot; workstream
          </Dialog>

        </div>
        <div className='workstream-view-tab--container'>
          {tabs.map(tab => (
            <NavLink className='workstream-view-tab--option' key={tab.key} to={tab.path} exact={tab.navLinkExact}>{tab.title}<div className='workstream-view-tab--underline'></div></NavLink>
          ))}
        </div>
        <Switch>
          {tabs.map(tab => (
            <Route key={tab.key} component={() => <tab.Component tagId={uuid} />} path={tab.path} exact={tab.exact} />
          ))}
          <Route path={`/workstreams/${uuid}/notes/:noteId`} component={() => <WorkStreamNoteEditView tagId={uuid} />}/>
        </Switch>
      </div>
    </div>
  );
};

export default WorkstreamsView;