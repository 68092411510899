import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { AddIcon, Button, ChevronDownIcon, Popover, SearchInput, Spinner, TextInputField } from 'evergreen-ui';
import { ChangeEvent, FormEvent, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { faGlobe, faHandSpock, faLock, faThumbtack } from '@fortawesome/free-solid-svg-icons';

import EntityTabs from 'components/EntityTabs';
import { NotificationType } from 'store/notifications/selectors';
import { fetchDocumentTimeline } from 'api/documents';
import { getConnectorProvidersForActivity } from 'store/connectors/selectors';
import { getCurrentUser } from 'store/user/selector';
import { getIconForMimetype } from 'components/icons/files';
import { getPinForDocument } from 'store/calendar/selectors';
import { getProvidersIcon } from 'components/Connector/providers';
import { notify } from 'store/notifications/slice';
import { receiveLinks } from 'store/links/slice';
import { searchDocumentsV2 } from 'api/search';
import { IDocument, getDocumentsNeverLoaded, getDocumentsOrderedByCreatedAt } from 'store/documents/selectors';
import { ITagEntry, getTagRelationId, getTagTimelineEntryForDocument } from 'store/tags/selectors';
import { PinSpaces, TagTimelineView } from 'constants/app';
import { TrackEventNames, tracker } from 'utils/tracking';
import { createAndPinLinkToEvent, deletePin as deletePinApi, pinDocumentToEvent, postTogglePinSpace } from 'api/calendar';
import { deletePin, pinDocument, pinLink, unpinDocument } from 'store/calendar/slice';
import { deleteTagRelation, postCreateAndPinLinkToTag, postPinDocumentToTag } from 'api/tags';
import { receiveDocuments, setOldestTimelineFetch } from 'store/documents/slice';
import { receiveTagTimelineItems, removeTagTimelineItem } from 'store/tags/slice';
import { useAppDispatch, useAppSelector, useDocument } from 'hooks';

import './style.css';


const PinSpaceToButtonLabel = {
  [PinSpaces.PUBLIC]: <div><FontAwesomeIcon icon={faGlobe}/> Public</div>,
  [PinSpaces.PRIVATE]: <div><FontAwesomeIcon icon={faLock}/> Private</div>,
};


type Pin = ITagEntry | {
  document: string;
  pin_id: string;
  pinned_by: string;
  pinned_at: string;
  pinSpace: PinSpaces;
};


const getPinSpace = (pin?: Pin) => {
  if (!pin) return undefined;

  if ('pinSpace' in pin) {
    return pin.pinSpace;
  } else {
    return pin.tag_relation.namespace?.startsWith('tea:') ? PinSpaces.PUBLIC : PinSpaces.PRIVATE;
  }
};

const getOnPinAPICall = (space: PinSpaces, documentId: string, currentPinSpace?: PinSpaces, pin?: Pin, eventId?: string, tagId?: string) => {
  if (eventId && (!pin || 'pin_id' in pin)) {
    if (pin && currentPinSpace !== space) {
      return () => postTogglePinSpace(pin.pin_id);
    } else {
      return () => pinDocumentToEvent(eventId, documentId, space === PinSpaces.PUBLIC);
    }
  } else if (tagId) {
    return () => postPinDocumentToTag(tagId, documentId);
  }
};

const getOnUnpinAPICall = (pin: Pin) => {
  if ('pin_id' in pin) {
    return () => deletePinApi(pin.pin_id);
  } else {
    return () => deleteTagRelation(getTagRelationId(pin.tag_relation));
  }
};

const getOnCreateAndPinLinkAPICall = (url: string, name: string, isPublic: boolean, eventId?: string, tagId?: string) => {
  const urlWithProtocol = url.startsWith('http') ? url : `https://${url}`;
  if (eventId) {
    return () => createAndPinLinkToEvent(eventId, urlWithProtocol, name, isPublic);
  } else if (tagId) {
    return () => postCreateAndPinLinkToTag(tagId, urlWithProtocol, name);
  } else {
    return undefined;
  }
};

const getPinnedBy = (pin?: Pin) => {
  if (!pin) return undefined;

  if ('pinned_by' in pin) {
    return pin.pinned_by;
  } else {
    return pin.tag_relation.data?.tagged_by;
  }
};


const DocumentRow = ({
  documentId,
  defaultSpace,
  hideIfPinned=false,
  eventId,
  tagId,
}: { documentId: string, defaultSpace: PinSpaces, hideIfPinned?: boolean, eventId?: string, tagId?: string }) => {
  const { document, loading, error } = useDocument(documentId);
  const connectorProviders = getConnectorProvidersForActivity(document?.latest_activity || []);
  const getPinFunc = eventId ? getPinForDocument : getTagTimelineEntryForDocument;
  const docPin = useAppSelector(s => getPinFunc(s, eventId || tagId || '', documentId));
  const currentUserId = useAppSelector(getCurrentUser)?.id;
  const isDocPinned = !!docPin;
  const dispatch = useAppDispatch();
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);

  const pinnedBy = getPinnedBy(docPin);
  // can only change pin space for calendar event pins for now
  // so first check that eventId is passed
  const canChangeSpace = eventId && (!docPin || (pinnedBy === currentUserId));

  const pinSpace = getPinSpace(docPin);

  if (error || !document) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const onPin = async (e: MouseEvent<HTMLDivElement>, space: PinSpaces=defaultSpace) => {
    e.stopPropagation();

    const notAllowed = pinLoading
      || (publicDisabled && space === PinSpaces.PUBLIC)
      || (publicSelected && space === PinSpaces.PUBLIC)
      || (privateSelected && space === PinSpaces.PRIVATE)
      || (privateDisabled && space === PinSpaces.PRIVATE);

    if (notAllowed) return;

    const apiCall = getOnPinAPICall(space, documentId, pinSpace, docPin, eventId, tagId);
    if (!apiCall) return;

    setPinLoading(true);
    try {
      const { data } = await apiCall();
      tracker.track(TrackEventNames.PSRD, { documentId, eventId, tagId });
      if ('document' in data) {
        dispatch(receiveDocuments([data.document]));
      }
      if (tagId && 'tag_relation' in data) {
        dispatch(receiveTagTimelineItems({
          timelineItems: [data],
          tagId,
          view: TagTimelineView.PINS,
        }));
      }
      if (eventId) {
        if (docPin && 'pin_id' in docPin && pinSpace !== space) {
          dispatch(deletePin({
            referenceId: eventId,
            pinId: docPin.pin_id,
          }));
        }
        dispatch(pinDocument({
          referenceId: eventId,
          docId: documentId,
          pinId: data.pin_id,
          isPublicPin: data.is_shared_namespace,
          pinnedBy: data.pinned_by,
        }));
      }
      setPinLoading(false);
      notify({
        message: `Pinned file to ${eventId ? 'meeting' : 'workstream'}`,
        type: NotificationType.SUCCESS,
      }, 1300)(dispatch);
    } catch (err) {
      console.warn(err);
      setPinLoading(false);
      notify({
        type: NotificationType.WARNING,
        message: 'Failed to pin file, please try again'
      })(dispatch);
    }
  };

  const onUnpin = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (pinLoading || !docPin) return;

    const apiCall = getOnUnpinAPICall(docPin);

    if (!apiCall) return;

    setPinLoading(true);
    try {
      await apiCall();
      tracker.track(TrackEventNames.UPF, { documentId, eventId, tagId });
      if (eventId) {
        dispatch(unpinDocument({ referenceId: eventId, documentId }));
      } else if ('tag_relation' in docPin && tagId) {
        dispatch(removeTagTimelineItem({ tagId, tagRelationId: getTagRelationId(docPin.tag_relation), view: TagTimelineView.PINS }));
      }
      setPinLoading(false);
    } catch (err) {
      setPinLoading(false);
      notify({
        type: NotificationType.WARNING,
        message: 'Failed to unpin file, please try again'
      })(dispatch);
    }
  };

  const Icon = getProvidersIcon(connectorProviders);

  const publicDisabled = !!docPin && pinSpace === PinSpaces.PUBLIC;
  const privateDisabled = !!docPin && pinSpace === PinSpaces.PUBLIC && pinnedBy !== currentUserId;
  const publicSelected = pinSpace === PinSpaces.PUBLIC;
  const privateSelected = pinSpace === PinSpaces.PRIVATE;

  const onPinClick = isDocPinned ? onUnpin : onPin;

  if (isDocPinned && hideIfPinned) return null;

  return (
    <div className={cx('pin-file-search-results--doc', { pinned: isDocPinned, hover: popoverIsOpen })} onClick={!canChangeSpace ? onPinClick : undefined}>
      {isDocPinned && <div className="pin-file-search-results-doc--already-pinned">Already pinned to the event</div>}
      <div className="pin-file-search-results--mask">
        <div className="pin-file-search-results--mask-bg" />
        <div className="pin-file-search-result-action--container">
          {canChangeSpace && <Popover minWidth={100} onOpen={() => setPopoverIsOpen(true)} onClose={() => setPopoverIsOpen(false)} content={() => (
            <div>
              <div
                onClick={(e: MouseEvent<HTMLDivElement>) => onPin(e, PinSpaces.PRIVATE)}
                className={cx('document-card-actions-pin-space--button', {disabled: privateDisabled, selected: privateSelected})}
              >{PinSpaceToButtonLabel[PinSpaces.PRIVATE]}</div>
              <div
                onClick={(e: MouseEvent<HTMLDivElement>) => onPin(e, PinSpaces.PUBLIC)}
                className={cx('document-card-actions-pin-space--button', {disabled: publicDisabled, selected: publicSelected})}
              >{PinSpaceToButtonLabel[PinSpaces.PUBLIC]}</div>
            </div>
          )}>
            <Button className="document-card-pin-space--button" isLoading={pinLoading}>{PinSpaceToButtonLabel[defaultSpace]}<ChevronDownIcon marginLeft={4} /></Button>
          </Popover>}
          <div className={cx('document-card--pin', { 'with-space': canChangeSpace, unpin: !!isDocPinned, pinning: pinLoading })} onClick={onPinClick}><FontAwesomeIcon icon={faThumbtack} size="sm" /></div>
        </div>
      </div>
      <div className="document-row-icons--container">
        {getIconForMimetype(document.mimetype)({ width: 32, height: 32, size: '2x' })}
        <Icon className="document-row--icon" />
      </div>
      <div className="pin-file-search-result--header">
        <div className='pin-file-search-results--filename'>{document.filename}</div>
        <div className='pin-file-search-results--date'>{new Date(document.created_at).toLocaleString('default', { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })}</div>
      </div>
      <div className={cx('pin-file-action--button', { unpin: isDocPinned, pin: !isDocPinned })}>
        <FontAwesomeIcon icon={faThumbtack} />
      </div>
    </div>
  );
};


const CreatePinLink = ({ onCancel, pinSpace, eventId, tagId }: { onCancel: () => void, pinSpace: PinSpaces, eventId?: string, tagId?: string }) => {
  const [nameInput, setNameInput] = useState('');
  const [urlInput, setUrlInput] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onCancelClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onCancel();
  };

  const submit = async (e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement> | KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!eventId && !tagId) return;

    const apiCall = getOnCreateAndPinLinkAPICall(urlInput, nameInput, pinSpace === PinSpaces.PUBLIC, eventId, tagId);

    if (!apiCall) return;

    setLoading(true);
    try {
      const { data } = await apiCall();
      tracker.track(TrackEventNames.PSRL, { linkId: data.link.id, eventId, tagId });
      dispatch(receiveLinks([data.link]));
      if (eventId) {
        dispatch(pinLink({
          referenceId: eventId,
          linkId: data.link.id,
          pinId: data.pin_id,
          isPublicPin: data.is_shared_namespace,
          pinnedBy: data.pinned_by,
        }));
      } else if (tagId) {
        dispatch(receiveTagTimelineItems({ timelineItems: [data], tagId, view: TagTimelineView.ACTIVITY }));
        dispatch(receiveTagTimelineItems({ timelineItems: [data], tagId, view: TagTimelineView.PINS }));
      }
      setNameInput('');
      setUrlInput('');
      notify({
        message: `Pinned link to ${eventId ? 'meeting' : 'workstream'}`,
        type: NotificationType.SUCCESS,
      }, 1300)(dispatch);
    } catch (err) {
      const is422 = axios.isAxiosError(err) ? (err as AxiosError).response?.status === 422 : false;
      if (is422) {
        notify({
          message: 'Invalid URL',
          type: NotificationType.WARNING,
        })(dispatch);
      } else {
        notify({
          message: 'Failed to save, please try again.',
          type: NotificationType.WARNING,
        })(dispatch);
      }
    } finally {
      setLoading(false);
    }
  };

  const maybeSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submit(e);
    }
  };

  const linkSpace = pinSpace === PinSpaces.PUBLIC ? 'Public' : 'Private';

  return (
    <div className="calendar-pin-link--container">
      <h3 className="calendar-pin-link--title">Pin {linkSpace} Link</h3>
      <form onSubmit={submit}>
        <TextInputField marginBottom="16px" autoFocus value={nameInput} label='Title' onChange={(e: ChangeEvent<HTMLInputElement>) => setNameInput(e.target.value)}  onKeyDown={maybeSubmit}/>
        <TextInputField marginBottom="16px" placeholder="https://www.google.com/..." value={urlInput} label='Link' onChange={(e: ChangeEvent<HTMLInputElement>) => setUrlInput(e.target.value)} onKeyDown={maybeSubmit}/>
        <div className="calendar-pin-link-buttons--container">
          <Button onClick={onCancelClick}>Cancel</Button>
          <Button iconBefore={() => <FontAwesomeIcon icon={faThumbtack} color="white" />} appearance='primary' onClick={submit} type="submit" isLoading={loading} disabled={loading || urlInput.length === 0}>Pin {linkSpace && `${linkSpace}ly`}</Button>
        </div>
      </form>
    </div>
  );
};


const SelectPinFile = ({defaultSpace, tagId, eventId}: {defaultSpace: PinSpaces, tagId?: string, eventId?: string}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<IDocument[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState<null | ReturnType<typeof setTimeout>>(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [loadingRecent, setLoadingRecent] = useState(false);
  const recentDocs = useAppSelector(getDocumentsOrderedByCreatedAt);
  const documentsNeverLoaded = useAppSelector(getDocumentsNeverLoaded);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!searchQuery) {
      setHasSearched(false);
    }
  }, [searchQuery]);

  // recent files
  useEffect(() => {
    const shouldFetch = documentsNeverLoaded && !loadingRecent;

    const loadDocuments = async (before?: string) => {
      try {
        const { data } = await fetchDocumentTimeline(before);
        dispatch(receiveDocuments(data));
        if (data.length) {
          dispatch(setOldestTimelineFetch(new Date(data[data.length - 1].created_at)));
        }
      } catch (err) {
        console.warn(err);
        // dispatch an empty receive to set "never loaded" to false
        dispatch(receiveDocuments([]));
      }
    };

    if (shouldFetch) {
      setLoadingRecent(true);
      loadDocuments().finally(() => setLoadingRecent(false));
    }
  }, [documentsNeverLoaded, loadingRecent]);

  // search
  useEffect(() => {
    const doSearch = async () => {
      if (!searchQuery) return;

      setSearchLoading(true);
      try {
        const { data } = await searchDocumentsV2(searchQuery, []);
        const docs = data.results.map(r => r.document);
        dispatch(receiveDocuments(docs));
        setSearchResults(data.results.map(r => r.document));
      } catch (err) {
        console.warn(err);
        notify({
          type: NotificationType.WARNING,
          message: 'Search failed, please try again',
        });
      }
      setSearchLoading(false);
      setHasSearched(true);
      if (searchTimeout) {
        clearTimeout(searchTimeout);
        setSearchTimeout(null);
      }
    };

    if (searchTimeout) {
      clearTimeout(searchTimeout);
      setSearchTimeout(null);
    }

    setSearchTimeout(setTimeout(doSearch, 500));
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
        setSearchTimeout(null);
      }
    };
  }, [searchQuery]);

  return (<div className="calendar-pin-select-file--container">
    <div className="calendar-pin-select-file-search--container">
      <SearchInput
        placeholder='Search files...'
        value={searchQuery}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
        autoFocus
        className="calendar-pin-select-file-search--input"
      />
    </div>
    {!hasSearched &&
      <div>
        {!searchLoading && recentDocs.length !== 0 && <div className='pin-file-search-results--header'>Recent files:</div>}
        {(searchLoading || loadingRecent) && <div className="pin-file-search-results--loading"><Spinner color="var(--color-purple-5)" /></div>}
        {!searchLoading && recentDocs.length === 0 &&
          <div className="pin-file-search-results--empty">
            <FontAwesomeIcon icon={faGlobe} size="2x" color="var(--color-neutral-9)" />
            <div>Search Files</div>
          </div>
        }
        {!searchLoading && recentDocs.length !== 0 && <div className="pin-file-search-results--container">{recentDocs.slice(0, 7).map(d => <DocumentRow key={d.id} eventId={eventId} tagId={tagId} defaultSpace={defaultSpace} hideIfPinned documentId={d.id} />)}</div>}
      </div>
    }
    {hasSearched &&
      <div>
        {searchLoading && <div className="pin-file-search-results--loading"><Spinner color="var(--color-purple-5)" /></div>}
        {!searchLoading &&
          <>
            {searchResults.length !== 0 && <div className='pin-file-search-results--header'>{searchResults.length} result{searchResults.length === 1 ? '' : 's'}</div>}
            {searchResults.length === 0 &&
              <div className="pin-file-search-results--empty">
                <FontAwesomeIcon icon={faHandSpock} size="2x" color="var(--color-neutral-9)" />
                <div>No Results</div>
              </div>
            }
            {searchResults.length !== 0 && <div className="pin-file-search-results--container">{searchResults.map(d => <DocumentRow key={d.id} defaultSpace={defaultSpace} eventId={eventId} tagId={tagId} documentId={d.id} />)}</div>}
          </>
        }
      </div>
    }
  </div>);
};


const PinPopover = ({
  defaultSpace,
  buttonClassName='',
  eventId,
  tagId,
}: {defaultSpace: PinSpaces, buttonClassName?: string, eventId?: string, tagId?: string}) => {

  return (
    <div onClick={(e: MouseEvent) => e.stopPropagation()}>
      <Popover bringFocusInside content={({ close }) =>
        <>
          <EntityTabs
            tabs={[
              { title: 'File', content: <SelectPinFile defaultSpace={defaultSpace} eventId={eventId} tagId={tagId} /> },
              { title: 'Link', content: <CreatePinLink pinSpace={defaultSpace} onCancel={close} eventId={eventId} tagId={tagId} /> },
            ]}
          />
        </>
      }>
        <Button iconBefore={AddIcon} className={buttonClassName} appearance="minimal">Pin content</Button>
      </Popover>
    </div>
  );
};


export default PinPopover;