import {sort} from 'ramda';

import { RootState } from 'store';



export enum RuleTriggers {
  RECEIVE_DOCUMENT = 'RECEIVE_DOCUMENT',
  RECEIVE_MEETING = 'RECEIVE_MEETING',
  RECEIVE_SLACK_MSG = 'RECEIVE_SLACK_MSG',
}


export enum RuleActions {
  STAR_DOCUMENT = 'STAR_DOCUMENT',
  ADD_TAG = 'ADD_TAG',
  NOTIFY_EMAIL = 'NOTIFY_EMAIL',
}


export enum RuleConditionField {
  HANDLE = 'HANDLE',
  NAME = 'NAME',
  MIMETYPE = 'MIMETYPE',
  CONNECTOR_PROVIDER = 'CONNECTOR_PROVIDER',
}


export enum RuleConditionOperators {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  ENDS_WITH = 'ENDS_WITH',
  NOT_ENDS_WITH = 'NOT_ENDS_WITH',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  EXISTS = 'EXISTS',
  NOT_EXISTS = 'NOT_EXISTS',
}


export interface RuleCondition {
  field: RuleConditionField,
  field_specifier?: string | null | undefined,
  operator: RuleConditionOperators,
  value: Array<string | number | boolean>,
}


export interface RuleConditionSet {
  conditions: Array<RuleCondition | RuleConditionSet>,
  evaluation: 'ANY' | 'ALL'
}


export interface IRule {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  place: number;
  name: string;
  triggers: RuleTriggers[],
  actions: {
    actions: {action: RuleActions, action_specifier?: null | undefined | string}[],
    stop_after_eval: boolean,
  },
  conditions: RuleConditionSet,
}


const root = (state: RootState) => state.rules;
export const rulesNeverLoaded = (state: RootState) => root(state).rulesById === null;
const getRulesList = (state: RootState) => Object.values(root(state).rulesById || {});
export const getRulesInOrder = (state: RootState) => {
  const rules = getRulesList(state);

  const nonDeletedRules = rules.filter(rule => rule.deleted_at === null);

  return sort(
    (rule1, rule2) => rule1.place - rule2.place,
    nonDeletedRules,
  );
};

const rulesById = (state: RootState) => root(state).rulesById;
export const getRuleById = (state: RootState, ruleId: string) => (rulesById(state) || {})[ruleId];