import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOtter } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { AddIcon, Button } from 'evergreen-ui';

import ActionItem from 'components/ActionItem';
import { IActionItem } from 'store/action_items/selectors';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import { TagTimelineView } from 'constants/app';
import { createActionItem } from 'api/action_items';
import { notify } from 'store/notifications/slice';
import { postPinActionItemToTag } from 'api/tags';
import { receiveActionItems } from 'store/action_items/slice';
import { receiveTagTimelineItems } from 'store/tags/slice';
import { ITagRelation, getAllParentTagActionItems, getTimelineForTagLoading } from 'store/tags/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';

import { LoadingIndicator } from './activity';
import './style.css';


const createAndPin = (title: string, description: string, data: { tagId: string, parentActionItemId: string | null }): Promise<{action_item: IActionItem, tag_relation: ITagRelation}> => {
  return createActionItem({ title, description, data, status: 'NOT_DONE' }).then(({ data: newActionItem }) => postPinActionItemToTag(data.tagId, newActionItem.id).then(({ data }) => data));
};


const WorkstreamTasksView = ({ tagId }: { tagId: string }) => {
  const dispatch = useAppDispatch();
  const actionItems = useAppSelector(s => getAllParentTagActionItems(s, tagId));
  const loading = useAppSelector(s => getTimelineForTagLoading(s, tagId, TagTimelineView.ACTION_ITEMS));
  const [loadingNewActionItem, setLoadingNewActionItem] = useState(false);

  const createNewActionItem = async (parentActionItemId: string | null=null) => {
    if (loadingNewActionItem) return;
    setLoadingNewActionItem(true);

    try {
      const tagPin = await createAndPin('', '', { tagId, parentActionItemId });
      dispatch(receiveActionItems([tagPin.action_item]));
      dispatch(receiveTagTimelineItems({
        timelineItems: [tagPin],
        tagId,
        view: TagTimelineView.ACTION_ITEMS,
      }));

      setLoadingNewActionItem(false);
    } catch (err) {
      setLoadingNewActionItem(false);
      notify({
        message: 'Could not create new task, please try again.',
        type: NotificationType.WARNING,
      })(dispatch);
    }
  };

  const showEmpty = actionItems?.length === 0 && !loading;

  return (
    <div className='workstream-notes--container'>
      {showEmpty && <div className='calendar-event-details--empty'>
        <FontAwesomeIcon icon={faOtter} size="2x"/>
        <p>No tasks yet</p>
        <Button width={80} iconBefore={AddIcon} appearance="minimal" onClick={() => createNewActionItem(null)} isLoading={loadingNewActionItem} disabled={loadingNewActionItem}>Task</Button>
      </div>}
      {!showEmpty && !loading && <Button width={80} iconBefore={AddIcon} appearance="minimal" onClick={() => createNewActionItem(null)} isLoading={loadingNewActionItem} disabled={loadingNewActionItem}>Task</Button>}
      {loading && <div className="calendar-event-details-notes--loading">Loading tasks<LoadingDots /></div>}
      {actionItems?.map((actionItem, idx) => <ActionItem actionItemId={actionItem.id} prevActionItem={actionItems[idx-1]?.id} key={actionItem.id} onEnterClick={createNewActionItem} />)}
      <LoadingIndicator tagId={tagId} view={TagTimelineView.ACTION_ITEMS} showNoMore={false} />
    </div>
  );
};

export default WorkstreamTasksView;