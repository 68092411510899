import { AppDispatch } from 'store';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchTeam } from 'api/teams';

import { ITeam } from './selectors';
import { receiveUsers } from 'store/user/slice';


export interface ITeamUpdatedWSPayload {
  team_id: string;
}

export interface TeamState {
  teamsById: {[teamId: string]: ITeam}
}

const initialState: TeamState = {
  teamsById: {},
};


export const wsFetchTeam = createAsyncThunk<ITeam, ITeamUpdatedWSPayload, {dispatch: AppDispatch}>(
  'teams/wsFetchTeam',
  async ({team_id: teamId}, {dispatch}) => {
    const {data} = await fetchTeam(teamId);

    dispatch(receiveUsers(data.users.map(({ user }) => user)));

    return {
      ...data,
      users: data.users.map(userData => ({...userData, user: userData.user.id})),
    };
  }
);


export const teamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    receiveTeams: (state, action: PayloadAction<ITeam[]>) => {
      action.payload.forEach(team => {
        state.teamsById[team.id] = team;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(wsFetchTeam.fulfilled, (state, action) => {
      state.teamsById[action.payload.id] = action.payload;
    });
  }
});

// Action creators are generated for each case reducer function
export const { receiveTeams } = teamSlice.actions;
export default teamSlice.reducer;
