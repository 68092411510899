import Select from 'react-select';
import { IconButton, TagInput, TrashIcon } from 'evergreen-ui';
import { difference, isEmpty } from 'ramda';
import { useEffect, useState } from 'react';

import { usePrevious } from 'hooks';

export interface ICondition {
  field: string;
  operator: string;
  value: string[];
}


export const DefaultConditionFieldOptions: {value: string, label: string, gate_required?: string}[] = [
  {label: 'File type', value: 'MIMETYPE'},
  {label: 'Name', value: 'NAME'},
  {label: 'Organinzer email', value: 'ORGANIZER_EMAIL', gate_required: 'has_organizer_email_rule_condition'},
];


const ConditionOperatorOptions = [
  {label: 'Contains', value: 'CONTAINS'},
  {label: 'Does not contain', value: 'NOT_CONTAINS'},
  {label: 'Starts with', value: 'STARTS_WITH'},
  {label: 'Does not start with', value: 'NOT_STARTS_WITH'},
  {label: 'Is', value: 'EQUALS'},
  {label: 'Is not', value: 'NOT_EQUALS'},
  {label: 'Ends with', value: 'ENDS_WITH'},
  {label: 'Does not end with', value: 'NOT_ENDS_WITH'},
];


const Condition = ({
  condition,
  place,
  onDeleteCondition,
  conjunction,
  onChange,
  fieldOptions=DefaultConditionFieldOptions,
}: {condition: ICondition, place: number, onDeleteCondition: () => void, conjunction: string, onChange: (condition: ICondition) => void, fieldOptions?: {label: string, value: string}[]}) => {
  const [values, setValues] = useState(condition.value);
  const [field, setField] = useState<{label: string, value: string, gate_required?: string} | null | undefined>(DefaultConditionFieldOptions.find(o => o.value === condition.field));
  const [operator, setOperator] = useState<{label: string, value: string} | null | undefined>(ConditionOperatorOptions.find(o => o.value === condition.operator));
  const previousValues = usePrevious(values);
  const previousField = usePrevious(field);
  const previousOperator = usePrevious(operator);

  useEffect(() => {
    const changed = operator !== previousOperator || field !== previousField || (!previousValues && values) || (previousValues && !isEmpty(difference(values, previousValues)));
    if (field && operator && changed) {
      onChange({value: values, field: field.value, operator: operator.value});
    }
  }, [values, field, operator, onChange, previousField, previousOperator, previousValues]);

  return <div>
    {place !== 0 && <div className="condition-field--conjunction">{conjunction}</div>}
    <div className="condition-fields--container">
      <Select className="condition-fields--field" styles={{ menuPortal: (base) => ({ ...base, fontSize: '12px', zIndex: 9999 }) }} menuPortalTarget={document.body} options={fieldOptions} value={field} onChange={setField} />
      <Select className="condition-fields--field" styles={{ menuPortal: (base) => ({ ...base, fontSize: '12px', zIndex: 9999 }) }} menuPortalTarget={document.body} options={ConditionOperatorOptions} value={operator} onChange={setOperator} />
      <TagInput className="condition-fields--field" values={values} onChange={setValues}/>
      <IconButton icon={TrashIcon} intent="danger" type="button" onClick={onDeleteCondition} />
    </div>
  </div>;
};

export default Condition;