import cx from 'classnames';
import { useState } from 'react';

import { IDocument } from 'store/documents/selectors';

import ActivityContext from './activity';
import './style.css';


const ContextOptions = {
  ACTIVITY: 'ACTIVITY',
};


export const DocumentContext = ({
  document
}: {document: IDocument}) => {
  const [selectedContext, setSelectedContext] = useState(ContextOptions.ACTIVITY);

  const shouldHideCtx = document.latest_activity.length === 0;

  if (shouldHideCtx) {
    return null;
  }

  return (
    <div className="document-contexts--container">
      <div className="document-context-options--container">
        <div className="document-context--title">Related context</div>
        <div className="document-context--options">
          <div
            className={cx('document-context--option', {active: selectedContext === ContextOptions.ACTIVITY})}
            onClick={() => setSelectedContext(ContextOptions.ACTIVITY)}
          >Activity</div>
        </div>
      </div>
      {selectedContext === ContextOptions.ACTIVITY && <ActivityContext document={document} />}
    </div>
  );
};

