import axios from 'axios';

import { API_URL } from 'constants/resources';
import { getToken } from 'utils/auth';


export const API = axios.create({
  baseURL: API_URL,
  timeout: 30000, // 30 second timeout
  headers: {
    'X-Classify-Version': process.env.REACT_APP_VERSION || 'no-version',
    'Authorization': `Bearer ${getToken()}`
  }
});