import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { faChevronDown, faGlobe, faLock, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import DocumentCard from 'components/DocumentCard';
import LinkCard from 'components/LinkCard';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import PinPopover from 'components/PinPopover';
import { deleteTagRelation } from 'api/tags';
import { markWorkstreamPinsAsRead } from 'store/read_receipts/slice';
import { notify } from 'store/notifications/slice';
import { store } from 'store';
import { PinSpaces, RelationTypes, TagRelationEntityTypes, TagTimelineView } from 'constants/app';
import { fetchNextTagTimeline, removeTagRelation } from 'store/tags/slice';
import { getCurrentUser, getUserById } from 'store/user/selector';
import { getEarlistTimelineEpochForTag, getTagById, getTagRelationId, getTimelineForTagHasNoMoreItems, getTimelineForTagItems, getTimelineForTagLoading } from 'store/tags/selectors';
import { getIsWorkstreamPinsUnread, getLastWorkstreamPinsRead } from 'store/read_receipts/selectors';
import { useAppDispatch, useAppSelector, useCalendarEvent } from 'hooks';

import './style.css';


export const Pin = ({
  tagRelationId,
  eventId,
  pinnedBy,
  pinnedAt,
  pinnedRelation,
  link,
  document,
  pinSpace,
  tagId,
  className='',
}: {
  eventId?: string,
  className?: string,
  tagRelationId: string,
  pinnedBy: string,
  pinnedAt: string,
  pinnedRelation: RelationTypes,
  pinSpace: PinSpaces,
  tagId: string,
  link?: string,
  document?: string,
}) => {

  const pinner = useAppSelector(s => getUserById(s, pinnedBy));
  const pinnerIsUser = useAppSelector(getCurrentUser)?.id === pinner?.id;
  const dispatch = useAppDispatch();
  const tag = useAppSelector(s => getTagById(s, tagId));
  const [isPinning, setIsPinning] = useState(false);
  const {calendarEvent} = useCalendarEvent(eventId || '');
  const lastPinsRead = useAppSelector(s => getLastWorkstreamPinsRead(s, tagId));

  let component = null;

  const unpin = async () => {
    setIsPinning(true);
    try {
      await deleteTagRelation(tagRelationId);
      dispatch(removeTagRelation({ tagId, tagRelationId, view: TagTimelineView.PINS }));
    } catch (err) {
      setIsPinning(false);
      notify({
        message: 'Failed to remove pin from workstream, please try again.',
        type: NotificationType.WARNING,
      })(dispatch);
    }
  };

  const onUnpin = pinnedRelation === RelationTypes.AUTOMATIC ? undefined : unpin;
  const pinnedInText = eventId ? (calendarEvent?.title || 'a meeting') : (tag.name || 'this workstream');
  const unread = !!lastPinsRead && new Date(pinnedAt).valueOf() > lastPinsRead;

  if (link) {
    component = <LinkCard unread={unread} pinnedBy={pinnedBy} pinLoading={isPinning} currentPinSpace={pinSpace} onUnpin={onUnpin} linkId={link} className="calendar-context-pinned--container" context={{ workstream: location.pathname }} />;
  } else if (document) {
    component = <DocumentCard unread={unread} pinnedBy={pinnedBy} pinLoading={isPinning} currentPinSpace={pinSpace} onUnpin={onUnpin} documentId={document} className="calendar-context-pinned--container" trackingCtx='Workstream pins'/>;
  }

  if (component === null) return null;

  return (
    <div className={cx('public-pin--container', className)}>
      {pinner && <div className="calendar-pin-context--pinner"><FontAwesomeIcon size="xs" icon={faThumbtack}/> by <strong>{pinnerIsUser ? 'You' : pinner.full_name}</strong> in <strong>{pinnedInText}</strong></div>}
      {component}
    </div>
  );
};



const WorkstreamPinsView = ({ tagId }: { tagId: string }) => {
  const earliestTimelinEpoch = useAppSelector(s => getEarlistTimelineEpochForTag(s, tagId, TagTimelineView.PINS));
  const loading = useAppSelector(s => getTimelineForTagLoading(s, tagId, TagTimelineView.PINS));
  const noMoreToLoad = useAppSelector(s => getTimelineForTagHasNoMoreItems(s, tagId, TagTimelineView.PINS));
  const items = useAppSelector(s => getTimelineForTagItems(s, tagId, TagTimelineView.PINS));
  const [isPrivatePinsOpen, setIsPrivatePinsOpen] = useState(true);
  const [isPublicPinsOpen, setIsPublicPinsOpen] = useState(true);
  const [shouldMarkAsRead, setShouldMarkAsRead] = useState(false);
  const pinsUnread = useAppSelector(s => getIsWorkstreamPinsUnread(s, tagId));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loading && !noMoreToLoad) {
      dispatch(fetchNextTagTimeline({ tagId, view: TagTimelineView.PINS, limit: 200 }));
    }

  }, [loading, dispatch, earliestTimelinEpoch, noMoreToLoad]);

  const showLoading = loading || !noMoreToLoad;

  useEffect(() => {
    const stillLoading = loading || !noMoreToLoad;
    if (!stillLoading && pinsUnread) {
      setShouldMarkAsRead(true);
    }
  }, [loading, noMoreToLoad, tagId, pinsUnread]);

  useEffect(() => {
    // mark on unmount so the markers don't go away on render
    return () => {
      if (shouldMarkAsRead) {
        markWorkstreamPinsAsRead({tagId})(dispatch, store.getState, {});
      }
    };
  }, [shouldMarkAsRead, tagId]);

  const publicPins = items.filter(item => item.tag_relation.namespace?.startsWith('tea:'));
  const privatePins = items.filter(item => !(item.tag_relation.namespace?.startsWith('tea:')));

  return (
    <div className="workstreams-tagged-activity-content-split--container">
      {showLoading && <div className="workstream-pins--loading">Loading pinned content<LoadingDots /></div>}
      {!showLoading &&
        <div className="workstream-pins-sections--container">
          <div className="workstream-pins-section--container">
            <div className="workstream-pins-section--header" onClick={() => setIsPublicPinsOpen(!isPublicPinsOpen)}>
              <div>
                <FontAwesomeIcon icon={faGlobe} className="workstream-pins-section-header--icon" />
                <label>Public pins ({publicPins.length})</label>
                <FontAwesomeIcon icon={faChevronDown} className={cx('calendar-context-section--collapsible-caret', {closed: !isPublicPinsOpen})} />
              </div>
              <PinPopover defaultSpace={PinSpaces.PRIVATE} tagId={tagId} buttonClassName="workstream-pins-section--pin-btn-hidden" />
            </div>
            {isPublicPinsOpen && <div className="workstream-pins-section-content--container">
              {publicPins.length === 0 && <em className="calendar-pins--empty">You have not pinned anything to this workstream yet</em>}
              {publicPins.map(item => <Pin
                key={getTagRelationId(item.tag_relation)}
                tagRelationId={getTagRelationId(item.tag_relation)}
                eventId={item.tag_relation.data?.calendar_event_id}
                pinnedBy={item.tag_relation.data?.tagged_by || item.tag_relation.updated_by}
                pinnedAt={item.tag_relation.updated_at || item.tag_relation.timestamp}
                pinnedRelation={item.tag_relation.relation}
                link={item.tag_relation.entity_type === TagRelationEntityTypes.LINK ? item.tag_relation.entity_id : undefined}
                document={item.tag_relation.entity_type === TagRelationEntityTypes.DOCUMENT ? item.tag_relation.entity_id : undefined}
                pinSpace={PinSpaces.PUBLIC}
                tagId={tagId}
              />)}
            </div>
            }
          </div>
          <div className="workstream-pins-section--container">
            <div className="workstream-pins-section--header" onClick={() => setIsPrivatePinsOpen(!isPrivatePinsOpen)}>
              <div>
                <FontAwesomeIcon icon={faLock} className="workstream-pins-section-header--icon" />
                <label>Private pins ({privatePins.length})</label>
                <FontAwesomeIcon icon={faChevronDown} className={cx('calendar-context-section--collapsible-caret', {closed: !isPrivatePinsOpen})} />
              </div>
              <PinPopover defaultSpace={PinSpaces.PRIVATE} tagId={tagId} />
            </div>
            {isPrivatePinsOpen && <div className="workstream-pins-section-content--container">
              {privatePins.length === 0 && <em className="calendar-pins--empty">You have not pinned anything to this workstream yet</em>}
              {privatePins.map(item => <Pin
                key={getTagRelationId(item.tag_relation)}
                tagRelationId={getTagRelationId(item.tag_relation)}
                eventId={item.tag_relation.data?.calendar_event_id}
                pinnedBy={item.tag_relation.data?.tagged_by || item.tag_relation.updated_by}
                pinnedAt={item.tag_relation.updated_at || item.tag_relation.timestamp}
                pinnedRelation={item.tag_relation.relation}
                link={item.tag_relation.entity_type === TagRelationEntityTypes.LINK ? item.tag_relation.entity_id : undefined}
                document={item.tag_relation.entity_type === TagRelationEntityTypes.DOCUMENT ? item.tag_relation.entity_id : undefined}
                pinSpace={PinSpaces.PRIVATE}
                tagId={tagId}
              />)}
            </div>
            }
          </div>
        </div>
      }
    </div>
  );
};

export default WorkstreamPinsView;