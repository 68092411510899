import { ComponentType, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { AppDispatch } from 'store';
import Button from 'components/Button';
import ConnectAnotherAccount from 'views/onboarding/signup/connectAnotherAccount';
import GoogleButton from 'components/GoogleButton';
import JiraIcon from 'components/icons/Jira';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import OutlookIcon from 'components/icons/OutlookIcon';
import SalesForceIcon from 'components/icons/SalesForce';
import Sidebar from 'components/Sidebar';
import SlackIcon from 'components/icons/Slack';
import { TrelloIcon } from 'components/icons/Trello';
import { getGoogleOAuthURI } from 'api/auth';
import { jiraScopes } from 'constants/app';
import { microsoftScopes } from 'constants/app';
import { notify } from 'store/notifications/slice';
import { APP_URL, DEBUG, JIRA_CLIENT_ID, MICROSOFT_CLIENT_ID, SALES_FORCE_CLIENT_ID, SLACK_CLIENT_ID, TRELLO_API_KEY } from 'constants/resources';
import { IConnector, receiveConnectors } from 'store/connectors/slice';
import { connectGmail, connectJira, connectMicrosoft, connectSalesForce, connectSlack, connectTrello, fetchAllConnectors } from 'api/connectors';
import { getCurrentUser, getUserFinishedOnboarding } from 'store/user/selector';
import { useAppDispatch, useAppSelector } from 'hooks';

import './style.css';

const openSlack = (user: {id: string} | null, dispatch: AppDispatch) => {
  if (!user) {
    notify({message: 'You need to sign in to try again.', type: NotificationType.WARNING})(dispatch);
    return;
  }

  const params = new URLSearchParams();
  params.append('client_id', SLACK_CLIENT_ID);
  params.append('scope', 'channels:history,channels:read,files:read,groups:history,im:history,im:read,links:read,mpim:history,users:read.email,users:read,mpim:read,groups:read,remote_files:read');
  params.append('state', btoa(JSON.stringify({userId: user.id})));
  params.append('redirect_uri', APP_URL + '/connect-success?provider=slack');
  window.open(`https://slack.com/oauth/authorize?${params.toString()}`);
};


const openJira = (user: {id: string} | null, dispatch: AppDispatch) => {
  if (!user) {
    notify({message: 'You need to sign in to try again.', type: NotificationType.WARNING})(dispatch);
    return;
  }
  
  const params = new URLSearchParams();
  params.append('audience', 'api.atlassian.com');
  params.append('client_id', JIRA_CLIENT_ID);
  params.append('scope', jiraScopes.join(' '));
  params.append('redirect_uri', APP_URL + '/connect-success?provider=jira');
  params.append('state', btoa(JSON.stringify({userId: user.id})));
  params.append('response_type', 'code');
  params.append('prompt', 'consent');

  window.open(`https://auth.atlassian.com/authorize?${params.toString()}`);
};

const openSalesForce = (user: {id: string} | null, dispatch: AppDispatch) => {
  if (!user) {
    notify({message: 'You need to sign in to try again.', type: NotificationType.WARNING})(dispatch);
    return;
  }
  
  const params = new URLSearchParams();
  params.append('response_type', 'code');
  params.append('client_id', SALES_FORCE_CLIENT_ID);
  params.append('scope', 'full offline_access');
  params.append('redirect_uri', APP_URL + '/connect-success?provider=sales_force');

  window.open(`https://login.salesforce.com/services/oauth2/authorize?${params.toString()}`);
};

const openMicrosoft = (user: {id: string} | null, dispatch: AppDispatch) => {
  if (!user) {
    notify({message: 'You need to sign in to try again.', type: NotificationType.WARNING})(dispatch);
    return;
  }
  const params = new URLSearchParams();
  params.append('response_type', 'code');
  params.append('client_id', MICROSOFT_CLIENT_ID);
  params.append('scope', microsoftScopes);
  params.append('redirect_uri', APP_URL + '/connect-success/microsoft');
  params.append('state', btoa(JSON.stringify({userId: user.id})));
  window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${params.toString()}`);
};

const openTrello = (user: {id: string} | null, dispatch: AppDispatch) => {
  if (!user) {
    notify({message: 'You need to sign in to try again.', type: NotificationType.WARNING})(dispatch);
    return;
  }
  
  const params = new URLSearchParams();
  params.append('expiration', 'never');
  params.append('name', 'Classify');
  params.append('scope', 'read');
  params.append('response_type', 'token');
  params.append('key', TRELLO_API_KEY);
  params.append('return_url ',  APP_URL + '/connect-success?provider=trello');

  window.open(`https://trello.com/1/authorize?${params.toString()}`);
};

const openGoogle = async (user: {id: string} | null, dispatch: AppDispatch) => {
  if (!user) {
    notify({message: 'You need to sign in to try again.', type: NotificationType.WARNING})(dispatch);
    return;
  }

  try {
    const {data} = await getGoogleOAuthURI();
    window.location.href = data.authorization_url;
  } catch (err) {
    console.warn('Could not get google auth URI', err);
  }
};

interface IProviderConfig {
  name: string,
  icon: ComponentType<{className?: string, onClick?: () => void}> | typeof GoogleButton,
  connectFn: (data: {code: string | null, user_id?: string, redirect_uri: string}) => Promise<AxiosResponse<IConnector | IConnector[]>>,
  tryAgainFn: (user: {id: string} | null, dispatch: AppDispatch) => void,
  showOnlyIcon?: boolean,
}


const providerConfigs: Record<string, IProviderConfig> = {
  slack: {
    name: 'Slack',
    icon: SlackIcon,
    connectFn: connectSlack,
    tryAgainFn: openSlack,
  },
  gmail: {
    name: 'Google',
    icon: GoogleButton,
    connectFn: connectGmail,
    tryAgainFn: openGoogle,
    showOnlyIcon: true,
  },
  jira: {
    name: 'Jira',
    icon: JiraIcon,
    connectFn: connectJira,
    tryAgainFn: openJira
  },
  sales_force: {
    name: 'Sales Force',
    icon: SalesForceIcon,
    connectFn: connectSalesForce,
    tryAgainFn: openSalesForce
  },
  microsoft: {
    name: 'Microsoft',
    icon: OutlookIcon,
    connectFn: connectMicrosoft,
    tryAgainFn: openMicrosoft
  },
  trello: {
    name: 'Trello',
    icon: ({className=''}: {className?: string}) => <TrelloIcon height={18} className={className} />,
    connectFn: connectTrello,
    tryAgainFn: openTrello
  }
};

const stateFromParams = (params: URLSearchParams): {userId?: string} => {
  try {
    return JSON.parse(atob(params.get('state') || '{}'));
  } catch (err) {
    return {};
  }
};


const VerifyConnector = ({ finishedOnboarding}: {finishedOnboarding: boolean }) => {
  const location = useLocation();
  const [requesting, setRequesting] = useState(false);
  const [triedOnce, setTriedOnce] = useState(false);
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('correct');
  const dispatch = useAppDispatch();
  let provider = new URLSearchParams(location.search).get('provider');
  const user = useAppSelector(getCurrentUser);
  if (!provider) {
    // For cases when we unable to add "?provider=conn_type" to redirect_uri. Instead we have ".../connect-success/microsoft"  
    const potentialProvider = location.pathname.split('/').slice(-1)[0];
    provider = (potentialProvider in providerConfigs) ? potentialProvider : provider;
  }

  if (!provider) {
    console.warn('[Classify.app.VerifyConnector] "provider" query param not given. Defaulting to Slack.');
    provider = 'slack';
  } else {
    provider = provider.toLowerCase();
  }
  const providerConfig = providerConfigs[provider];

  const providerName = providerConfig.name;

  const isLocalhostDevMode = DEBUG && window.location.hostname === 'localhost';

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const hash = new URLSearchParams(location.hash.slice(1));
    const isQueryProvider = (params.get('provider')) ? true : false;

    const connect = async (payload: {code: string | null, user_id?: string, redirect_uri: string}) => {
      try {
        const {data} = await providerConfig.connectFn(payload);
        const notification = {
          message: `Successfully connected to ${providerName} 🎉`,
          type: NotificationType.SUCCESS,
        };
        notify(notification)(dispatch);
        setSuccess(true);
        dispatch(receiveConnectors(Array.isArray(data) ? data : [data]));
        const workspace = Array.isArray(data) ? data[0].meta.workspace : data.meta.workspace;
        if (workspace) {
          setWorkspaceName(workspace);
        }
        const nextRedirect = params.get('destination');
        if (nextRedirect) {
          if (nextRedirect.startsWith('/workstreams')) {
            window.setTimeout(() => {
              window.location.replace(nextRedirect);
            }, 1000);
          }
          else {
            window.setTimeout(() => {
              window.location.replace('/?viewDate=' + encodeURIComponent(nextRedirect));
            }, 1000);
          }
        }
      } catch (err) {
        const status = axios.isAxiosError(err) ? (err as AxiosError).response?.status || -1 : -1;
        const errDetail = axios.isAxiosError(err) ? (err as AxiosError).response?.data?.detail : null;

        // user did not grant us access to everything we need from gmail/google calendar
        const isWrongGmailScopesErr = errDetail === 'WRONG_GMAIL_SCOPES';
        const isWrongGdriveScopesErr = errDetail === 'WRONG_GDRIVE_SCOPES';
        const isWrongGcalScopesErr = errDetail === 'WRONG_GCAL_SCOPES';
        const isNoScopesGrantedErr = errDetail === 'NO_GOOGLE_SCOPES';
        const isPartialScopesError = errDetail === 'PARTIAL_GOOGLE_SCOPES';

        let message = `Failed to connect to ${providerName}.`;
        let type = NotificationType.ERROR;

        if (isPartialScopesError) {
          message = 'You only gave Classify access to some of your Google account permissions. Try reconnecting with all permissions to add everything.';
          // partial scopes means one or more new connectors
          // were created
          fetchAllConnectors()
            .then(({ data }) => dispatch(receiveConnectors(data)))
            .catch(console.warn);
        } else if (isWrongGcalScopesErr) {
          message = 'Classify needs to view your calendars and events to integrate with Google Calendar.';
        } else if (isNoScopesGrantedErr) {
          message = 'No permissions were given for Classify to integrate with Google.';
        } else if (isWrongGdriveScopesErr) {
          message = 'Classify needs file information and activity permission to integrate with Google Drive.';
        } else if (isWrongGmailScopesErr) {
          message = 'Classify needs access to view your email to integrate with Gmail.';
        } else if (status === 409) {
          message = `Another user has already connected that ${providerName} account.`;
        } else if (status >= 400 && status < 500) {
          message = `${providerName} failed to respond properly, please try again.`;
          type = NotificationType.WARNING;
        } else if (status >= 500) {
          message = 'Classify failed to connect. Our team has been notified.';
        }

        setFailed(true);
        const notification = {message, type, requireDismiss: true};
        notify(notification)(dispatch);
      }
    };

    if (!requesting && !triedOnce) {
      setTriedOnce(true);
      setRequesting(true);
      const state = stateFromParams(params);
      let redirectURI = `${APP_URL}/connect-success?provider=${params.get('provider')}`;
      if (!isQueryProvider){
        const provider = location.pathname.split('/').slice(-1)[0];
        redirectURI = `${APP_URL}/connect-success/${provider}`;
      }

      // Workaround for strict mode where the request is sent twice otherwise
      // See also: https://trello.com/c/WY1mnrwn/96-double-bottom-right-toast-messages-when-you-integrate-a-connector
      if (isLocalhostDevMode) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(window as any).__connected) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).__connected = true;
          const code = providerConfig.name === providerConfigs.trello.name ? hash.get('token') : params.get('code');
          connect({
            code,
            user_id: state.userId,
            redirect_uri: redirectURI
          }).then(() => setRequesting(false));
        }
      } else {
        connect({
          code: params.get('code'),
          user_id: state.userId,
          redirect_uri: redirectURI
        }).then(() => setRequesting(false));
      }
    }

  }, [requesting, providerConfig, providerName, location.search, location.pathname, dispatch, triedOnce]);

  const loading = requesting || !triedOnce;
  const Icon = providerConfig.icon;
  const tryAgain = () => providerConfig.tryAgainFn(user, dispatch);

  if (!finishedOnboarding) {
    return <ConnectAnotherAccount providerName={providerName} loading={loading} failed={failed}/>;
  }

  return (
    <div className='connect-success--container sidebar-sibling-content--container'>
      <h3 className='connect-success--headline'>Syncing your {providerName} account to Classify</h3>
      <div className='connect-success--description'>
        Once your account is verified, Classify will immediately begin organizing your files
        to give you the simplest way ever to find your files.
      </div>
      <div>
        {loading && <div className='connect-success--loading'>Verifying connection to {providerName}<LoadingDots /></div>}
        {failed && <div className='connect-success--failed'>Connection to {providerName} failed. Feel free to try again, or try <Link to='/settings/accounts'>another account</Link>.</div>}
        {failed && !providerConfig.showOnlyIcon && <Button className='connect-success--btn' buttonType='subtle' onClick={tryAgain}>
          <Icon className='connect-success--icon' />
          Try again
        </Button>}
        {failed && providerConfig.showOnlyIcon && <Icon onClick={tryAgain} />}
        {success && <div className='connect-success--success'>Hooray! Your {providerName} account { provider=== 'slack' && `in the ${workspaceName} workspace `}was connected successfully.</div>}
      </div>
    </div>
  );
};

const ConnectSuccessContainer = () => {
  const finishedOnboarding = useAppSelector(getUserFinishedOnboarding);

  return (
    <div>
      {finishedOnboarding && <Sidebar />}
      <VerifyConnector finishedOnboarding={finishedOnboarding}/>
    </div>
  );
};

export default ConnectSuccessContainer;