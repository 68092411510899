import { IActivity } from 'store/documents/selectors';
import { ITagEntry } from 'store/tags/selectors';
import { getAllAttendeesForEvent } from 'store/calendar/selectors';
import { getCurrentUser } from 'store/user/selector';
import { receiveTagTimelineItems } from 'store/tags/slice';
import { RelationTypes, TagRelationEntityTypes, TagTimelineView } from 'constants/app';
import { useActivityIntersection_OLD, useAppDispatch, useAppSelector, useCalendarEvent } from 'hooks';

import './style.css';


interface ITaggedLinkActivityProps {
  tagTimelineEntry: ITagEntry;
}


const TaggedCalEvent = ({
  tagTimelineEntry,
}: ITaggedLinkActivityProps) => {
  const relation = tagTimelineEntry.tag_relation;
  const { calendarEvent } = useCalendarEvent(relation.entity_id);
  const currentUserId = useAppSelector(getCurrentUser)?.id || '';
  const dispatch = useAppDispatch();

  const allAttendees = useAppSelector(s => getAllAttendeesForEvent(s, calendarEvent?.id || '')).map(contact => contact.id);
  const activityIntersection = useActivityIntersection_OLD(allAttendees);

  const timelineItems: undefined | ITagEntry[] = activityIntersection.activities?.map((activity: IActivity) => ({
    activity,
    tag_relation: {
      entity_id: activity.id,
      entity_type: TagRelationEntityTypes.ACTIVITY,
      entity_subtype: null,
      relation: RelationTypes.MANUAL,
      namespace: `usr:${calendarEvent?.user_id}`,
      tag_id: relation.tag_id,
      timestamp: activity.timestamp,
      updated_by: currentUserId,
    }
  }));

  if (timelineItems) {
    dispatch(receiveTagTimelineItems({ timelineItems, tagId: relation.tag_id, view: TagTimelineView.ACTIVITY }));
  }
  return null;
};


export default TaggedCalEvent;