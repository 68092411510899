import { API } from 'api';
import { IDocument } from 'store/documents/selectors';
import { TrackEventNames, tracker } from 'utils/tracking';

export const fetchDocumentTimeline = (before?: string, starred?: boolean) => {
  let path = `/api/v1/documents/list/timeline/?starred=${starred || false}`;
  if (before) {
    path += `&before=${before}`;
  }
  return API.get(path);
};

export const fetchBookmarkedDocumentTimeline = (before?: string) => {
  let path = '/api/v1/documents/list/timeline/?';
  if (before) {
    path += `before=${before}`;
  }
  return API.get(path);
};


export const reindexDocument = (documentId: string) => API.post(`/api/v1/documents/${documentId}/reindex`);

export const rememberDocument = (documentId: string) => API.post(`/api/v1/documents/${documentId}/remember`)
  .then(tracker.trackAfterRequest(TrackEventNames.SD, {documentId}));

export const fetchDocument = (docId: string) => API.get<IDocument>(`/api/v1/documents/${docId}/`);

export const fetchDocumentLocation = (docId: string) => API.get<{location: string}>(`/api/v1/documents/${docId}/location`, {timeout: 200});

export const fetchDocumentsFromContact = (contactId: string) => API.get<IDocument[]>(`/api/v1/documents/list/from-contact/${contactId}`);
