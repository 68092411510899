
export enum ConnectorProviders {
  GMAIL = 'GMAIL',
  GDRIVE = 'GDRIVE',
  OUTLOOK = 'OUTLOOK',
  SLACK = 'SLACK',
  GCAL = 'GCAL',
  JIRA = 'JIRA',
  SALESFORCE = 'SALESFORCE',
  TRELLO = 'TRELLO'
}

export enum ActivitySubtitles {
  intersectionQuery = 'intersectionQuery',
  internalIntersectionQuery = 'internalIntersectionQuery',
  unionQuery = 'unionQuery',
  fileActivity = 'fileActivity',
}

export const jiraScopes = ['write:issue.vote:jira', 'read:workflow.property:jira', 'read:workflow:jira', 'read:workflow-scheme:jira',
  'read:user.property:jira', 'read:user.columns:jira', 'read:user:jira', 'read:user-configuration:jira',
  'read:status:jira', 'read:role:jira', 'read:project.property:jira', 'read:project.component:jira',
  'read:project:jira', 'read:project-version:jira', 'read:project-category:jira', 'read:label:jira',
  'read:issue.watcher:jira', 'read:issue.votes:jira', 'read:issue.vote:jira', 'read:issue.transition:jira',
  'read:issue.time-tracking:jira', 'read:issue.remote-link:jira', 'read:issue.property:jira',
  'read:issue.changelog:jira', 'read:issue:jira', 'read:issue-worklog.property:jira', 'read:issue-worklog:jira',
  'read:issue-type.property:jira', 'read:issue-type:jira', 'read:issue-type-transition:jira',
  'read:issue-type-screen-scheme:jira', 'read:issue-type-scheme:jira', 'read:issue-type-hierarchy:jira',
  'read:issue-status:jira', 'read:issue-security-scheme:jira', 'read:issue-security-level:jira',
  'read:issue-meta:jira', 'read:issue-link:jira', 'read:issue-link-type:jira', 'read:issue-field-values:jira',
  'read:issue-event:jira', 'read:issue-details:jira', 'read:issue-adjustments:jira', 'read:group:jira',
  'read:field:jira', 'read:field-configuration:jira', 'read:field-configuration-scheme:jira',
  'read:dashboard:jira', 'read:comment:jira', 'read:build-info:jira', 'read:avatar:jira',
  'read:attachment:jira', 'read:application-role:jira', 'read:audit-log:jira', 'write:webhook:jira',
  'delete:webhook:jira', 'read:jql:jira', 'read:webhook:jira', 'read:comment.property:jira',
  'read:epic:jira-software', 'read:project-role:jira', 'offline_access', 'read:me', 'read:account'];

export const microsoftScopes = 'offline_access User.Read Calendars.Read Calendars.Read.Shared';

export enum TagRelationEntityTypes {
  DOCUMENT = 'DOCUMENT',
  CALENDAR_EVENT = 'CALENDAR_EVENT',
  ACTIVITY = 'ACTIVITY',
  LINK = 'LINK',
  NOTE = 'NOTE',
  ACTION_ITEM = 'ACTION_ITEM',
}

export enum PinSpaces {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}


export enum AppQueryParams {
  CALENDAR_VIEW_DATE = 'viewDate',
  CALENDAR_FOCUS_EVENT = 'cfe',
}

export enum TagTimelineView {
  ACTIVITY = 'ACTIVITY',
  NOTES = 'NOTES',
  PINS = 'PINS',
  ACTION_ITEMS = 'ACTION_ITEMS',
}

export enum RelationTypes {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
  DISASSOCIATED = 'DISASSOCIATED',
}