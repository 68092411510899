const CheckEmailIcon = ({ className = '' }: { className?: string }) => (
  <svg width="294" height="264" viewBox="0 0 294 264" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M291.421 100.959C291.315 100.959 291.213 100.927 291.126 100.866L148.48 1.49218C148.046 1.19117 147.53 1.03031 147.002 1.03121C146.474 1.03211 145.959 1.19473 145.526 1.49722L3.90686 100.865C3.79484 100.944 3.65621 100.975 3.52145 100.951C3.38669 100.928 3.26683 100.851 3.18827 100.739C3.1097 100.627 3.07884 100.489 3.10249 100.354C3.12615 100.219 3.20236 100.099 3.31438 100.02L144.933 0.652244C145.54 0.228827 146.261 0.00122055 147 4.89514e-06C147.74 -0.00121076 148.462 0.224021 149.07 0.645442L291.716 100.019C291.806 100.082 291.874 100.172 291.909 100.276C291.945 100.38 291.946 100.493 291.913 100.598C291.88 100.702 291.815 100.794 291.726 100.859C291.638 100.924 291.531 100.959 291.421 100.959L291.421 100.959Z" fill="#3F3D56" />
    <path d="M11.999 104.482L147.142 4.29297L283.311 111.611L154.105 188.23L83.9578 172.236L11.999 104.482Z" fill="#E6E6E6" />
    <path d="M89.8793 235.553H23.1154C22.7054 235.553 22.2994 235.473 21.9205 235.316C21.5416 235.16 21.1973 234.93 20.9072 234.64C20.6171 234.351 20.387 234.006 20.23 233.628C20.073 233.249 19.9922 232.843 19.9922 232.433C19.9922 232.023 20.073 231.617 20.23 231.238C20.387 230.859 20.6171 230.515 20.9072 230.225C21.1973 229.935 21.5416 229.705 21.9205 229.549C22.2994 229.392 22.7054 229.312 23.1154 229.313H89.8793C90.2893 229.312 90.6953 229.392 91.0742 229.549C91.4531 229.705 91.7974 229.935 92.0875 230.225C92.3776 230.515 92.6077 230.859 92.7647 231.238C92.9217 231.617 93.0025 232.023 93.0025 232.433C93.0025 232.843 92.9217 233.249 92.7647 233.628C92.6077 234.006 92.3776 234.351 92.0875 234.64C91.7974 234.93 91.4531 235.16 91.0742 235.316C90.6953 235.473 90.2893 235.553 89.8793 235.553Z" fill="#7649FD" />
    <path d="M47.0688 222.139H23.1154C22.7054 222.139 22.2994 222.059 21.9205 221.902C21.5416 221.746 21.1973 221.516 20.9072 221.226C20.6171 220.937 20.387 220.592 20.23 220.214C20.073 219.835 19.9922 219.429 19.9922 219.019C19.9922 218.609 20.073 218.202 20.23 217.824C20.387 217.445 20.6171 217.101 20.9072 216.811C21.1973 216.521 21.5416 216.291 21.9205 216.135C22.2994 215.978 22.7054 215.898 23.1154 215.898H47.0688C47.4787 215.898 47.8848 215.978 48.2637 216.135C48.6426 216.291 48.9869 216.521 49.277 216.811C49.567 217.101 49.7972 217.445 49.9542 217.824C50.1112 218.202 50.192 218.609 50.192 219.019C50.192 219.429 50.1112 219.835 49.9542 220.214C49.7972 220.592 49.567 220.937 49.277 221.226C48.9869 221.516 48.6426 221.746 48.2637 221.902C47.8848 222.059 47.4787 222.139 47.0688 222.139Z" fill="#7649FD" />
    <path d="M149.043 160.287C148.535 160.287 148.032 160.189 147.563 159.997L64.2158 125.403V17.1175C64.2169 16.16 64.5977 15.242 65.2745 14.5649C65.9514 13.8878 66.8691 13.507 67.8263 13.5059H227.721C228.678 13.507 229.596 13.8878 230.273 14.5649C230.95 15.242 231.33 16.16 231.332 17.1175V125.478L231.175 125.545L150.567 159.98C150.085 160.183 149.566 160.287 149.043 160.287Z" fill="white" />
    <path d="M149.043 160.544C148.502 160.545 147.965 160.44 147.465 160.236L63.958 125.575V17.1177C63.9592 16.0918 64.3671 15.1082 65.0923 14.3827C65.8175 13.6573 66.8008 13.2492 67.8264 13.248H227.721C228.747 13.2492 229.73 13.6573 230.455 14.3827C231.18 15.1082 231.588 16.0918 231.59 17.1177V125.649L150.669 160.217C150.154 160.434 149.601 160.545 149.043 160.544ZM64.9896 124.886L147.858 159.282C148.631 159.595 149.496 159.59 150.266 159.267L230.558 124.967V17.1177C230.557 16.3654 230.258 15.6441 229.726 15.1121C229.194 14.5801 228.473 14.2808 227.721 14.28H67.8264C67.0743 14.2808 66.3533 14.5801 65.8215 15.1121C65.2897 15.6441 64.9905 16.3654 64.9896 17.1177L64.9896 124.886Z" fill="#3F3D56" />
    <path d="M290.389 99.9258H290.286L231.074 125.218L150.059 159.823C149.742 159.956 149.402 160.025 149.059 160.027C148.716 160.029 148.375 159.963 148.057 159.833L64.4737 125.146L3.80647 99.9671L3.71378 99.9258H3.61053C2.65328 99.9268 1.73554 100.308 1.05867 100.985C0.381794 101.662 0.00106214 102.58 0 103.537V260.387C0.0010635 261.345 0.381794 262.263 1.05867 262.94C1.73554 263.617 2.65328 263.998 3.61053 263.999H290.389C291.347 263.998 292.264 263.617 292.941 262.94C293.618 262.263 293.999 261.345 294 260.387V103.537C293.999 102.58 293.618 101.662 292.941 100.985C292.264 100.308 291.347 99.9268 290.389 99.9258ZM292.968 260.387C292.968 261.071 292.696 261.727 292.213 262.211C291.729 262.695 291.073 262.967 290.389 262.967H3.61053C2.92663 262.967 2.27083 262.695 1.78724 262.211C1.30365 261.727 1.03185 261.071 1.03158 260.387V103.537C1.03251 102.87 1.29121 102.229 1.75357 101.749C2.21593 101.268 2.84618 100.984 3.51256 100.958L64.4737 126.26L147.66 160.788C148.561 161.154 149.57 161.148 150.466 160.772L231.074 126.337L290.493 100.958C291.158 100.986 291.786 101.271 292.248 101.751C292.709 102.232 292.967 102.871 292.968 103.537V260.387Z" fill="#3F3D56" />
    <path d="M148.21 129.58C145.133 129.586 142.137 128.591 139.675 126.745L139.523 126.63L107.383 102.015C105.895 100.874 104.646 99.4514 103.707 97.8277C102.768 96.2039 102.159 94.4111 101.913 92.5517C101.666 90.6922 101.789 88.8024 102.273 86.9903C102.757 85.1781 103.593 83.4791 104.734 81.9902C105.874 80.5013 107.296 79.2516 108.92 78.3126C110.543 77.3736 112.335 76.7636 114.194 76.5175C116.053 76.2714 117.942 76.3939 119.754 76.8781C121.565 77.3622 123.264 78.1986 124.752 79.3394L145.57 95.307L194.765 31.1293C195.906 29.6408 197.328 28.3917 198.952 27.4532C200.576 26.5148 202.368 25.9054 204.227 25.6599C206.086 25.4145 207.975 25.5377 209.787 26.0226C211.598 26.5074 213.296 27.3445 214.784 28.4859L214.478 28.9013L214.792 28.4919C217.794 30.7995 219.758 34.2039 220.253 37.9582C220.748 41.7125 219.735 45.5101 217.435 48.5178L159.57 124.006C158.231 125.746 156.51 127.153 154.54 128.12C152.57 129.087 150.404 129.586 148.21 129.58Z" fill="#7649FD" />
  </svg>
);

export default CheckEmailIcon;
