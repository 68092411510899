import { isEmpty } from 'ramda';
import { useHistory } from 'react-router';
import { Alert, Button as EvergreenButton, Position, Tooltip } from 'evergreen-ui';

import Button from 'components/Button';
import { ConnectedAccountGroup } from 'components/Connector';
import { ConnectorProviders } from 'constants/app';
import GoogleButton from 'components/GoogleButton';
import { IConnector } from 'store/connectors/slice';
import LoadingDots from 'components/LoadingDots';
import OnboardingCardWithHeader from 'components/OnboardingCardWithHeader';
import SlackIcon from 'components/icons/Slack';
import { getConnectorName } from 'utils/message_ctx';
import { getConnectorsList } from 'store/connectors/selectors';
import { getCurrentUser } from 'store/user/selector';
import { getGoogleOAuthURI } from 'api/auth';
import { getProviderIcon } from 'components/Connector/providers';
import { useAppSelector } from 'hooks';
import { APP_URL, SLACK_CLIENT_ID } from 'constants/resources';

import './style.css';

const openGoogle = async (authuser?: string) => {
  try {
    const {data} = await getGoogleOAuthURI(undefined, authuser);
    window.location.href = data.authorization_url;
  } catch (err) {
    console.warn('Could not get google auth URI', err);
  }
};

const ConnectedAccount = ({ connector } : { connector: IConnector }) => {
  const Icon = getProviderIcon(connector.provider);
  const connectorName = getConnectorName(connector);

  return (<div className="connected-account">
    <Icon className="connected-account--icon"/>
    {connectorName}
  </div>);
};

const DisabledButton = () => (
  <Tooltip content="Account must be connected" position={Position.LEFT}>
    <div><EvergreenButton disabled onClick={() => {}}>Done</EvergreenButton></div>
  </Tooltip>
);

const ConnectAnotherAccount = ({ providerName, loading, failed }: { providerName: string, loading: boolean, failed: boolean }) => {
  const user = useAppSelector(getCurrentUser);
  const connectors = useAppSelector(getConnectorsList);
  const history = useHistory();

  const openSlack = () => {
    if (!user) return;

    const params = new URLSearchParams();
    params.append('client_id', SLACK_CLIENT_ID);
    params.append('scope', 'channels:history,channels:read,files:read,groups:history,im:history,im:read,links:read,mpim:history,users:read.email,users:read,mpim:read,groups:read,remote_files:read');
    params.append('state', btoa(JSON.stringify({userId: user.id})));
    params.append('redirect_uri', APP_URL + '/connect-success?provider=slack');
    window.open(`https://slack.com/oauth/authorize?${params.toString()}`);
  };

  if (loading) {
    return <OnboardingCardWithHeader className="connect-another-account--card"><div className="connect-account-account--loader">Verifying connection to {providerName}<LoadingDots className="connect-another-account--loading-dots"/></div></OnboardingCardWithHeader>;
  }

  const groupedConnectors: {[name: string]: IConnector[]} = {};

  connectors.forEach(conn => {
    const name = getConnectorName(conn);
    if (groupedConnectors[name]) {
      groupedConnectors[name].push(conn);
    } else {
      groupedConnectors[name] = [conn];
    }
  });

  return (
    <OnboardingCardWithHeader headerText='Add another account' className="connect-another-account--card">
      {failed && <Alert intent="warning" className="connect-another-account--alert" title={`Could not connect to your ${providerName} account. Please try again.`} marginBottom={24}></Alert>}
      <div className="connect-another-account--buttons">
        <GoogleButton onClick={() => openGoogle()}/>
        <Button buttonType="secondary" className="connect-another-account--slack-button" onClick={() => openSlack()}>
          <SlackIcon className="slack-button--icon" />
          Sign in with Slack
        </Button>
      </div>
      {!isEmpty(connectors) && <>
        <div className="connect-another-account--accounts-header">Your accounts</div>
        {Object.entries(groupedConnectors).map(([name, connectors]) => {
          if (connectors.length === 1 && connectors[0].provider === ConnectorProviders.SLACK) {
            return <ConnectedAccount connector={connectors[0]}/>;
          }
          return <ConnectedAccountGroup name={name} key={connectors[0].id} connectors={connectors} />;
        })}
      </>}
      <div className="connect-another-account--footer">
        {isEmpty(connectors) ? <DisabledButton/> : <EvergreenButton className="connect-another-account--done-button" onClick={() => history.push('/signup-complete')}>Done</EvergreenButton>}
      </div>
    </OnboardingCardWithHeader>
  );
};

export default ConnectAnotherAccount;
