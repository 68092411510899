import { Route, Switch } from 'react-router-dom';

import Sidebar from 'components/Sidebar';

import AccountsSettings from './accounts';
import GeneralSettings from './general';
import RuleSettings from './rules';
import './style.css';


const SettingsPanes = [
  {
    name: 'General',
    location: '/settings',
    component: GeneralSettings,
  },
  {
    name: 'Accounts',
    location: '/settings/accounts',
    component: AccountsSettings,
  },
  {
    name: 'Rules',
    location: '/settings/rules',
    component: RuleSettings,
  }
];


const SettingsLayout = () => {
  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <Switch>
        {SettingsPanes.map(pane =>
          <Route key={pane.name} component={pane.component} path={pane.location} exact />)
        }
      </Switch>
    </div>
  );
};

export default SettingsLayout;