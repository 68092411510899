import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent } from 'react';
import cx from 'classnames';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faExternalLinkAlt, faBookmark as faSolidBookmark } from '@fortawesome/free-solid-svg-icons';
import { fetchDocumentLocation, rememberDocument } from 'api/documents';

import Button from 'components/Button';
import { IDocument } from 'store/documents/selectors';
import { NotificationType } from 'store/notifications/selectors';
import { getConnectorProvidersForActivity } from 'store/connectors/selectors';
import { getExternalLinkFromActivities } from 'utils/message_ctx';
import { getIconForMimetype } from 'components/icons/files';
import { getIsSuperuser } from 'store/user/selector';
import { getProvidersIcon } from 'components/Connector/providers';
import { getSearchDocumentById } from 'store/search/selectors';
import { humanizeMimetype } from 'utils/strings';
import { notify } from 'store/notifications/slice';
import { receiveDocuments } from 'store/documents/slice';
import DocumentSenderContext, { RenderType } from 'components/DocumentSenderContext';
import { TrackEventNames, tracker } from 'utils/tracking';
import { useAppDispatch, useAppSelector } from 'hooks';

import { DocumentContext } from './context';
import './style.css';

const dateFmt: Intl.DateTimeFormatOptions = {month: 'numeric', year: '2-digit', day: 'numeric', hour: 'numeric', minute: '2-digit'};


const BasicDocumentInfo = ({
  document
}: {document: IDocument}) => {
  const Icon = getIconForMimetype(document.mimetype);
  const dateString = new Date(document.updated_at).toLocaleString(navigator.language, dateFmt);
  const connectorProviders = getConnectorProvidersForActivity(document.latest_activity);
  const ProviderIcon = getProvidersIcon(connectorProviders);

  return <div className="document-sidebar-basic-info--container">
    <div className="document-sidebar-basic-info--icon">
      <Icon width={80} height={80} size="5x" />
      <div>
        <ProviderIcon className="document-sidebar-basic-info-provider--icon"/>
      </div>
    </div>
    <div className="document-sidebar-basic--container">
      <div className="document-sidebar-basic--info title">{document.filename}</div>
      <div className="document-sidebar-basic--info">{humanizeMimetype(document.mimetype)}</div>
      <div className="document-sidebar-basic--info subtitle">Last shared by</div>
      <div className="document-sidebar-basic--info"><DocumentSenderContext docId={document.id} activities={document.latest_activity} renderType={RenderType.SENDER_NAME_ONLY}/> on {dateString}</div>
    </div>
  </div>;
};


const DocumentActions = ({
  document
}: {document: IDocument}) => {
  const connectorProviders = getConnectorProvidersForActivity(document.latest_activity);
  const fallbackExternalLink = getExternalLinkFromActivities(document.latest_activity, document.mimetype);
  const dispatch = useAppDispatch();

  const openExtLink = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    tracker.track(TrackEventNames.ELC, {documentId: document.id, integration: connectorProviders[0], from: 'document actions'});
    try {
      const {data} = await fetchDocumentLocation(document.id);
      window.open(data.location || fallbackExternalLink);
    } catch (err) {
      const openNewTab = fallbackExternalLink?.startsWith('http');
      if (openNewTab) {
        window.open(fallbackExternalLink);
      } else {
        window.location.href = fallbackExternalLink as string;
      }
    }
  };

  const rememberDoc = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      const {data} = await rememberDocument(document.id);
      dispatch(receiveDocuments([data]));
    } catch (err) {
      notify({ type: NotificationType.WARNING, message: 'Failed to add document to remember'})(dispatch);
      console.warn('Failed to reindex', err);
    }
  };

  return (
    <div className="document-sidebar-actions--container">
      <div className="document-sidebar-action--container">
        <Button buttonType="subtle" active={document.star} className="document-sidebar-action--button" onClick={rememberDoc}>
          <FontAwesomeIcon icon={document.star ? faSolidBookmark : faBookmark} size="2x" />
        </Button>
      </div>

      <div className="document-sidebar-action--container">
        <Button buttonType="subtle" className="document-sidebar-action--button" onClick={openExtLink}>
          <FontAwesomeIcon icon={faExternalLinkAlt} size="2x" />
        </Button>
      </div>
    </div>
  );
};

const SearchRelevance = ({
  documentId
}: { documentId: string}) => {
  const searchResult = useAppSelector(s => getSearchDocumentById(s, documentId));

  if (!searchResult) {
    return null;
  }


  const fieldMap: Record<string, {label: string, order: number}> = {
    'message_context.content': {
      label: 'Message',
      order: 2,
    },
    'filename': {
      label: 'File name',
      order: 1,
    },
    'message_context.recipients.name': {
      label: 'Sent to',
      order: 1,
    },
    'message_context.sender.name': {
      label: 'Sent from',
      order: 1,
    },
  };

  const relevanceMessages: Record<string, {className: string, text: string}> = {
    V_HIGH: {
      className: 'very-high',
      text: 'Very high',
    },
    HIGH: {
      className: 'high',
      text: 'High',
    },
    MID: {
      className: 'medium',
      text: 'Medium',
    },
    LOW: {
      className: 'low',
      text: 'Low'
    },
  };

  const highlight = Object.entries(searchResult.highlight)
    .sort((a, b) => (fieldMap[a[0]] || {order: 0}).order - (fieldMap[b[0]] || {order: 0}).order)
    .map(([field, values]) => ({field: (fieldMap[field] || {label: field}).label, value: values[0]}));

  let relevance = relevanceMessages.LOW;

  if (searchResult.score >= 50) {
    relevance = relevanceMessages.V_HIGH;
  } else if (searchResult.score >= 30) {
    relevance = relevanceMessages.HIGH;
  } else if (searchResult.score >= 5) {
    relevance = relevanceMessages.MID;
  }

  return <div className="search-score--container">
    <h4 className="search-score--title">Search score</h4>
    <div className="search-score--section">Relevance <div className={cx('search-relevance--pill', relevance.className)}>{relevance.text}</div></div>
    <div>
      <div className="search-score--section">Reason</div>
      <div>
        {highlight.map(({field, value}) => (
          <div key={field} className="search-score-relevance-field--container">
            <div className="search-score-relevance-field--elt">{field}</div>
            <div className="search-score-relevance-field--elt" dangerouslySetInnerHTML={{__html:value}}/>
          </div>
        ))}
      </div>
    </div>
  </div>;
};


const SidebarLoadingSkeleton = () => {
  return <div>
    <div className="view-document-sidebar--header loading-skeleton">
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
    </div>
    <div className="view-document-sidebar--body loading-skeleton">
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image small" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image small" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image small" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
    </div>
  </div>;
};


const DocumentSidebar = ({
  searchSidebar=false,
  onClose,
  document,
  hidden,
  showBackBtn,
  onBack,
}: {searchSidebar?: boolean, hidden: boolean, onClose: () => void, document: IDocument | null, showBackBtn?: boolean, onBack?: () => void}) => {
  const isSuperuser = useAppSelector(getIsSuperuser);

  return (
    <div className={cx('view-document-sidebar--container', { hidden })}>
      <div className="view-document-sidebar-content--container">
        {!document && <SidebarLoadingSkeleton />}
        {document && (
          <>
            <div className="view-document-sidebar--header">
              <div className="view-document-sidebar--title">
                {showBackBtn && onBack &&
                  <FontAwesomeIcon icon={faArrowLeft} onClick={onBack}/>
                }
                <div>File details</div>
              </div>
              <div className="view-document-sidebar--close" onClick={onClose}>&times;</div>
            </div>
            <div className="view-document-sidebar--body">
              <BasicDocumentInfo document={document} />
              {isSuperuser && searchSidebar && <SearchRelevance documentId={document.id}/>}
              <DocumentActions document={document} />
              <DocumentContext document={document} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentSidebar;