import LoadingDots from 'components/LoadingDots';
import { useAppDispatch, useAppSelector, useVisibility } from 'hooks';
import { useEffect, useState } from 'react';

import { NotificationType } from 'store/notifications/selectors';
import { fetchDocumentTimeline } from 'api/documents';
import { notify } from 'store/notifications/slice';
import { IDocument, getDocumentDateGroups, getHasNoDocuments, getOldestDocumentTimestamp, getOldestRememberTimestamp } from 'store/documents/selectors';
import { receiveDocuments, setOldestRememberFetch, setOldestTimelineFetch } from 'store/documents/slice';

import DocumentRow from './row';
import './style.css';


const DocumentGroup = ({date, documents}: {date: Date, documents: IDocument[]}) => {
  const dateIsToday = (new Date()).toDateString() === date.toDateString();
  let dateString = date.toLocaleDateString(navigator.language, {weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'});

  if (dateIsToday) {
    dateString = `Today, ${date.toLocaleDateString(navigator.language, {month: 'short', day: 'numeric'})}`;
  }

  return <div className="document-group--container">
    <div className="document-group--header">{dateString}</div>
    <div className="document-group--items">{documents.map(document => <DocumentRow key={document.id} document={document} />)}</div>
  </div>;
};

const LoadingIndicator = ({
  starred
}: {starred?: boolean}) => {
  const oldestDocumentTs = useAppSelector(starred ? getOldestRememberTimestamp : getOldestDocumentTimestamp);
  const [loading, setLoading] = useState(false);
  const [noMoreToLoad, setNoMoreToLoad] = useState(false);
  const [isVisible, checkInView, currentElement] = useVisibility<HTMLDivElement>(100);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadMore = async () => {
      setLoading(true);
      try {
        const {data} = await fetchDocumentTimeline(oldestDocumentTs, starred);
        const oldestFetchEpoch = new Date(oldestDocumentTs || '').valueOf();
        if (data.length === 0 || (data.length === 1 && new Date(data[0].created_at).valueOf() === oldestFetchEpoch)) {
          setNoMoreToLoad(true);
        } else {
          const action = starred ? setOldestRememberFetch : setOldestTimelineFetch;
          dispatch(action(new Date(data[data.length - 1].created_at)));
        }
        dispatch(receiveDocuments(data));
        setTimeout(() => checkInView(), 0);
      } catch (err) {
        notify({
          message: 'Failed to load more documents',
          type: NotificationType.ERROR
        })(dispatch);
      } finally {
        setTimeout(() => setLoading(false), 250);
      }
    };

    if (!loading && isVisible && !noMoreToLoad) {
      loadMore();
    }

  }, [isVisible, loading, dispatch, oldestDocumentTs, noMoreToLoad, checkInView, starred]);

  return <div ref={currentElement} className="document-timeline--loading">
    {loading && <div className="document-timeline--loading-content"><span>Loading more documents</span><LoadingDots /></div>}
    {!loading && noMoreToLoad && <div>No older documents found.</div>}
    {' '}
  </div>;
};

const DocumentTimeline = ({
  title='Recent Activity',
  starred
}: {starred?: boolean, title?: string}) => {
  const hasNoDocuments = useAppSelector(getHasNoDocuments);
  const documentGroups = useAppSelector((s) => getDocumentDateGroups(s, starred));

  if (hasNoDocuments) {
    return null;
  }

  return <div className="document-timeline--container">
    <h2 className="document-timeline--header">{title}</h2>
    <div className="document-timeline--section">
      {documentGroups.map(group => <DocumentGroup {...group} key={group.date.valueOf()} />)}
      <LoadingIndicator starred={starred} />
    </div>
  </div>;
};

export default DocumentTimeline;