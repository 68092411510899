import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { faBookmark as faEmptyBookmark } from '@fortawesome/free-regular-svg-icons';
import { MouseEvent, useState } from 'react';
import { faExternalLinkAlt, faBookmark as faFilledBookmark, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import Button from 'components/Button';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import { getConnectorProvidersForActivity } from 'store/connectors/selectors';
import { getExternalLinkFromActivities } from 'utils/message_ctx';
import { getIconForMimetype } from 'components/icons/files';
import { getIsSuperuser } from 'store/user/selector';
import { getProvidersIcon } from 'components/Connector/providers';
import { notify } from 'store/notifications/slice';
import DocumentSenderContext, { RenderType } from 'components/DocumentSenderContext';
import { IDocument, getSidebarDocumentId, getSidebarIsOpen } from 'store/documents/selectors';
import { TrackEventNames, tracker, } from 'utils/tracking';
import { fetchDocumentLocation, reindexDocument, rememberDocument } from 'api/documents';
import { openSideBarToDocument, receiveDocuments } from 'store/documents/slice';
import { useAppDispatch, useAppSelector } from 'hooks';


const DocumentRow = ({document}: {document: IDocument}) => {

  const connectorProviders = getConnectorProvidersForActivity(document.latest_activity);
  const sidebarDocId = useAppSelector(getSidebarDocumentId);
  const sidebarIsOpen = useAppSelector(getSidebarIsOpen);
  const isSuperuser = useAppSelector(getIsSuperuser);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const Icon = getProvidersIcon(connectorProviders);
  const timeString = (new Date(document.created_at)).toLocaleTimeString(navigator.language, {hour: '2-digit', minute: '2-digit'});

  const fallbackExternalLink = getExternalLinkFromActivities(document.latest_activity, document.mimetype);

  const openSidebar = () => {
    tracker.track(TrackEventNames.DTSO, {documentId: document.id, integration: connectorProviders[0]});
    dispatch(openSideBarToDocument(document.id));
  };

  const reindexDoc = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      await reindexDocument(document.id);
      notify({ type: NotificationType.SUCCESS, message: 'Refreshed document data' })(dispatch);
    } catch (err) {
      notify({ type: NotificationType.WARNING, message: 'Could not refresh document'})(dispatch);
      console.warn('Failed to reindex', err);
    } finally {
      setIsLoading(false);
    }
  };

  const rememberDoc = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      const {data} = await rememberDocument(document.id);
      dispatch(receiveDocuments([data]));
    } catch (err) {
      notify({ type: NotificationType.WARNING, message: 'Failed to bookmark document'})(dispatch);
      console.warn('Failed to bookmark', err);
    } finally {
      setIsLoading(false);
    }
  };

  const openExtLink = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    tracker.track(TrackEventNames.ELC, {documentId: document.id, integration: connectorProviders[0], from: 'document timeline row'});
    try {
      const {data} = await fetchDocumentLocation(document.id);
      window.open(data.location || fallbackExternalLink);
    } catch (err) {
      const openNewTab = fallbackExternalLink?.startsWith('http');
      if (openNewTab) {
        window.open(fallbackExternalLink);
      } else {
        window.location.href = fallbackExternalLink as string;
      }
    }
  };


  const selected = sidebarIsOpen && document.id === sidebarDocId;

  const FileIcon = getIconForMimetype(document.mimetype);

  return (
    <div className="activity-document--row">
      <div className="document-row--time">{timeString}</div>
      <div className={cx('document-row--container', {selected})} onClick={openSidebar}>
        <div className="document-row--inner-container">
          <div className="document-row-icons--container">
            <FileIcon />
            <Icon className="document-row--icon"/>
          </div>
          <div className="document-row--filename">
            <div className="document-row-filename--content">{document.filename}</div>
            <div className="document-row--send-context">
              <DocumentSenderContext
                renderType={RenderType.NAME_AND_LINK}
                docId={document.id}
                activities={document.latest_activity}
              />
            </div>
          </div>
        </div>
        <div className="document-row--actions">
          {isLoading && <LoadingDots className="document-row--loading" />}
          <Button size="small" buttonType="subtle" className="document-row--button" onClick={rememberDoc} active={document.star} ><FontAwesomeIcon icon={document.star ? faFilledBookmark : faEmptyBookmark} /></Button>
          {isSuperuser && <Button size="small" buttonType="subtle" className="document-row--button" onClick={reindexDoc} ><FontAwesomeIcon icon={faSyncAlt}/></Button>}
          <Button size="small" buttonType="subtle" className="document-row--button" onClick={openExtLink}><FontAwesomeIcon icon={faExternalLinkAlt} /></Button>
        </div>
      </div>
    </div>
  );
};


export default DocumentRow;