import { Button } from 'evergreen-ui';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';

import CompleteSignupIcon from 'components/icons/CompleteSignup';
import { NotificationType } from 'store/notifications/selectors';
import OnboardingCardWithHeader from 'components/OnboardingCardWithHeader';
import { notify } from 'store/notifications/slice';
import { putSetting } from 'api/user';
import { receiveUser } from 'store/user/slice';
import { OnboardingStepNames, UserPropertyNames } from 'store/user/selector';

import './style.css';

const SignupComplete = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    putSetting(UserPropertyNames.ONBOARDING_STEP, 'STRING', OnboardingStepNames.SIGNUP_COMPLETE)
      .catch(() => console.error('Unable to save onboarding step'));
  });

  const onFinishSignup = async () => {
    setLoading(true);
    putSetting(UserPropertyNames.ONBOARDING_FINISHED, 'BOOLEAN', 'true')
      .then(({ data })=> {
        dispatch(receiveUser(data));
        history.push('/');
      })
      .catch(() => {
        notify({
          message: 'Failed to finish onboarding. Our team has been alerted.',
          type: NotificationType.ERROR,
        })(dispatch);
      })
      .finally(() => setLoading(false));
  };

  return (
    <OnboardingCardWithHeader className="signup-complete--card">
      <CompleteSignupIcon />
      <div className="signup-complete--header">You are all set!</div>
      <div className="signup-complete--description">We’re still ingesting some of your data, but you can jump right in!</div>
      <Button isLoading={loading} appearance="primary" className="signup-complete--submit-button" onClick={onFinishSignup}>Get started</Button>
    </OnboardingCardWithHeader>
  );
};

export default SignupComplete;
