import { MouseEvent } from 'react';
import { withErrorBoundary } from '@sentry/react';

import { ConnectorProviders } from 'constants/app';
import LoadingDots from 'components/LoadingDots';
import { getConnector } from 'store/connectors/selectors';
import { getContactById } from 'store/contacts/selectors';
import { getContactName } from 'utils/strings';
import { getDocumentById } from 'store/documents/selectors';
import { IActivity, INewCommentGdriveActivityData, INewFileGmailActivityData } from 'store/documents/selectors';
import { TrackEventNames, tracker } from 'utils/tracking';
import { activityMessageThatSentFile, getExternalLinkFromActivity } from 'utils/message_ctx';
import { useAppSelector, useContact } from 'hooks';

import './style.css';


export enum RenderType {
  SENDER_NAME_ONLY = 'SENDER_NAME_ONLY',
  LINK_ONLY = 'LINK_ONLY',
  SUBJECT_ONLY = 'SUBJECT_ONLY',
  NAME_AND_LINK = 'NAME_AND_LINK',
  VERBOSE = 'VERBOSE',
}

interface ISenderContextProps {
  docId?: string,
  showFileName?: boolean,
  renderType: RenderType,
  activity: IActivity,
}


interface IMsgContextProps {
  activity: IActivity,
  renderType: RenderType,
}

interface IFullContextProps {
  activities: IActivity[],
  activity?: IActivity,
  showFileName?: boolean,
  docId: string,
  renderType: RenderType,
}

const UnknownSender = () => <em>Unknown Sender</em>;


const SlackSenderContext = ({
  renderType, activity,
}: ISenderContextProps) => {
  const slackData = activity.data;

  let channelName = '';
  if ('slack_file_share' in slackData) {
    channelName = slackData.slack_file_share.channel_name.startsWith('Group messaging') ? 'Group DM' : slackData.slack_file_share.channel_name;
  } else if ('slack_message' in slackData) {
    channelName = slackData.slack_message.channel_name.startsWith('Group messaging') ? 'Group DM' : slackData.slack_message.channel_name;
  }

  const channelLink = getExternalLinkFromActivity(activity, undefined, true);
  const messageWithFile = activityMessageThatSentFile(activity);

  const navigate = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    tracker.track(TrackEventNames.ELC, { integration: 'SLACK', from: 'sender context' });
    window.location.href = channelLink;
  };

  const link = <a href={channelLink} onClick={navigate} className="sender-context--link">{channelName}</a>;

  if (!messageWithFile) {
    return <UnknownSender />;
  }


  switch (renderType) {
    case RenderType.LINK_ONLY:
      return link;
    case RenderType.SENDER_NAME_ONLY:
      return <>{messageWithFile.sender.name}</>;
    case RenderType.NAME_AND_LINK:
      return <>{messageWithFile.sender.name} · {link}</>;
    case RenderType.SUBJECT_ONLY:
      return <>{channelName}</>;
    case RenderType.VERBOSE:
    default:
      return <>Sent by {messageWithFile.sender.name} in {link}</>;
  }
};


const GdriveSenderContext = ({
  docId, renderType, activity, showFileName=false,
}: ISenderContextProps) => {
  const contact = useAppSelector(s => getContactById(s, activity.data.actor?.contact_id || ''));
  let contactName = getContactName(contact);
  // INewFileGdriveActivityData is a subset of INewCommentGdriveActivityData
  const gdriveData = (activity.data as INewCommentGdriveActivityData);
  const document = useAppSelector(s => getDocumentById(s, docId || ''));

  // GDrive comment activity doesn't have any link to contacts, so we need to pull the
  // sender name out of the comment message data
  if (!contact && !!gdriveData.messages) {
    contactName = gdriveData.messages.reduce((nameSoFar, nextMsg) => nextMsg.sender.name ? nextMsg.sender.name : nameSoFar, contactName);
  }

  const href = getExternalLinkFromActivity(activity, document?.mimetype);

  const trackClick = (e: MouseEvent<HTMLAnchorElement>) => {
    tracker.track(TrackEventNames.ELC, { documentId: docId, integration: 'GDRIVE', from: 'sender context' });
    e.stopPropagation();
  };

  const link = <a href={href} onClick={trackClick} target="_blank" rel="noopener noreferrer nofollow" className="sender-context--link">Google Drive</a>;

  switch (renderType) {
    case RenderType.LINK_ONLY:
      return link;
    case RenderType.SENDER_NAME_ONLY:
      return <>{contactName}</>;
    case RenderType.NAME_AND_LINK:
      return <>{contactName} · {link}</>;
    case RenderType.SUBJECT_ONLY:
      return <>{showFileName && document ? document?.filename : 'Google Drive'}</>;
    case RenderType.VERBOSE:
    default:
      return <>Sent by {contactName} in {link}</>;
  }
};


const GmailSenderContext = ({
  docId, renderType, activity,
}: ISenderContextProps) => {

  const contact = useAppSelector(s => getContactById(s, activity.data.actor.contact_id));
  const document = useAppSelector(s => getDocumentById(s, docId || ''));
  const contactName = getContactName(contact);
  const gmailData = (activity.data as INewFileGmailActivityData);
  const msgData = gmailData.gmail_message || gmailData.gmail_file_share;
  const emailSubject = msgData.subject;
  const href = getExternalLinkFromActivity(activity, document?.mimetype);

  const trackClick = (e: MouseEvent<HTMLAnchorElement>) => {
    tracker.track(TrackEventNames.ELC, { documentId: docId, integration: 'GMAIL', from: 'sender context' });
    e.stopPropagation();
  };

  const link = <a href={href} onClick={trackClick} target="_blank" rel="noopener noreferrer nofollow" className="sender-context--link">{emailSubject}</a>;

  switch (renderType) {
    case RenderType.LINK_ONLY:
      return link;
    case RenderType.SENDER_NAME_ONLY:
      return <>{contactName}</>;
    case RenderType.NAME_AND_LINK:
      return <>{contactName} · {link}</>;
    case RenderType.SUBJECT_ONLY:
      return <>{emailSubject}</>;
    case RenderType.VERBOSE:
    default:
      return <>Sent by {contactName} in {link}</>;
  }

};


const DocumentSenderContext = ({
  docId, renderType = RenderType.VERBOSE, activity, activities, showFileName=false,
}: IFullContextProps) => {
  const latestActivity = activity || (activities.length === 0 ? undefined : activities.reduce((soFar, next) => new Date(soFar.timestamp).valueOf() > new Date(next.timestamp).valueOf() ? soFar : next, activity || activities[0]));
  const contactId = latestActivity?.data.actor?.contact_id || '';
  const connector = useAppSelector(s => getConnector(s, latestActivity?.data.connector?.id || (latestActivity?.meta.connector_id as string)));

  const {
    loading,
    error: fetchFailed,
    contact,
  } = useContact(contactId);

  if (!latestActivity) {
    return <UnknownSender />;
  }

  if (fetchFailed) {
    return <UnknownSender />;
  }

  if (loading) {
    return <div className="document-sender-context--loading">loading <LoadingDots /></div>;
  }

  switch (connector?.provider) {
    case ConnectorProviders.SLACK:
      return <SlackSenderContext docId={docId} renderType={renderType} activity={latestActivity} />;
    case ConnectorProviders.GMAIL:
      return <GmailSenderContext docId={docId} renderType={renderType} activity={latestActivity} />;
    case ConnectorProviders.GDRIVE:
      return <GdriveSenderContext docId={docId} renderType={renderType} activity={latestActivity} showFileName={showFileName} />;
    default:
      if (contact) {
        return <span>{getContactName(contact)}</span>;
      } else {
        return <UnknownSender />;
      }
  }
};

export const MessageSenderContext = ({
  renderType = RenderType.VERBOSE, activity
}: IMsgContextProps) => {
  const contactId = activity?.data.actor?.contact_id || '';
  const connector = useAppSelector(s => getConnector(s, activity?.data.connector?.id || (activity?.meta.connector_id as string)));

  const {
    loading,
    error: fetchFailed,
  } = useContact(contactId);

  if (fetchFailed) {
    return <UnknownSender />;
  }

  if (loading) {
    return <div className="document-sender-context--loading">loading <LoadingDots /></div>;
  }

  switch (connector?.provider) {
    case ConnectorProviders.SLACK:
      return <SlackSenderContext renderType={renderType} activity={activity} />;
    case ConnectorProviders.GMAIL:
      return <GmailSenderContext renderType={renderType} activity={activity} />;
    case ConnectorProviders.GDRIVE:
      return <GdriveSenderContext renderType={renderType} activity={activity} />;
    default:
      return <UnknownSender />;
  }
};

export default withErrorBoundary(DocumentSenderContext, { fallback: <UnknownSender /> });