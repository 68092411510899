import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent } from 'react';
import cx from 'classnames';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom';

import { useNote } from 'hooks';



interface INotePillProps {
  noteId: string;
  tagId: string;
  className?: string,
  trackingCtx?: string,
}


const NotePill = ({
  noteId,
  tagId,
  className='',
}: INotePillProps) => {
  const {note} = useNote(noteId);
  const history = useHistory();

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    history.push(`/workstreams/${tagId}/notes/${noteId}`);
  };

  if (!note) return null;

  return <div className={cx('document-card-pill--container', className)} onClick={onClick}>
    <div className='document-card-pill-file--container'>
      <FontAwesomeIcon width={14} height={18} color="var(--color-purple-5)" icon={faStickyNote} size="sm" />
      <div className="document-card-pill-file--name">{note.title}</div>
    </div>
  </div>;
};


export default NotePill;