import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_IGNORE_MIMETYPES } from './constants';
import { fetchDocument } from 'api/documents';
import { AppDispatch, RootState } from 'store';
import { IDocument, getOldestDocumentTimestamp, isFetchingDocument } from './selectors';


interface ISidebarState {
  isOpen: boolean;
  documentId: string | null;
}

interface IAsyncDocument {
  data: IDocument | null;
  isFetching: boolean;
  lastReceived: Date | null;
  fetchFailed: boolean;
}


interface IDocumentsFromContactPayloadAction {
  contactId: string,
  docs: IDocument[]
}

interface DocumentsState {
  documentsById: {[documentId: string]: IAsyncDocument} | null,
  sidebar: ISidebarState,
  oldestTimelineFetch?: Date,
  oldestRememberFetch?: Date,
  documentsByContactId: {[contactId: string]: string[] } | null
}

const initialState: DocumentsState = {
  documentsById: null,
  sidebar: { isOpen: false, documentId: null },
  oldestTimelineFetch: undefined,
  oldestRememberFetch: undefined,
  documentsByContactId: null
};

export interface IDocumentWSPayload {
  id: string,
  created_at: string;
  updated_at: string;
  mimetype: string;
}


export const wsFetchUpdatedDocument = createAsyncThunk(
  'documents/wsFetchUpdatedDocument',
  async ({id, mimetype}: IDocumentWSPayload) => {
    if (DEFAULT_IGNORE_MIMETYPES.includes(mimetype)) {
      return;
    }
    const {data} = await fetchDocument(id);
    return data;
  }
);

export const fetchDocumentById = createAsyncThunk<IDocument | undefined, string, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'documents/fetchById',
  async (documentId: string, {getState, dispatch}) => {
    if (!isFetchingDocument(getState(), documentId)) {
      dispatch(setFetchingDocument({ documentId, isFetching: true}));
      const {data} = await fetchDocument(documentId);
      return data;
    }
  }
);


export const wsFetchNewDocument = createAsyncThunk<IDocument | undefined, IDocumentWSPayload, { state: RootState }>(
  'documents/wsFetchNewDocument',
  async ({id, mimetype, created_at}: IDocumentWSPayload, { getState }) => {
    if (DEFAULT_IGNORE_MIMETYPES.includes(mimetype)) {
      return;
    }
    const twoMonthAgoDate = new Date();
    twoMonthAgoDate.setMonth(twoMonthAgoDate.getMonth() - 2);
    const oldestDocumentTimestamp = getOldestDocumentTimestamp(getState());
    const oldestRelevantDate = oldestDocumentTimestamp ? new Date(oldestDocumentTimestamp): twoMonthAgoDate;
    const createdAtDate = new Date(created_at);
    if (createdAtDate < oldestRelevantDate) {
      return;
    }
    const {data} = await fetchDocument(id);
    return data;
  }
);

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    receiveDocuments: (state, action: PayloadAction<IDocument[]>) => {
      if (state.documentsById === null) {
        state.documentsById = {};
      }

      action.payload.forEach(document => {
        if (state.documentsById === null) {
          state.documentsById = {};
        }

        state.documentsById[document.id] = {
          data: document,
          isFetching: false,
          lastReceived: new Date(),
          fetchFailed: false,
        };
      });
    },

    openSideBarToDocument: (state, action: PayloadAction<string>) => {
      state.sidebar.isOpen = true;
      state.sidebar.documentId = action.payload;
    },

    closeSidebar: (state) => {
      state.sidebar.isOpen = false;
    },

    setOldestTimelineFetch: (state, action: PayloadAction<Date>) => {
      state.oldestTimelineFetch = action.payload;
    },

    setOldestRememberFetch: (state, action: PayloadAction<Date>) => {
      state.oldestRememberFetch = action.payload;
    },

    receiveDocumentsFromContact: (state, action: PayloadAction<IDocumentsFromContactPayloadAction>) => {
      if (state.documentsByContactId === null) {
        state.documentsByContactId = {};
      }
      state.documentsByContactId[action.payload.contactId] = action.payload.docs.map(doc => doc.id);
      action.payload.docs.forEach(doc => {
        if (!state.documentsById) {
          state.documentsById = {};
        }

        if (!state.documentsById[doc.id]) {
          state.documentsById[doc.id] = {
            data: doc,
            lastReceived: new Date(),
            isFetching: false,
            fetchFailed: false,
          };
        } else {
          state.documentsById[doc.id].data = doc;
          state.documentsById[doc.id].lastReceived = new Date();
        }
      });
    },

    setFetchingDocument: (state, action: PayloadAction<{documentId: string, isFetching: boolean}>) => {
      if (!state.documentsById) {
        state.documentsById = {};
      }

      if (!state.documentsById[action.payload.documentId]) {
        state.documentsById[action.payload.documentId] = {
          data: null,
          lastReceived: null,
          isFetching: action.payload.isFetching,
          fetchFailed: false,
        };
      } else {
        state.documentsById[action.payload.documentId].isFetching = action.payload.isFetching;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(wsFetchUpdatedDocument.fulfilled, (state, action) => {
      if (state.documentsById !== null && action.payload) {
        state.documentsById[action.payload.id] = {
          data: action.payload,
          isFetching: false,
          lastReceived: new Date(),
          fetchFailed: false,
        };
      }
    });

    builder.addCase(wsFetchNewDocument.fulfilled, (state, action) => {
      if (state.documentsById !== null && action.payload) {
        state.documentsById[action.payload.id] = {
          data: action.payload,
          isFetching: false,
          lastReceived: new Date(),
          fetchFailed: false,
        };
      }
    });

    builder.addCase(fetchDocumentById.rejected, (state, action) => {
      if (!state.documentsById) {
        state.documentsById = {};
      }

      const docummentId = action.meta.arg;

      if (!state.documentsById[docummentId]) {
        state.documentsById[docummentId] = {
          data: null,
          isFetching: false,
          lastReceived: null,
          fetchFailed: true,
        };
      } else {
        state.documentsById[docummentId].fetchFailed = true;
      }
    });

    builder.addCase(fetchDocumentById.fulfilled, (state, action) => {
      if (!state.documentsById) {
        state.documentsById = {};
      }

      if (!action.payload) {
        // thunk short-circuited because another fetch is in flight
        return;
      }

      state.documentsById[action.payload.id] = {
        data: action.payload,
        isFetching: false,
        lastReceived: new Date(),
        fetchFailed: false,
      };
    });
  }
});

export const { receiveDocuments, setFetchingDocument, openSideBarToDocument, closeSidebar, setOldestTimelineFetch, setOldestRememberFetch, receiveDocumentsFromContact } = documentsSlice.actions;
export default documentsSlice.reducer;
