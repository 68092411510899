import { Button, EmptyState, MapIcon } from 'evergreen-ui';
import { useEffect, useState } from 'react';

import FakeNewNote from 'components/Note/fakeNote';
import LoadingDots from 'components/LoadingDots';
import Note from 'components/Note';
import { NotificationType } from 'store/notifications/selectors';
import Sidebar from 'components/Sidebar';
import { createNote } from 'api/notes';
import { notify } from 'store/notifications/slice';
import { store } from 'store';
import { Route, Switch, useHistory } from 'react-router-dom';
import { fetchNextNoteTimeline, pushNoteToTimeline, receiveNotes } from 'store/notes/slice';
import { getNoMoreNotesInTimeline, getNoteTimelineIds, getNoteTimelineLoading, getNoteTimelineOffset } from 'store/notes/selectors';
import { useAppDispatch, useAppSelector, useVisibility } from 'hooks';

import './style.css';


const NotesViewEmpty = ({onClickNew, onClickTemplate}: {onClickNew: () => void, onClickTemplate: () => void}) => (
  <EmptyState
    title="You have no contact"
    icon={<MapIcon color="var(--color-yellow-3)" />}
    background="dark"
    iconBgColor="var(--color-purple-2)"
    description="Create your first contact to have Classify supercharge your work day."
    primaryCta={<div><Button onClick={onClickNew}>Create a new rule</Button> or <Button onClick={onClickTemplate}>Pick from a template</Button></div>}
  />
);

const LoadingIndicator = () => {
  const offset = useAppSelector(getNoteTimelineOffset);
  const loading = useAppSelector(getNoteTimelineLoading);
  const noMoreToLoad = useAppSelector(getNoMoreNotesInTimeline);
  const [isVisible, checkInView, currentElement] = useVisibility<HTMLDivElement>(100);
  const noteIds = useAppSelector(getNoteTimelineIds);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loading && isVisible && !noMoreToLoad) {
      fetchNextNoteTimeline({limit: 20})(dispatch, store.getState, {}).finally(() => {
        setTimeout(() => checkInView(), 100);
      });
    }

  }, [isVisible, loading, dispatch, offset, noMoreToLoad, checkInView]);

  const isEmpty = noteIds.length === 0;

  return <div ref={currentElement} className="document-timeline--loading">
    {loading && <div className="document-timeline--loading-content"><span>Loading more notes</span><LoadingDots /></div>}
    {!loading && noMoreToLoad && !isEmpty && <div>No older notes found.</div>}
    {!loading && noMoreToLoad && isEmpty && <NotesViewEmpty onClickNew={() => console.log('new')} onClickTemplate={() => console.log('template')}/>}
  </div>;
};



const NotesView = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const noteIds = useAppSelector(getNoteTimelineIds);
  const [loadingNewNote, setLoadingNewNote] = useState(false);

  const createAndViewNote = async () => {
    if (loadingNewNote) return;
    setLoadingNewNote(true);

    try {
      const {data} = await createNote({title: 'New note', content: ''});
      dispatch(receiveNotes([data]));
      dispatch(pushNoteToTimeline(data));

      history.push(`/notes/${data.id}`);
      setLoadingNewNote(false);
    } catch (err) {
      setLoadingNewNote(false);
      notify({
        message: 'Could not create new note, please try again.',
        type: NotificationType.WARNING,
      })(dispatch);
    }
  };

  return (
    <div className="sidebar-sibling-content--container notes-view--container">
      <Sidebar />
      <Switch>
        <Route path="/notes" exact>
          <FakeNewNote onClick={createAndViewNote} defaultTitle={`${new Date().toLocaleDateString('default', { month: 'numeric', day: 'numeric', year: 'numeric' })} New note`} loading={loadingNewNote} />
          {noteIds.map(noteId => <Note key={noteId} noteId={noteId} onViewNote={() => history.push(`/notes/${noteId}`)} />)}
          <LoadingIndicator />
        </Route>
        <Route path="/notes/:noteId" render={({match}) => <div className="notes-view-note--container"><Note noteId={match.params.noteId} disablePopover showBackArrow showAsEditor onViewNote={() => history.push('/notes')}/></div>} />
      </Switch>
    </div>
  );
};


export default NotesView;