
const OnboardingTimeline = () => {
  return <div className="onboarding-section--container">
    <div className="onboarding-section--hello">About activity</div>
    <h3 className="onboarding-section--headline">Get into the zone faster</h3>
    <div className="onboarding-section--description">Keep tabs on file activity across your digital world.
      Activity will highlight active files based on what’s happening
      between you and others.
    </div>
  </div>;
};

export default OnboardingTimeline;