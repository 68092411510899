import { RootState } from 'store';

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export interface INotificationConfig {
  type: NotificationType;
  message: JSX.Element | string;
  requireDismiss?: boolean;
  showDismiss?: boolean;
}

export interface INotification extends INotificationConfig {
  id: string;
  shownAt: number;
}

const root = (state: RootState) => state.notifications;

const byId = (state: RootState) => root(state).notificationsById;

export const getAllNotifications = (state: RootState) => Object.values(byId(state));

export const getNumNotifications = (state: RootState) => getAllNotifications(state).length;

export const getOldestNotification = (state: RootState) => {
  const notifs = getAllNotifications(state);
  return notifs.reduce((a1, a2) => (a1.shownAt < a2.shownAt ? a1 : a2), notifs[0]);
};