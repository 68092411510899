import { Button } from 'evergreen-ui';
import Select from 'react-select';
import { useHistory } from 'react-router';
import { FormEvent, MouseEvent, useEffect, useState } from 'react';

import OnboardingCardWithHeader from 'components/OnboardingCardWithHeader';
import { putSetting } from 'api/user';
import { useAppSelector } from 'hooks';

import { OnboardingStepNames, UserPropertyNames, getUserOnboardingStep } from 'store/user/selector';

import './style.css';

const JobTypeOptions = [
  {label: 'Founder/CEO', value: 'founder_ceo'},
  {label: 'Marketing', value: 'marketing'},
  {label: 'Sales', value: 'sales'},
  {label: 'Developer', value: 'developer'},
  {label: 'Design', value: 'design'},
  {label: 'Other', value: 'other'}
];

const CompanySizeOptions = [
  {label: 'Only me', value: '1'},
  {label: '2-5', value: '2_to_5'},
  {label: '6-10', value: '6_to_10'},
  {label: '11-25', value: '11_to_25'},
  {label: 'More than 25', value: '25_plus'}
];

const SourceOptions = [
  {label: 'Through a friend', value: 'friend'},
  {label: 'Referral', value: 'referral'},
  {label: 'LinkedIn', value: 'linked_in'},
  {label: 'Blog', value: 'blog'},
  {label: 'Other', value: 'other'}
];

const SignupSurvey = () => {
  const [jobType, setJobType] = useState<{label: string, value: string} | null>();
  const [companySize, setCompanySize] = useState<{label: string, value: string} | null>();
  const [source, setSource] = useState<{label: string, value: string} | null>();
  const onboardingStep = useAppSelector(getUserOnboardingStep);
  const history = useHistory();

  useEffect(() => {
    if (onboardingStep && onboardingStep !== OnboardingStepNames.SIGNUP_SURVEY) {
      return history.push('/' + onboardingStep);
    }
    if (!onboardingStep) {
      putSetting(UserPropertyNames.ONBOARDING_STEP, 'STRING', OnboardingStepNames.SIGNUP_SURVEY)
        .catch(() => console.error('Unable to save onboarding step'));
    }
  }, [onboardingStep]);

  const onSubmitSurvey = async (e: MouseEvent<HTMLButtonElement> & FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Promise.all([
      putSetting(UserPropertyNames.SIGNUP_JOB_TYPE, 'STRING', jobType?.value),
      putSetting(UserPropertyNames.SIGNUP_COMPANY_SIZE, 'STRING', companySize?.value),
      putSetting(UserPropertyNames.SIGNUP_DISCOVERY_SOURCE, 'STRING', source?.value),
    ])
      .finally(() => window.location.href = '/connect-google');
  };

  return (
    <OnboardingCardWithHeader headerText="Tell us about yourself">
      <form onSubmit={onSubmitSurvey}>
        <label className="login-input--label">What kind of work do you do?</label>
        <Select className="signup-survey-select" placeholder="Select work similar to yours" options={JobTypeOptions} value={jobType} onChange={setJobType} />
        <label className="login-input--label">How many people do you work with?</label>
        <Select className="signup-survey-select" placeholder="Select a range" options={CompanySizeOptions} value={companySize} onChange={setCompanySize} />
        <label className="login-input--label">How did you hear about Classify?</label>
        <Select className="signup-survey-select" placeholder="Please select one" options={SourceOptions} value={source} onChange={setSource} />
        <div className="signup-survey-footer"><Button appearance="primary" disabled={!jobType || !companySize || !source} type="submit" className="signup-survey-submit-button" onClick={onSubmitSurvey}>Continue</Button></div>
      </form>
    </OnboardingCardWithHeader>
  );
};

export default SignupSurvey;
