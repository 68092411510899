import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Button from 'components/Button';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import Sidebar from 'components/Sidebar';
import { notify } from 'store/notifications/slice';
import { useAppDispatch } from 'hooks';
import { resendVerificationEmail, verifyUser } from 'api/user';


const VerifyView = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const token = new URLSearchParams(location.search).get('verification_token');

  useEffect(() => {
    const verify = async () => {
      setLoading(true);
      if (!token) {
        setError('Could not verify from the email link.');
        return;
      }

      try {
        await Promise.all([
          verifyUser(token),
          await new Promise((res) => setTimeout(res, 1500)),
        ]);
        setError('');
        setSuccess(true);
        notify({
          message: 'Successfully verified your account 🎉',
          type: NotificationType.SUCCESS,
        })(dispatch);
      } catch (err) {
        console.warn('error when verifying user', err);
        setError('Could not verify. Needed to verify within 2 days of receiving the email.');
      } finally {
        setLoading(false);
      }
    };

    verify();
  }, [token, dispatch]);

  const resendVerify = async () => {
    setResendLoading(true);
    try {
      await resendVerificationEmail();
      notify({
        message: 'Re-sent verification email',
        type: NotificationType.INFO,
      })(dispatch);
    } catch (err) {
      console.log('error when requesting resend verification', err);
      notify({
        message: 'You need to be logged in to do that.',
        type: NotificationType.ERROR,
      })(dispatch);
    } finally {
      setResendLoading(false);
      setResendDisabled(true);
    }
  };


  return (
    <div>
      <Sidebar />
      <div className="verify-account--container sidebar-sibling-content--container">
        <h2 className="verify-account--headline">Verify your account</h2>
        <div className="verify-account--description">Verifying your account unlocks restricted access of Classify.</div>
        {loading && <div className="verify-account--loading">Verifying <LoadingDots /></div>}
        {error && <div className="verify-account--failed">{error}</div>}
        {success && <div className="verify-account--success">Your account has been verified!</div>}
        {error && <Button disabled={resendDisabled} loading={resendLoading} className="verify-account--btn" buttonType="subtle" onClick={resendVerify}>
          <FontAwesomeIcon icon={faPaperPlane} />
          Re-send email
        </Button>}
      </div>
    </div>
  );
};


export default VerifyView;