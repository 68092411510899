import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IProperty, IUser, IUserShallow } from './selector';


export interface UserState {
  user: IUser | null,
  usersById: {[userId: string]: IUserShallow},
  propertiesByName: {[propertyName: string]: IProperty} | null
  forceNonSuperuser: boolean,
}

export interface IGateWSPayload {
  gate: string,
  allowed: boolean;
}

const initialState: UserState = {
  user: null,
  usersById: {},
  forceNonSuperuser: false,
  propertiesByName: null
};


export const wsFetchUpdatedUserGate = createAsyncThunk(
  'user/wsFetchUpdatedUserGate',
  async ({gate, allowed}: IGateWSPayload) => {
    const updatedUserGate = { name: gate, value: allowed, namespace: 'GATES' };
    return updatedUserGate;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    receiveUsers: (state, action: PayloadAction<IUserShallow[]>) => {
      action.payload.forEach(user => {
        state.usersById[user.id] = user;
      });
    },
    receiveUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;

      action.payload.properties.forEach(property => {
        if (!state.propertiesByName) {
          state.propertiesByName = {};
        }

        state.propertiesByName[property.name] = property;
      });
    },

    toggleForceSuperuser: (state) => {
      state.forceNonSuperuser = !state.forceNonSuperuser;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(wsFetchUpdatedUserGate.fulfilled, (state, action) => {
      if (state.propertiesByName === null) {
        state.propertiesByName = {};
      }

      const { name } = action.payload;
      state.propertiesByName[name] = action.payload;
    });
  }
});

// Action creators are generated for each case reducer function
export const { receiveUser, receiveUsers, toggleForceSuperuser } = userSlice.actions;
export default userSlice.reducer;
