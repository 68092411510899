import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Avatar, Button, CaretDownIcon, Dialog, SelectMenu, TextInput } from 'evergreen-ui';
import { ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { faArrowLeft, faCheck, faEnvelope, faInfoCircle, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Activity } from 'components/DocumentSidebar/context/activity';
import DocumentCard from 'components/DocumentCard';
import EntityTabs from 'components/EntityTabs';
import { IConnector } from 'store/connectors/slice';
import LinkCard from 'components/LinkCard';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import { SidebarHistory } from 'contexts/SidebarHistory';
import SlackIcon from 'components/icons/Slack';
import { fetchDocumentsFromContact } from 'api/documents';
import { fetchLinksFromContact } from 'api/links';
import { getConnectorName } from 'utils/message_ctx';
import { getConnectorsList } from 'store/connectors/selectors';
import { getCurrentUserEmail } from 'store/user/selector';
import { notify } from 'store/notifications/slice';
import { patchContact } from 'api/contacts';
import { receiveContacts } from 'store/contacts/slice';
import { receiveDocumentsFromContact } from 'store/documents/slice';
import { IActivity, IDocument, getDocumentsByContactId, getDocumentsFromContact } from 'store/documents/selectors';
import { IContact, IContactHandle } from 'store/contacts/selectors';
import { ILink, receiveLinksFromContact } from 'store/links/slice';
import { TrackEventNames, tracker } from 'utils/tracking';
import { getContactName, getSlackWorkspaceIdFronHandle, titleCase } from 'utils/strings';
import { getEventById, getFocusEventId } from 'store/calendar/selectors';
import { getLinksByContactId, getLinksFromContact } from 'store/links/selectors';
import { pinDocument, pinLink } from 'store/calendar/slice';
import { pinDocumentToEvent, pinLinkToEvent } from 'api/calendar';
import { useActivitiesForContact, useActivityIntersection, useAppDispatch, useAppSelector, useContactByHandle } from 'hooks';

import './style.css';

const makeShowEmailOptions = (contact: IContact) => ([
  { label: 'All emails', value: 'All emails' },
  { label: `Between me & ${getContactName(contact)}`, value: 'Between us' }
]);

const EmailFilter = ({ onChangeEmailFilter, contact }: { onChangeEmailFilter: (type: 'All emails' | 'Between us') => void, contact: IContact }) => {
  const [selected, setSelected] = useState<undefined | string>();

  useEffect(() => {
    if (selected === 'All emails' || selected === 'Between us') {
      onChangeEmailFilter(selected);
    }
  }, [selected]);

  return (
    <SelectMenu
      height={66}
      hasFilter={false}
      hasTitle={false}
      options={makeShowEmailOptions(contact)}
      selected={selected}
      onSelect={({ value }) => setSelected(value as string)}
    >
      <Button iconAfter={CaretDownIcon} size='small' marginLeft='16px'>{selected || 'All emails'}</Button>
    </SelectMenu >
  );
};


const getSlackName = (connectors: IConnector[], handle: IContactHandle) => {
  const workspace = getSlackWorkspaceIdFronHandle(handle.handle);

  const connector = connectors.find((conn: IConnector) => conn.external_account_id.startsWith(`SLACK-${workspace}`));
  if (!connector) {
    return handle.name;
  } else {
    return `${handle.name} in ${getConnectorName(connector)}`;
  }
};


const Handle = ({ handle }: { handle: IContactHandle }) => {
  const connectors = useAppSelector(getConnectorsList);
  const icon = handle.source === 'slack' ? <SlackIcon className="view-contact-sidebar-handle--icon" /> : <FontAwesomeIcon icon={faEnvelope} className="view-contact-sidebar-handle--icon" />;

  const name = handle.source === 'slack' ? getSlackName(connectors, handle) : handle.handle;

  return <div className="view-contact-sidebar-handle--container">
    <div className="view-contact-sidebar-handle--source">{icon} {titleCase(handle.source)}</div>
    <div className="view-contact-sidebar-handle--name">{name}</div>
  </div>;
};


const SidebarLoadingSkeleton = () => {
  return <div>
    <div className="view-document-sidebar--header loading-skeleton">
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
    </div>
    <div className="view-document-sidebar--body loading-skeleton">
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image small" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image small" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image small" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
    </div>
  </div>;
};


const ContactSidebar = ({
  hidden,
  contact,
  onClose,
  onBack,
  showBackBtn,
}: { hidden: boolean, contact: IContact | null, onClose: () => void, onBack?: () => void, showBackBtn?: boolean }) => {
  const name = getContactName(contact);
  const eventId = useAppSelector(getFocusEventId) || '';
  const event = useAppSelector((s) => getEventById(s, eventId));
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [nameInput, setNameInput] = useState(name);
  const documentsByContactId = useAppSelector(getDocumentsByContactId);
  const documentsFromContact = useAppSelector((s) => getDocumentsFromContact(s, contact?.id || ''));
  const linksByContactId = useAppSelector(getLinksByContactId);
  const linksFromContact = useAppSelector((s) => getLinksFromContact(s, contact?.id || ''));
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [linksLoading, setLinksLoading] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const {
    isLoading: activitiesLoading,
    data: activitiesFromContact,
  } = useActivitiesForContact(contact?.id, ['NEW_FILE_GMAIL', 'NEW_MESSAGE_GMAIL']);
  const loggedUserEmail = useAppSelector(s => getCurrentUserEmail(s));
  const { contact: userAsContact, loading } = useContactByHandle(loggedUserEmail || '');
  const {
    isLoading: betweenEmailsLoading,
    data: betweenEmailActivities
  } = useActivityIntersection([userAsContact?.id, contact?.id], ['NEW_FILE_GMAIL', 'NEW_MESSAGE_GMAIL']);
  const [selectedActivities, setSelectedActivites] = useState<'All emails' | 'Between us'>('All emails');

  useEffect(() => {
    setNameInput(name);
    setIsEditing(false);
  }, [name]);

  useEffect(() => {
    const requestDocumentsFromContact = async () => {
      if (!contact) return;

      setDocumentsLoading(true);
      try {
        const { data } = await fetchDocumentsFromContact(contact.id);
        dispatch(receiveDocumentsFromContact({ contactId: contact.id, docs: data }));
      } catch (err) {
        console.warn('Failed to fetch documents from contact');
      }
      setDocumentsLoading(false);
    };

    if (contact && (!documentsByContactId || !documentsByContactId[contact.id])) {
      requestDocumentsFromContact();
    }

  }, [documentsByContactId, contact, dispatch]);

  useEffect(() => {
    const requestLinksFromContact = async () => {
      if (!contact) return;

      setLinksLoading(true);
      try {
        const { data } = await fetchLinksFromContact(contact.id);
        dispatch(receiveLinksFromContact({ contactId: contact.id, links: data }));
      } catch (err) {
        console.warn('Failed to fetch links from contact');
      }
      setLinksLoading(false);
    };
    if (contact && (!linksByContactId || !linksByContactId[contact.id])) {
      requestLinksFromContact();
    }
  }, [linksByContactId, contact, dispatch]);

  const save = async () => {
    if (!contact) return;

    try {
      const { data } = await patchContact(contact.id, nameInput);
      dispatch(receiveContacts({data: [data]}));
      setIsEditing(false);
    } catch (err) {
      notify({
        type: NotificationType.WARNING,
        message: 'Could not update contact, please try again.'
      })(dispatch);
    }
  };

  const maybeSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      save();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setIsEditing(false);
      inputRef.current?.blur();
    }
  };

  const onPinLink = async (e: MouseEvent | undefined, link: ILink) => {
    e?.stopPropagation();
    const eid = event?.entity_reference_id;
    if (!eid) return;

    try {
      const { data } = await pinLinkToEvent(eid, link.id);
      tracker.track(TrackEventNames.PCSL, { linkId: link.id, eventId: eid });
      dispatch(pinLink({
        referenceId: eid,
        linkId: link.id,
        pinId: data.pin_id,
        isPublicPin: data.is_shared_namespace,
        pinnedBy: data.pinned_by,
      }));
    } catch (err) {
      notify({
        type: NotificationType.WARNING,
        message: 'Failed to pin file, please try again'
      })(dispatch);
    }
  };

  const onPinDoc = async (e: MouseEvent | undefined, doc: IDocument) => {
    e?.stopPropagation();
    const eid = event?.entity_reference_id;
    if (!eid) return;

    try {
      const { data } = await pinDocumentToEvent(eid, doc.id);
      tracker.track(TrackEventNames.PCSD, { documentId: doc.id, eventId: eid });
      dispatch(pinDocument({
        referenceId: eid,
        docId: doc.id,
        pinId: data.pin_id,
        isPublicPin: data.is_shared_namespace,
        pinnedBy: data.pinned_by,
      }));
    } catch (err) {
      notify({
        type: NotificationType.WARNING,
        message: 'Failed to pin file, please try again'
      })(dispatch);
    }
  };

  const selectedVisibleActivities: IActivity[] = selectedActivities === 'All emails' ? (activitiesFromContact || []) : (betweenEmailActivities || []);
  const emailsLoading = activitiesLoading || loading || betweenEmailsLoading;

  const docsLen = documentsFromContact.length;
  const linksLen = linksFromContact.length;
  const activitiesLen = selectedVisibleActivities.length;
  const tabs = [
    {
      title: <div className="contact-sidebar-tab--title">Emails {emailsLoading ? <LoadingDots /> : `(${activitiesLen}${activitiesLen === 20 ? '+' : ''})`}</div>,
      key: 'emails',
      content: <>
        {emailsLoading && <div className="contact-sidebar-tab-content--container loading">Loading emails<LoadingDots /></div>}
        {!emailsLoading && userAsContact && contact && <EmailFilter contact={contact} onChangeEmailFilter={setSelectedActivites} />}
        {activitiesLen === 0 && !emailsLoading && <em className="view-contact-sidebar-documents--empty">There are no emails from this contact.</em>}
        {activitiesLen !== 0 && !emailsLoading && selectedVisibleActivities.map((act, idx) => <Activity defaultOpen={idx === 0} activity={act} key={act.id} />)}
      </>,
    },
    {
      title: <div className="contact-sidebar-tab--title">Files {documentsLoading ? <LoadingDots /> : `(${docsLen}${docsLen === 10 ? '+' : ''})`}</div>,
      key: 'files',
      content: <>
        {documentsLoading && <div className="contact-sidebar-tab-content--container loading">Loading files<LoadingDots /></div>}
        {documentsFromContact?.length === 0 && !documentsLoading && <em className="view-contact-sidebar-documents--empty">There are no files from this contact.</em>}
        {documentsFromContact?.length !== 0 && !documentsLoading &&
          <div className="contact-sidebar-tab-content--container">
            {documentsFromContact.map(docId =>
              <SidebarHistory.Consumer key={docId}>
                {({ onDocumentClick }) => <DocumentCard onPin={onPinDoc} documentId={docId} onClick={onDocumentClick} />}
              </SidebarHistory.Consumer>)}
          </div>
        }
      </>
    },
    {
      title: <div className="contact-sidebar-tab--title">Links {linksLoading ? <LoadingDots /> : `(${linksLen}${linksLen === 10 ? '+' : ''})`}</div>,
      key: 'links',
      content: <>
        {linksLoading && <div className="contact-sidebar-tab-content--container loading">Loading links<LoadingDots /></div>}
        {linksFromContact.length === 0 && !linksLoading && <em className="view-contact-sidebar-documents--empty">There are no links from this contact.</em>}
        {linksFromContact.length !== 0 && !linksLoading &&
          <div className="contact-sidebar-tab-content--container">
            {linksFromContact.map((linkId) =>
              <SidebarHistory.Consumer key={linkId}>
                {({ onLinkClick }) => <LinkCard linkId={linkId} onClick={onLinkClick} onPin={onPinLink} />}
              </SidebarHistory.Consumer>
            )}
          </div>
        }
      </>
    },
  ];

  const modalClose = () => {
    setIsEditing(false);
    setContactModalOpen(false);
  };

  return (
    <div className={cx('view-document-sidebar--container', { hidden })}>
      <div className="view-document-sidebar-content--container">
        {!contact && <SidebarLoadingSkeleton />}
        {contact && (
          <>
            <div className="view-document-sidebar--header">
              <div className="view-document-sidebar--title">
                {onBack && showBackBtn &&
                  <FontAwesomeIcon icon={faArrowLeft} onClick={onBack} />
                }
                <div>Contact details</div>
              </div>
              <div className="view-document-sidebar--close" onClick={onClose}>&times;</div>
            </div>
            <div className="view-document-sidebar--body">
              <div className="view-contact-sidebar--title">
                <Avatar name={name} size={40} shape="square" />
                <div className="view-contact-sidebar--name">{name}</div>
                <FontAwesomeIcon icon={faInfoCircle} className="view-contact-sidebar--info" onClick={() => setContactModalOpen(true)} />
              </div>
              <Dialog
                isShown={contactModalOpen}
                title="About"
                onCloseComplete={modalClose}
                hasFooter={false}
              >
                {!isEditing &&
                  <div className="view-contact-sidebar--modal">
                    <div className="view-contact-sidebar--name">{name}</div>
                    <FontAwesomeIcon icon={faPencilAlt} className="view-contact-sidebar--edit" onClick={() => setIsEditing(true)} />
                  </div>
                }
                {isEditing &&
                  <div className="view-contact-sidebar--modal">
                    <TextInput className="view-contact-sidebar-name--input" placeholder="Contact name" value={nameInput} onChange={(e: ChangeEvent<HTMLInputElement>) => setNameInput(e.target.value)} onKeyDown={maybeSubmit} ref={inputRef} />
                    <FontAwesomeIcon icon={faCheck} className="view-contact-sidebar--edit save" onClick={save} />
                    <FontAwesomeIcon icon={faTimes} className="view-contact-sidebar--edit cancel" onClick={() => setIsEditing(false)} />
                  </div>
                }
                <div className="view-contact-sidebar--handle">
                  {contact.handles.map(handle => <Handle key={`${handle.source}-${handle.handle}`} handle={handle} />)}
                </div>
              </Dialog>
              <EntityTabs tabs={tabs} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactSidebar;