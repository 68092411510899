import { Link } from 'react-router-dom';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { useHistory } from 'react-router';
import { Alert, Button, TextInput } from 'evergreen-ui';
import { ChangeEvent, FormEvent, MouseEvent, useState } from 'react';

import OnboardingCardWithHeader from 'components/OnboardingCardWithHeader';
import { resetPasswordViaEmail } from 'api/user';
import { validateEmail } from 'utils/strings';

const TOO_MANY_REQ_ERROR = {
  title: 'Email not sent',
  children: 'Please wait 5 minutes before requesting another password reset email.'
};

const ResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [error, setError] = useState<typeof TOO_MANY_REQ_ERROR | null>(null);

  const onSendPasswordResetEmail = (event: MouseEvent<HTMLButtonElement> & FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    resetPasswordViaEmail(email)
      .then(() => {
        history.push('/reset-pw-email');
      })
      .catch((err) => {
        const status = err?.response?.status || -1;
        if (status === 400) {
          setError(TOO_MANY_REQ_ERROR);
          return;
        }
        history.push('/reset-pw-email');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCheckEmailValidity = debounce((newEmail: string) => {
    setIsEmailValid(validateEmail(newEmail));
  }, 500);


  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    onCheckEmailValidity(e.target.value);
  };

  return (
    <OnboardingCardWithHeader headerText="Reset Your Password">
      {error && <Alert intent="warning" title={error.title} marginBottom={24}>{error.children}</Alert>}
      <form onSubmit={onSendPasswordResetEmail}>
        <label className="login-input--label" htmlFor="email">Email</label>
        <TextInput isInvalid={!isEmailValid} className={cx('login-input', { 'login-input--invalid': !isEmailValid })} width="100%" height="40px" type="email" id="email" value={email} onChange={onChangeEmail} />
        {!isEmailValid && <div className="login-input--error">Please enter a valid email address</div>}
        <Button appearance="primary" type="submit" className="login-submit-button" onClick={onSendPasswordResetEmail} disabled={!email || !isEmailValid} isLoading={loading}>Send password reset email</Button>
      </form>
      <div className="login-card-footer">
        <div>Don’t have an account? <Link className="login-link" to="/signup">Sign up</Link></div>
        <div className="pw-reset-login-link"><Link className="login-link" to="/login">Back to login</Link></div>
      </div>
    </OnboardingCardWithHeader>
  );
};

export default ResetPassword;
