import { INoteData } from 'store/notes/slice';
import { RootState } from 'store';
import { SearchType } from 'store/search/slice';
import { getLinkById } from 'store/links/selectors';
import { getNoteById } from 'store/notes/selectors';
import { IDocument, IMessageContext, getDocumentById } from 'store/documents/selectors';

interface ISearchDoc {
  id: string
  connector: {
    id: string,
    provider: string
  } | {id: string, provider: string}[]
  size: number // bytes
  filename: string
  mimetype: string
  star: boolean
  created_at: string
  updated_at: string
  message_context: IMessageContext[]
}

interface ISearchLink {
  // Existing props not in the schema
  connector_id: string
  data: {
    description: string
    image: string
    title: string
    type: string
    background_color: string;
    background_scaled_image: string;
  }
  namespace: string
  tags: [
    {
      timestamp: string
      tag_id: string
      namespace: string
      relation: string
      entity_id: string
      entity_type: string
      entity_subtype: string
      meta: object
      data: object
      updated_at: string
      updated_by: string
    }
  ]
  
  // Schema from https://github.com/ClassifyVenture/api/blob/main/app/search/mappings/link.json
  id: string
  created_at: string
  updated_at: string
  user_id: string
  sender: {
    contact_handle_id: string
    subject: string
    shared_at: string
  }
  connector: {
    id: string
    provider: string
  }
  star: boolean
  message_context: {
    sender: {
      name: string
      handle: string
      source: string
    }
  }
  content: string
  sent_at: string
  thread_id: string
  recipients: {
    name: string
    handle: string
    source: string
  }
  url: string
  normalized_url: string
  domain: string
  title: string
  description: string
  type: string
}

interface ISearchNote {
  id: string
  org_id: string
  title: string
  namespace: string
  content: string
  data: INoteData
  meta: object
  created_by: string
  created_at: string
  updated_by: string
  updated_at: string
  deleted_by?: string | null
  deleted_at?: string | null
  latest_activity: Array<unknown>,
  tags: Array<{
    timestamp: string
    tag_id: string
    namespace: string
    relation: string
    entity_id: string
    entity_type: string
    entity_subtype: string
    meta: object
    data: object
    updated_at: string
    updated_by: string
  }>
}

export interface IHighlight {
  [field: string]: string[];
}

export interface IDocSearchResultV2 {
  document: IDocument;
  highlight: IHighlight;
  score: number;
}

export interface IDocSearchResult {
  search_doc: ISearchDoc;
  highlight: IHighlight;
  score: number;
}

export interface ILinkSearchResult {
  link: ISearchLink;
  highlight: IHighlight;
  score: number;
}

export interface INoteSearchResult {
  note: ISearchNote;
  highlight: IHighlight;
  score: number;
}

const root = (state: RootState) => state.search;

export const activeSearchType = (state: RootState) => root(state).activeSearchType;

export const searchResultsDocuments = (state: RootState) => root(state).searchResultsDocuments;
export const searchResultsLinks = (state: RootState) => root(state).searchResultsLinks;
export const searchResultsNotes = (state: RootState) => root(state).searchResultsNotes;
export const searchResults = (state: RootState) => activeSearchType(state) === 'documents' ? searchResultsDocuments(state) : activeSearchType(state) === 'notes' ? searchResultsNotes(state) : searchResultsLinks(state);

export const getSearchDocumentById = (state: RootState, documentId: string) => searchResultsDocuments(state).find(d => d.search_doc.id === documentId);
export const getSearchLinkById = (state: RootState, linkId: string) => searchResultsLinks(state).find(d => d.link.id === linkId);
export const getSearchNoteById = (state: RootState, noteId: string) => searchResultsNotes(state).find(d => d.note.id === noteId);

const getSearchFacetsDocuments = (state: RootState) => root(state).searchFacetsDocuments;
const getSearchFacetsLinks = (state: RootState) => root(state).searchFacetsLinks;
const getSearchFacetsNotes = (state: RootState) => root(state).searchFacetsNotes;
export const getSearchFacets = (state: RootState) => activeSearchType(state) === 'documents' ? getSearchFacetsDocuments(state) : activeSearchType(state) === 'notes' ? getSearchFacetsNotes(state) : getSearchFacetsLinks(state);

const getSearchState = (state: RootState) => root(state).searchState;
export const getSearchFilters = (state: RootState) => getSearchState(state).filters;
const getSearchFilterForCategory = (state: RootState, category: string) => getSearchFilters(state)[category] || [];
export const isFilterSelected = (state: RootState, category: string, value: string) => getSearchFilterForCategory(state, category).includes(value);

const getSearchPaginationDocuments = (state: RootState) => root(state).searchPageDocuments;
const getSearchPaginationLinks = (state: RootState) => root(state).searchPageLinks;
const getSearchPaginationNotes = (state: RootState) => root(state).searchPageNotes;
const getTotalSearchResultsDocuments = (state: RootState) => getSearchPaginationDocuments(state)?.total_results;
const getTotalSearchResultsLinks = (state: RootState) => getSearchPaginationLinks(state)?.total_results;
const getTotalSearchResultsNotes = (state: RootState) => getSearchPaginationNotes(state)?.total_results;
export const getTotalSearchResults = (state: RootState) => activeSearchType(state) === 'documents' ? getTotalSearchResultsDocuments(state) : activeSearchType(state) === 'notes' ? getTotalSearchResultsNotes(state) : getTotalSearchResultsLinks(state);
export const getTotalSearchResultsForType = (state: RootState) => (type: SearchType) => (type === 'documents' ? getTotalSearchResultsDocuments(state) : type === 'notes' ? getTotalSearchResultsNotes(state) : getTotalSearchResultsLinks(state));

const getSidebar = (state: RootState) => root(state).sidebar;
export const isSearchSidebarOpen = (state: RootState) => getSidebar(state).isOpen;

const searchSidebarDocId = (state: RootState) => getSidebar(state).documentId;
export const isSearchBarSelectedDoc = (state: RootState, docId: string) => searchSidebarDocId(state) === docId;
export const getSearchSidebarDocument = (state: RootState) => getDocumentById(state, searchSidebarDocId(state) || '');
const searchSidebarLinkId = (state: RootState) => getSidebar(state).linkId;
export const isSearchBarSelectedLink = (state: RootState, linkId: string) => searchSidebarLinkId(state) === linkId;
export const getSearchSidebarLink = (state: RootState) => getLinkById(state, searchSidebarLinkId(state) || '');
const searchSidebarNoteId = (state: RootState) => getSidebar(state).noteId;
export const isSearchBarSelectedNote = (state: RootState, noteId: string) => searchSidebarNoteId(state) === noteId;
export const getSearchSidebarNote = (state: RootState) => getNoteById(state, searchSidebarNoteId(state) || '');