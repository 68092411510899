import { Activity } from 'components/DocumentSidebar/context/activity';
import { ITagEntry } from 'store/tags/selectors';
import { getActivityById } from 'store/activities/slice';
import { useAppSelector, useDocument } from 'hooks';

import './style.css';


interface ITaggedActivityProps {
  tagTimelineEntry: ITagEntry;
}


const TaggedActivity = ({
  tagTimelineEntry,
}: ITaggedActivityProps) => {
  const relation = tagTimelineEntry.tag_relation;
  const activity = useAppSelector(s => getActivityById(s, relation.entity_id));


  let docId = '';
  if (activity && 'document_id' in activity.data) {
    docId = activity.data.document_id;
  } else if (activity && 'document_ids' in activity.data) {
    docId = activity.data.document_ids[0];
  }
  useDocument(docId);


  return (
    <div className="tagged-document-activity--container activity">
      <Activity documentId={docId} activity={activity} showFileName trackingCtx='workstream activity' />
    </div>
  );
};


export default TaggedActivity;