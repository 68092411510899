import { AllActivity, IActivity } from 'store/documents/selectors';
import { AppDispatch, RootState } from 'store';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchActivityFromIntersection, fetchActivityFromUnion } from 'api/activities';

const initialState: ActivitiesState = {
  activitiesById: {},
  activityIdsByIntersection: {},
  activityIdsByUnion: {},
};

interface IAsyncActivityUnion {
  activityIds: string[] | null;
  lastReceived: null | Date;
  isFetching: boolean;
  fetchFailed: boolean;
}


interface IAsyncActivityIntersection {
  activityIds: string[] | null;
  lastReceived: null | Date;
  isFetching: boolean;
  fetchFailed: boolean;
}

export interface ActivitiesState {
  activitiesById: { [activityId: string]: AllActivity },
  activityIdsByIntersection: { [intersectionHash: string]: IAsyncActivityIntersection },
  activityIdsByUnion: { [intersectionHash: string]: IAsyncActivityUnion },
}


const root = (state: RootState) => state.activities;

export const getActivitiesById = (state: RootState) => root(state).activitiesById;
const activityIdsByIntersection = (state: RootState) => root(state).activityIdsByIntersection;
const activityIdsByUnion = (state: RootState) => root(state).activityIdsByUnion;
export const getActivityById = (state: RootState, activityId: string): AllActivity => (getActivitiesById(state) || {})[activityId];

const getAsyncActivityByIntersection = (state: RootState, intersectionHash: string) => activityIdsByIntersection(state)[intersectionHash];
export const isFetchingActivityIntersection = (state: RootState, intersectionHash: string) => (getAsyncActivityByIntersection(state, intersectionHash) || {}).isFetching;
export const fetchActivityIntersectionFailed = (state: RootState, intersectionHash: string) => (getAsyncActivityByIntersection(state, intersectionHash) || {}).fetchFailed || false;
export const getActivityIntersectionByHash = (state: RootState, intersectionHash: string) => (getAsyncActivityByIntersection(state, intersectionHash) || {}).activityIds || null;

const getAsyncActivityByUnion = (state: RootState, unionHash: string) => activityIdsByUnion(state)[unionHash];
export const isFetchingActivityUnion = (state: RootState, unionHash: string) => (getAsyncActivityByUnion(state, unionHash) || {}).isFetching;
export const fetchActivityUnionFailed = (state: RootState, unionHash: string) => (getAsyncActivityByUnion(state, unionHash) || {}).fetchFailed || false;
export const getActivityUnionByHash = (state: RootState, unionHash: string) => (getAsyncActivityByUnion(state, unionHash) || {}).activityIds || null;

export const fetchActivitiesByUnion = createAsyncThunk<AllActivity[] | undefined, { unionHash: string, contactIds: string[], activityTypes: string[]}, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'activities/fetchByUnion',
  async ({unionHash, contactIds, activityTypes}, {getState, dispatch}) => {
    if (!isFetchingActivityUnion(getState(), unionHash)) {
      dispatch(setFetchingUnion({ unionHash, isFetching: true}));
      const twoMonthsAgo = new Date();
      twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
      const {data} = await fetchActivityFromUnion(contactIds, [], twoMonthsAgo, activityTypes);
      return data;
    }
  }
);


export const fetchActivitiesByIntersection = createAsyncThunk<AllActivity[] | undefined, { intersectionHash: string, contactIds: string[], activityTypes?: string[]}, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'activities/fetchByIntersection',
  async ({intersectionHash, contactIds, activityTypes}, {getState, dispatch}) => {
    if (!isFetchingActivityIntersection(getState(), intersectionHash)) {
      dispatch(setFetchingIntersection({ intersectionHash, isFetching: true}));
      const twoMonthsAgo = new Date();
      twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
      const {data} = await fetchActivityFromIntersection(contactIds, [], twoMonthsAgo, activityTypes);
      return data;
    }
  }
);


export const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    setFetchingIntersection: (state, action: PayloadAction<{intersectionHash: string, isFetching: boolean}>) => {
      if (!state.activityIdsByIntersection[action.payload.intersectionHash]) {
        state.activityIdsByIntersection[action.payload.intersectionHash] = {
          activityIds: null,
          lastReceived: null,
          isFetching: action.payload.isFetching,
          fetchFailed: false,
        };
      } else {
        state.activityIdsByIntersection[action.payload.intersectionHash].isFetching = action.payload.isFetching;
      }
    },

    setFetchingUnion: (state, action: PayloadAction<{unionHash: string, isFetching: boolean}>) => {
      if (!state.activityIdsByUnion[action.payload.unionHash]) {
        state.activityIdsByUnion[action.payload.unionHash] = {
          activityIds: null,
          lastReceived: null,
          isFetching: action.payload.isFetching,
          fetchFailed: false,
        };
      } else {
        state.activityIdsByUnion[action.payload.unionHash].isFetching = action.payload.isFetching;
      }
    },

    receiveActivities: (state, action: PayloadAction<IActivity[]>) => {
      action.payload.forEach(activity => {
        state.activitiesById[activity.id] = activity;
      });
    },
  },


  extraReducers: (builder) => {
    builder.addCase(fetchActivitiesByIntersection.rejected, (state, action) => {
      const {intersectionHash} = action.meta.arg;

      if (!state.activityIdsByIntersection[intersectionHash]) {
        state.activityIdsByIntersection[intersectionHash] = {
          activityIds: null,
          isFetching: false,
          lastReceived: null,
          fetchFailed: true,
        };
      } else {
        state.activityIdsByIntersection[intersectionHash].fetchFailed = true;
      }
    });

    builder.addCase(fetchActivitiesByIntersection.fulfilled, (state, action) => {
      if (!action.payload) {
        // thunk short-circuited because another fetch is in flight
        return;
      }

      action.payload.forEach(act => {
        state.activitiesById[act.id] = act;
      });

      state.activityIdsByIntersection[action.meta.arg.intersectionHash] = {
        activityIds: action.payload.map(a => a.id),
        isFetching: false,
        lastReceived: new Date(),
        fetchFailed: false,
      };
    });

    builder.addCase(fetchActivitiesByUnion.rejected, (state, action) => {
      const {unionHash} = action.meta.arg;

      if (!state.activityIdsByUnion[unionHash]) {
        state.activityIdsByUnion[unionHash] = {
          activityIds: null,
          isFetching: false,
          lastReceived: null,
          fetchFailed: true,
        };
      } else {
        state.activityIdsByUnion[unionHash].fetchFailed = true;
      }
    });

    builder.addCase(fetchActivitiesByUnion.fulfilled, (state, action) => {
      if (!action.payload) {
        // thunk short-circuited because another fetch is in flight
        return;
      }

      action.payload.forEach(act => {
        state.activitiesById[act.id] = act;
      });

      state.activityIdsByUnion[action.meta.arg.unionHash] = {
        activityIds: action.payload.map(a => a.id),
        isFetching: false,
        lastReceived: new Date(),
        fetchFailed: false,
      };
    });

  },
});

export const { setFetchingUnion, setFetchingIntersection, receiveActivities } = activitiesSlice.actions;
export default activitiesSlice.reducer;