import { isEmpty } from 'ramda';
import throttle from 'lodash.throttle';
import React, { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { fetchActionItemById } from 'store/action_items/slice';
import { fetchDocumentById } from 'store/documents/slice';
import { IActionItem, getActionItem, getFetchActionItemFailed, getFetchingActionItem } from 'store/action_items/selectors';
import { IActivity, IDocument, fetchDocumentFailed, getDocumentById, isFetchingDocument } from 'store/documents/selectors';
import {
  ICalEventSuggestions,
  IMinimalCalendarEvent,
  fetchEventFailed,
  fetchEventPinsFailed,
  fetchEventSuggestionsFailed,
  getAllParentEventActionItemPins,
  getAllSortedEventNotePins,
  getEventById,
  getEventPinsData,
  getEventSuggestionsData,
  getPrivatePins,
  getPublicNotePins,
  getPublicPins,
  isFetchingEvent,
  isFetchingEventPins,
  isFetchingEventSuggestions,
} from 'store/calendar/selectors';
import { IContact, fetchContactByHandleFailed, fetchContactFailed, getContactByHandle, getContactById, isFetchingContact, isFetchingContactByHandle } from 'store/contacts/selectors';
import { ILink, fetchLinkById } from 'store/links/slice';
import { INamespacedPins, fetchCalendarEventById, fetchPinsByEvent, fetchSuggestionsByEvent } from 'store/calendar/slice';
import { INote, fetchNoteById } from 'store/notes/slice';
import { fetchActivitiesByIntersection, fetchActivitiesByUnion, fetchActivityIntersectionFailed, fetchActivityUnionFailed, getActivitiesById, getActivityIntersectionByHash, getActivityUnionByHash, isFetchingActivityIntersection, isFetchingActivityUnion } from 'store/activities/slice';
import { fetchContactByHandle, fetchContactById } from 'store/contacts/slice';
import { fetchLinkFailed, getLinkById, isFetchingLink } from 'store/links/selectors';
import { fetchingNoteFailed, getNoteById, isFetchingNote } from 'store/notes/selectors';
import { useActivitiesForContactQuery, useActivityIntersectionQuery } from 'api/activities';

import { store } from './store';
import type { AppDispatch, RootState } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


/**
 * Check if an element is in viewport
 * credit where it's due: https://stackoverflow.com/a/61719846/8015233

 * @param {number} offset - Number of pixels up to the observable element from the top
 * @param {number} throttleMilliseconds - Throttle observable listener, in ms
 */
export function useVisibility<Element extends HTMLElement>(
  offset = 0,
  throttleMilliseconds = 100
): [boolean, () => void, React.RefObject<Element>] {
  const [isVisible, setIsVisible] = useState(false);
  const currentElement = useRef<Element>(null);

  const onScroll = throttle(() => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }
    const top = currentElement.current.getBoundingClientRect().top;
    setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
  }, throttleMilliseconds);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });

  useEffect(() => {
    onScroll();
  }, [onScroll]);


  return [isVisible, onScroll, currentElement];
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useContact(contactId: string): { loading: boolean, contact: IContact | null, error: boolean } {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(s => isFetchingContact(s, contactId));
  const error = useAppSelector(s => fetchContactFailed(s, contactId));
  const contact = useAppSelector(s => getContactById(s, contactId));

  useEffect(() => {
    if (!loading && !contact && contactId && !error) {
      fetchContactById(contactId)(dispatch, store.getState, {});
    }

  }, [contact, loading, error]);

  return {
    loading: (loading || !contact) && !!contactId,
    error,
    contact: contact || null,
  };
}


export function useContactByHandle(handle: string, source = 'email'): { loading: boolean, contact: IContact | null, error: boolean } {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(s => isFetchingContactByHandle(s, source, handle));
  const error = useAppSelector(s => fetchContactByHandleFailed(s, source, handle));
  const contactByHandle = useAppSelector(s => getContactByHandle(s, source, handle));

  useEffect(() => {
    if (!loading && handle && !error && isEmpty(contactByHandle)) {
      fetchContactByHandle({ source, handle })(dispatch, store.getState, {});
    }

  }, [contactByHandle, loading, error, handle]);

  const contact = useAppSelector(s => getContactById(s, contactByHandle?.contactId || ''));

  return {
    loading: loading || !contactByHandle,
    error,
    contact,
  };
}


export function useLink(linkId: string): { loading: boolean, link: ILink | null, error: boolean } {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(s => isFetchingLink(s, linkId));
  const error = useAppSelector(s => fetchLinkFailed(s, linkId));
  const link = useAppSelector(s => getLinkById(s, linkId));

  useEffect(() => {
    if (!loading && !link && linkId && !error) {
      fetchLinkById(linkId)(dispatch, store.getState, {});
    }

  }, [link, loading, error]);

  return {
    loading: loading || !link,
    error,
    link: link || null,
  };
}


export function useDocument(documentId: string): { loading: boolean, document: IDocument | null, error: boolean } {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(s => isFetchingDocument(s, documentId));
  const error = useAppSelector(s => fetchDocumentFailed(s, documentId));
  const document = useAppSelector(s => getDocumentById(s, documentId));

  useEffect(() => {
    if (!loading && !document && documentId && !error) {
      fetchDocumentById(documentId)(dispatch, store.getState, {});
    }

  }, [document, loading, error]);

  return {
    loading: loading || !document,
    error,
    document: document || null,
  };
}


export function useEventActionItems(event?: IMinimalCalendarEvent | null) : { loading: boolean, actionItems: IActionItem[] | undefined, error: boolean } {
  const dispatch = useAppDispatch();
  const refId = event?.entity_reference_id || '';
  const fetching = useAppSelector(s => isFetchingEventPins(s, refId));
  const error = useAppSelector(s => fetchEventPinsFailed(s, refId));
  const pinsData = useAppSelector(s => getEventPinsData(s, refId));
  const parentActionItems = useAppSelector(s => getAllParentEventActionItemPins(s, refId));

  useEffect(() => {
    if (!fetching && !pinsData && event && !error) {
      fetchPinsByEvent(event)(dispatch, store.getState, {});
    }
  }, [pinsData, fetching, error]);

  const loading = fetching || !pinsData;

  return {
    loading,
    error,
    actionItems: loading ? undefined : parentActionItems,
  };
}


export function useEventNotes(event?: IMinimalCalendarEvent | null) : { loading: boolean, notePins: {note: string, pinned_at: string, pinned_by: string, pin_id: string}[] | undefined, error: boolean, publicNoteIds: string[] | undefined } {
  const dispatch = useAppDispatch();
  const refId = event?.entity_reference_id || '';
  const fetching = useAppSelector(s => isFetchingEventPins(s, refId));
  const error = useAppSelector(s => fetchEventPinsFailed(s, refId));
  const pinsData = useAppSelector(s => getEventPinsData(s, refId));
  const publicNoteIds = useAppSelector(s => getPublicNotePins(s, refId)).map(d => d.note);
  const notePins = useAppSelector(s => getAllSortedEventNotePins(s, refId));

  useEffect(() => {
    if (!fetching && !pinsData && event && !error) {
      fetchPinsByEvent(event)(dispatch, store.getState, {});
    }
  }, [pinsData, fetching, error]);

  const loading = fetching || !pinsData;

  return {
    loading,
    error,
    notePins: loading ? undefined : notePins,
    publicNoteIds: loading ? undefined : publicNoteIds,
  };
}


export function useActivityUnion(contactIds: string[], documentIds: string[], activityTypes: string[]): { loading: boolean, activities: IActivity[] | null, error: boolean } {
  const dispatch = useAppDispatch();
  // turn the arrays of IDs into something we can use to access the store in a selector
  const storeHash = contactIds
    .map(cid => `ctc.${cid.replace(/-/g, '')}`)
    .concat(documentIds.map(did => `doc.${did.replace(/-/g, '')}`))
    .sort()
    .join('_');
  const loading = useAppSelector(s => isFetchingActivityUnion(s, storeHash));
  const error = useAppSelector(s => fetchActivityUnionFailed(s, storeHash));
  const activityIds = useAppSelector(s => getActivityUnionByHash(s, storeHash));
  const activitiesById = useAppSelector(getActivitiesById);

  useEffect(() => {
    if (!loading && !activityIds && storeHash && !error) {
      fetchActivitiesByUnion({ unionHash: storeHash, contactIds, activityTypes })(dispatch, store.getState, {});
    }
  }, [activityIds, loading, error, storeHash]);

  return {
    loading: loading || (!activityIds && !!storeHash),
    error,
    activities: activityIds?.map(aId => activitiesById[aId]) || null,
  };
}


export function usePublicEventPins(event?: IMinimalCalendarEvent | null): { loading: boolean, pins: INamespacedPins | undefined, error: boolean } {
  const dispatch = useAppDispatch();
  const refId = event?.entity_reference_id || '';
  const loading = useAppSelector(s => isFetchingEventPins(s, refId));
  const error = useAppSelector(s => fetchEventPinsFailed(s, refId));
  const pins = useAppSelector(s => getPublicPins(s, refId));
  const pinsData = useAppSelector(s => getEventPinsData(s, refId));

  useEffect(() => {
    if (!loading && !pinsData && event && !error) {
      fetchPinsByEvent(event)(dispatch, store.getState, {});
    }
  }, [pinsData, loading, error]);

  return {
    loading: loading || !pinsData,
    error,
    pins,
  };
}


export function usePrivateEventPins(event?: IMinimalCalendarEvent | null): { loading: boolean, pins: INamespacedPins | undefined, error: boolean } {
  const dispatch = useAppDispatch();
  const refId = event?.entity_reference_id || '';
  const loading = useAppSelector(s => isFetchingEventPins(s, refId));
  const error = useAppSelector(s => fetchEventPinsFailed(s, refId));
  const pins = useAppSelector(s => getPrivatePins(s, refId));
  const pinsData = useAppSelector(s => getEventPinsData(s, refId));

  useEffect(() => {
    if (!loading && !pinsData && event && !error) {
      fetchPinsByEvent(event)(dispatch, store.getState, {});
    }
  }, [pinsData, loading, error]);

  return {
    loading: loading || !pinsData,
    error,
    pins,
  };
}


export function useEventSuggestions(eventId: string): { loading: boolean, suggestions: ICalEventSuggestions | null, error: boolean } {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(s => isFetchingEventSuggestions(s, eventId));
  const error = useAppSelector(s => fetchEventSuggestionsFailed(s, eventId));
  const suggestions = useAppSelector(s => getEventSuggestionsData(s, eventId));

  useEffect(() => {
    if (!loading && !suggestions && eventId && !error) {
      fetchSuggestionsByEvent(eventId)(dispatch, store.getState, {});
    }

  }, [suggestions, loading, error]);

  return {
    loading: loading || !suggestions,
    error,
    suggestions,
  };
}

// a small wrapper around useActivitiesForContactQuery to make sure we call it in a consistent manner
// even args in a different order can throw off the query
export function useActivitiesForContact(contactId?: string, activityTypes?: string[]): ReturnType<typeof useActivitiesForContactQuery> {
  return useActivitiesForContactQuery({contactId: contactId || '', types: activityTypes?.sort() || []}, {skip: !contactId});
}

// a small wrapper around useActivitiesForContactQuery to make sure we call it in a consistent manner
// even args in a different order can throw off the query
export function useActivityIntersection(contactIds: Array<string | undefined>, activityTypes?: string[], documentIds?: string[]):  ReturnType<typeof useActivityIntersectionQuery>{

  const skip = contactIds.some(cid => cid === undefined) ||
    (contactIds.length === 0 && (!documentIds || documentIds.length === 0));

  const afterDate = new Date();
  afterDate.setHours(0, 0, 0, 0);
  afterDate.setMonth(afterDate.getMonth() - 2);
  const after = afterDate.toISOString();
  return useActivityIntersectionQuery({
    contactIds: (contactIds.filter(cid => !!cid).sort() as string[]),
    documentIds: documentIds?.sort() || [],
    activityTypes: activityTypes?.sort(),
    after,
  }, {skip});
}


// TODO: deprecated, remove eventually
export function useActivityIntersection_OLD(contactIds?: string[], documentIds?: string[], activityTypes?: string[]): { loading: boolean, activities: IActivity[] | null, error: boolean } {
  const dispatch = useAppDispatch();
  // turn the arrays of IDs into something we can use to access the store in a selector
  const storeHash = (contactIds || [])
    .map(cid => `ctc.${cid.replace(/-/g, '')}`)
    .concat((documentIds || []).map(did => `doc.${did.replace(/-/g, '')}`))
    .sort()
    .join('_');
  const loading = useAppSelector(s => isFetchingActivityIntersection(s, storeHash));
  const error = useAppSelector(s => fetchActivityIntersectionFailed(s, storeHash));
  const activityIds = useAppSelector(s => getActivityIntersectionByHash(s, storeHash));
  const activitiesById = useAppSelector(getActivitiesById);
  const moreThanOneContact = (contactIds?.length || 0) > 1;

  useEffect(() => {
    if (!loading && !activityIds && storeHash && !error && moreThanOneContact) {
      fetchActivitiesByIntersection({ intersectionHash: storeHash, contactIds: contactIds || [], activityTypes })(dispatch, store.getState, {});
    }
  }, [activityIds, loading, error, storeHash]);

  return {
    loading: loading || (!activityIds && moreThanOneContact),
    error,
    activities: activityIds?.map(aId => activitiesById[aId]) || null,
  };
}


export function useCalendarEvent(calendarEventId: string): { loading: boolean, calendarEvent: IMinimalCalendarEvent | null, error: boolean } {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(s => isFetchingEvent(s, calendarEventId));
  const error = useAppSelector(s => fetchEventFailed(s, calendarEventId));
  const event = useAppSelector(s => getEventById(s, calendarEventId));

  useEffect(() => {
    if (!loading && !event && calendarEventId && !error) {
      fetchCalendarEventById(calendarEventId)(dispatch, store.getState, {});
    }

  }, [event, loading, error]);

  return {
    loading: loading || !event,
    error,
    calendarEvent: event || null,
  };
}


export function useNote(noteId: string): { loading: boolean, note: INote | null, error: boolean } {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(s => isFetchingNote(s, noteId));
  const error = useAppSelector(s => fetchingNoteFailed(s, noteId));
  const note = useAppSelector(s => getNoteById(s, noteId));

  useEffect(() => {
    if (!loading && !note && noteId && !error) {
      fetchNoteById(noteId)(dispatch, store.getState, {});
    }

  }, [note , loading, error]);

  return {
    loading: loading || !note,
    error,
    note: note || null,
  };
}


export function useActionItem(actionItemId: string): { loading: boolean, actionItem: IActionItem | null, error: boolean } {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(s => getFetchingActionItem(s, actionItemId));
  const error = useAppSelector(s => getFetchActionItemFailed(s, actionItemId));
  const actionItem = useAppSelector(s => getActionItem(s, actionItemId));

  useEffect(() => {
    if (!loading && !actionItem && actionItemId && !error) {
      fetchActionItemById(actionItemId)(dispatch, store.getState, {});
    }

  }, [actionItem, loading, error]);

  return {
    loading: loading || !actionItem,
    error,
    actionItem: actionItem || null,
  };
}