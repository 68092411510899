import { MouseEvent } from 'react';
import cx from 'classnames';

import { fetchDocumentLocation } from 'api/documents';
import { getExternalLinkFromActivities } from 'utils/message_ctx';
import { getIconForMimetype } from 'components/icons/files';
import { useDocument } from 'hooks';
import { TrackEventNames, tracker } from 'utils/tracking';

import './style.css';


interface IDocumentCardPillProps {
  documentId: string;
  className?: string,
  trackingCtx?: string,
}


const DocumentCardPill = ({
  documentId,
  className='',
  trackingCtx='',
}: IDocumentCardPillProps) => {
  const {document} = useDocument(documentId);
  const FileIcon = getIconForMimetype(document?.mimetype || '');
  const fallbackExternalLink = getExternalLinkFromActivities(document?.latest_activity || [], document?.mimetype);

  const open = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    tracker.track(TrackEventNames.ELC, {documentId: documentId, from: trackingCtx || 'document card pill'});
    try {
      const {data} = await fetchDocumentLocation(documentId);
      window.open(data.location || fallbackExternalLink);
    } catch (err) {
      const openNewTab = fallbackExternalLink?.startsWith('http');
      if (openNewTab) {
        window.open(fallbackExternalLink);
      } else {
        window.location.href = fallbackExternalLink as string;
      }
    }
  };

  if (!document) return null;

  return <div className={cx('document-card-pill--container', className)} onClick={open}>
    <div className='document-card-pill-file--container'>
      <div className="document-card-pill--icon">
        <FileIcon width={14} height={18} size="sm" />
      </div>
      <div className="document-card-pill-file--name">{document.filename}</div>
    </div>
  </div>;
};


export default DocumentCardPill;