const CompleteSignupIcon = () => (
  <svg width="348" height="264" viewBox="0 0 348 264" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_135_5886)">
      <path d="M244.827 203.371C214.069 203.371 186.274 182.079 179.364 150.901C172.966 122.038 191.295 93.3637 220.221 86.9806C225.743 85.7621 231.451 85.6407 237.02 86.6234C242.588 87.6061 247.909 89.6737 252.676 92.708C257.444 95.7422 261.567 99.6839 264.808 104.308C268.049 108.932 270.346 114.147 271.567 119.657C273.534 128.579 271.874 137.915 266.95 145.617C262.027 153.319 254.244 158.758 245.307 160.741C239.132 162.078 232.686 161.281 227.025 158.48C221.365 155.679 216.827 151.04 214.158 145.326C214.135 145.278 214.122 145.225 214.12 145.172C214.118 145.118 214.126 145.065 214.145 145.014C214.163 144.964 214.191 144.918 214.228 144.879C214.264 144.839 214.308 144.808 214.357 144.785C214.406 144.763 214.458 144.75 214.512 144.748C214.566 144.746 214.619 144.755 214.669 144.773C214.72 144.792 214.766 144.821 214.805 144.857C214.844 144.894 214.876 144.938 214.898 144.986C217.489 150.532 221.893 155.035 227.387 157.753C232.881 160.472 239.138 161.245 245.131 159.947C253.857 158.011 261.457 152.7 266.264 145.18C271.071 137.66 272.692 128.544 270.771 119.832C268.352 108.916 261.685 99.4055 252.238 93.3935C242.791 87.3814 231.338 85.3602 220.397 87.7745C206.703 90.8126 194.776 99.1466 187.233 110.948C179.689 122.75 177.145 137.055 180.159 150.726C188.046 186.312 223.477 208.861 259.142 200.991C270.925 198.399 281.998 193.268 291.585 185.958C301.173 178.648 309.043 169.335 314.645 158.672C314.67 158.625 314.704 158.583 314.745 158.548C314.786 158.514 314.834 158.488 314.885 158.472C314.936 158.456 314.99 158.451 315.043 158.455C315.096 158.46 315.148 158.476 315.196 158.5C315.243 158.525 315.285 158.559 315.32 158.6C315.354 158.641 315.38 158.689 315.395 158.74C315.411 158.791 315.417 158.845 315.412 158.898C315.407 158.951 315.391 159.003 315.366 159.05C309.709 169.818 301.762 179.223 292.08 186.604C282.399 193.986 271.217 199.167 259.318 201.784C254.56 202.837 249.701 203.369 244.827 203.371Z" fill="#E6E6E6" />
      <path d="M324.092 111.862C323.992 111.862 323.895 111.825 323.82 111.758C323.745 111.691 323.698 111.598 323.688 111.499C323.356 108.426 322.855 105.374 322.186 102.356C311.547 54.3523 268.127 20.202 218.943 21.2001C218.835 21.2023 218.73 21.1615 218.652 21.0868C218.575 21.0121 218.53 20.9096 218.527 20.8018C218.525 20.694 218.566 20.5897 218.641 20.512C218.716 20.4342 218.819 20.3893 218.927 20.3871C243.068 19.9398 266.62 27.8412 285.587 42.7502C304.554 57.6592 317.766 78.6573 322.982 102.181C323.657 105.228 324.163 108.31 324.498 111.412C324.503 111.465 324.499 111.519 324.484 111.57C324.468 111.621 324.443 111.669 324.41 111.711C324.376 111.752 324.335 111.787 324.288 111.812C324.241 111.838 324.189 111.854 324.136 111.859C324.122 111.861 324.107 111.862 324.092 111.862Z" fill="#E6E6E6" />
      <path d="M99.4218 178.527C99.3291 178.527 99.2392 178.495 99.167 178.437C99.0947 178.38 99.0445 178.299 99.0245 178.208C93.8971 155.154 95.1509 131.142 102.652 108.744C110.152 86.3462 123.618 66.4059 141.605 51.0585C141.646 51.0233 141.693 50.9966 141.744 50.9797C141.795 50.9628 141.849 50.9561 141.902 50.9601C141.956 50.964 142.008 50.9785 142.056 51.0027C142.104 51.0269 142.147 51.0602 142.182 51.1009C142.217 51.1416 142.243 51.1888 142.26 51.2398C142.276 51.2907 142.283 51.3445 142.278 51.3979C142.274 51.4513 142.259 51.5034 142.235 51.5511C142.21 51.5987 142.177 51.6411 142.136 51.6757C124.26 66.9274 110.879 86.7434 103.425 109.002C95.9707 131.26 94.7246 155.122 99.8199 178.033C99.8332 178.092 99.8328 178.154 99.819 178.213C99.8051 178.273 99.7781 178.328 99.7398 178.375C99.7016 178.423 99.6532 178.461 99.5981 178.487C99.5431 178.514 99.4828 178.527 99.4218 178.527Z" fill="#E6E6E6" />
      <path d="M212.01 65.445H148.459C146.623 65.443 144.862 64.7143 143.564 63.4188C142.266 62.1233 141.535 60.3668 141.533 58.5347V6.91034C141.535 5.07824 142.266 3.32176 143.564 2.02627C144.862 0.730773 146.623 0.00206248 148.459 0H212.01C213.846 0.00206248 215.607 0.730773 216.905 2.02627C218.203 3.32176 218.934 5.07824 218.936 6.91034V58.5347C218.934 60.3668 218.203 62.1233 216.905 63.4188C215.607 64.7143 213.846 65.443 212.01 65.445ZM148.459 0.812982C146.839 0.814811 145.285 1.4578 144.14 2.60088C142.994 3.74396 142.35 5.29378 142.348 6.91034V58.5347C142.35 60.1513 142.994 61.7011 144.14 62.8442C145.285 63.9872 146.839 64.6302 148.459 64.6321H212.01C213.63 64.6302 215.184 63.9872 216.329 62.8442C217.475 61.7011 218.119 60.1513 218.121 58.5347V6.91034C218.119 5.29378 217.475 3.74396 216.329 2.60088C215.184 1.4578 213.63 0.814811 212.01 0.812982H148.459Z" fill="#3F3D56" />
      <path d="M204.786 15.5038H172.712C172.43 15.5095 172.157 15.4082 171.947 15.2204C171.737 15.0325 171.607 14.7722 171.582 14.492C171.574 14.3469 171.596 14.2018 171.646 14.0655C171.696 13.9292 171.774 13.8045 171.874 13.699C171.974 13.5936 172.095 13.5096 172.228 13.4522C172.362 13.3948 172.506 13.3652 172.652 13.3652H205.301C206.376 14.3083 205.721 15.5038 204.786 15.5038L204.786 15.5038Z" fill="#CCCCCC" />
      <path d="M204.786 21.1171H172.712C172.43 21.1228 172.157 21.0215 171.947 20.8336C171.737 20.6458 171.607 20.3855 171.582 20.1053C171.574 19.9602 171.596 19.8151 171.646 19.6788C171.696 19.5424 171.774 19.4177 171.874 19.3123C171.974 19.2069 172.095 19.1229 172.228 19.0655C172.362 19.0081 172.506 18.9785 172.652 18.9785H205.301C206.376 19.9216 205.721 21.1171 204.786 21.1171H204.786Z" fill="#CCCCCC" />
      <path d="M165.781 24.3125H155.993C155.673 24.3122 155.367 24.1854 155.141 23.9599C154.915 23.7344 154.787 23.4286 154.787 23.1097V11.5837C154.787 11.2648 154.915 10.959 155.141 10.7335C155.367 10.508 155.673 10.3812 155.993 10.3809H165.781C166.101 10.3813 166.407 10.5082 166.633 10.7336C166.859 10.9591 166.986 11.2648 166.987 11.5837V23.1097C166.986 23.4286 166.859 23.7344 166.633 23.9599C166.407 24.1854 166.101 24.3122 165.781 24.3125Z" fill="#7649FD" />
      <path d="M204.694 32.6112H155.744C155.462 32.6169 155.188 32.5156 154.978 32.3278C154.768 32.14 154.638 31.8796 154.613 31.5994C154.605 31.4544 154.627 31.3093 154.677 31.1729C154.727 31.0366 154.805 30.9119 154.905 30.8065C155.005 30.701 155.126 30.617 155.26 30.5596C155.393 30.5023 155.537 30.4727 155.683 30.4727H205.209C206.285 31.4157 205.629 32.6112 204.694 32.6112V32.6112Z" fill="#CCCCCC" />
      <path d="M204.694 38.2264H155.744C155.462 38.2321 155.188 38.1309 154.978 37.943C154.768 37.7552 154.638 37.4949 154.613 37.2147C154.605 37.0696 154.627 36.9245 154.677 36.7882C154.727 36.6518 154.805 36.5271 154.905 36.4217C155.005 36.3162 155.126 36.2323 155.26 36.1749C155.393 36.1175 155.537 36.0879 155.683 36.0879H205.209C206.285 37.031 205.629 38.2264 204.694 38.2264V38.2264Z" fill="#CCCCCC" />
      <path d="M204.694 43.8378H155.744C155.462 43.8435 155.188 43.7422 154.978 43.5544C154.768 43.3665 154.638 43.1062 154.613 42.826C154.605 42.6809 154.627 42.5358 154.677 42.3995C154.727 42.2631 154.805 42.1384 154.905 42.033C155.005 41.9276 155.126 41.8436 155.26 41.7862C155.393 41.7288 155.537 41.6992 155.683 41.6992H205.209C206.285 42.6423 205.629 43.8378 204.694 43.8378V43.8378Z" fill="#CCCCCC" />
      <path d="M204.694 49.453H155.744C155.462 49.4587 155.188 49.3574 154.978 49.1696C154.768 48.9818 154.638 48.7214 154.613 48.4412C154.605 48.2962 154.627 48.151 154.677 48.0147C154.727 47.8784 154.805 47.7537 154.905 47.6482C155.005 47.5428 155.126 47.4588 155.26 47.4014C155.393 47.3441 155.537 47.3145 155.683 47.3145H205.209C206.285 48.2575 205.629 49.453 204.694 49.453V49.453Z" fill="#CCCCCC" />
      <path d="M204.694 55.0643H155.744C155.462 55.07 155.188 54.9687 154.978 54.7809C154.768 54.5931 154.638 54.3328 154.613 54.0525C154.605 53.9075 154.627 53.7624 154.677 53.626C154.727 53.4897 154.805 53.365 154.905 53.2596C155.005 53.1541 155.126 53.0702 155.26 53.0128C155.393 52.9554 155.537 52.9258 155.683 52.9258H205.209C206.285 53.8688 205.629 55.0643 204.694 55.0643V55.0643Z" fill="#CCCCCC" />
      <path d="M342.575 159.268H296.541C295.103 159.266 293.724 158.695 292.708 157.681C291.691 156.666 291.119 155.29 291.117 153.856V116.461C291.119 115.026 291.691 113.65 292.708 112.636C293.724 111.621 295.103 111.05 296.541 111.049H342.575C344.013 111.05 345.392 111.621 346.409 112.636C347.426 113.65 347.998 115.026 347.999 116.461V153.856C347.998 155.29 347.426 156.666 346.409 157.681C345.392 158.695 344.013 159.266 342.575 159.268ZM296.541 111.862C295.319 111.863 294.148 112.348 293.283 113.21C292.419 114.073 291.933 115.242 291.932 116.461V153.856C291.933 155.075 292.419 156.244 293.283 157.106C294.148 157.968 295.319 158.453 296.541 158.455H342.575C343.797 158.453 344.969 157.968 345.833 157.106C346.697 156.244 347.183 155.075 347.184 153.856V116.461C347.183 115.242 346.697 114.073 345.833 113.21C344.969 112.348 343.797 111.863 342.575 111.862L296.541 111.862Z" fill="#3F3D56" />
      <path d="M337.344 122.686H314.111C313.907 122.69 313.709 122.617 313.557 122.481C313.405 122.344 313.31 122.156 313.292 121.953C313.286 121.848 313.302 121.743 313.339 121.644C313.375 121.545 313.431 121.455 313.504 121.379C313.576 121.302 313.664 121.241 313.761 121.2C313.857 121.158 313.962 121.137 314.067 121.137H337.717C338.496 121.82 338.021 122.686 337.344 122.686L337.344 122.686Z" fill="#CCCCCC" />
      <path d="M337.344 126.752H314.111C313.907 126.756 313.709 126.683 313.557 126.547C313.405 126.411 313.31 126.222 313.292 126.019C313.286 125.914 313.302 125.809 313.339 125.71C313.375 125.612 313.431 125.521 313.504 125.445C313.576 125.369 313.664 125.308 313.761 125.266C313.857 125.225 313.962 125.203 314.067 125.203H337.717C338.496 125.886 338.021 126.752 337.344 126.752L337.344 126.752Z" fill="#CCCCCC" />
      <path d="M309.087 129.066H301.996C301.765 129.066 301.543 128.974 301.379 128.811C301.215 128.647 301.123 128.426 301.123 128.195V119.846C301.123 119.615 301.215 119.393 301.379 119.23C301.543 119.067 301.765 118.975 301.996 118.975H309.087C309.318 118.975 309.54 119.067 309.704 119.23C309.867 119.393 309.96 119.615 309.96 119.846V128.195C309.96 128.426 309.868 128.647 309.704 128.811C309.54 128.974 309.318 129.066 309.087 129.066Z" fill="#7649FD" />
      <path d="M337.276 135.076H301.818C301.614 135.081 301.416 135.007 301.264 134.871C301.112 134.735 301.017 134.547 300.999 134.344C300.994 134.238 301.009 134.133 301.046 134.035C301.082 133.936 301.138 133.846 301.211 133.769C301.283 133.693 301.371 133.632 301.468 133.59C301.565 133.549 301.669 133.527 301.774 133.527H337.649C338.428 134.21 337.953 135.076 337.276 135.076V135.076Z" fill="#CCCCCC" />
      <path d="M337.276 139.145H301.818C301.614 139.149 301.416 139.076 301.264 138.939C301.112 138.803 301.017 138.615 300.999 138.412C300.994 138.307 301.009 138.202 301.046 138.103C301.082 138.004 301.138 137.914 301.211 137.837C301.283 137.761 301.371 137.7 301.468 137.659C301.565 137.617 301.669 137.596 301.774 137.596H337.649C338.428 138.279 337.953 139.145 337.276 139.145V139.145Z" fill="#CCCCCC" />
      <path d="M337.276 143.209H301.818C301.614 143.213 301.416 143.14 301.264 143.004C301.112 142.868 301.017 142.679 300.999 142.476C300.994 142.371 301.009 142.266 301.046 142.167C301.082 142.069 301.138 141.978 301.211 141.902C301.283 141.826 301.371 141.765 301.468 141.723C301.565 141.682 301.669 141.66 301.774 141.66H337.649C338.428 142.343 337.953 143.209 337.276 143.209V143.209Z" fill="#CCCCCC" />
      <path d="M337.276 147.276H301.818C301.614 147.28 301.416 147.206 301.264 147.07C301.112 146.934 301.017 146.746 300.999 146.543C300.994 146.438 301.009 146.333 301.046 146.234C301.082 146.135 301.138 146.045 301.211 145.968C301.283 145.892 301.371 145.831 301.468 145.79C301.565 145.748 301.669 145.727 301.774 145.727H337.649C338.428 146.41 337.953 147.276 337.276 147.276V147.276Z" fill="#CCCCCC" />
      <path d="M337.276 151.342H301.818C301.614 151.346 301.416 151.273 301.264 151.137C301.112 151.001 301.017 150.812 300.999 150.609C300.994 150.504 301.009 150.399 301.046 150.3C301.082 150.201 301.138 150.111 301.211 150.035C301.283 149.958 301.371 149.898 301.468 149.856C301.565 149.814 301.669 149.793 301.774 149.793H337.649C338.428 150.476 337.953 151.342 337.276 151.342V151.342Z" fill="#CCCCCC" />
      <path d="M229.455 145.714H200.27C199.318 145.713 198.406 145.335 197.734 144.664C197.061 143.993 196.683 143.083 196.682 142.134V118.426C196.683 117.477 197.061 116.567 197.734 115.895C198.406 115.224 199.318 114.847 200.27 114.846H229.455C230.406 114.847 231.318 115.224 231.991 115.895C232.663 116.567 233.042 117.477 233.043 118.426V142.134C233.042 143.083 232.663 143.993 231.991 144.664C231.318 145.335 230.406 145.713 229.455 145.714ZM200.27 115.659C199.534 115.66 198.829 115.951 198.31 116.47C197.79 116.989 197.497 117.692 197.496 118.426V142.134C197.497 142.867 197.79 143.571 198.31 144.089C198.829 144.608 199.534 144.9 200.27 144.901H229.455C230.19 144.9 230.895 144.608 231.415 144.089C231.935 143.571 232.227 142.867 232.228 142.134V118.426C232.227 117.692 231.935 116.989 231.415 116.47C230.895 115.951 230.19 115.66 229.455 115.659H200.27Z" fill="#3F3D56" />
      <path d="M224.842 132.821H204.88C204.394 132.821 203.928 132.628 203.584 132.285C203.24 131.942 203.047 131.476 203.047 130.991C203.047 130.506 203.24 130.041 203.584 129.698C203.928 129.355 204.394 129.162 204.88 129.162H224.842C225.328 129.162 225.794 129.355 226.138 129.698C226.482 130.041 226.675 130.506 226.675 130.991C226.675 131.476 226.482 131.942 226.138 132.285C225.794 132.628 225.328 132.821 224.842 132.821Z" fill="#7649FD" />
      <path d="M220.768 125.301H208.954C208.468 125.301 208.002 125.108 207.658 124.765C207.314 124.422 207.121 123.957 207.121 123.472C207.121 122.987 207.314 122.521 207.658 122.178C208.002 121.835 208.468 121.643 208.954 121.643H220.768C221.255 121.643 221.721 121.835 222.065 122.178C222.408 122.521 222.602 122.987 222.602 123.472C222.602 123.957 222.408 124.422 222.065 124.765C221.721 125.108 221.255 125.301 220.768 125.301Z" fill="#7649FD" />
      <path d="M224.842 138.918H204.88C204.394 138.918 203.928 138.725 203.584 138.382C203.24 138.039 203.047 137.574 203.047 137.089C203.047 136.604 203.24 136.139 203.584 135.796C203.928 135.452 204.394 135.26 204.88 135.26H224.842C225.328 135.26 225.794 135.452 226.138 135.796C226.482 136.139 226.675 136.604 226.675 137.089C226.675 137.574 226.482 138.039 226.138 138.382C225.794 138.725 225.328 138.918 224.842 138.918Z" fill="#7649FD" />
      <path d="M51.4916 192.514C51.2098 191.987 51.0473 191.405 51.0155 190.808C50.9838 190.211 51.0835 189.615 51.3077 189.061C51.5319 188.506 51.8752 188.008 52.3133 187.601C52.7514 187.193 53.2737 186.887 53.8435 186.702L54.1436 172.18L60.3718 176.464L59.1319 189.599C59.3786 190.574 59.2581 191.606 58.7933 192.499C58.3284 193.392 57.5515 194.083 56.6098 194.443C55.668 194.802 54.6268 194.804 53.6835 194.449C52.7402 194.094 51.9603 193.405 51.4916 192.514H51.4916Z" fill="#A0616A" />
      <path d="M58.3776 136.595L52.2812 182.33L59.6202 182.607L65.7536 134.595L64.2816 133.729C63.7215 133.399 63.0884 133.213 62.4387 133.187C61.789 133.161 61.1429 133.296 60.5582 133.579C59.9735 133.863 59.4683 134.287 59.0878 134.813C58.7073 135.339 58.4633 135.951 58.3776 136.595Z" fill="#7649FD" />
      <path d="M77.8372 259.16L82.8313 259.16L85.2075 239.938L77.8359 239.938L77.8372 259.16Z" fill="#A0616A" />
      <path d="M92.4629 263.785L76.7662 263.786L76.7659 257.734L86.398 257.734C88.0064 257.734 89.549 258.371 90.6864 259.506C91.8238 260.641 92.4628 262.18 92.4629 263.785Z" fill="#2F2E41" />
      <path d="M36.2812 253.203L40.8134 255.297L51.0631 238.85L44.3739 235.76L36.2812 253.203Z" fill="#A0616A" />
      <path d="M47.6074 263.533L33.363 256.953L35.9108 251.462L44.6517 255.499C46.1113 256.174 47.2427 257.399 47.797 258.906C48.3513 260.412 48.2831 262.077 47.6074 263.533Z" fill="#2F2E41" />
      <path d="M83.0704 255.097C82.9973 255.097 82.9242 255.093 82.8516 255.084L78.9002 254.62C78.4846 254.57 78.0986 254.379 77.8059 254.081C77.5131 253.782 77.3309 253.393 77.2894 252.977L72.5461 203.328C72.5337 203.198 72.48 203.076 72.3929 202.979C72.3058 202.882 72.1898 202.815 72.062 202.789C71.9341 202.762 71.8012 202.777 71.6826 202.832C71.5639 202.886 71.4659 202.977 71.4029 203.091L45.0123 250.8C44.8138 251.161 44.4987 251.444 44.1181 251.604C43.7375 251.763 43.314 251.789 42.9166 251.678L38.4777 250.448C38.2381 250.381 38.0145 250.267 37.8205 250.112C37.6266 249.957 37.4663 249.764 37.3495 249.545C37.2327 249.326 37.1618 249.085 37.1411 248.838C37.1204 248.591 37.1504 248.342 37.2292 248.107C41.0951 236.565 60.4636 178.719 62.1196 173.394C64.0983 167.033 78.869 164.526 80.1417 164.321L80.8728 163.592L81.0135 163.781C87.5331 172.538 90.6878 184.136 89.8967 196.439L89.8963 196.444L84.892 253.427C84.8519 253.882 84.6429 254.306 84.3058 254.615C83.9688 254.924 83.5281 255.096 83.0704 255.097Z" fill="#2F2E41" />
      <path d="M62.2538 126.179C67.7798 126.179 72.2595 121.709 72.2595 116.195C72.2595 110.681 67.7798 106.211 62.2538 106.211C56.7278 106.211 52.248 110.681 52.248 116.195C52.248 121.709 56.7278 126.179 62.2538 126.179Z" fill="#A0616A" />
      <path d="M62.766 171.866L56.829 140.988C56.3322 138.38 56.8476 135.681 58.2707 133.437C59.6938 131.194 61.9183 129.574 64.4936 128.906C66.0851 128.493 67.7594 128.521 69.3363 128.986C70.9131 129.452 72.3329 130.338 73.4428 131.549C76.4733 134.85 80.3227 139.653 81.4521 143.794C82.4081 147.308 85.0985 160.478 85.1256 160.611L85.147 160.716L85.0726 160.793C85.0584 160.807 83.6169 162.294 81.3732 164.149C80.0566 165.238 78.7109 166.248 77.3734 167.153C73.618 169.683 63.4004 171.74 62.9672 171.826L62.766 171.866Z" fill="#7649FD" />
      <path opacity="0.2" d="M77.2574 166.983C78.7483 165.975 80.101 164.934 81.2415 163.991C80.8302 157.918 71.8636 145.203 68.2298 145.203C68.2298 145.203 67.3703 164.8 77.2574 166.983Z" fill="black" />
      <path d="M95.7146 182.933C95.1515 182.729 94.6401 182.405 94.2163 181.982C93.7926 181.56 93.4667 181.05 93.2617 180.489C93.0568 179.927 92.9777 179.328 93.0301 178.733C93.0824 178.137 93.2649 177.561 93.5648 177.043L84.0488 166.05L91.5505 165.076L99.4369 175.665C100.274 176.225 100.877 177.072 101.13 178.046C101.383 179.02 101.27 180.053 100.811 180.948C100.352 181.844 99.58 182.541 98.6407 182.907C97.7014 183.273 96.6602 183.282 95.7146 182.933H95.7146Z" fill="#A0616A" />
      <path d="M69.1096 129.565C69.524 129.573 69.9368 129.62 70.3424 129.706C71.1636 129.893 71.9254 130.281 72.5594 130.834C73.1935 131.387 73.68 132.089 73.9754 132.876L85.7397 157.399L98.3685 170.813L92.0541 175.895L72.0924 158.009L64.1175 136.351C63.8629 135.568 63.7983 134.737 63.9293 133.925C64.0602 133.113 64.3829 132.344 64.8706 131.681C65.3583 131.018 65.9971 130.48 66.7341 130.112C67.4712 129.744 68.2855 129.557 69.1096 129.565Z" fill="#7649FD" />
      <path d="M61.6634 126.086C60.2393 123.96 59.1155 121.01 60.6834 118.987C62.231 116.989 65.2836 117.283 67.6539 116.4C70.9553 115.168 72.87 111.298 72.2729 107.832C71.6757 104.367 68.8874 101.493 65.5657 100.318C62.244 99.1424 58.4983 99.5247 55.2454 100.878C51.2412 102.545 47.8062 105.769 46.3658 109.852C44.9254 113.936 45.6954 118.839 48.6835 121.978C51.8871 125.343 56.9149 126.24 61.566 126.185" fill="#2F2E41" />
      <path d="M45.9514 114.315C45.5438 116.588 43.0452 117.879 40.7488 118.163C38.4525 118.446 36.0771 118.082 33.8416 118.678C29.9005 119.729 27.3011 123.511 25.8704 127.323C24.4397 131.134 23.8052 135.248 21.9463 138.872C20.0874 142.495 16.4831 145.689 12.418 145.35C13.2143 147.351 14.6099 149.057 16.4143 150.237C18.2186 151.417 20.3439 152.013 22.5001 151.943C24.6563 151.874 26.7384 151.142 28.4624 149.848C30.1863 148.553 31.4681 146.76 32.1329 144.712C33.6263 140.112 31.912 134.626 34.5236 130.553C35.9688 128.299 38.4642 126.973 40.8704 125.792C43.2766 124.61 45.802 123.398 47.4153 121.26C49.0285 119.123 49.3508 115.747 47.3628 113.951" fill="#2F2E41" />
      <path d="M0.48366 263.999H130.966C131.094 263.999 131.217 263.948 131.308 263.857C131.398 263.766 131.449 263.643 131.449 263.515C131.449 263.387 131.398 263.264 131.308 263.174C131.217 263.083 131.094 263.032 130.966 263.031H0.48366C0.355257 263.032 0.232243 263.083 0.141579 263.174C0.0509155 263.264 0 263.387 0 263.515C0 263.643 0.0509155 263.766 0.141579 263.857C0.232243 263.948 0.355257 263.999 0.48366 263.999Z" fill="#3F3D56" />
      <path d="M107.569 102.919C110.718 102.919 113.272 100.371 113.272 97.228C113.272 94.085 110.718 91.5371 107.569 91.5371C104.419 91.5371 101.865 94.085 101.865 97.228C101.865 100.371 104.419 102.919 107.569 102.919Z" fill="#E6E6E6" />
      <path d="M288.854 52.9208C292.004 52.9208 294.557 50.3729 294.557 47.2299C294.557 44.087 292.004 41.5391 288.854 41.5391C285.704 41.5391 283.15 44.087 283.15 47.2299C283.15 50.3729 285.704 52.9208 288.854 52.9208Z" fill="#E6E6E6" />
      <path d="M226.526 206.575C229.675 206.575 232.229 204.027 232.229 200.884C232.229 197.741 229.675 195.193 226.526 195.193C223.376 195.193 220.822 197.741 220.822 200.884C220.822 204.027 223.376 206.575 226.526 206.575Z" fill="#E6E6E6" />
    </g>
    <defs>
      <clipPath id="clip0_135_5886">
        <rect width="348" height="264" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CompleteSignupIcon;