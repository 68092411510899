import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ConnectorProviders } from 'constants/app';


export interface IConnector {
  status: 'ACTIVE' | 'DISABLED' | 'SUSPENDED';
  provider: ConnectorProviders;
  external_account_id: string;
  meta: {
    email?: string;
    workspace?: string;
    workspace_id?: string;
    url?: string;
    name?: string;
    user_id?: string;
    instance_url?: string;
    principal_name?: string;
    username?: string;
  };
  id: string;
  user_id: string;
  created_at: string;
  updated_at:string;
}

export interface ConnectorState {
  connectorsById: {[connectorId: string]: IConnector},
}

const initialState: ConnectorState = {
  connectorsById: {},
};

export const connectorsSlice = createSlice({
  name: 'connectors',
  initialState,
  reducers: {
    receiveConnectors: (state, action: PayloadAction<IConnector[]>) => {
      action.payload.forEach(connector => {
        state.connectorsById[connector.id] = connector;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { receiveConnectors } = connectorsSlice.actions;
export default connectorsSlice.reducer;
