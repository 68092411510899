import { useState } from 'react';
import { Button, EditIcon, TrashIcon } from 'evergreen-ui';

import { IRule } from 'store/rules/selectors';
import { NotificationType } from 'store/notifications/selectors';
import { deleteRule } from 'api/rules';
import { notify } from 'store/notifications/slice';
import { receiveRules } from 'store/rules/slice';
import { useAppDispatch } from 'hooks';

import './style.css';

const Rule = ({
  rule,
  onEditClick,
}: {rule: IRule, onEditClick: (ruleId: string) => void}) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onDeleteRule = async () => {
    if (deleteLoading) return;

    setDeleteLoading(true);

    try {
      const {data} = await deleteRule(rule.id);
      dispatch(receiveRules([data]));
      setDeleteLoading(false);
    } catch (err) {
      setDeleteLoading(false);
      notify({
        type: NotificationType.WARNING,
        message: 'Failed to delete rule, please try again.',
      })(dispatch);
    }
  };

  return <div className="rule--container">
    <div>{rule.name}</div>
    <div>
      <Button className="rule-action--button" size="small" iconBefore={EditIcon} onClick={() => onEditClick(rule.id)}>Edit</Button>
      <Button className="rule-action--button" intent="danger" size="small" iconBefore={TrashIcon} isLoading={deleteLoading} disabled={deleteLoading} onClick={onDeleteRule}>Delete</Button>
    </div>
  </div>;
};

export default Rule;