import cx from 'classnames';
import { MouseEvent, useState } from 'react';

import { LinkIcon } from 'components/LinkCard/LinkIcon';
import { useLink } from 'hooks';
import { TrackEventNames, tracker } from 'utils/tracking';

import './style.css';


interface ILinkCardPillProps {
  linkId: string;
  className?: string,
  trackingCtx?: string,
}


const LinkCardPill = ({
  linkId,
  className='',
  trackingCtx='',
}: ILinkCardPillProps) => {
  const {link} = useLink(linkId);
  const linkData = link?.data;
  const [imageSrc, setImageSrc] = useState<string | undefined>(linkData?.image);

  const onImageError = () => {
    setImageSrc(undefined);
  };

  const open = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!link) return;
    tracker.track(TrackEventNames.ELC, {linkId, from: trackingCtx || 'link card pill'});
    window.open(link.url);
  };

  return <div className={cx('link-card-pill--container', className)} onClick={open}>
    <div className='link-cards-pill-inner--container'>
      {imageSrc && <img className="link-card-pill--link-image" src={imageSrc} onError={onImageError} alt={`${linkData?.site_name} logo`} />}
      {!imageSrc && <LinkIcon url={link?.url} iconType={link?.data?.site_name || link?.data?.title} background_color={link?.data?.background_color} background_scaled_image={link?.data?.background_scaled_image} />}
      <div className="link-card-pill-data--name">{link?.title || link?.data?.title || link?.url}</div>
    </div>
  </div>;
};


export default LinkCardPill;