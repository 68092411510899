import { CalendarIcon, EmptyState, WarningSignIcon } from 'evergreen-ui';

import { ConnectorProviders } from 'constants/app';
import GoogleButton from 'components/GoogleButton';
import { getEventsList } from 'store/calendar/selectors';
import { getGoogleOAuthURI } from 'api/auth';
import { getHasNoGCalConnectors } from 'store/connectors/selectors';
import { useAppSelector } from 'hooks';


export const EmptyHome = () => {
  const events = useAppSelector(getEventsList);
  const hasNoGoogleConnectors = useAppSelector(getHasNoGCalConnectors);

  const noMeetings = events.length === 0;

  const openGoogle = async () => {
    try {
      const {data} = await getGoogleOAuthURI(ConnectorProviders.GCAL);
      window.location.href = data.authorization_url;
    } catch (err) {
      console.warn('Could not get google auth URI', err);
    }
  };

  if (hasNoGoogleConnectors) {
    return <EmptyState background="light"
      title="You don't have a calendar connected"
      orientation="horizontal"
      icon={<WarningSignIcon color="var(--color-orange-7)"/>}
      description="Connect your Google calendar to view upcoming meetings."
      iconBgColor="var(--color-orange-5)"
      primaryCta={<GoogleButton onClick={() => openGoogle()} />}
    />;
  }

  if (noMeetings) {
    return <EmptyState background="light"
      title="You have no meetings today"
      orientation="horizontal"
      icon={<CalendarIcon color="var(--color-purple-5)" />}
      iconBgColor="var(--color-purple-3)"
      description="Today is all yours for some much needed focus time."
    />;
  }

  // TODO: not sure what this should be...? Maybe throw them to onboarding?
  return <EmptyState background="light"
    title="You don't have a calendar connected"
    orientation="horizontal"
    icon={<WarningSignIcon color="var(--color-orange-7)"/>}
    description="Connect your Google calendar to view upcoming meetings."
    iconBgColor="var(--color-orange-5)"
    primaryCta={<GoogleButton onClick={() => openGoogle()} />}
  />;
};
