import cx from 'classnames';

import { TextUnit } from 'slate/dist/interfaces/types';
import { withHistory } from 'slate-history';
import { BaseEditor, Editor, NodeEntry, Point, Range, Element as SlateElement, Transforms, createEditor } from 'slate';
import { Editable, ReactEditor, Slate, useSlate, withReact } from 'slate-react';

import XRegExp from 'xregexp';
import { HeaderOneIcon, HeaderThreeIcon, HeaderTwoIcon } from 'evergreen-ui';
import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faListOl, faListUl, faUnderline } from '@fortawesome/free-solid-svg-icons';

import './style.css';

type HeaderElementType = 'heading-one' | 'heading-two' | 'heading-three'; 
type ElementType = 'paragraph' | 'block-quote' | 'bulleted-list' | 'list-item' | 'numbered-list' | HeaderElementType;
type AlignmentType = 'center'| 'left' | 'right' | 'justify';
type StyleType = 'bold' | 'italic' | 'underline' | 'code';

export type CustomElement = {
  type: ElementType;
  children: Array<CustomText | CustomElement>;
  align?: AlignmentType;
}

export type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  code?: boolean;
  underline?: boolean;
  link?: boolean;
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const SHORTCUTS: {[index: string]: ElementType } = {
  '*': 'list-item',
  '-': 'list-item',
  '+': 'list-item',
  '>': 'block-quote',
  '#': 'heading-one',
  '##': 'heading-two',
  '###': 'heading-three',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const withShortcuts = (editor: ReactEditor) => {
  const { deleteBackward, insertText, insertBreak } = editor;

  editor.insertBreak = () => {
    const match = Editor.above(editor, {
      match: n => Editor.isBlock(editor, n),
    });

    if (match) {
      const path = match[1];
      const [currentElement] = Editor.node(editor, path);
      if (
        !Editor.isEditor(currentElement) &&
        SlateElement.isElement(currentElement) &&
        currentElement.type === 'list-item' && 
        currentElement.children.length === 1
      ) {
        const currentElementContent = currentElement.children[0];
        if (
          customElementIsText(currentElementContent) && 
          currentElementContent.text === ''
        ) {
          Transforms.liftNodes(editor, { at: path, mode: 'all' });
          const newPath = [...path];
          newPath.pop();
          newPath[newPath.length - 1] = newPath[newPath.length - 1] + 1;
          
          Transforms.setNodes(editor, {
            type: 'paragraph',
          }, {
            at: newPath
          });

          return;
        }
      }
    }

    insertBreak();

    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const newProperties: Partial<SlateElement> = {
        type: 'paragraph',
      };

      Transforms.setNodes(editor, newProperties, {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          ['heading-one', 'heading-two', 'heading-three'].includes(n.type)
      });
    }
  };

  editor.deleteBackward = (unit: TextUnit) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      });

      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);

        if (
          !Editor.isEditor(block) &&
          SlateElement.isElement(block) &&
          block.type !== 'paragraph' &&
          Point.equals(selection.anchor, start)
        ) {
          const newProperties: Partial<SlateElement> = {
            type: 'paragraph',
          };
          Transforms.setNodes(editor, newProperties);

          if (block.type === 'list-item') {
            Transforms.unwrapNodes(editor, {
              match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === 'bulleted-list',
              split: true,
            });
          }

          return;
        }
      }

      deleteBackward(unit);
    }
  };

  editor.insertText = (text: string) => {

    const { selection } = editor;

    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = { anchor, focus: start };
      const beforeText = Editor.string(editor, range);
      const type = SHORTCUTS[beforeText];

      if (type) {
        Transforms.select(editor, range);
        Transforms.delete(editor);
        const newProperties: Partial<SlateElement> = {
          type,
        };
        Transforms.setNodes<SlateElement>(editor, newProperties, {
          match: n => Editor.isBlock(editor, n),
        });

        if (type === 'list-item') {
          const list: CustomElement = {
            type: 'bulleted-list',
            children: [],
          };
          Transforms.wrapNodes(editor, list, {
            match: n =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === 'list-item',
          });
        }

        return;
      }
    }

    insertText(text);
  };

  return editor;
};

function customElementIsText(customElement: CustomText | CustomElement): customElement is CustomText {
  return customElement && ((customElement as CustomText).text !== undefined);
}

const NoteEditor = ({ onChange, noteContent, disabled, noteId, hideControls = false }: { onChange: (newContent: string) => void, noteContent: string, noteId: string, disabled: boolean, hideControls?: boolean }) => {
  const textarea = useRef<null | HTMLTextAreaElement>(null);
  const renderElement = useCallback(props => <RenderElement {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withShortcuts(withReact(withHistory(createEditor()))), []);
  let initialValue;
  try {
    initialValue = JSON.parse(noteContent);
  } catch (err) {
    initialValue = [{type: 'paragraph', children: [{text: noteContent}]}];
  }

  useEffect(() => {
    if (textarea.current) {
      textarea.current.focus();
      textarea.current.setSelectionRange(noteContent.length, noteContent.length);
    }
  }, [noteId]);

  return <div className='calendar-context-section--notes-editor'>
    <Slate editor={editor} value={initialValue} onChange={value => {
      const isAstChange = editor.operations.some(op => op.type !== 'set_selection');
      if (isAstChange && !disabled) {
        onChange(JSON.stringify(value));
      }
    }}>
      <Editable
        decorate={linkDecorator}
        disabled={disabled}
        placeholder='Start typing...'
        className={SLATE_CLASS_NAME}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
      {hideControls ? null :
        <div className="calendar-context-section-notes--format-container">
          <BlockButton format="heading-one" icon={<HeaderOneIcon size={12} />} />
          <BlockButton format="heading-two" icon={<HeaderTwoIcon size={12} />} />
          <BlockButton format="heading-three" icon={<HeaderThreeIcon size={12} />} />
          <BlockButton format="numbered-list" icon={<FontAwesomeIcon icon={faListOl} size="xs" />} />
          <BlockButton format="bulleted-list" icon={<FontAwesomeIcon icon={faListUl} size="xs" />} />
          <MarkButton format="bold" icon={<FontAwesomeIcon icon={faBold} size="xs" />} />
          <MarkButton format="italic" icon={<FontAwesomeIcon icon={faItalic} size="xs" />} />
          <MarkButton format="underline" icon={<FontAwesomeIcon icon={faUnderline} size="xs" />} />
        </div>
      }
    </Slate>
  </div>;
};



const Leaf = ({ attributes, children, leaf }: {leaf: CustomText, children: ReactNode, attributes: Record<string, unknown>}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.link) {
    children = (
      <a
        href={leaf.text}
        target='_blank' 
        rel='noopener noreferrer'
        onClick={() => {
          window.open(leaf.text, '_blank', 'noopener,noreferrer');
        }}
      >
        {children}
      </a>
    );
  }

  return <span {...attributes}>{children}</span>;
};



const RenderElement = ({ attributes, children, element }: {element: CustomElement, children: CustomElement[], attributes: Record<string, unknown>}) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'heading-three':
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const linkDecorator: (entry: NodeEntry) => Range[] = ([node, path]) => {
  const nodeText = (node as CustomText).text;

  if (!nodeText) {
    return [];
  }

  const urls = findUrlsInText(nodeText);

  return urls.map(([url, index]) => {
    return {
      anchor: {
        path,
        offset: index,
      },
      focus: {
        path,
        offset: index + url.length,
      },
      link: true,
    };
  });
};

const toggleBlock = (editor: BaseEditor & ReactEditor, format: AlignmentType | ElementType) => {
  const isActive = isBlockActive(
    editor,
    format,
    // @ts-expect-error something funky with Slate + TS
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<SlateElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : (format as AlignmentType),
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : (format as ElementType),
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block: CustomElement = { type: (format as ElementType), children: [] };
    Transforms.wrapNodes(editor, block);
  }
};


const toggleMark = (editor: BaseEditor & ReactEditor, format: string) => {
  // @ts-expect-error something funky with Slate + TS
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};



const isBlockActive = (editor: BaseEditor & ReactEditor, format: unknown, blockType: 'type' = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor: BaseEditor & ReactEditor, format: AlignmentType | ElementType | StyleType) => {
  const marks = Editor.marks(editor);
  // @ts-expect-error something funky with Slate + TS
  return marks ? marks[format] === true : false;
};


const MarkButton = ({ format, icon }: {format: StyleType, icon: ReactNode}) => {
  const editor = useSlate();
  return (
    <div onClick={() => {
      toggleMark(editor, format);
      getNoteEditorElement()?.focus();
    }} className={cx('note-editor--button', {active: isMarkActive(editor, format)})}
    >
      {icon}
    </div>
  );
};


const BlockButton = ({ format, icon }: {format: AlignmentType | ElementType, icon: ReactNode}) => {
  const editor = useSlate();
  return (
    <div onClick={() => {
      toggleBlock(editor, format);
      getNoteEditorElement()?.focus();
    }} className={cx('note-editor--button', {active: isBlockActive(editor, format)})}>
      {icon}
    </div>
  );
};

// Regular Expression inspired by https://github.com/validatorjs/validator.js isURL
// eslint-disable-next-line no-useless-escape
const linkRegExp = XRegExp.cache('(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?', 'gi');

const findUrlsInText: (text: string) => Array<[string, number]> = (text) => {
  const matches = text.match(linkRegExp);
  if (!matches) {
    return [];
  }
  
  return matches.map((m) => {
    const trimmedMatch = m.trim();
    return [trimmedMatch, text.indexOf(trimmedMatch)];
  });
};

const SLATE_CLASS_NAME = 'calendar-context-section-notes--textarea';

export const getNoteEditorElement = () => {
  const textArea = document.getElementsByClassName(SLATE_CLASS_NAME)?.[0];
  return textArea as HTMLTextAreaElement | null;
};


export default NoteEditor;