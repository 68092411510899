import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Alert, Button, TextInput } from 'evergreen-ui';
import { ChangeEvent, FocusEvent, FormEvent, MouseEvent, useState } from 'react';

import OnboardingCardWithHeader from 'components/OnboardingCardWithHeader';
import { loginViaEmail } from 'api/auth';
import { setToken } from 'utils/auth';
import { validateEmail } from 'utils/strings';

import './style.css';

const LOGIN_ERROR = {
  title: 'Login invalid. Try again',
  children: <div className="onboarding-alert-children">Your email and/or password is incorrect. <Link className="login-link" to="/signup">Sign up</Link> or <Link className="login-link" to="/reset-pw">Reset password</Link></div>
};

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<typeof LOGIN_ERROR | null>(null);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const onSubmitEmail = (event: MouseEvent<HTMLButtonElement> & FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    loginViaEmail(email, password)
      .then(({ data }) => {
        setToken(data.access_token);
        setTimeout(() => window.location.href = '/' + window.location.search, 200);
      })
      .catch(() => {
        setError(LOGIN_ERROR);
      })
      .finally(() => setLoading(false));
  };

  const onCheckEmailValidity = (e: FocusEvent<HTMLInputElement>) => {
    setIsEmailValid(validateEmail(e.target.value));
  };

  return (
    <OnboardingCardWithHeader headerText={'Log in to Classify'}>
      {error && <Alert intent="warning" title={error.title} marginBottom={24}>{error.children}</Alert>}
      <form onSubmit={onSubmitEmail}>
        <label className="login-input--label" htmlFor="email">Email</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': !isEmailValid })} width="100%" height="40px" type="email" id="email" onBlur={onCheckEmailValidity} isInvalid={!isEmailValid} value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value); }} />
        {!isEmailValid && <div className="login-input--error">Please enter a valid email address</div>}
        <label className="login-input--label" htmlFor="password">Password</label>
        <TextInput className="login-input" width="100%" height="40px" type="password" id="password" value={password} onChange={(e: ChangeEvent<HTMLInputElement>) => { setPassword(e.target.value); }} />
        <Button appearance="primary" type="submit" className="login-submit-button" onClick={onSubmitEmail} disabled={!email || !password} isLoading={loading}>Log in</Button>
        <div className="login-card-footer">
          <div>Don’t have an account? <Link className="login-link" to="/signup">Sign up</Link></div>
          <Link className="login-link forgot-password-link" to="/reset-pw">Forgot your password?</Link>
        </div>
      </form>
    </OnboardingCardWithHeader>
  );
};

export default Login;
