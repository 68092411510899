import cx from 'classnames';
import { useEffect, useState } from 'react';

import DocumentSidebar from 'components/DocumentSidebar';
import DocumentTimeline from 'components/DocumentTimeline';
import LoadingDots from 'components/LoadingDots';
import SearchBar from 'components/SearchBar';
import Sidebar from 'components/Sidebar';
import { fetchDocumentTimeline } from 'api/documents';
import { getHasNoConnectors } from 'store/connectors/selectors';
import { closeSidebar, receiveDocuments, setOldestTimelineFetch } from 'store/documents/slice';
import { getDocumentsNeverLoaded, getHasNoDocuments, getSidebarDocument, getSidebarIsOpen } from 'store/documents/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';

import OnboardingTimeline from './onboarding';
import './style.css';


const TimelineContent = () => {
  const hasNoConnectors = useAppSelector(getHasNoConnectors);
  const documentsNeverLoaded = useAppSelector(getDocumentsNeverLoaded);
  const hasNoDocuments = useAppSelector(getHasNoDocuments);
  const sidebarOpen = useAppSelector(getSidebarIsOpen);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const document = useAppSelector(getSidebarDocument);

  const close = () => {
    dispatch(closeSidebar());
  };

  useEffect(() => {
    const shouldFetch = documentsNeverLoaded && !hasNoConnectors && !loading;

    const loadDocuments = async (before?: string) => {
      try {
        const {data} = await fetchDocumentTimeline(before);
        dispatch(receiveDocuments(data));
        if (data.length) {
          dispatch(setOldestTimelineFetch(new Date(data[data.length - 1].created_at)));
        }
      } catch (err) {
        console.warn(err);
        // dispatch an empty receive to set "never loaded" to false
        dispatch(receiveDocuments([]));
      }
    };

    if (shouldFetch) {
      setLoading(true);
      loadDocuments().finally(() => setLoading(false));
    }
  }, [documentsNeverLoaded, hasNoConnectors, loading, dispatch]);

  return <div className="sidebar-sibling-content--container timeline--container">
    {loading && <div className="timeline--loading">Loading <LoadingDots /></div>}
    {!loading && hasNoDocuments && <OnboardingTimeline />}
    <div className={cx('timeline-content--container', {small: sidebarOpen})}>
      {!loading && <SearchBar />}
      <DocumentTimeline />
    </div>
    <DocumentSidebar hidden={!sidebarOpen} document={document} onClose={close} />
  </div>;
};


const TimelineContainer = () => {
  return (
    <div>
      <Sidebar />
      <TimelineContent />
    </div>
  );
};

export default TimelineContainer;