import { API } from 'api';
import { IContact } from 'store/contacts/selectors';


export const fetchContactsList = (before?: string, name?: string) => {
  const path = `/api/v1/contacts/?${ before? `before=${before}&` : '' }${name ? `name=${name}` : ''}`;
  return API.get<IContact[]>(path);
};

export const fetchContact = (contactId: string) => API.get<IContact>(`/api/v1/contacts/${contactId}`);

export const mergeContacts = (contact_ids: string[]) => API.post<IContact>('/api/v1/contacts/merge', {contact_ids});

export const deleteContact = (contactId: string) => API.delete(`/api/v1/contacts/${contactId}`);

export const fetchContactFromHandle = (source: string, handle: string) => API.get<IContact>(`/api/v1/contacts/find/${source}/${encodeURIComponent(handle)}`);

export const patchContact = (contactId: string, name: string) => API.patch<IContact>(`/api/v1/contacts/${contactId}`, {name});