import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOtter } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { AddIcon, Button } from 'evergreen-ui';

import ActionItem from 'components/ActionItem';
import { IActionItem } from 'store/action_items/selectors';
import { IMinimalCalendarEvent } from 'store/calendar/selectors';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import { createActionItem } from 'api/action_items';
import { notify } from 'store/notifications/slice';
import { pinActionItem } from 'store/calendar/slice';
import { pinActionItemToEvent } from 'api/calendar';
import { receiveActionItems } from 'store/action_items/slice';
import { useAppDispatch, useEventActionItems } from 'hooks';

import './style.css';


const createAndPin = (title: string, description: string, data: { calendarEventId: string, calendarEventStart: string, parentActionItemId: string | null }, entityReferenceId: string): Promise<{action_item: IActionItem, pin_id: string, pinned_by: string, pinned_at: string, is_shared_namespace: boolean}> => {
  return createActionItem({ title, description, data, status: 'NOT_DONE' }).then(({ data: newActionItem }) => pinActionItemToEvent(entityReferenceId, newActionItem.id).then(({ data }) => data));
};

const CalendarTasks = ({ event }: { event: IMinimalCalendarEvent }) => {
  const { actionItems, loading } = useEventActionItems(event);
  const dispatch = useAppDispatch();
  const [loadingNewActionItem, setLoadingNewActionItem] = useState(false);

  const createNewActionItem = async (parentActionItemId: string | null=null) => {
    if (loadingNewActionItem) return;

    try {
      setLoadingNewActionItem(true);
      const pin = await createAndPin('', '', { calendarEventId: event.id, calendarEventStart: event.start_dt, parentActionItemId }, event.entity_reference_id);
      dispatch(receiveActionItems([pin.action_item]));
      dispatch(pinActionItem({
        referenceId: event.entity_reference_id,
        actionItemId: pin.action_item.id,
        isPublicPin: pin.is_shared_namespace,
        pinId: pin.pin_id,
        pinnedBy: pin.pinned_by,
      }));
      setLoadingNewActionItem(false);
    } catch (err) {
      setLoadingNewActionItem(false);
      notify({
        message: 'Could not create new task, please try again.',
        type: NotificationType.WARNING,
      })(dispatch);
    }
  };

  const showEmpty = actionItems?.length === 0 && !loading;

  return (
    <div className="calendar-event-details--section notes">
      {showEmpty && <div className='calendar-event-details--empty'>
        <FontAwesomeIcon icon={faOtter} size="2x"/>
        <p>No tasks yet</p>
        <Button width={80} iconBefore={AddIcon} appearance="minimal" onClick={() => createNewActionItem(null)} isLoading={loadingNewActionItem} disabled={loadingNewActionItem}>Task</Button>
      </div>}
      {!showEmpty && !loading && <Button width={80} iconBefore={AddIcon} appearance="minimal" onClick={() => createNewActionItem(null)} isLoading={loadingNewActionItem} disabled={loadingNewActionItem}>Task</Button>}
      {loading && <div className="calendar-event-details-notes--loading">Loading tasks<LoadingDots /></div>}
      {actionItems?.map((actionItem, idx) => <ActionItem actionItemId={actionItem.id} prevActionItem={actionItems[idx-1]?.id} key={actionItem.id} onEnterClick={createNewActionItem} />)}
    </div>
  );
};

export default CalendarTasks;