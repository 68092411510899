import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOtter } from '@fortawesome/free-solid-svg-icons';
import { AddIcon, Button } from 'evergreen-ui';
import { useEffect, useState } from 'react';

import LoadingDots from 'components/LoadingDots';
import Note from 'components/Note';
import { NotificationType } from 'store/notifications/selectors';
import { PinSpaces } from 'constants/app';
import { markCalendarNotesAsRead } from 'store/read_receipts/slice';
import { notify } from 'store/notifications/slice';
import { postTogglePinSpace } from 'api/calendar';
import { store } from 'store';
import { IMinimalCalendarEvent, getPinByNoteId } from 'store/calendar/selectors';
import { INote, receiveNotes } from 'store/notes/slice';
import { createNote, pinNoteToEvent } from 'api/notes';
import { getIsCalendarNotesUnread, getLastCalendarNotesRead } from 'store/read_receipts/selectors';
import { pinNote, removeAndAddPinnedNote } from 'store/calendar/slice';
import { useAppDispatch, useAppSelector, useEventNotes } from 'hooks';

import './style.css';


const createAndPin = (title: string, content: string, data: { calendarEventId: string, calendarEventStart: string }, entityReferenceId: string): Promise<{note: INote, pin_id: string, pinned_by: string, pinned_at: string, is_shared_namespace: boolean}> => {
  return createNote({ title, content, data }).then(({ data: newNote }) => pinNoteToEvent(entityReferenceId, newNote.id).then(({ data }) => data));
};

const CalendarNotes = ({ event }: { event: IMinimalCalendarEvent }) => {
  const { notePins, loading } = useEventNotes(event);
  const noteIds = notePins?.map(({note}) => note) || [];
  const dispatch = useAppDispatch();
  const [viewingNoteId, setViewingNoteId] = useState<undefined | string>(undefined);
  const [loadingNewNote, setLoadingNewNote] = useState(false);
  const [shouldMarkAsRead, setShouldMarkAsRead] = useState(false);
  const notesUnread = useAppSelector((s) => getIsCalendarNotesUnread(s, event.entity_reference_id));
  const notesLastRead = useAppSelector(s => getLastCalendarNotesRead(s, event.entity_reference_id));

  const viewingNotePin = useAppSelector(s => getPinByNoteId(s, event.entity_reference_id, viewingNoteId || ''));

  useEffect(() => {
    setViewingNoteId(undefined);
  }, [event]);

  const createAndViewNote = async () => {
    if (loadingNewNote) return;

    try {
      setLoadingNewNote(true);
      const pin = await createAndPin(event.title || 'New note', '', { calendarEventId: event.id, calendarEventStart: event.start_dt }, event.entity_reference_id);
      dispatch(receiveNotes([pin.note]));
      dispatch(pinNote({
        referenceId: event.entity_reference_id,
        noteId: pin.note.id,
        isPublicPin: pin.is_shared_namespace,
        pinId: pin.pin_id,
        pinnedBy: pin.pinned_by,
      }));
      setViewingNoteId(pin.note.id);
      setLoadingNewNote(false);
    } catch (err) {
      setLoadingNewNote(false);
      notify({
        message: 'Could not create new note, please try again.',
        type: NotificationType.WARNING,
      })(dispatch);
    }
  };

  useEffect(() => {
    if (!loading && notesUnread) {
      setShouldMarkAsRead(true);
    }
  }, [loading, event, notesUnread]);

  useEffect(() => {
    // mark on unmount so the markers don't go away on render
    return () => {
      if (shouldMarkAsRead) {
        markCalendarNotesAsRead({referenceId: event.entity_reference_id})(dispatch, store.getState, {});
      }
    };
  }, [shouldMarkAsRead, event]);

  const onViewNote = (noteId?: string) => {
    markCalendarNotesAsRead({referenceId: event.entity_reference_id})(dispatch, store.getState, {});
    setViewingNoteId(noteId);
  };

  const onTogglePinSpace = async (viewingNoteId?: string) => {
    const pin = getPinByNoteId(store.getState(), event.entity_reference_id, viewingNoteId || '');
    if (!pin) return;

    try {
      const { data } = await postTogglePinSpace(pin.pin_id);
      if ('note' in data && data.note) {
        const dispatchPayload = {
          referenceId: event.entity_reference_id,
          removePinId: pin.pin_id || '',
          addToSpace: pin.pinSpace === PinSpaces.PRIVATE ? PinSpaces.PUBLIC : PinSpaces.PRIVATE,
        };
        dispatch(receiveNotes([data.note]));
        dispatch(removeAndAddPinnedNote({
          ...dispatchPayload,
          pinnedNote: {...data, note: data.note.id},
        }));
      }
    } catch (err) {
      notify({
        message: `Failed to set note to ${pin.pinSpace === PinSpaces.PUBLIC ? 'private' : 'public'}, please try again.`,
        type: NotificationType.WARNING,
      })(dispatch);
    }
  };

  const showEmpty = !loading && noteIds.length === 0;

  return (
    <div className="calendar-event-details--section notes">
      {loading && <div className="calendar-event-details-notes--loading">Loading notes<LoadingDots /></div>}
      {showEmpty && <div className='calendar-event-details--empty'>
        <FontAwesomeIcon icon={faOtter} size="2x"/>
        <p>No notes yet</p>
        <Button width={80} iconBefore={AddIcon} appearance="minimal" onClick={createAndViewNote} isLoading={loadingNewNote} disabled={loadingNewNote}>Note</Button>
      </div>}
      {!showEmpty && !loading && !viewingNoteId && <Button marginBottom={16} width={80} iconBefore={AddIcon} appearance="minimal" onClick={createAndViewNote} isLoading={loadingNewNote} disabled={loadingNewNote}>Note</Button>}
      {!viewingNoteId && noteIds.map(noteId => <Note eventId={event.id} key={noteId} notesLastRead={notesLastRead} onTogglePinSpace={onTogglePinSpace} noteId={noteId} onViewNote={onViewNote} />)}
      {viewingNoteId && <Note showNoteMadePrivate={!viewingNotePin} showBackArrow onTogglePinSpace={onTogglePinSpace} noteId={viewingNoteId} onViewNote={onViewNote} showAsEditor />}
    </div>
  );
};

export default CalendarNotes;