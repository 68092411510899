import { EmptyState, WarningSignIcon } from 'evergreen-ui';

import { ConnectorProviders } from 'constants/app';
import GoogleButton from 'components/GoogleButton';
import { getGoogleOAuthURI } from 'api/auth';


const ErrorHome = () => {

  const openGoogle = async () => {
    try {
      const {data} = await getGoogleOAuthURI(ConnectorProviders.GCAL);
      window.location.href = data.authorization_url;
    } catch (err) {
      console.warn('Could not get google auth URI', err);
    }
  };

  return <EmptyState
    background="light"
    title="Could not load calendar"
    description="We failed to load your Google calendar events. Please try reconnecting your Google account."
    icon={<WarningSignIcon color="var(--color-red-4)"/>}
    iconBgColor="var(--color-red-2)"
    primaryCta={<GoogleButton onClick={openGoogle}/>}
  />;
};

export default ErrorHome;
