import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import enUS from 'date-fns/locale/en-US';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import { pathOr } from 'ramda';
import startOfWeek from 'date-fns/startOfWeek';
import { useLocation } from 'react-router-dom';
import { Button, CaretDownIcon, CornerDialog, Popover, TickCircleIcon } from 'evergreen-ui';
import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Calendar, NavigateAction, ToolbarProps, dateFnsLocalizer } from 'react-big-calendar';
import axios, { AxiosError } from 'axios';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { AppQueryParams } from 'constants/app';
import Cache from 'utils/cache';
import { CalendarEventDetails } from 'components/CalendarEventDetails';
import ContactSidebar from 'components/ContactSidebar';
import DocumentSidebar from 'components/DocumentSidebar';
import LoadingDots from 'components/LoadingDots';
import { NotificationType } from 'store/notifications/selectors';
import SearchBar from 'components/SearchBar';
import Sidebar from 'components/Sidebar';
import { SidebarHistory } from 'contexts/SidebarHistory';
import { fetchContact } from 'api/contacts';
import { fetchDocument } from 'api/documents';
import { fetchReadReceiptsByKeys } from 'api/read_receipts';
import { fetchTodayEvents } from 'api/calendar';
import { getDocumentById } from 'store/documents/selectors';
import { getHasNoGCalConnectors } from 'store/connectors/selectors';
import { getTags } from 'store/tags/selectors';
import { notify } from 'store/notifications/slice';
import { receiveContacts } from 'store/contacts/slice';
import { receiveDocuments } from 'store/documents/slice';
import { shadeColor } from 'utils/colors';
import { IContact, getContactById } from 'store/contacts/selectors';
import { IMinimalCalendarEvent, ITag, getEarliestRequestedTime, getEventsList, getFocusEventId, getLatestRequestedTime, getSidebarEntityId, getSidebarHasHistory, isContactSidebarOpen, isDocumentSidebarOpen, isLinkSidebarOpen } from 'store/calendar/selectors';
import { TrackEventNames, tracker } from 'utils/tracking';
import { closeSidebar, focusOnEvent, openSidebarToContact, openSidebarToDocument, openSidebarToLink, popSidebarHistory, receiveEvents, setEarliestRequestedDate, setLatestRequestedDate } from 'store/calendar/slice';
import { isReceiptUnread, receiptsByKey } from 'store/read_receipts/selectors';
import { useAppDispatch, useAppSelector, usePrevious } from 'hooks';

import { EmptyHome } from './empty';
import ErrorHome from './error';
import LinkSidebar from 'components/LinkSidebar';
import { fetchLink } from 'api/links';
import { getLinkById } from 'store/links/selectors';
import { receiveLinks } from 'store/links/slice';
import { receiveReceipts } from 'store/read_receipts/slice';
import './style.css';


const lastViewCacheKey = 'calendar.lastView';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});


const TimeSlotWrapper = ({ value, resource }: { value?: Date, resource?: null, children?: ReactNode }) => {
  if (!value || value.getMinutes() !== 0 || resource === null) {
    return null;
  }
  return <div className="calendar-time-slot--wrapper">{value.toLocaleTimeString(navigator.language, { hour: 'numeric' })}</div>;
};


interface IBigCalEventObject {
  title?: ReactNode;
  start: Date;
  end: Date;
  allDay: boolean;
  resource: IMinimalCalendarEvent;
}

interface IRenderCalEvent extends IMinimalCalendarEvent {
  isUnread: boolean;
}

const classifyEventToCalEvent = (classifyEvent: IRenderCalEvent): IBigCalEventObject => ({
  title: classifyEvent.isUnread ? <div>{classifyEvent.title}{classifyEvent.isUnread && <span className="calendar-event-block--notification"/>}</div> : classifyEvent.title,
  start: new Date(classifyEvent.start_dt),
  end: new Date(classifyEvent.end_dt),
  allDay: false,
  resource: classifyEvent,
});


const CalToolbar = ({
  date,
  onNavigate,
  onDateChange,
  onChangeView,
  loading,
  view,
}: {
  date: Date,
  onNavigate: (navigate: NavigateAction, date?: Date) => void,
  onDateChange: (date: Date) => void,
  loading: boolean,
  onChangeView: (view: 'day' | 'week') => void,
  view: string,
}) => {
  const dateDelta = view === 'week' ? 7 : 1;

  const viewYesterday = () => {
    const nextDate = new Date(date.setDate(date.getDate() - dateDelta));
    onDateChange(nextDate);
    onNavigate('DATE', nextDate);
  };

  const viewTomorrow = () => {
    const nextDate = new Date(date.setDate(date.getDate() + dateDelta));
    onDateChange(nextDate);
    onNavigate('DATE', nextDate);
  };

  const viewToday = () => {
    onDateChange(new Date());
    onNavigate('TODAY');
  };

  const popoverContent = (
    <div className="">
      <div className={cx('calendar-view-select--option', { selected: view === 'day' })} onClick={() => onChangeView('day')}>
        {view === 'day' && <TickCircleIcon className="calendar-view-select--icon" size={12} color="var(--color-green-2-3)" />}
        Day
      </div>
      <div className={cx('calendar-view-select--option', { selected: view === 'week' })} onClick={() => onChangeView('week')}>
        {view === 'week' && <TickCircleIcon className="calendar-view-select--icon" size={12} color="var(--color-green-2-3)" />}
        Week
      </div>
    </div>
  );

  return (
    <div className="calendar-day-toolbar--container">
      <div className="calendar-day--title">{date.toLocaleDateString('default', { day: 'numeric', month: 'short', weekday: 'short' })}{loading && <LoadingDots />}</div>
      <div className="calendar-day-toolbar--actions">
        <Button onClick={viewToday} size="small" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>Today</Button>
        <Popover content={popoverContent}>
          <Button padding="0" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: 'none', minWidth: '16px' }} size="small"><CaretDownIcon size={12} /></Button>
        </Popover>
        <div onClick={viewYesterday} className="calendar-day-toolbar--navigate"><FontAwesomeIcon icon={faChevronLeft} /></div>
        <div onClick={viewTomorrow} className="calendar-day-toolbar--navigate"><FontAwesomeIcon icon={faChevronRight} /></div>
      </div>
    </div>
  );
};

const makeGetStyleForEvent = (tagsById: {[tagId: string]: ITag}) =>
  (event: IBigCalEventObject, _s: unknown, _e: unknown, isSelected: boolean) => {

    const mostRecentTag = event.resource.tags.reduce((soFar: ITag | null, nextTag: ITag) => {
      if (!soFar) {
        return nextTag;
      }

      const soFarDt = new Date(soFar.updated_at || soFar.created_at).valueOf();
      const nextTagDt = new Date(nextTag.updated_at || nextTag.created_at).valueOf();

      return soFarDt > nextTagDt ? soFar : nextTag;
    }, null);

    const tag = tagsById[mostRecentTag?.id || ''];
    const baseColor = pathOr('#7649FD', ['config', 'colorHash'], tag || {}); // --color-purple-5 as backup
    const selfAttendance = event.resource.user_self_attendance;
    const onlyOneAttendee = event.resource.attendees.length === 1;
    const style: CSSProperties = {
      borderColor: shadeColor(baseColor, -20),
      transition: 'box-shadow 0.3s ease',
      boxShadow: isSelected ? 'var(--box-shadow-4)' : undefined,
      fontSize: '12px',
    };
    switch (selfAttendance) {
      case 'NO_RESPONSE':
        if (onlyOneAttendee) {
          style.backgroundColor = baseColor;
          style.color = 'white';
        } else {
          style.backgroundColor = 'white';
          style.color = baseColor;
        }
        break;
      case 'TENTATIVE':
        style.color = 'white';
        style.backgroundColor = baseColor;
        style.backgroundImage = 'linear-gradient(45deg,transparent,transparent 40%,rgba(0,0,0,0.2) 40%,rgba(0,0,0,0.2) 50%,transparent 50%,transparent 90%,rgba(0,0,0,0.2) 90%,rgba(0,0,0,0.2))';
        style.backgroundSize = '12px 12px';
        break;
      case 'DECLINED':
        style.backgroundColor = 'white';
        style.color = baseColor;
        style.textDecoration = 'line-through';
        break;
      case 'ACCEPTED':
      default:
        style.backgroundColor = baseColor;
        style.color = 'white';
    }
    return { style };
  };


const getViewDates = (view: 'day' | 'week', viewDay: Date): Date[] => {
  const viewDayStart = new Date(viewDay.valueOf());
  if (view === 'week') {
    viewDayStart.setDate(viewDayStart.getDate() - viewDayStart.getDay());
  }
  viewDayStart.setHours(0, 0, 0, 0);

  const viewDayEnd = new Date(viewDay.valueOf());
  if (view === 'week') {
    viewDayEnd.setDate(viewDayEnd.getDate() + (6 - viewDayEnd.getDay()));
  }
  viewDayEnd.setHours(23, 59, 59, 999);

  return [viewDayStart, viewDayEnd];
};


const HomeContent = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlViewDate = params.get(AppQueryParams.CALENDAR_VIEW_DATE);
  const urlFocusRefId = params.get(AppQueryParams.CALENDAR_FOCUS_EVENT);
  const [calendarView, setCalendarView] = useState<'day' | 'week'>(Cache.get(lastViewCacheKey) || 'day');
  const hasNoGCalConnectors = useAppSelector(getHasNoGCalConnectors);
  const events = useAppSelector(getEventsList);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const [calendarErrored, setCalendarErrored] = useState(false);
  const [googleDisconnected, setGoogleDisconnected] = useState(false);
  const [retryRequest, setRetryRequest] = useState(false);
  const [scrollToTime, setScrollToTime] = useState(urlViewDate ? new Date(urlViewDate) : new Date());
  const [viewDay, setViewDay] = useState(urlViewDate ? new Date(urlViewDate) : new Date());
  const [today, setToday] = useState(new Date());
  const prevToday = usePrevious(today);
  const earliestRequestedDate = useAppSelector(getEarliestRequestedTime);
  const latestRequestedDate = useAppSelector(getLatestRequestedTime);
  const dispatch = useAppDispatch();
  const selectedEventId = useAppSelector(getFocusEventId);
  const contactSidebarOpen = useAppSelector(isContactSidebarOpen);
  const documentSidebarOpen = useAppSelector(isDocumentSidebarOpen);
  const linkSidebarOpen = useAppSelector(isLinkSidebarOpen);
  const sidebarEntityId = useAppSelector(getSidebarEntityId);
  const sidebarDocument = useAppSelector((s) => getDocumentById(s, sidebarEntityId || ''));
  const sidebarLink = useAppSelector((s) => getLinkById(s, sidebarEntityId || ''));
  const sidebarContact = useAppSelector((s) => getContactById(s, sidebarEntityId || ''));
  const hasHistory = useAppSelector(getSidebarHasHistory);
  const readReceipts = useAppSelector(receiptsByKey);
  const tagsById = useAppSelector(getTags);
  const rbcFormats = calendarView == 'day' ? {} :  { eventTimeRangeFormat: () => '' };

  const isCalEventUnread = (entRefId: string) => ([
    readReceipts[`calendar_event:${entRefId}.notes`],
    readReceipts[`calendar_event:${entRefId}.pins`],
    readReceipts[`calendar_event:${entRefId}.activity`],
  ].some(isReceiptUnread));

  // set "today" value every 30 seconds and change the view date when
  // "today" is a different day (e.g. we pass over midnight)
  useEffect(() => {
    if (hasNoGCalConnectors) return;

    if (prevToday && prevToday.getDate() !== today.getDate()) {
      setViewDay(new Date());
    }

    const timeoutId = setTimeout(() => setToday(new Date()), 30000);

    return () => clearTimeout(timeoutId);
  }, [today]);

  // fetch the events for the view day
  useEffect(() => {
    // NOTE: this logic assumes there are no "gaps" in the dates that are loaded
    // e.g. if you had Monday and Wednesday loaded, it assumed you also loaded Tuesday's events
    const [viewDayStart, viewDayEnd] = getViewDates(calendarView, viewDay);

    const haventFetchedViewDay = earliestRequestedDate === null || latestRequestedDate === null ||
      (earliestRequestedDate > viewDayStart.valueOf() || latestRequestedDate < viewDayEnd.valueOf());
    const shouldFetchViewDay = haventFetchedViewDay && !loading && (!googleDisconnected || retryRequest) && (!calendarErrored || retryRequest) && !hasNoGCalConnectors;

    const loadEvents = async (start: Date, end: Date) => {
      try {
        const { data } = await fetchTodayEvents(start, end);
        const allContacts: {[contactId: string]: IContact} = {};
        data.events.forEach(event => {
          event.attendees.forEach(contact => {
            allContacts[contact.id] = contact;
          });
        });
        dispatch(receiveContacts({data: Object.values(allContacts)}));
        dispatch(receiveEvents(data.events));
        const eventActivityReadReceiptKeys = data.events.map(evt => `calendar_event:${evt.entity_reference_id}.activity`);
        dispatch(setEarliestRequestedDate(start));
        dispatch(setLatestRequestedDate(end));
        const {data: receiptData} = await fetchReadReceiptsByKeys(eventActivityReadReceiptKeys);
        dispatch(receiveReceipts(receiptData.receipts));
        setCalendarErrored(false);
      } catch (err) {
        // user did not grant us access to everything we need from gmail/google calendar
        const errDetail = axios.isAxiosError(err) ? (err as AxiosError).response?.data?.detail : null;

        // if the user leaves their computer open e.g. overnight and the network drops,
        // we'll get an axios error without a response - meaning it was a network error
        const isNetworkError = axios.isAxiosError(err) && !(err as AxiosError).response === undefined;
        if (isNetworkError) {
          return;
        }

        if (errDetail === 'GOOGLE_DISCONNECTED') {
          notify({
            type: NotificationType.ERROR,
            message: 'Your Google account has been disconnected',
          })(dispatch);
          setGoogleDisconnected(true);
        } else {
          setCalendarErrored(true);
        }
      }
    };

    if (shouldFetchViewDay) {
      setLoading(true);
      loadEvents(viewDayStart, viewDayEnd).finally(() => {
        setLoading(false);
        setInitiallyLoaded(true);
        setRetryRequest(false);
      });
    }
  }, [viewDay, earliestRequestedDate, latestRequestedDate, loading, dispatch, calendarErrored, retryRequest, calendarView]);

  // load selected contact
  useEffect(() => {
    const requestContact = async () => {
      try {
        const { data } = await fetchContact(sidebarEntityId as string);
        dispatch(receiveContacts({data: [data]}));
      } catch (err) {
        dispatch(closeSidebar());
        notify({
          message: 'Could not load contact info',
          type: NotificationType.WARNING,
        });
      }
    };
    if (!loadingContact && contactSidebarOpen && !sidebarContact) {
      setLoadingContact(true);
      requestContact().finally(() => setLoadingContact(false));
    }
  }, [loadingContact, contactSidebarOpen, sidebarContact, sidebarEntityId, dispatch]);

  // load selected document
  useEffect(() => {
    const requestDocument = async () => {
      try {
        const { data } = await fetchDocument(sidebarEntityId as string);
        dispatch(receiveDocuments([data]));
      } catch (err) {
        dispatch(closeSidebar());
        notify({
          message: 'Could not load file data',
          type: NotificationType.WARNING,
        });
      }
    };
    if (!loadingDocument && documentSidebarOpen && !sidebarDocument) {
      setLoadingDocument(true);
      requestDocument().finally(() => setLoadingDocument(false));
    }
  }, [loadingDocument, documentSidebarOpen, sidebarDocument, sidebarEntityId, dispatch]);

  // load selected link
  useEffect(() => {
    const requestLink = async () => {
      try {
        const { data } = await fetchLink(sidebarEntityId as string);
        dispatch(receiveLinks([data]));
      } catch (err) {
        dispatch(closeSidebar());
        notify({
          message: 'Could not load link data',
          type: NotificationType.WARNING,
        });
      }
    };
    if (!loadingLink && linkSidebarOpen && !sidebarLink) {
      setLoadingLink(true);
      requestLink().finally(() => setLoadingLink(false));
    }
  }, [loadingLink, linkSidebarOpen, sidebarLink, sidebarEntityId, dispatch]);

  const focusEvent = (e: IBigCalEventObject) => {
    tracker.track(TrackEventNames.FOCE, {
      eventId: e.resource.id,
    });
    dispatch(focusOnEvent(e.resource.id as string));
    if (e.resource.id !== selectedEventId) dispatch(closeSidebar());
  };

  // set user calendar view cache data
  const setView = (view: 'day' | 'week') => {
    setCalendarView(view);
    Cache.set(lastViewCacheKey, view);
  };

  // choose the event to auto-focus on when the calendar
  // loads
  useEffect(() => {
    if (events.length && !selectedEventId) {
      const now = new Date();
      const nextEvent = events.reduce((sofar, nextEvent) => {
        if (urlFocusRefId) {
          if (urlFocusRefId === sofar.entity_reference_id) {
            return sofar;
          } else if (urlFocusRefId === nextEvent.entity_reference_id) {
            return nextEvent;
          }
        }
        const sofarTime = new Date(sofar.start_dt);
        const sofarEndTime = new Date(sofar.end_dt);
        const nextTime = new Date(nextEvent.start_dt);
        const nextEndTime = new Date(nextEvent.end_dt);

        const sofarDiff = sofarTime.valueOf() - now.valueOf();
        const nexttimeDiff = nextTime.valueOf() - now.valueOf();

        const sofarHappeningNow = sofarTime < now && sofarEndTime > now;
        const nextEventHappeningNow = nextTime < now && nextEndTime > now;

        if (sofarHappeningNow) {
          return sofar;
        } else if (nextEventHappeningNow) {
          return nextEvent;
        } else if (nextTime > now && (sofarDiff > nexttimeDiff || sofarDiff < 0)) {
          return nextEvent;
        } else {
          return sofar;
        }
      }, events[0]);
      const start = new Date(nextEvent.start_dt);
      start.setHours(Math.max(0, start.getHours() - 2));
      setScrollToTime(start);
      dispatch(focusOnEvent(nextEvent.id));
    }
  }, [events, selectedEventId, dispatch]);

  const close = () => {
    dispatch(closeSidebar());
  };

  const noMeetings = events.length === 0;
  const height = window.innerHeight - 73;

  const isEmpty = noMeetings || hasNoGCalConnectors;
  const calendarEvents = events
    .map(evt => ({...evt, isUnread: isCalEventUnread(evt.entity_reference_id)}))
    .map(classifyEventToCalEvent);
  const selectedEvent = calendarEvents.find(evt => evt.resource.id === selectedEventId);

  const ToolbarWrapper = (props: ToolbarProps) => {
    return <CalToolbar onDateChange={setViewDay} loading={loading} onChangeView={setView} {...props} />;
  };

  const showError = googleDisconnected;
  const showRetry = calendarErrored;
  const showLoading = (isEmpty && !initiallyLoaded) || (loading && !initiallyLoaded);
  const showCalendar = !showLoading && !showError;
  const onSidebarBack = () => dispatch(popSidebarHistory());

  return <div className="timeline--container">
    <SidebarHistory.Provider value={{
      onDocumentClick: (id: string) => dispatch(openSidebarToDocument({ id, pushState: true })),
      onContactClick: (id: string) => dispatch(openSidebarToContact({ id, pushState: true })),
      onLinkClick: (id: string) => dispatch(openSidebarToLink({ id, pushState: true })),
      onBack: onSidebarBack,
    }}>
      <div className="timeline-content--container">
        <SearchBar />
        {showError && <ErrorHome />}
        <CornerDialog
          title="Could not load calendar events"
          isShown={showRetry}
          hasFooter={false}
        >
          <div>
            <p>We failed to load your calendar events. Trying again usually fixes this.</p>
            <Button appearance='primary' isLoading={loading} onClick={() => setRetryRequest(true)}>Retry</Button>
          </div>
        </CornerDialog>
        {showLoading && <div className="timeline--loading">Loading <LoadingDots /></div>}
        {showCalendar &&
          <div className="calendar-content--container" style={{ height }}>
            <div style={{ height, width: calendarView === 'week' ? '55%' : '35%', minWidth: '35%', maxWidth: '55%', transition: 'width 0.3s ease' }}>
              <Calendar
                localizer={localizer}
                formats={rbcFormats}
                scrollToTime={scrollToTime}
                events={calendarEvents}
                startAccessor="start"
                endAccessor="end"
                defaultView='day'
                view={calendarView}
                onView={newview => {
                  if (newview === 'day' || newview === 'week') {
                    setCalendarView(newview);
                  }
                }}
                views={['day', 'week']}
                toolbar={true}
                onNavigate={(newDate) => setViewDay(newDate)}
                defaultDate={viewDay}
                components={{ timeSlotWrapper: TimeSlotWrapper, toolbar: ToolbarWrapper }}
                onSelectEvent={focusEvent}
                eventPropGetter={makeGetStyleForEvent(tagsById)}
                selected={selectedEvent}
              />
            </div>
            {isEmpty ? <EmptyHome /> : <CalendarEventDetails small={calendarView === 'week'} />}
          </div>
        }
      </div>
      <ContactSidebar
        onClose={close}
        contact={sidebarContact}
        hidden={!contactSidebarOpen}
        showBackBtn={hasHistory}
        onBack={onSidebarBack}
      />
      <DocumentSidebar
        onClose={close}
        document={sidebarDocument}
        hidden={!documentSidebarOpen}
        showBackBtn={hasHistory}
        onBack={onSidebarBack}
      />
      <LinkSidebar
        onClose={close}
        link={sidebarLink}
        hidden={!linkSidebarOpen}
        showBackBtn={hasHistory}
        onBack={onSidebarBack}
      />
    </SidebarHistory.Provider>
  </div>;
};


const HomeView = () => {
  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <HomeContent />
    </div>
  );
};

export default HomeView;