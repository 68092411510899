import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';

import CalendarEventPill from 'components/CalendarEventPill';
import DocumentCardPill from 'components/DocumentCard/pill';
import LoadingDots from 'components/LoadingDots';
import { ITagEntry, getTagById } from 'store/tags/selectors';
import { getCurrentUser, getUserNameById } from 'store/user/selector';
import { useAppSelector, useCalendarEvent, useDocument } from 'hooks';

import './style.css';


interface ITaggedDocumentActivityProps {
  tagTimelineEntry: ITagEntry;
}


const TaggedDocument = ({
  tagTimelineEntry,
}: ITaggedDocumentActivityProps) => {
  const relation = tagTimelineEntry.tag_relation;
  const { loading: docLoading, error } = useDocument(relation.entity_id);
  const tag = useAppSelector(s => getTagById(s, relation.tag_id));
  const entRefId = relation.data?.calendar_event_instance_id || relation.data?.calendar_event_id || '';
  const { loading: calEvtLoading } = useCalendarEvent(entRefId);
  const dateStr = new Date(relation.timestamp).toLocaleString('default', { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' });

  const document = docLoading ? <div className="tagged-document-activity--loading">Loading<LoadingDots /></div> : <DocumentCardPill documentId={relation.entity_id} />;
  const event = calEvtLoading ? <div className="tagged-document-activity--loading">Loading<LoadingDots /></div> : <CalendarEventPill calendarEventId={entRefId} navigateDate={relation.timestamp} />;

  const currentUserId = useAppSelector(getCurrentUser)?.id;
  const userId = relation.data?.tagged_by;
  const fullName = useAppSelector(s => getUserNameById(s, userId || '') || 'You');
  const usersName = (currentUserId === userId) ? 'You' : `${fullName}`;

  return (
    <div className="tagged-document-activity--container">
      {error && <div className="tagged-document-activity--error">Failed to load document</div>}
      {!error && <div className="tagged-document-activity-content-container">
        <div className="tagged-document-activity--content">
          <FontAwesomeIcon className="tagged-document-activity--icon" icon={faThumbtack} />
          {usersName} pinned {document} to {entRefId ? event : (tag.name || 'this workstream')}
        </div>
        <div className="tagged-document-activity--time">{dateStr}</div>
      </div>}
    </div>
  );
};


export default TaggedDocument;