import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent } from 'react';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { formatDistance } from 'date-fns';

import { AppQueryParams } from 'constants/app';
import LoadingDots from 'components/LoadingDots';
import Note from 'components/Note';
import { getLastWorkstreamNotesRead } from 'store/read_receipts/selectors';
import { getTagById } from 'store/tags/selectors';
import { useAppSelector, useCalendarEvent, useNote } from 'hooks';

import './style.css';


const WorkstreamNote = ({
  noteId,
  onViewNote,
  showAsEditor,
  tagId,
}: {noteId: string, showAsEditor: boolean, onViewNote: (noteId?: string) => void, tagId: string}) => {

  const {note} = useNote(noteId);
  const tag = useAppSelector(s => getTagById(s, tagId));
  const lastRead = useAppSelector(s => getLastWorkstreamNotesRead(s, tagId));

  const calEvtId = (note?.data.calendarEventId as string | undefined);
  const calEvtDt = (note?.data.calendarEventStart as string | undefined);
  const hasCalEvt = !!calEvtId;
  const calStart = new Date(calEvtDt || note?.created_at || new Date()).toLocaleDateString('default', {month: '2-digit', day: '2-digit', year: '2-digit'});

  const navigateToCalEvt = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!calEvtId || !calEvtDt) return;

    const params = new URLSearchParams();
    params.set(AppQueryParams.CALENDAR_VIEW_DATE, calEvtDt);
    params.set(AppQueryParams.CALENDAR_FOCUS_EVENT, calEvtId);
    window.open(`/?${params}`);
  };

  const {calendarEvent, loading: calEvtLoading} = useCalendarEvent(calEvtId || '');
  const calTitle = calEvtLoading ? <LoadingDots /> : calendarEvent?.title;
  const calEvtText = <div className='workstream-note-footer--text' onClick={navigateToCalEvt}><FontAwesomeIcon icon={faCalendarDay} className="workstream-note-footer--icon"/>{calTitle} {calStart}</div>;
  const relTime = note ? formatDistance(new Date(note.created_at), new Date()) : '';
  const createdTxt = (note?.namespace.startsWith('usr') ? `Created ${relTime} ago` : `Shared ${relTime} ago`);

  return <Note
    noteId={noteId}
    showBackArrow
    onViewNote={onViewNote}
    disablePopover
    notesLastRead={lastRead}
    showAsEditor={showAsEditor}
    footerLeftContent={hasCalEvt ? calEvtText : (tag.name || 'This workstream')}
    footerRightContent={createdTxt}
    tagId={tagId}
  />;
};

export default WorkstreamNote;