import { sort } from 'ramda';

import { RootState } from 'store';
import { getContactName } from 'utils/strings';

const root = (state: RootState) => state.contacts;
const getContactsById = (state: RootState) => root(state).contactsById;
const getFilteredContacts = (state: RootState) => root(state).contactsFiltered;
const getContactsByHandle = (state: RootState) => root(state).contactsByHandle;

export const getContactByHandle = (state: RootState, source: string, handle: string) => (getContactsByHandle(state) || {})[`${source}-${handle}`] || {};
export const getContact = (state: RootState, id: string) => (getContactsById(state) || {})[id] || {};
export const isFetchingContact = (state: RootState, id: string) => getContact(state, id).isFetching;
export const isFetchingContactByHandle = (state: RootState, source: string, handle: string) => getContactByHandle(state, source, handle).isFetching;
export const fetchContactFailed = (state: RootState, id: string) => getContact(state, id).fetchFailed;
export const getContactById = (state: RootState, id: string) => getContact(state, id).data;
export const getContactsNeverLoaded = (state: RootState) => getContactsById(state) === null;
export const fetchContactByHandleFailed = (state: RootState, source: string, handle: string) => getContactByHandle(state, source, handle).fetchFailed;

export interface IContactHandle {
  source: string;
  handle: string;
  name: string;
  id?: string;
}

export interface IContact {
  id: string;
  name: string;
  type: string;
  created_at: string;
  updated_at: string;
  handles: IContactHandle[];
}

const sidebar = (state: RootState) => root(state).sidebar;

export const getSidebarIsOpen = (state: RootState) => sidebar(state).isOpen;
export const getSidebarContactId = (state: RootState) => sidebar(state).contactId;
export const getSidebarContact = (state: RootState) => getContactById(state, getSidebarContactId(state) || '');
export const getContactsByHandleId = (state: RootState) => {
  const contacts = Object.values(getContactsById(state) || {});
  const byHandleId: {[handleId: string]: IContact} = {};
  contacts.forEach(contact => {
    (contact.data || {handles: []}).handles.forEach(handle => {
      if (handle.id && contact.data) {
        byHandleId[handle.id] = contact.data;
      }
    });
  });
  return byHandleId;
};
export const getContactByHandleId = (state: RootState, handleId?: string) => {
  if (!handleId) {
    return null;
  }
  const contacts = Object.values(getContactsById(state) || {});
  const contact = contacts.find(contact => (contact.data || { handles: [] }).handles.filter(handle => handle.id === handleId).length === 1);
  return contact?.data;
};

export const getAllSlackHandlesToNameMap = (state: RootState) => {
  const handleToName: { [key: string]: string } = {};

  const contacts = Object.values(getContactsById(state) || {});
  contacts.forEach(contact => {
    const name = getContactName(contact.data);
    contact.data?.handles.forEach(handle => {
      if (handle.source === 'slack') {
        handleToName[handle.handle] = name;
      }
    });
  });

  return handleToName;
};


export const getContactsOrderedByUpdatedAt = (state: RootState) => {
  const contacts = Object.values(getContactsById(state) || {});

  const sorter = (c1: IContact, c2: IContact) => {
    return Date.parse(c2.updated_at) - Date.parse(c1.updated_at);
  };

  return sort(sorter, (contacts.filter(c => !!c && !!c.data).map(c => c.data) as IContact[]));
};

export const getFilteredContactsOrderedByUpdatedAt = (state: RootState, filter: string) => {
  const contacts = getFilteredContacts(state)[filter] || [];

  const sorter = (c1: IContact, c2: IContact) => {
    return Date.parse(c2.updated_at) - Date.parse(c1.updated_at);
  };

  return sort(sorter, contacts);
};

export const getOldestContactUpdated = (state: RootState) => {
  const oldest = root(state).oldestContactFetch;
  return oldest && oldest.toISOString().replace('+00:00', 'Z');
};


// inefficient to always sort but we can fix later
export const getHasNoContacts = (state: RootState) => getContactsOrderedByUpdatedAt(state).length === 0;