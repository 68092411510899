import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch } from 'evergreen-ui';
import { FormEvent, useEffect, useState } from 'react';
import { faAddressCard, faBuilding } from '@fortawesome/free-solid-svg-icons';

import Button from 'components/Button';
import { NotificationType } from 'store/notifications/selectors';
import { notify } from 'store/notifications/slice';
import { patchCurrentUser } from 'api/user';
import { getCurrentUser, getForcedNormalUser, getOrgName, getRawIsSuperuser } from 'store/user/selector';
import { receiveUser, toggleForceSuperuser } from 'store/user/slice';
import { useAppDispatch, useAppSelector } from 'hooks';


const GeneralSettings = () => {
  const currentUser = useAppSelector(getCurrentUser) || {full_name: ''};
  const orgName = useAppSelector(getOrgName);
  const dispatch = useAppDispatch();
  const isSuperuser = useAppSelector(getRawIsSuperuser);
  const isForcedNormalUser = useAppSelector(getForcedNormalUser);

  const [nameInput, setNameInput] = useState(currentUser.full_name);
  const [orgNameInput, setOrgNameInput] = useState(orgName);

  const [anyInputsHaveChanged, setAnyInputsHaveChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAnyInputsHaveChanged(
      nameInput !== currentUser.full_name ||
      orgNameInput !== orgName
    );
  }, [nameInput, orgNameInput, currentUser.full_name, orgName]);

  const submit = async (e?: FormEvent) => {
    e?.preventDefault();
    setLoading(true);
    try {
      // TODO: capture the "min wait" pattern as a util
      const res = await Promise.all([
        patchCurrentUser(
          currentUser.full_name !== nameInput ? nameInput : undefined,
          orgName !== orgNameInput ? orgNameInput : undefined,
        ),
        await new Promise(res => setTimeout(res, 400))
      ]);
      const {data} = res[0];
      dispatch(receiveUser(data));
      notify({message: 'Saved new settings', type: NotificationType.SUCCESS})(dispatch);
    } catch (error) {
      notify({message: 'Failed to update settings', type: NotificationType.ERROR})(dispatch);
    } finally {
      setLoading(false);
    }
  };

  const toggleSuperuser = () => {
    notify({
      message: `Admin view of app ${isForcedNormalUser ? 'enabled' : 'disabled'}`,
      type: NotificationType.SUCCESS,
    })(dispatch);
    dispatch(toggleForceSuperuser());
  };

  return <div>
    <h3 className="general-settings--title">Update your Classify settings</h3>
    <form onSubmit={submit} className="general-settings--form">
      <div className="general-settings-form-input--container">
        <label htmlFor="name">Name</label>
        <FontAwesomeIcon icon={faAddressCard} />
        <input type="text" id="name" value={nameInput} placeholder="Jane Doe" onChange={e => setNameInput(e.target.value)} />
      </div>

      <div className="general-settings-form-input--container">
        <label htmlFor="companyName">Company</label>
        <FontAwesomeIcon icon={faBuilding} />
        <input type="text" id="companyName" value={orgNameInput} placeholder="Fortune 500, Inc." onChange={e => setOrgNameInput(e.target.value)} />
      </div>
      <Button className="general-settings-submit--btn" type="submit" size="large" buttonType="secondary" loading={loading} onClick={submit} disabled={!anyInputsHaveChanged}>Save</Button>
    </form>
    {isSuperuser && <div className="general-settings--admin-container">
      <div>View app as admin</div>
      <Switch checked={!isForcedNormalUser} height={22}  marginTop={16} onChange={toggleSuperuser} />
    </div>}
  </div>;
};

export default GeneralSettings;