import { SearchInput } from 'evergreen-ui';
import cx from 'classnames';
import { useHistory } from 'react-router';
import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';

import { usePrevious } from 'hooks';
import './style.css';


interface ISearchBarProps {
  startText?: string,
  loading?: boolean,
}


const SearchBar = ({
  startText='',
  loading=false,
}: ISearchBarProps) => {
  const [searchText, setSearchText] = useState(startText);
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const wasLoading = usePrevious(loading);
  const isLoading = wasLoading || loading;
  const doneLoading = wasLoading && !loading;

  const maybeSubmit = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      history.push(`/search?q=${searchText}`);
    } else if (e.key === 'Escape') {
      e.preventDefault();
      inputRef.current?.blur();
    }
  };

  return (
    <div className={cx('search-bar--container')}>
      <SearchInput
        placeholder="Search Classify..."
        className="search-bar--input"
        value={searchText}
        width="100%"
        onKeyDown={maybeSubmit}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
        ref={inputRef}
      />
      {isLoading && <div className={cx('search-bar-loading--line', {done: doneLoading})}/>}
    </div>
  );
};

export default SearchBar;