import { useSelector } from 'react-redux';

import Button from 'components/Button';
import { ConnectedAccountGroup } from 'components/Connector';
import GoogleButton from 'components/GoogleButton';
import { IConnector } from 'store/connectors/slice';
import JiraIcon from 'components/icons/Jira';
import OutlookIcon from 'components/icons/OutlookIcon';
import SalesForceIcon from 'components/icons/SalesForce';
import SlackIcon from 'components/icons/Slack';
import { TrelloIcon } from 'components/icons/Trello';
import { getConnectorName } from 'utils/message_ctx';
import { getConnectorsList } from 'store/connectors/selectors';
import { getCurrentUser } from 'store/user/selector';
import { getGoogleOAuthURI } from 'api/auth';
import { jiraScopes } from 'constants/app';
import { microsoftScopes } from 'constants/app';
import { openTrello } from 'utils/connect';
import { useAppSelector } from 'hooks';
import { APP_URL, JIRA_CLIENT_ID, MICROSOFT_CLIENT_ID, SALES_FORCE_CLIENT_ID, SLACK_CLIENT_ID } from 'constants/resources';
import { GateNames, userHasGate } from 'store/user/selector';



const AccountsSettings = () => {
  const connectors = useSelector(getConnectorsList);
  const user = useAppSelector(getCurrentUser);
  const hasJiraConnGate = useAppSelector(s => userHasGate(s, GateNames.JIRA_CONNECT));
  const hasSFConnGate = useAppSelector(s => userHasGate(s, GateNames.SALES_FORCE));
  const hasOutlookConnGate = useAppSelector(s => userHasGate(s, GateNames.OUTLOOK));
  const hasTrelloConnGate = useAppSelector(s => userHasGate(s, GateNames.TRELLO));

  const openJira = () => {
    if (!user) {
      return;
    }
    
    const params = new URLSearchParams();
    params.append('audience', 'api.atlassian.com');
    params.append('client_id', JIRA_CLIENT_ID);
    params.append('scope', jiraScopes.join(' '));
    params.append('redirect_uri', APP_URL + '/connect-success?provider=jira');
    params.append('state', btoa(JSON.stringify({userId: user.id})));
    params.append('response_type', 'code');
    params.append('prompt', 'consent');
  
    window.open(`https://auth.atlassian.com/authorize?${params.toString()}`);
  };

  const openSalesForce = () => {
    if (!user) {
      return;
    }
    
    const params = new URLSearchParams();
    params.append('response_type', 'code');
    params.append('client_id', SALES_FORCE_CLIENT_ID);
    params.append('scope', 'full offline_access');
    params.append('redirect_uri', APP_URL + '/connect-success?provider=sales_force');
    params.append('state', btoa(JSON.stringify({userId: user.id})));
    window.open(`https://login.salesforce.com/services/oauth2/authorize?${params.toString()}`);
  };

  const openMicrosoft = () => {
    if (!user) {
      return;
    }
    const params = new URLSearchParams();
    params.append('response_type', 'code');
    params.append('client_id', MICROSOFT_CLIENT_ID);
    params.append('scope', microsoftScopes);
    params.append('redirect_uri', APP_URL + '/connect-success/microsoft');
    params.append('state', btoa(JSON.stringify({userId: user.id})));
    window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${params.toString()}`);
  };

  const openSlack = () => {
    if (!user) return;

    const params = new URLSearchParams();
    params.append('client_id', SLACK_CLIENT_ID);
    params.append('scope', 'channels:history,channels:read,files:read,groups:history,im:history,im:read,links:read,mpim:history,users:read.email,users:read,mpim:read,groups:read,remote_files:read');
    params.append('state', btoa(JSON.stringify({userId: user.id})));
    params.append('redirect_uri', APP_URL + '/connect-success?provider=slack');
    window.open(`https://slack.com/oauth/authorize?${params.toString()}`);
  };

  const openGoogle = async () => {
    try {
      const {data} = await getGoogleOAuthURI();
      window.location.href = data.authorization_url;
    } catch (err) {
      console.warn('Could not get google auth URI', err);
    }
  };

  const groupedConnectors: {[name: string]: IConnector[]} = {};

  connectors.forEach(conn => {
    const name = getConnectorName(conn);
    if (groupedConnectors[name]) {
      groupedConnectors[name].push(conn);
    } else {
      groupedConnectors[name] = [conn];
    }
  });

  return (
    <div className="account-settings--container">
      <div>
        <h3 className="account-settings--title">Accounts</h3>
        <div>Manage your connected accounts and get the most out of Classify by connecting additional Gmail or Outlook email accounts.</div>
      </div>
      <div>
        <div>Connect more accounts</div>
        <GoogleButton className="onboarding-section-connect-google--button" onClick={() => openGoogle()}/>
        <Button buttonType="secondary" className="onboarding-section--btn " onClick={() => openSlack()}>
          <SlackIcon className="onboarding-section--icon" />
          Connect Slack
        </Button>
        {hasJiraConnGate &&
        <Button buttonType="secondary" className="onboarding-section--btn atlassian" onClick={() => openJira()}>
          <JiraIcon className="onboarding-section--icon" />
          Connect Jira
        </Button>
        }
        {hasSFConnGate &&
        <Button buttonType="secondary" className="onboarding-section--btn salesforce" onClick={() => openSalesForce()}>
          <SalesForceIcon className="onboarding-section--icon" />
          Connect SalesForce
        </Button>
        }
        {hasOutlookConnGate &&
        <Button buttonType="secondary" className="onboarding-section--btn microsoft" onClick={() => openMicrosoft()}>
          <OutlookIcon className="onboarding-section--icon" />
          Connect Outlook
        </Button>
        }
        {hasTrelloConnGate &&
        <Button buttonType="secondary" className="onboarding-section--btn trello" onClick={() => openTrello()}>
          <TrelloIcon className="onboarding-section--icon" />
          Connect Trello
        </Button>
        }
      </div>
      <div className="accounts-settings-connectors--container">
        {Object.entries(groupedConnectors).map(([name, connectors]) => <ConnectedAccountGroup canSetStatus name={name} key={connectors[0].id} connectors={connectors} />)}
      </div>
    </div>
  );
};

export default AccountsSettings;