import { API } from 'api';
import { APP_URL } from 'constants/resources';
import { ConnectorProviders } from 'constants/app';


export const loginViaEmail = (email: string, password: string) => {
  const params = new URLSearchParams();
  params.append('username', email);
  params.append('password', password);
  return API.post(
    '/api/v1/login/access-token',
    params,
    {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
  );
};

export const newPasswordUpdate = (token: string, new_password: string) =>
  API.post(
    '/api/v1/login/change-password',
    { token, new_password }
  );

export const getGoogleOAuthURI = (scopeProvider?: ConnectorProviders, authuser?: string) => {
  let query = '?';
  if (scopeProvider) {
    query += `scope_provider=${scopeProvider}&`;
  }
  if (authuser) {
    query += `login_hint=${authuser}&`;
  }
  query += `auth_host=${APP_URL}`;

  return API.get(`/api/v1/users/oauth/google-redirect${query}`);
};
