import cx from 'classnames';

import LoadingDots from 'components/LoadingDots';
import { MouseEvent, ReactNode } from 'react';

import './style.css';

interface IButtonProps {
  children: ReactNode;
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  buttonType?: 'primary' | 'secondary' | 'subtle' | 'danger';
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  active?: boolean;
}


const Button = ({
  children,
  size='medium',
  loading=false,
  className,
  disabled=false,
  buttonType='primary',
  onClick,
  active=false,
  ...rest
}: IButtonProps) => {
  return (
    <button className={cx('classify-btn', size, className, buttonType, { disabled: disabled || loading, active })} onClick={onClick} disabled={disabled} {...rest}>
      {loading ? <LoadingDots /> : children}
    </button>
  );
};

export default Button;