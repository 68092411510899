import { INote } from 'store/notes/slice';
import Note from 'components/Note';
import cx from 'classnames';
import { getIsSuperuser } from 'store/user/selector';
import { getSearchNoteById} from 'store/search/selectors';
import { useAppSelector } from 'hooks';

const SearchRelevance = ({
  noteId
}: { noteId: string}) => {
  const searchResult = useAppSelector(s => getSearchNoteById(s, noteId));

  if (!searchResult) {
    return null;
  }

  const fieldMap: Record<string, {label: string, order: number}> = {
    'message_context.content': {
      label: 'Message',
      order: 2,
    },
    'filename': {
      label: 'File name',
      order: 1,
    },
    'message_context.recipients.name': {
      label: 'Sent to',
      order: 1,
    },
    'message_context.sender.name': {
      label: 'Sent from',
      order: 1,
    },
  };

  const relevanceMessages: Record<string, {className: string, text: string}> = {
    V_HIGH: {
      className: 'very-high',
      text: 'Very high',
    },
    HIGH: {
      className: 'high',
      text: 'High',
    },
    MID: {
      className: 'medium',
      text: 'Medium',
    },
    LOW: {
      className: 'low',
      text: 'Low'
    },
  };

  const highlight = Object.entries(searchResult.highlight)
    .sort((a, b) => (fieldMap[a[0]] || {order: 0}).order - (fieldMap[b[0]] || {order: 0}).order)
    .map(([field, values]) => ({field: (fieldMap[field] || {label: field}).label, value: values[0]}));

  let relevance = relevanceMessages.LOW;

  if (searchResult.score >= 50) {
    relevance = relevanceMessages.V_HIGH;
  } else if (searchResult.score >= 30) {
    relevance = relevanceMessages.HIGH;
  } else if (searchResult.score >= 5) {
    relevance = relevanceMessages.MID;
  }

  return <div className="search-score--container">
    <h4 className="search-score--title">Search score</h4>
    <div className="search-score--section">Relevance <div className={cx('search-relevance--pill', relevance.className)}>{relevance.text}</div></div>
    <div>
      <div className="search-score--section">Reason</div>
      <div>
        {highlight.map(({field, value}) => (
          <div key={field} className="search-score-relevance-field--container">
            <div className="search-score-relevance-field--elt">{field}</div>
            <div className="search-score-relevance-field--elt" dangerouslySetInnerHTML={{__html:value}}/>
          </div>
        ))}
      </div>
    </div>
  </div>;
};

const NoteSidebar = ({
  searchSidebar=false,
  onClose,
  note,
}: {searchSidebar?: boolean, onClose: () => void, note: INote | null }) => {
  const isSuperuser = useAppSelector(getIsSuperuser);
  
  return (
    <div className={cx('view-document-sidebar--container')}>
      <div className="view-document-sidebar-content--container">
        {note && (
          <>
            <div className="view-document-sidebar--header">
              <div className="view-document-sidebar--title">
                <div>Note details</div>
              </div>
              <div className="view-document-sidebar--close" onClick={onClose}>&times;</div>
            </div>
            <div key={note.id} className="view-document-sidebar--body">
              <Note noteId={note.id} onViewNote={() => void 0} disablePopover showAsEditor />
              {isSuperuser && searchSidebar && <SearchRelevance noteId={note.id}/>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NoteSidebar;