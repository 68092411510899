import { AxiosResponse } from 'axios';
import { LOGROCKET_APP_ID } from 'constants/resources';
import LogRocket from 'logrocket';

import { IUser } from 'store/user/selector';

declare const heap: {identify: (id: string) => void, addUserProperties: (props: Record<string, unknown>) => void, track: (event: string, props: Record<string, unknown>) => void};

// Central place to show all events that we track
export enum TrackEventNames {
  CI = 'Connected integration',
  DI = 'Disabled integration',
  DTSO = 'Document timeline sidebar opened',
  ECFGC = 'Entered Classify from Google Calendar',
  ELC = 'External link clicked',
  FOCE = 'Focused on calendar event',
  FOMG = 'Focused on meeting guest',
  FOPD = 'Focused on pinned documment',
  FOPL = 'Focused on pinned file',
  FOSD = 'Focused on suggested document',
  JM = 'Joined meeting',
  LNDE = 'Link name or description edited',
  PSD = 'Pinned suggested document',
  PSRD = 'Pinned searched document',
  PSRL = 'Pinned searched link',
  PCSL = 'Pinned contact sidebar link',
  PCSD = 'Pinned contact sidebar document',
  RI = 'Re-enabled integration',
  S = 'Search',
  SD = 'Star document',
  CETAG = 'Tagged calendar event',
  UPF = 'Unpinned file',
  UPL = 'Unpinned link',
  EN = 'Edited note',
  ET = 'Edited task',
}

class Tracker {
  constructor() {}

  identify(user: IUser) {
    try {
      heap.identify(user.id);
      heap.addUserProperties({email: user.email, name: user.full_name, status: user.status});
      window.drift.identify(user.id, {email: user.email, name: user.full_name});
      if (LOGROCKET_APP_ID !== null) {
        LogRocket.identify(user.id, {
          name: user.full_name,
          email: user.email,
        });
      }
    } catch (err) {
      console.warn('[Classify.app] failed to identify user with tracker', err);
    }
  }

  setSignupVersion() {
    heap.addUserProperties({ signupVersion: process.env.REACT_APP_VERSION });
  }

  track(event: TrackEventNames, properties: Record<string, unknown> = {}) {
    // TODO: maybe restrict tracking to non-anonymous users
    heap.track(event, properties);
  }

  trackAfterRequest(event: TrackEventNames, properties: Record<string, unknown> = {}) {
    return (res: AxiosResponse) => {
      tracker.track(event, properties);
      return res;
    };
  }
}

export const tracker = new Tracker();