import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_URL } from 'constants/resources';
import { getToken } from 'utils/auth';


export const queries = createApi({
  reducerPath: 'apiQueries',

  tagTypes: ['Activity', 'Action Item'],

  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + '/api/v1',

    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('X-Classify-Version', process.env.REACT_APP_VERSION || 'no-version');
      return headers;
    },

  }),

  endpoints: () => ({}),
});
