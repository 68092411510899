import { ReactNode } from 'react';
import cx from 'classnames';

import ClassifyIcon from 'components/icons/Classify';

import './style.css';

interface IOnboardingCardWithHeaderProps {
  headerText?: string;
  className?: string;
  children?: ReactNode;
}

const OnboardingCardWithHeader: React.FC<IOnboardingCardWithHeaderProps> = ({ headerText, className, children }: IOnboardingCardWithHeaderProps) => (
  <div className="onboarding-card-with-header--background">
    <div className={cx('onboarding-card-with-header--container', className)}>
      {headerText && <div className="onboarding-card-with-header--header">
        <ClassifyIcon className="onboarding-card-with-header--classify-logo" />
        {headerText}
      </div>}
      {children}
    </div>
  </div>
);

export default OnboardingCardWithHeader;
