import { IActivity, INewCommentGdriveActivityData } from 'store/documents/selectors';
import { IContact, IContactHandle } from 'store/contacts/selectors';


export const titleCase = (word: string) => word.slice(0,1).toUpperCase() + word.slice(1).toLowerCase();

const formalFileExtensions: Record<string, {name: string, icon: string}> = {
  'image/jpeg': {
    name: 'jpeg',
    icon: 'image',
  },
  'image/jpg': {
    name: 'jpg',
    icon: 'image',
  },
  'image/heic': {
    name: 'heic',
    icon: 'image',
  },
  'image/png': {
    name: 'png',
    icon: 'image',
  },
  'image/gif': {
    name: 'gif',
    icon: 'image',
  },

  'text/plain': {
    name: 'text',
    icon: 'file',
  },

  'application/json': {
    name: 'json',
    icon: 'file',
  },

  'application/vnd.rar': {
    name: 'rar',
    icon: 'file',
  },
  'application/x-tar': {
    name: 'tar',
    icon: 'file',
  },
  'application/zip': {
    name: 'zip',
    icon: 'file',
  },

  'application/pdf': {
    name: 'PDF',
    icon: 'pdf',
  },

  'application/vnd.ms-powerpoint': {
    name: 'Powerpoint',
    icon: 'powerpoint',
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    name: 'Office slides',
    icon: 'slides',
  },
  'application/vnd.google-apps.presentation': {
    name: 'Google slides',
    icon: 'slides',
  },

  'application/vnd.ms-excel': {
    name: 'Excel',
    icon: 'spreadsheet',
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    name: 'Office spreadsheet',
    icon: 'spreadsheet',
  },
  'application/vnd.google-apps.spreadsheet': {
    name: 'Google sheet',
    icon: 'spreadsheet',
  },

  'application/msword': {
    name: 'MS Word',
    icon: 'document',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    name: 'Office word',
    icon: 'document',
  },
  'application/vnd.google-apps.document': {
    name: 'Google doc',
    icon: 'document',
  },
  'application/vnd.microsoft.portable-executable': {
    name: 'Windows executable',
    icon: 'file',
  },
  'application/pkcs7-signature': {
    name: 'Email hash',
    icon: 'file',
  },

  'video/webm': {
    name: 'Web video',
    icon: 'video',
  },
  'video/x-msvideo': {
    name: 'MS video',
    icon: 'video',
  },
  'video/ogg': {
    name: 'OGG video',
    icon: 'video',
  },
  'video/mp4': {
    name: 'MP4',
    icon: 'video',
  },
  'video/mpeg': {
    name: 'MPEG',
    icon: 'video',
  },
  'video/mpg': {
    name: 'MPG',
    icon: 'video',
  },
  'video/mp2': {
    name: 'MP2',
    icon: 'video',
  },
  'video/quicktime': {
    name: 'Quicktime',
    icon: 'video',
  },

  'audio/wav': {
    name: 'WAV audio',
    icon: 'file',
  },
  'audio/mpeg': {
    name: 'MPEG audio',
    icon: 'file',
  },
  'audio/webm': {
    name: 'Web audio',
    icon: 'file',
  },
  'audio/mp4': {
    name: 'MP4 audio',
    icon: 'file',
  },

  'application/octet-stream': {
    name: 'Unspecified',
    icon: 'file',
  },

  'application/vnd.google-apps.form': {
    name: 'Google Form',
    icon: 'file',
  },

  'application/vnd.google-apps.drawing': {
    name: 'Google Drawing',
    icon: 'drawing',
  },

};


export const iconNameForMimetype = (mimetype: string) => (formalFileExtensions[mimetype] || {icon: 'file'}).icon;

export const humanizeMimetype = (mimetype: string) => (formalFileExtensions[mimetype] || {name: mimetype}).name;


const randomColors = [
  {light: 'var(--color-green-2-1)', dark: 'var(--color-green-2-3)'},
  {light: 'var(--color-yellow-2)', dark: 'var(--color-yellow-4)'},
  {light: 'var(--color-purple-2)', dark: 'var(--color-purple-5)'},
  {light: 'var(--color-neutral-4)', dark: 'var(--color-blue-2)'},
  {light: 'var(--color-blue-2-1)', dark: 'var(--color-blue-2-4)'},
  {light: 'var(--color-neutral-7)', dark: 'var(--color-neutral-10)'},
  {light: 'var(--color-red-2)', dark: 'var(--color-red-5)'},
  {light: 'var(--color-green-1)', dark: 'var(--color-green-5)'},
];

// take a string and consistently return the same color
export const stringToColor = (anyString: string) => {
  const asciiString = anyString.replace(/[^a-zA-Z0-9]/g, '');
  const int = parseInt(asciiString, 36);
  return randomColors[int % randomColors.length];
};

const automatedHandles = [
  'noreply',
  'no-reply',
  'jira@',
  'mail.airtable.com',
  'notification@slack-mail.com',
  'calendar-notification@google.com',
  'hello@intercom.com',
  'MAILER-DAEMON',
  'webinar',
  '@email.figma.com',
];


export const getSlackWorkspaceIdFronHandle = (slackHandle: string) => slackHandle.split('.')[0];

export const getContactName = (contact?: IContact | null) => {
  if (!contact) {
    return '(Unknown name)';
  }

  const handles: IContactHandle[] = contact.handles;

  const anyHandlesAreAutomated = handles.some(({handle}) => automatedHandles.some(automated => handle.includes(automated)));

  if (contact.name && !anyHandlesAreAutomated) {
    return contact.name;
  }

  const handleName = handles.reduce((sofar: string | null | undefined, h: IContactHandle) => sofar || h.name, contact.name);

  if (handleName && !anyHandlesAreAutomated) {
    return handleName;
  }

  const emailHandle = handles.find((h) => h.source === 'email');
  if (emailHandle) {
    return emailHandle.handle;
  }

  return '(Unknown name)';
};

export const validateEmail = (email: string) => {
  // Link to regex expression for email validation
  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const emailValidation = /\S+@\S+\.\S+/;
  return emailValidation.test(String(email).toLowerCase());
};

export const extractUrls = (corpus: string, limit?: number): string[] => {
  // (start of string OR single whitespace) followed by http(s optional)://
  // followed by any non-whitespace character one or more times
  // Case insensitive since URLs are case insensitive
  // NOTE: this is only made to catch web addresses and will not
  // capture emails, ftp links, "native" links (e.g. slack://), etc.
  const urlRegex = /(^|\s)https?:\/\/[^\s]+/gi;
  return [...corpus.matchAll(urlRegex)].map(([url])=> url).slice(0, limit || Infinity);
};

export const wrapURLsWithAnchorTags = (urlString: string) => {
  return extractUrls(urlString)
    // reduce over the event description and, for every URL that matches the regex
    // replace the URL we found with an anchor tag wrapped URL
    .reduce(
      (rawString, url) => rawString.replace(url, ` <a href=${url} target="_blank" rel="noopener noreferrer nofollow">${url}</a>`),
      urlString
    );
};

export enum ActivityTitleTemplateVariables {
  ACTOR_NAME = '__ACTOR_NAME__',
  EMAIL_SUBJECT = '__EMAIL_SUBJECT__',
  FILE_NAME = '__FILE_NAME__',
}


export const getActivityTitleTemplate = (activity: IActivity) => {
  if ('slack_file_share' in activity.data) {
    switch (activity.data.slack_file_share.channel_space) {
      case 'im':
        return `${ActivityTitleTemplateVariables.ACTOR_NAME} shared a file with you`;
      case 'mpim':
        return `${ActivityTitleTemplateVariables.ACTOR_NAME} shared a file in a Group DM`;
      default:
        return `${ActivityTitleTemplateVariables.ACTOR_NAME} shared a file in ${activity.data.slack_file_share.channel_name}`;
    }
  } else if ('slack_message' in activity.data) {
    switch (activity.data.slack_message.channel_space) {
      case 'im':
        return `${ActivityTitleTemplateVariables.ACTOR_NAME} mentioned you`;
      case 'mpim':
        return `${ActivityTitleTemplateVariables.ACTOR_NAME} mentioned you in a Group DM`;
      default:
        return `${ActivityTitleTemplateVariables.ACTOR_NAME} mentioned you in ${activity.data.slack_message.channel_name}`;
    }
  } else if ('gmail_message' in activity.data) {
    return `${activity.data.gmail_message.subject} from ${ActivityTitleTemplateVariables.ACTOR_NAME}`;
  } else if (activity.type === 'NEW_FILE_COMMENT_GDRIVE' || activity.type === 'NEW_FILE_MENTION_GDRIVE') {
    const commentData = (activity.data as INewCommentGdriveActivityData);
    const lastIsResolved = commentData.messages[commentData.messages.length - 1].content.text === '__MARKED_GDRIVE_COMMENT_RESOLVED__';
    if (lastIsResolved) {
      return `${ActivityTitleTemplateVariables.ACTOR_NAME} resolved a comment in ${ActivityTitleTemplateVariables.FILE_NAME}`;
    } else if (commentData.messages.length === 1) {
      return `${ActivityTitleTemplateVariables.ACTOR_NAME} commented on ${ActivityTitleTemplateVariables.FILE_NAME}`;
    } else {
      return `${ActivityTitleTemplateVariables.ACTOR_NAME} replied to a comment in ${ActivityTitleTemplateVariables.FILE_NAME}`;
    }
  }
  return '';
};

export const extractHostName = (url: string) => {
  const { hostname } = new URL(url);
  return hostname;
};
