import { RootState } from 'store';


export interface IProperty {
  namespace: string;
  name: string;
  value: unknown;
}

const EMPTY_PROPERTY = {
  namespace: '*',
  name: 'NOT_FOUND',
  value: undefined,
};
interface IOrg {
  id: string;
  properties: IProperty[];
}

export interface IUserShallow {
  id: string;
  full_name: string;
  email: string;
  is_superuser: boolean;
  org_id: string;
  status: string;
}

export interface IUser {
  id: string;
  full_name: string;
  email: string;
  is_superuser: boolean;
  org_id: string;
  status: string;
  properties: IProperty[];
  org: IOrg;
}

export const UserPropertyNames = {
  ONBOARDING_FINISHED: 'onboarding.finished',
  ONBOARDING_STEP: 'onboarding.step',
  SIGNUP_JOB_TYPE: 'job_type',
  SIGNUP_COMPANY_SIZE: 'company_size',
  SIGNUP_DISCOVERY_SOURCE: 'classify_discovery_source',
};

export const OnboardingStepNames = {
  SIGNUP_SURVEY: 'signup-survey',
  CONNECT_GOOGLE: 'connect-google',
  SIGNUP_COMPLETE: 'signup-complete'
};

export const GateNames = {
  RULES: 'has_rules',
  JIRA_CONNECT: 'has_jira_connect',
  SALES_FORCE: 'has_sales_force_connect',
  OUTLOOK: 'has_outlook_connect',
  CONTACTS: 'has_contacts_link',
  LINKS_SEARCH: 'has_links_search',
  TRELLO: 'has_trello_connect',
  NOTES_SEARCH: 'has_notes_search',
};

const root = (state: RootState) => state.user;

const usersById = (state: RootState) => root(state).usersById;
export const getUserById = (state: RootState, userId: string) => usersById(state)[userId];
export const getUserNameById = (state: RootState, userId: string) => getUserById(state, userId)?.full_name;

export const getCurrentUser = (state: RootState) => root(state).user;
export const getCurrentUserEmail = (state: RootState) => getCurrentUser(state)?.email;
export const getCurrentUserEmailDomain = (state: RootState) => getCurrentUserEmail(state)?.split('@')[1] || '__NONE__';
export const getUserPropsByName = (state: RootState) => root(state).propertiesByName;
const getAllProps = (state: RootState) => Object.values(root(state).propertiesByName || {});

const getUserPropByName = (state: RootState, name: string): IProperty => (getUserPropsByName(state) || {})[name] || EMPTY_PROPERTY;
const getUserGateByName = (state: RootState, name: string): IProperty => {
  const prop = getUserPropByName(state, name);
  if (prop.namespace === 'GATES') {
    return prop;
  } else {
    return EMPTY_PROPERTY;
  }
};
export const userHasGate = (state: RootState, name: string): boolean => getUserGateByName(state, name).value === true;
export const getEnabledGateNames = (state: RootState): Set<string> => {
  return new Set(getAllProps(state).filter(prop => prop.namespace === 'GATES' && prop.value === true).map(prop => prop.name));
};
export const getUserFinishedOnboarding = (state: RootState) => {
  const prop = getUserPropByName(state, UserPropertyNames.ONBOARDING_FINISHED);
  return prop.value === true;
};

export const getUserOnboardingStep = (state: RootState) => {
  const prop = getUserPropByName(state, UserPropertyNames.ONBOARDING_STEP);
  return prop.value;
};

const getCurrentOrg = (state: RootState) => (getCurrentUser(state) || {}).org;
const getOrgProps = (state: RootState) => (getCurrentOrg(state) || {}).properties || [];
const getOrgPropByName = (state: RootState, name: string): IProperty => getOrgProps(state).find(p => p.name === name) || EMPTY_PROPERTY;
export const getOrgName = (state: RootState): string => (getOrgPropByName(state, 'name').value as string) || '';

export const getRawIsSuperuser = (state: RootState) => (getCurrentUser(state) || {}).is_superuser;

export const getIsSuperuser = (state: RootState) => getRawIsSuperuser(state) && !root(state).forceNonSuperuser;
export const getForcedNormalUser = (state: RootState) => root(state).forceNonSuperuser;