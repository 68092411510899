const CACHE_NAME = 'classify_app_cache';

export default class Cache {
  static getUserData() {
    return JSON.parse(localStorage.getItem(CACHE_NAME) || '{}');
  }

  static get(key: string) {
    return Cache.getUserData()[key];
  }

  static saveUserData(value: string) {
    localStorage.setItem(CACHE_NAME, JSON.stringify(value));
  }

  static set(key: string, value: string) {
    const data = Cache.getUserData();
    data[key] = value;
    Cache.saveUserData(data);
  }
}
