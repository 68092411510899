
const OnboardingRemember = () => {
  return <div className="onboarding-section--container">
    <div className="onboarding-section--hello">About remember</div>
    <h3 className="onboarding-section--headline">Files to attend later</h3>
    <div className="onboarding-section--description">Use Remember to keep track of files, people, or messages
      that you want to return to later
    </div>
  </div>;

};

export default OnboardingRemember;