import { IActivity } from 'store/documents/selectors';
import { RootState } from 'store';

import { ConnectorProviders } from 'constants/app';


const root = (state: RootState) => state.connectors;
export const getConnectorsList = (state: RootState) => Object.values(root(state).connectorsById);
export const getHasNoConnectors = (state: RootState) => getConnectorsList(state).length === 0;
export const getHasNoGCalConnectors = (state: RootState) => getConnectorsList(state).filter(conn => conn.provider === ConnectorProviders.GCAL).length === 0;
export const getConnector = (state: RootState, id: string) => root(state).connectorsById[id];
export const getConnectorProvidersForActivity = (activity: IActivity[]) => {
  const providers = new Set();
  activity.forEach(act => {
    if (act.data.connector && act.data.connector.provider) {
      providers.add(act.data.connector.provider);
    }
  });

  return ([...providers] as string[]);
};
