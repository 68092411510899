let ResourcesConstants;

if (process.env.REACT_APP_ENV === 'prod') {
  ResourcesConstants = {
    WWW_URL: 'https://www.classifyltd.com',
    WS_URL: 'wss://live.api.classify-prod.com',
    APP_URL: process.env.REACT_APP_APP_URL || 'https://app.classify-prod.com',
    API_URL: 'https://app.api.classify-prod.com',
    SENTRY_DSN: 'https://b8f29e4b005d452d9d8e66b07c63613c@o960944.ingest.sentry.io/5934133',
    SENTRY_SAMPLE_RATE: 1.0, // TODO: change this when we have more users
    SLACK_CLIENT_ID: '1393041578817.2488584299671',
    JIRA_CLIENT_ID: 'GTGvtIXeoDDD18INZCcPGVd9v3aVLzqb',
    SALES_FORCE_CLIENT_ID: '3MVG9t0sl2P.pByp1JZnDD9swx1uF9yMs31XG1nPB1hijS5Q1CcAKzynkZ9Md_sg2CWnanRmDTbSnszEhm9SU',
    MICROSOFT_CLIENT_ID: '5f4a605f-d88e-4999-9272-d050a6724d4c',  // TODO: must be updated when microsoft conn go to prod
    HEAP_APP_ID: '3581943487',
    TRELLO_API_KEY: 'bcf01d8e0c63c70574462de8774fb328', 
    LOGROCKET_APP_ID: 'siu3m9/classify',
  };
} else if (process.env.REACT_APP_ENV === 'dev') {
  ResourcesConstants = {
    WWW_URL: 'https://www.classifyltd.com',
    WS_URL: 'wss://live.api.classify-dev.com',
    APP_URL: process.env.REACT_APP_APP_URL  || 'https://app.classify-dev.com',
    API_URL: 'https://app.api.classify-dev.com',
    SENTRY_DSN: 'https://b8f29e4b005d452d9d8e66b07c63613c@o960944.ingest.sentry.io/5934133',
    SENTRY_SAMPLE_RATE: 1.0,
    SLACK_CLIENT_ID: '1393041578817.2415762665523',
    JIRA_CLIENT_ID: 'GTGvtIXeoDDD18INZCcPGVd9v3aVLzqb',
    SALES_FORCE_CLIENT_ID: '3MVG9t0sl2P.pByp1JZnDD9swx1uF9yMs31XG1nPB1hijS5Q1CcAKzynkZ9Md_sg2CWnanRmDTbSnszEhm9SU',
    MICROSOFT_CLIENT_ID: '5f4a605f-d88e-4999-9272-d050a6724d4c',  // TODO: must be updated when microsoft conn go to prod
    HEAP_APP_ID: '634855772',
    TRELLO_API_KEY: '6f1a43553dfe3d2dc5872e55203e830a',  
    LOGROCKET_APP_ID: 'siu3m9/classify-dev',
  };
} else {
  ResourcesConstants = {
    // API_URL: 'http://localhost:8000',
    API_URL: process.env.REACT_APP_API_URL || 'https://app.api.classify-dev.com',
    WS_URL: process.env.REACT_APP_WS_URL || 'wss://live.api.classify-dev.com',
    WWW_URL: 'http://localhost:8000',
    APP_URL: 'https://localhost:3000',
    SENTRY_DSN: '',
    SENTRY_SAMPLE_RATE: 1.0,
    SLACK_CLIENT_ID: '1393041578817.2415762665523',
    JIRA_CLIENT_ID: 'GTGvtIXeoDDD18INZCcPGVd9v3aVLzqb',
    SALES_FORCE_CLIENT_ID: '3MVG9t0sl2P.pByp1JZnDD9swx1uF9yMs31XG1nPB1hijS5Q1CcAKzynkZ9Md_sg2CWnanRmDTbSnszEhm9SU',
    MICROSOFT_CLIENT_ID: '5f4a605f-d88e-4999-9272-d050a6724d4c',
    HEAP_APP_ID: '634855772',
    TRELLO_API_KEY: '6f1a43553dfe3d2dc5872e55203e830a',
    LOGROCKET_APP_ID: process.env.REACT_APP_LOGROCKET_APP_ID || 'siu3m9/classify-local',
  };
}

export const DEBUG = process.env.REACT_APP_ENV !== 'prod';
export const LOCAL = DEBUG && process.env.REACT_APP_ENV !== 'dev';
export const API_URL = ResourcesConstants.API_URL;
export const WWW_URL = ResourcesConstants.WWW_URL;
export const APP_URL = ResourcesConstants.APP_URL;
export const WS_URL = ResourcesConstants.WS_URL;
export const SENTRY_DSN = ResourcesConstants.SENTRY_DSN;
export const SENTRY_SAMPLE_RATE = ResourcesConstants.SENTRY_SAMPLE_RATE;
export const SLACK_CLIENT_ID = ResourcesConstants.SLACK_CLIENT_ID;
export const JIRA_CLIENT_ID = ResourcesConstants.JIRA_CLIENT_ID;
export const SALES_FORCE_CLIENT_ID = ResourcesConstants.SALES_FORCE_CLIENT_ID;
export const MICROSOFT_CLIENT_ID = ResourcesConstants.MICROSOFT_CLIENT_ID;
export const HEAP_APP_ID = ResourcesConstants.HEAP_APP_ID;
export const TRELLO_API_KEY = ResourcesConstants.TRELLO_API_KEY;
export const LOGROCKET_APP_ID = ResourcesConstants.LOGROCKET_APP_ID;
