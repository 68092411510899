import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IAsyncDataSlice } from 'store/interfaces';
import { getCurrentUser } from 'store/user/selector';
import { getTeamIds } from 'store/teams/selectors';
import { AppDispatch, RootState } from 'store';
import { fetchNote, fetchNotesPage, patchNote } from 'api/notes';
import { getDirtyNoteContent, getDirtyNoteTitle, getNoteById, getNotePatchTimeoutId, getNoteTimelineLoading, getNoteTimelineOffset, getSavingNote, isFetchingNote } from './selectors';

export interface INoteData {
  calendarEventId?: string;
  calendarEventStart?: string;
}

export interface INote {
  id: string
  title: string
  data: INoteData
  content: string
  org_id: string
  namespace: string
  created_by: string
  updated_by: string
  deleted_by?: string
  created_at: string
  updated_at: string
  deleted_at?: string
}

interface IDirtyNoteData {
  title: string
  content: string
  saving: boolean
  saveTimeoutId: null | ReturnType<typeof setTimeout>
  saveFailed: boolean
}

export interface NotesState {
  notesById: { [noteId: string]: IAsyncDataSlice<INote> },
  dirtyNoteDataById: { [noteId: string]: IDirtyNoteData },
  notesTimeline: {
    timelineOffset: number;
    noMoreScrollback: boolean;
    isFetching: boolean;
    noteIds: string[];
  }
}

const initialState: NotesState = {
  notesById: {},
  dirtyNoteDataById: {},
  notesTimeline: {
    timelineOffset: 0,
    noMoreScrollback: false,
    isFetching: false,
    noteIds: [],
  },
};

export interface INoteUpdatedWSPayload {
  created_at: string,
  created_by: string,
  deleted_at: string | null,
  deleted_by: string | null,
  id: string,
  updated_at: string,
  updated_by:string,
}


export const fetchNextNoteTimeline = createAsyncThunk<{notes: INote[], limit: number} | undefined, {limit: number}, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'notes/fetchNextNoteTimeline',
  async ({limit}, {getState, dispatch}) => {
    if (!getNoteTimelineLoading(getState())) {
      const namespaces: string[] = [];
      const user = getCurrentUser(getState());
      if (user) {
        namespaces.push(`usr:${user.id}`);
      }
      getTeamIds(getState()).forEach(teamId => {
        namespaces.push(`tea:${teamId}`);
      });
      const offset = getNoteTimelineOffset(getState());
      dispatch(notesSlice.actions.setNoteTimelineLoading({ loading: true }));
      const {data} = await fetchNotesPage(offset, namespaces, limit);
      dispatch(notesSlice.actions.receiveNotes(data));
      return {notes: data, limit};
    }
  }
);


export const wsFetchNoteById = createAsyncThunk<INote | undefined, INoteUpdatedWSPayload, {dispatch: AppDispatch}>(
  'notes/wsFetchNoteById',
  async ({id: noteId}, {dispatch}) => {
    // TODO: filtering in the store to decide if we want to fetch
    const {data} = await fetchNote(noteId);
    dispatch(receiveNotes([data]));
    return data;
  }
);

export const fetchNoteById = createAsyncThunk<INote | undefined, string, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'notes/fetchNoteById',
  async (noteId: string, {getState, dispatch}) => {
    if (!isFetchingNote(getState(), noteId)) {
      dispatch(notesSlice.actions.setFetchingNote({ noteId, isFetching: true }));
      const {data} = await fetchNote(noteId);
      dispatch(receiveNotes([data]));
      return data;
    }
  }
);


export const updateNote = ({noteId, content, title}: { noteId: string, content?: string, title?: string }) => async (dispatch: AppDispatch, getState: () => RootState) => {
  if (typeof content === 'string') {
    dispatch(notesSlice.actions.setNoteContent({ noteId, content }));
  }

  if (typeof title === 'string') {
    dispatch(notesSlice.actions.setNoteTitle({ noteId, title }));
  }

  const save = (typeof title === 'string') || (typeof content === 'string') && !getSavingNote(getState(), noteId);

  const patch = async () => {
    const note = getNoteById(getState(), noteId);
    const patchContent = getDirtyNoteContent(getState(), noteId);
    const patchTitle = getDirtyNoteTitle(getState(), noteId);
    dispatch(notesSlice.actions.startedNotePatch({ noteId }));
    try {
      const {data} = await patchNote(noteId, patchContent, patchTitle, note?.data || {});
      dispatch(receiveNotes([data]));
      dispatch(notesSlice.actions.finishedNotePatch({ noteId }));
    } catch (err) {
      dispatch(notesSlice.actions.failedNotePatch({ noteId }));
      console.warn(err);
    }
  };

  const existingTimeoutId = getNotePatchTimeoutId(getState(), noteId);

  if (save) {
    if (existingTimeoutId) clearTimeout(existingTimeoutId);

    const timeoutId = setTimeout(patch, 1200);
    dispatch(notesSlice.actions.queueNotePatch({ timeoutId, noteId }));
  }
};


export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    receiveNotes: (state, action: PayloadAction<INote[]>) => {
      action.payload.forEach(note => {
        state.notesById[note.id] = {
          data: note,
          isFetching: false,
          fetchFailed: false,
          lastReceived: new Date(),
        };
      });
    },

    setNoteTimelineLoading: (state, action: PayloadAction<{loading: boolean}>) => {
      state.notesTimeline.isFetching = action.payload.loading;
    },

    removeNote: (state, action: PayloadAction<{noteId: string}>) => {
      const { noteId } = action.payload;
      delete state.notesById[noteId];
      delete state.dirtyNoteDataById[noteId];
    },

    setFetchingNote: (state, action: PayloadAction<{noteId: string, isFetching: boolean}>) => {
      const {
        noteId,
        isFetching,
      } = action.payload;

      if (!state.notesById[noteId]) {
        state.notesById[noteId] = {
          data: null,
          isFetching,
          lastReceived: null,
          fetchFailed: false,
        };
      } else {
        state.notesById[noteId].isFetching = isFetching;
      }
    },

    setNoteContent: (state, action: PayloadAction<{noteId: string, content: string}>) => {
      const {
        noteId,
        content,
      } = action.payload;

      if (!state.dirtyNoteDataById[noteId]) {
        const note = (state.notesById[noteId] || {}).data || {title: '', content};
        state.dirtyNoteDataById[noteId] = {
          title: note.title,
          content: content,
          saving: false,
          saveTimeoutId: null,
          saveFailed: false,
        };
      } else {
        state.dirtyNoteDataById[noteId].content = content;
      }
    },

    setNoteTitle: (state, action: PayloadAction<{noteId: string, title: string}>) => {
      const {
        noteId,
        title,
      } = action.payload;

      if (!state.dirtyNoteDataById[noteId]) {
        const note = (state.notesById[noteId] || {}).data || {title, content: ''};
        state.dirtyNoteDataById[noteId] = {
          title: title,
          content: note.content,
          saving: false,
          saveTimeoutId: null,
          saveFailed: false,
        };
      } else {
        state.dirtyNoteDataById[noteId].title = title;
      }
    },

    startedNotePatch: (state, action: PayloadAction<{noteId: string}>) => {
      const {
        noteId,
      } = action.payload;

      if (!state.dirtyNoteDataById[noteId]) {
        const note = (state.notesById[noteId] || {}).data || {title: '', content: ''};
        state.dirtyNoteDataById[noteId] = {
          title: note.title,
          content: note.content,
          saving: true,
          saveTimeoutId: null,
          saveFailed: false,
        };
      } else {
        state.dirtyNoteDataById[noteId].saving = true;
        state.dirtyNoteDataById[noteId].saveFailed = false;
      }
    },

    finishedNotePatch: (state, action: PayloadAction<{noteId: string}>) => {
      const {
        noteId,
      } = action.payload;

      if (!state.dirtyNoteDataById[noteId]) {
        const note = (state.notesById[noteId] || {}).data || {title: '', content: ''};
        state.dirtyNoteDataById[noteId] = {
          title: note.title,
          content: note.content,
          saving: false,
          saveTimeoutId: null,
          saveFailed: false,
        };
      } else {
        state.dirtyNoteDataById[noteId].saving = false;
        state.dirtyNoteDataById[noteId].saveTimeoutId = null;
        state.dirtyNoteDataById[noteId].saveFailed = false;
      }
    },

    failedNotePatch: (state, action: PayloadAction<{noteId: string}>) => {
      const {
        noteId,
      } = action.payload;

      if (!state.dirtyNoteDataById[noteId]) {
        const note = (state.notesById[noteId] || {}).data || {title: '', content: ''};
        state.dirtyNoteDataById[noteId] = {
          title: note.title,
          content: note.content,
          saving: false,
          saveTimeoutId: null,
          saveFailed: true,
        };
      } else {
        state.dirtyNoteDataById[noteId].saving = false;
        state.dirtyNoteDataById[noteId].saveTimeoutId = null;
        state.dirtyNoteDataById[noteId].saveFailed = true;
      }
    },

    queueNotePatch: (state, action: PayloadAction<{noteId: string, timeoutId: ReturnType<typeof setTimeout>}>) => {
      const {
        noteId,
        timeoutId,
      } = action.payload;

      if (!state.dirtyNoteDataById[noteId]) {
        const note = (state.notesById[noteId] || {}).data || {title: '', content: ''};
        state.dirtyNoteDataById[noteId] = {
          title: note.title,
          content: note.content,
          saving: false,
          saveTimeoutId: timeoutId,
          saveFailed: false,
        };
      } else {
        state.dirtyNoteDataById[noteId].saveTimeoutId = timeoutId;
        state.dirtyNoteDataById[noteId].saveFailed = false;
      }
    },

    pushNoteToTimeline: (state, action: PayloadAction<INote>) => {
      state.notesTimeline.noteIds.unshift(action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNextNoteTimeline.fulfilled, (state, action) => {
      if (!action.payload) {
        // thunk short-circuited because another fetch is in flight
        return;
      }

      const {
        notes,
        limit
      } = action.payload;

      state.notesTimeline = {
        isFetching: false,
        timelineOffset: limit + state.notesTimeline.timelineOffset,
        noMoreScrollback: notes.length < limit,
        // TODO: account for duplicates
        noteIds: [...state.notesTimeline.noteIds, ...notes.map(n => n.id)],
      };
    });
  },
});

export const { receiveNotes, removeNote, pushNoteToTimeline } = notesSlice.actions;
export default notesSlice.reducer;