import { ActivitySubtitles } from 'constants/app';
import * as ramda from 'ramda';

import { ITagRelation } from 'store/tags/selectors';
import { RootState } from 'store';
import { getContactName } from 'utils/strings';


const root = (state: RootState) => state.documents;

const getDocumentsById = (state: RootState) => root(state).documentsById;
const getDocument = (state: RootState, id: string) => (getDocumentsById(state) || {})[id] || {};
export const isFetchingDocument = (state: RootState, id: string) => getDocument(state, id).isFetching;
export const fetchDocumentFailed = (state: RootState, id: string) => getDocument(state, id).fetchFailed;
export const getDocumentById = (state: RootState, documentId: string) => getDocument(state, documentId).data;
const sidebar = (state: RootState) => root(state).sidebar;

export const getSidebarIsOpen = (state: RootState) => sidebar(state).isOpen;
export const getSidebarDocumentId = (state: RootState) => sidebar(state).documentId;
export const getSidebarDocument = (state: RootState) => getDocumentById(state, getSidebarDocumentId(state) || '');

export const getDocumentsNeverLoaded = (state: RootState) => getDocumentsById(state) === null;

const getMostRecentDocActivity = (state: RootState, docId: string) => getDocumentById(state, docId)?.latest_activity[0];
export const getMostRecentDocTimestamp = (state: RootState, docId: string) => getMostRecentDocActivity(state, docId)?.timestamp || getDocumentById(state, docId)?.created_at;
export const getContactIdOfMostRecentDocActivity = (state: RootState, docId: string) => getMostRecentDocActivity(state, docId)?.data.actor?.contact_id;
export const getSenderNameOfMostRecentActivityMessages = (state: RootState, docId: string) => {
  const activity = getMostRecentDocActivity(state, docId);
  if (activity && 'messages' in activity.data) {
    const msgs = activity.data.messages;
    return msgs.reduce((oldName, msg) => msg.sender.name || oldName, getContactName(null));
  }
  return getContactName(null);
};
export const getMostRecentDocActivityType = (state: RootState, docId: string) => getMostRecentDocActivity(state, docId)?.type;

export const getDocumentsOrderedByCreatedAt = (state: RootState, starred?:boolean) => {
  const docs = getDocumentsById(state);

  if (docs === null) {
    return [];
  }

  const filteredDocs = starred ? Object.values(docs).filter(doc => doc.data?.star).map(d => d.data) : Object.values(docs).map(d => d.data);
  const userPrivateDocs = filteredDocs.filter(d => !!d).filter(doc => doc?.namespace?.startsWith('usr:'));

  return ramda.sort(
    (doc1, doc2) => Date.parse(doc2.created_at) - Date.parse(doc1.created_at),
    (userPrivateDocs as IDocument[])
  );
};

export const getOldestDocumentTimestamp = (state: RootState) => {
  const oldest = root(state).oldestTimelineFetch;
  return oldest && oldest.toISOString().replace('+00:00', 'Z');
};

export const getOldestRememberTimestamp = (state: RootState) => {
  const oldest = root(state).oldestRememberFetch;
  return oldest && oldest.toISOString().replace('+00:00', 'Z');
};


// inefficient to always sort but we can fix later
export const getHasNoDocuments = (state: RootState) => getDocumentsOrderedByCreatedAt(state).length === 0;

interface IContact {
  name: string
  handle: string
  source: string
}

export interface IMessageContext {
  sender: IContact
  recipients: IContact[]
  content: string
  sent_at: string // ISO8601 datetime string
  thread_id: string
}

export interface IActivityMessage {
  sent_at: string;
  sender: {
    name: string;
    contact_id: string | null;  // may be null
    contact_handle_id: string | null;
  };
  content: {
    text: string;
    html?: string;
    quoted_html?: string;
  };
}

export interface IAnonymousActivityMessage {
  sent_at: string;
  sender: {
    name: string;
    contact_id: string | null;
    contact_handle_id: string | null;
  };
  content: {
    text: string;
    html?: string;
    quoted_html?: string;
  };
}

interface IActivityData {
  actor: {
    contact_id: string;
    contact_handle_id: string;
  },
  connector: {
    id: string;
    provider: string;
  },
}

interface IAnonymousActivityData {
  actor: {
    contact_id: string;
    contact_handle_id: string;
  } | null,
  connector: {
    id: string;
    provider: string;
  },
}


export interface INewFileGdriveActivityData extends IActivityData {
  document_id: string;
  gdrive_file: {
    authuser: string;
    file_id: string;
  };
}


export interface INewCommentGdriveActivityData extends IAnonymousActivityData {
  document_id: string,
  gdrive_file: {
    file_id: string,
    comment_id: string,
    authuser: string,
  },
  messages: IAnonymousActivityMessage[],
}


export interface INewFileGmailActivityData extends IActivityData {
  document_ids: string[];
  gmail_message: {
    authuser: string;
    message_id: string;
    thread_id: string;
    snippet: string;
    subject: string;
  };
  // deprecated, but kept for older events
  // that don't have gmail_message
  gmail_file_share: {
    authuser: string;
    message_id: string;
    thread_id: string;
    snippet: string;
    subject: string;
  };
  messages: IActivityMessage[];
}


export interface INewMentionSlackActivityData extends IActivityData {
  slack_message: {
    message_ts: string,
    team_id: string,
    channel_id: string,
    channel_space: string,
    channel_name: string,
    slack_user_id: string,
  };
  messages: IActivityMessage[];
}


export interface INewFileSlackActivityData extends IActivityData {
  document_id: string;
  slack_file_share: {
    file_id: string;
    channel_id: string;
    channel_name: string;
    channel_space: string;
    slack_user_id: string;
  };
  messages: IActivityMessage[];
}

export interface IActivity {
  id: string
  user_id: string
  timestamp: string
  type: string
  meta: Record<string, unknown>;
  data: INewFileGdriveActivityData | INewFileGmailActivityData | INewFileSlackActivityData | INewMentionSlackActivityData;
  dedup_key: string
}

export interface IActivityRender extends IActivity {
  subtitle?: ActivitySubtitles;
}

export type AllActivity = IActivity & IActivityRender


export interface IDocument {
  id: string
  connector_id: string
  size_bytes: number
  filename: string
  mimetype: string
  namespace?: string
  star: boolean
  created_at: string
  updated_at: string
  location: string
  latest_activity: IActivity[]
  tags: ITagRelation[]
}

interface IDateAndDocuments {
  date: Date;
  documents: IDocument[]; // TODO: fill out interface
}

export const getDocumentDateGroups = (state: RootState, starred?: boolean): IDateAndDocuments[] => {
  const orderedDocs = getDocumentsOrderedByCreatedAt(state, starred);

  const groups: IDateAndDocuments[] = [];

  if (orderedDocs.length === 0) {
    return groups;
  }

  let currentDate = new Date(orderedDocs[0].created_at);
  let currentGroup: IDocument[] = [];

  orderedDocs.forEach(document => {
    const docDate = new Date(document.created_at);

    if (docDate.getDate() !== currentDate.getDate()) {
      groups.push({
        date: currentDate,
        documents: currentGroup,
      });
      currentDate = docDate;
      currentGroup = [];
    }

    currentGroup.push(document);
  });

  if (currentGroup.length) {
    groups.push({
      date: currentDate,
      documents: currentGroup,
    });
  }

  return groups;
};

export const getDocumentsByContactId = (state: RootState) => root(state).documentsByContactId;
export const getDocumentsFromContact = (state: RootState, contactId: string) => (getDocumentsByContactId(state) || {})[contactId] || [];
