import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { getCurrentUser, getUserFinishedOnboarding, getUserOnboardingStep } from 'store/user/selector';


const LoginRequiredRoute = ({ children, ...rest }: {children: JSX.Element} & RouteProps) => {
  const isLoggedIn = useAppSelector(getCurrentUser);
  const finishedOnboarding = useAppSelector(getUserFinishedOnboarding);
  const onboardingStep = useAppSelector(getUserOnboardingStep);

  const location = useLocation();
  const shouldRedirectToOnboarding = !finishedOnboarding && !!onboardingStep && isLoggedIn;

  if (shouldRedirectToOnboarding) {
    return <Redirect to={'/'+ onboardingStep} />;
  }

  const nextSearch = btoa(new URLSearchParams(location.search).toString());

  const nextParams = new URLSearchParams({next: location.pathname, search: nextSearch});

  return (
    <Route
      {...rest}
      render={() => isLoggedIn ? children : <Redirect to={`/login?${nextParams}`} />
      }
    />
  );
};


export default LoginRequiredRoute;