import { ComponentType } from 'react';
import { uniq } from 'ramda';

import GCalIcon from 'components/icons/GCal';
import GDriveIcon from 'components/icons/GDrive';
import GmailIcon from 'components/icons/Gmail';
import OutlookIcon from 'components/icons/OutlookIcon';
import SalesForceIcon from 'components/icons/SalesForce';
import SlackIcon from 'components/icons/Slack';
import { TrelloIcon } from 'components/icons/Trello';


const ProviderIcons: { [provider: string]: ComponentType<{ className?: string, onClick?: () => void }> } = {
  SALESFORCE: SalesForceIcon,
  SLACK: SlackIcon,
  GMAIL: GmailIcon,
  GDRIVE: GDriveIcon,
  GCAL: GCalIcon,
  OUTLOOK: OutlookIcon,
  TRELLO: TrelloIcon,
};


const NoOpIcon = () => <div />;


export const getProvidersIcon = (providers: string[]): ComponentType<{ className?: string, onClick?: () => void }> => {
  const uniqProviders = uniq(providers);
  switch (uniqProviders.length) {
    case 0:
      return NoOpIcon;
    case 1:
      return getProviderIcon(uniqProviders[0]);
    case 2:
      const Icon1 = getProviderIcon(uniqProviders[0]);
      const Icon2 = getProviderIcon(uniqProviders[1]);
      const TwoIcons: ComponentType<{ className?: string }> = ({ className }: { className?: string }) => <div><Icon1 className={className} /> <Icon2 className={`${className} under-icon--right`} /></div>;
      return TwoIcons;
    default:
      const Icon = getProviderIcon(uniqProviders[0]);
      const IconPlusOne = () => <div><Icon /> +{uniqProviders.length - 1}</div>;
      return IconPlusOne;
  }
};

export const getProviderIcon = (provider: string): ComponentType<{ className?: string, onClick?: () => void }> => {
  const icon = ProviderIcons[provider];
  if (icon) {
    return icon;
  } else {
    return NoOpIcon;
  }
};