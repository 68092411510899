import ContactSidebar from 'components/ContactSidebar';
import ContactsList from 'components/ContactsList';
import Sidebar from 'components/Sidebar';
import { closeSidebar } from 'store/contacts/slice';
import { getSidebarContact, getSidebarIsOpen } from 'store/contacts/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';

import './style.css';


const ContactsContent = () => {
  const dispatch = useAppDispatch();
  const hidden = !(useAppSelector(getSidebarIsOpen));
  const contact = useAppSelector(getSidebarContact);

  return <div className="contacts--container sidebar-sibling-content--container">
    <div className="contacts-content--container">
      <ContactsList />
    </div>
    <ContactSidebar
      onClose={() => dispatch(closeSidebar())}
      contact={contact}
      hidden={hidden}
    />
  </div>;
};


const ContactsContainer = () => {
  return (
    <div>
      <Sidebar />
      <ContactsContent />
    </div>
  );
};

export default ContactsContainer;