import { useHistory } from 'react-router';
import { Button, Link } from 'evergreen-ui';

import CheckEmailIcon from 'components/icons/CheckEmail';
import OnboardingCardWithHeader from 'components/OnboardingCardWithHeader';

import './style.css';


const PasswordResetEmailSent = () => {
  const history = useHistory();

  return (
    <OnboardingCardWithHeader className="pw-reset-email-sent--card">
      <div className="pw-reset-email-sent--card-content">
        <CheckEmailIcon />
        <div className="pw-reset-email-sent--title">Check your Email</div>
        <div className="pw-reset-email-sent--description">
          We just sent you an email containing a temporary link that will allow you to reset your password. Please check your spam folder if the email doesn’t appear within a few minutes.
          <div className="pw-reset-email-sent--subtext">
            If you have issues <Link className="login-link" href="mailto:support@classify.ltd">email us</Link>, and we’ll fix it
          </div>
        </div>
        <Button appearance="primary" className="pw-reset-email-sent--submit-button" onClick={() => { history.push('/login'); }}>Return to Login</Button>
      </div>
    </OnboardingCardWithHeader>
  );
};

export default PasswordResetEmailSent;
