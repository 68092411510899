import { APP_URL, TRELLO_API_KEY } from 'constants/resources';

export const openTrello = (redirectOverride?: string) => {
  const params = new URLSearchParams();
  params.append('expiration', 'never');
  params.append('name', 'Classify');
  params.append('scope', 'read,write,account');
  params.append('response_type', 'token');
  params.append('key', TRELLO_API_KEY);
  const additionalRedirect = redirectOverride ? ('&destination=' + redirectOverride) : '';
  params.append('return_url', APP_URL + '/connect-success?provider=trello' + additionalRedirect);
  
  window.open(`https://trello.com/1/authorize?${params.toString()}`);
};