import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { lclId } from 'utils/uuid';
import { AppDispatch, store } from 'store';

import {INotification, INotificationConfig, getNumNotifications, getOldestNotification } from './selectors';


export interface NotificationsState {
  notificationsById: {[notificationId: string]: INotification},
}

const initialState: NotificationsState = {
  notificationsById: {},
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showTransientNotification: (state, action: PayloadAction<INotification>) => {
      state.notificationsById[action.payload.id] = action.payload;
    },
    hideTransientNotification: (state, action: PayloadAction<string>) => {
      delete state.notificationsById[action.payload];
    },
  },
});

export const { showTransientNotification, hideTransientNotification } = notificationsSlice.actions;

const configInvalid = (config: INotificationConfig) => {
  return !config.showDismiss && config.requireDismiss;
};

export const notify = (config: INotificationConfig, dismissAfter=10000) => async (dispatch: AppDispatch) => {
  if (configInvalid(config)) {
    console.warn('[Classify.app.notify] Notification config invalid. Setting showDismiss to true.', config);
    config.showDismiss = true;
  }

  const id = lclId();
  if (getNumNotifications(store.getState()) >= 5) {
    dispatch(hideTransientNotification(getOldestNotification(store.getState()).id));
  }
  dispatch(showTransientNotification({id, shownAt: Date.now(), ...config}));
  if (!config.requireDismiss) {
    return new Promise((res) => {
      setTimeout(() => {
        dispatch(hideTransientNotification(id));
      }, dismissAfter);
      res(true);
    });
  } else {
    return Promise.resolve();
  }
};

export default notificationsSlice.reducer;
