import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import cx from 'classnames';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { neverGuardDefault } from 'utils/neverguard';
import { FailedResponseResultsCount, ISearchFacet, SearchType, SearchTypes, setSearchFilter, setSearchType } from 'store/search/slice';
import { GateNames, userHasGate } from 'store/user/selector';
import { activeSearchType, getSearchFacets, getTotalSearchResultsForType } from 'store/search/selectors';
import { faFile, faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { humanizeMimetype, titleCase } from 'utils/strings';
import { useAppDispatch, useAppSelector } from 'hooks';

import './style.css';


const FacetCategories = {
  SENDER: 'message_context.sender.name.raw',
  RECIPIENT: 'message_context.recipients.name.raw',
  STAR: 'star',
  PROVIDER: 'connector.provider',
  MIMETYPE: 'mimetype'
};

const bucketValueFormatters = {
  [FacetCategories.STAR]: (value: string) => value === 'true' ? 'Yes' : 'No',
  [FacetCategories.PROVIDER]: titleCase,
  [FacetCategories.MIMETYPE]: humanizeMimetype,
};


const formatBucketValue = (category: string, value: string) => {
  const formatter = bucketValueFormatters[category];
  return formatter ? formatter(value) : value;
};


const categoryToDisplayName = {
  [FacetCategories.SENDER]: 'Sender Name',
  [FacetCategories.RECIPIENT]: 'Recipient Name',
  [FacetCategories.STAR]: 'Bookmarked',
  [FacetCategories.MIMETYPE]: 'File type',
  [FacetCategories.PROVIDER]: 'Source',
};

// shim to make TS happy with the Array.isArray call
declare global {
  interface ArrayConstructor {
    isArray(arg: ReadonlyArray<unknown> | unknown): arg is ReadonlyArray<unknown>
  }
}

const SearchFacet = ({
  facet
}: {facet: ISearchFacet}) => {

  const dispatch = useAppDispatch();

  const options = facet.buckets.filter(bucket => bucket.value).map(bucket => ({
    value: bucket.value,
    label: (<div className="facet-option-bucket--label">
      <div>{formatBucketValue(facet.category, bucket.value)}</div>
      <div className="facet-option-bucket--count">{bucket.count}</div>
    </div>)
  }));

  const onChange = (res: {value: string, label: JSX.Element} | readonly {value: string, label: JSX.Element}[] | null) => {
    if (Array.isArray(res)) {
      dispatch(setSearchFilter({ category: facet.category, values: res.map((d) => d.value)}));
    } else {
      dispatch(setSearchFilter({ category: facet.category, values: res ? [res.value] : []}));
    }
  };

  return <div className="search-facet--container"><Select
    options={options}
    placeholder={categoryToDisplayName[facet.category]}
    isMulti={facet.category !== FacetCategories.STAR}
    onChange={onChange}
    isClearable={true}
    isSearchable={facet.category !== FacetCategories.STAR}
  /></div>;
};

const SearchTab = ({name, isActive}: {name: SearchType, isActive: boolean}) => {
  const dispatch = useAppDispatch();
  const resultsCount = useAppSelector(getTotalSearchResultsForType)(name);

  return (<div onClick={() => dispatch(setSearchType(name))} className={cx({ 'search-tabs--active-tab': isActive })}>
    {getSearchTabContent(name, resultsCount)}
  </div>);
};

const getSearchTabContent = (name: SearchType, resultsCount: number | undefined) => {
  const uppercaseName = titleCase(name);
  const displayName = resultsCount === undefined || resultsCount === FailedResponseResultsCount ? uppercaseName : `${uppercaseName} (${resultsCount})`;

  switch(name) {
    case 'documents': 
      return <>
        <FontAwesomeIcon className='search-tabs--name-icon' icon={faFile} size="sm" color="var(--color-neutral-8)" />
        {displayName}
      </>;
    case 'links': 
      return <>
        <FontAwesomeIcon className='search-tabs--name-icon' icon={faLink} size="sm" color="var(--color-neutral-8)" />
        {displayName}
      </>;
    case 'notes':
      return <>
        <FontAwesomeIcon className='search-tabs--name-icon' icon={faStickyNote} size="sm" color="var(--color-neutral-8)" />
        {displayName}
      </>;
    default: 
      return neverGuardDefault(name, null);
  }
};

const SearchTabs = () => {
  const activeTab = useAppSelector(activeSearchType);
  const hasNotesSearch = useAppSelector(s => userHasGate(s, GateNames.NOTES_SEARCH));
  const hasLinksSearch = useAppSelector(s => userHasGate(s, GateNames.LINKS_SEARCH));

  if (!hasLinksSearch && !hasNotesSearch) {
    return null;
  }
  
  return <div className="search-tabs--container">
    {SearchTypes.map(type => {
      if (type === 'links' && !hasLinksSearch) {
        return null;
      }
      if (type === 'notes' && !hasNotesSearch) {
        return null;
      }

      return <SearchTab name={type} key={type} isActive={type === activeTab} />;
    })}
  </div>;
};

const SearchFacets = () => {
  const facets = useAppSelector(getSearchFacets);

  return <>
    <SearchTabs />
    <div className="search-facets--container">
      {facets.map(facet => <SearchFacet facet={facet} key={facet.category} />)}
    </div>
  </>;
};

export default SearchFacets;