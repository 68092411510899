import { RootState } from 'store';

const root = (state: RootState) => state.links;

export const getLinksByContactId = (state: RootState) => root(state).linksByContactId;
export const getLinksFromContact = (state: RootState, contactId: string) => (getLinksByContactId(state) || {})[contactId] || [];

export const linksById = (state: RootState) => root(state).linksById;
const getLink = (state: RootState, linkId: string) => (linksById(state)[linkId] || {});
export const isFetchingLink = (state: RootState, linkId: string) => getLink(state, linkId).isFetching;
export const fetchLinkFailed = (state: RootState, linkId: string) => getLink(state, linkId).fetchFailed;
export const getLinkById = (state: RootState, linkId: string) => getLink(state, linkId).data;
