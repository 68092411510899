import { API } from 'api';
import { IReadReceipt } from 'store/read_receipts/selectors';


export const fetchReadReceiptsByKeys = (keys: string[]) => {
  const params = new URLSearchParams();
  keys.forEach(key => params.append('receipt_key', key));
  return API.get<{receipts: IReadReceipt[]}>(`/api/v1/read_receipts/find-by-keys?${params}`);
};

export const markAsRead = (receipt_keys: string[], read_at: string) => API.post('/api/v1/read_receipts/', {receipt_keys, read_at});