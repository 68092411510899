import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextInput } from 'evergreen-ui';
import cx from 'classnames';
import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { faArrowLeft, faCheck, faLink, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

import { NotificationType } from 'store/notifications/selectors';
import { getConnector } from 'store/connectors/selectors';
import { getIsSuperuser } from 'store/user/selector';
import { getProviderIcon } from 'components/Connector/providers';
import { getSearchLinkById} from 'store/search/selectors';
import { notify } from 'store/notifications/slice';
import { patchLink } from 'api/links';
import { wrapURLsWithAnchorTags } from 'utils/strings';
import { ILink, receiveLinks } from 'store/links/slice';
import { TrackEventNames, tracker } from 'utils/tracking';
import { useAppDispatch, useAppSelector } from 'hooks';

import './style.css';

const dateFmt: Intl.DateTimeFormatOptions = {month: 'numeric', year: '2-digit', day: 'numeric', hour: 'numeric', minute: '2-digit'};


const BasicLinkInfo = ({
  link
}: {link: ILink}) => {
  const dateString = new Date(link.updated_at).toLocaleString(navigator.language, dateFmt);
  const connector = useAppSelector(s => getConnector(s, link.connector_id || ''));
  const ProviderIcon = connector ? getProviderIcon(connector.provider) : null;
  const [isEditing, setIsEditing] = useState(false);
  const title = link.title || link.data.title;
  const [titleInput, setTitleInput] = useState(title);
  const description = link.description || link.data.description;
  const [descriptionInput, setDescriptionInput] = useState(description);
  const inputTitleRef = useRef<HTMLInputElement>(null);
  const inputDescriptionRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [imageSrc, setImageSrc] = useState<string | undefined>(link.data.image);

  // sometimes data from old connectors sticks around
  if (!connector && link.connector_id) {
    return null;
  }

  const onImageError = () => {
    setImageSrc(undefined);
  };


  const save = async () => {
    try {
      const {data} = await patchLink(link.id, titleInput || null, descriptionInput || null);
      tracker.track(TrackEventNames.LNDE, {linkId: link.id});
      dispatch(receiveLinks([data]));
      setIsEditing(false);
    } catch (err) {
      notify({
        type: NotificationType.WARNING,
        message: 'Could not update link, please try again.'
      })(dispatch);
    }
  };

  const maybeSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      save();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setIsEditing(false);
      inputTitleRef.current?.blur();
    }
  };

  return <div className="document-sidebar-basic-info--container">
    <div className="document-sidebar-basic-info--icon">
      {ProviderIcon && <div className="document-sidebar-basic-info-provider--icon">
        <ProviderIcon />
      </div>}
      {imageSrc && <img className="link-sidebar-content--link-image" src={imageSrc} onError={onImageError} alt={`${link.data.site_name} logo`} />}
      {!imageSrc && <FontAwesomeIcon className="link-sidebar-content--link-icon" icon={faLink} size="3x" color="var(--color-neutral-1)" />}
    </div>
    <div className="document-sidebar-basic--container">
      {!isEditing &&
        <div className="link-sidebar-title--container">
          <div>
            <div className={cx('view-link-sidebar--name', {empty: !title})}>{title || '(no title)'}</div>
            <div className={cx('view-link-sidebar--description', {empty: !description})}>{description || '(no description)'}</div>
          </div>
          <FontAwesomeIcon icon={faPencilAlt} className="view-contact-sidebar--edit" onClick={() => setIsEditing(true)}/>
        </div>
      }
      {isEditing &&
        <div className="link-sidebar-title--container">
          <div>
            <TextInput className="view-link-sidebar-name--input" placeholder="Link title" value={titleInput} onChange={(e: ChangeEvent<HTMLInputElement>) => setTitleInput(e.target.value)} onKeyDown={maybeSubmit} ref={inputTitleRef} />
            <TextInput
              className="view-link-sidebar-name--input"
              placeholder="Link description"
              value={descriptionInput}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setDescriptionInput(e.target.value)} onKeyDown={maybeSubmit} ref={inputDescriptionRef}
            />
          </div>
          <FontAwesomeIcon icon={faCheck} className="view-contact-sidebar--edit save" onClick={save}/>
          <FontAwesomeIcon icon={faTimes} className="view-contact-sidebar--edit cancel" onClick={() => setIsEditing(false)}/>
        </div>
      }

      <div className="document-sidebar-basic--info constrained-width" dangerouslySetInnerHTML={{__html: wrapURLsWithAnchorTags(link.url)}} />
      <div className="document-sidebar-basic--info subtitle">Last shared at</div>
      <div className="document-sidebar-basic--info">{dateString}</div>
    </div>
  </div>;
};

const SearchRelevance = ({
  linkId
}: { linkId: string}) => {
  const searchResult = useAppSelector(s => getSearchLinkById(s, linkId));

  if (!searchResult) {
    return null;
  }


  const fieldMap: Record<string, {label: string, order: number}> = {
    'message_context.content': {
      label: 'Message',
      order: 2,
    },
    'filename': {
      label: 'File name',
      order: 1,
    },
    'message_context.recipients.name': {
      label: 'Sent to',
      order: 1,
    },
    'message_context.sender.name': {
      label: 'Sent from',
      order: 1,
    },
  };

  const relevanceMessages: Record<string, {className: string, text: string}> = {
    V_HIGH: {
      className: 'very-high',
      text: 'Very high',
    },
    HIGH: {
      className: 'high',
      text: 'High',
    },
    MID: {
      className: 'medium',
      text: 'Medium',
    },
    LOW: {
      className: 'low',
      text: 'Low'
    },
  };

  const highlight = Object.entries(searchResult.highlight)
    .sort((a, b) => (fieldMap[a[0]] || {order: 0}).order - (fieldMap[b[0]] || {order: 0}).order)
    .map(([field, values]) => ({field: (fieldMap[field] || {label: field}).label, value: values[0]}));

  let relevance = relevanceMessages.LOW;

  if (searchResult.score >= 50) {
    relevance = relevanceMessages.V_HIGH;
  } else if (searchResult.score >= 30) {
    relevance = relevanceMessages.HIGH;
  } else if (searchResult.score >= 5) {
    relevance = relevanceMessages.MID;
  }

  return <div className="search-score--container">
    <h4 className="search-score--title">Search score</h4>
    <div className="search-score--section">Relevance <div className={cx('search-relevance--pill', relevance.className)}>{relevance.text}</div></div>
    <div>
      <div className="search-score--section">Reason</div>
      <div>
        {highlight.map(({field, value}) => (
          <div key={field} className="search-score-relevance-field--container">
            <div className="search-score-relevance-field--elt">{field}</div>
            <div className="search-score-relevance-field--elt" dangerouslySetInnerHTML={{__html:value}}/>
          </div>
        ))}
      </div>
    </div>
  </div>;
};

const SidebarLoadingSkeleton = () => {
  return <div>
    <div className="view-document-sidebar--header loading-skeleton">
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
    </div>
    <div className="view-document-sidebar--body loading-skeleton">
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image small" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image small" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
      <div className="view-doc-loading--wrapper">
        <div className="view-doc-loading--image small" />
        <div className="view-doc-loading--text">
          <div className="view-doc-loading--text-line" />
        </div>
      </div>
    </div>
  </div>;
};


const LinkSidebar = ({
  searchSidebar=false,
  onClose,
  link,
  hidden,
  showBackBtn,
  onBack,
}: {searchSidebar?: boolean, hidden: boolean, onClose: () => void, link: ILink | null, showBackBtn?: boolean, onBack?: () => void}) => {
  const isSuperuser = useAppSelector(getIsSuperuser);
  
  return (
    <div className={cx('view-document-sidebar--container', { hidden })}>
      <div className="view-document-sidebar-content--container">
        {!link && <SidebarLoadingSkeleton />}
        {link && (
          <>
            <div className="view-document-sidebar--header">
              <div className="view-document-sidebar--title">
                {showBackBtn && onBack &&
                  <FontAwesomeIcon icon={faArrowLeft} onClick={onBack}/>
                }
                <div>Link details</div>
              </div>
              <div className="view-document-sidebar--close" onClick={onClose}>&times;</div>
            </div>
            <div className="view-document-sidebar--body">
              <BasicLinkInfo link={link} />
              {isSuperuser && searchSidebar && <SearchRelevance linkId={link.id}/>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LinkSidebar;