import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Button, ChevronDownIcon, Dialog, IconButton, InfoSignIcon, Popover } from 'evergreen-ui';
import { MouseEvent, useState } from 'react';
import { faExternalLinkAlt, faGlobe, faLock, faThumbtack } from '@fortawesome/free-solid-svg-icons';

import { ConnectorProviders } from 'constants/app';
import { ILink } from 'store/links/slice';
import { LinkIcon } from 'components/LinkCard/LinkIcon';
import { PinSpaces } from 'constants/app';
import { TagPills } from 'components/DocumentCard';
import { getConnectorsList } from 'store/connectors/selectors';
import linkAccount from '../../assets/images/linkAccount.png';
import { openTrello } from 'utils/connect';
import { GateNames, userHasGate } from 'store/user/selector';
import { TrackEventNames, tracker } from 'utils/tracking';
import { getCurrentUser, getUserNameById } from 'store/user/selector';
import { useAppSelector, useLink } from 'hooks';

import './style.css';


interface ILinkCardProps {
  linkId: string;
  onClick?: (linkId: string) => void;
  selected?: boolean;
  onPin?: (e: MouseEvent<HTMLDivElement> | undefined, link: ILink) => void,
  onUnpin?: (e: MouseEvent<HTMLDivElement> | undefined, link: ILink) => void,
  onPrivatePin?: (e: MouseEvent<HTMLDivElement>, link: ILink) => void,
  onPublicPin?: (e: MouseEvent<HTMLDivElement>, link: ILink) => void,
  currentPinSpace?: PinSpaces,
  pinLoading?: boolean,
  className?: string,
  unread?: boolean,
  pinnedBy?: string,
  context?: {
    event_start_dt?: string;
    workstream?: string;
  },
}


const PinSpaceToButtonLabel = {
  [PinSpaces.PUBLIC]: <div><FontAwesomeIcon icon={faGlobe}/> Public</div>,
  [PinSpaces.PRIVATE]: <div><FontAwesomeIcon icon={faLock}/> Private</div>,
};

/**
 * for just showing a URL, without a full link
 **/
export const URLCard = ({
  url,
}: {url: string}) => {
  const track = async (e: MouseEvent, open=true) => {
    e.stopPropagation();
    tracker.track(TrackEventNames.ELC, {link: url, from: 'url card'});
    if (open) {
      window.open(url);
    }
  };

  return (
    <div className="url-card--container" onClick={track}>
      <FontAwesomeIcon icon={faExternalLinkAlt} className="url-card--icon" />
      <a href={url} target="_blank" rel="noopener noreferrer nofollow" onClick={(e) => track(e, false)}>{url}</a>
    </div>
  );
};


const LinkCard = ({
  linkId,
  onClick,
  onPin,
  onUnpin,
  onPrivatePin,
  onPublicPin,
  selected=false,
  pinLoading=false,
  className='',
  currentPinSpace=PinSpaces.PRIVATE,
  unread=false,
  pinnedBy,
  context,
}: ILinkCardProps) => {
  // TODO: handle loading/error state
  const {link} = useLink(linkId);
  const linkData = link?.data;
  const linkUpdate = link?.updated_at ? link?.updated_at : link?.created_at;
  const dateStr = new Date(linkUpdate || new Date()).toLocaleString('default', {month: 'numeric', day: 'numeric', year: '2-digit', hour: 'numeric', minute: '2-digit'});
  const [imageSrc, setImageSrc] = useState<string | undefined>(linkData?.image);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [showRemovePinModal, setShowRemovePinModal] = useState(false);
  const hasTrelloConnGate = useAppSelector(s => userHasGate(s, GateNames.TRELLO));
  // ToDo: needs to be sent from backend as there might be multiple accounts
  const isTrelloNotConnected = !useAppSelector(getConnectorsList).filter(conn => conn.provider === ConnectorProviders.TRELLO && conn.status === 'ACTIVE').length;

  const currentUserId = useAppSelector(getCurrentUser)?.id;
  const currentUserPinnedLink = !pinnedBy || pinnedBy === currentUserId;
  const pinnerName = useAppSelector(s => getUserNameById(s, pinnedBy || ''));

  const onImageError = () => {
    setImageSrc(undefined);
  };

  const open = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!link) return;
    tracker.track(TrackEventNames.ELC, {linkId, from: 'link card'});
    window.open(link.url);
  };

  if (!link) return null;

  const shouldShowPinIcon = (!!onPin) || (!!onUnpin);

  const onPinClick = (e: undefined | MouseEvent<HTMLDivElement>) => {
    if (!shouldShowPinIcon) return;
    e?.stopPropagation();

    // unpin when: user is unpinning a link that they pinned OR they confirmed to unpin another's pinned public link
    const shouldUnpin = (!!onUnpin && shouldShowPinIcon && currentUserPinnedLink) || (!!onUnpin && showRemovePinModal);
    if (shouldUnpin) {
      return onUnpin(e, link);
    }

    const shouldShowRemovePinModal = !onPin && !!onUnpin && shouldShowPinIcon && !currentUserPinnedLink;
    if (shouldShowRemovePinModal) {
      return setShowRemovePinModal(true);
    }

    const shouldPin = !!onPin && shouldShowPinIcon;
    if (shouldPin) {
      return onPin(e, link);
    }
  };

  const showPinSpaces = shouldShowPinIcon && (onPrivatePin || onPublicPin) && currentUserPinnedLink;

  const isTrelloLink = linkData?.type === 'Trello Board' || linkData?.site_name === 'Trello';

  return <div className={cx('link-card--container', className, {hover: popoverIsOpen, unread})}>
    <div className={cx('link-cards-inner--container', { selected, clickable: !!onClick })} onClick={() => onClick && onClick(linkId)}>
      {imageSrc && <img className="link-card--link-image" src={imageSrc} onError={onImageError} alt={`${linkData?.site_name} logo`} />}
      {!imageSrc && <LinkIcon url={link.url} className="link-card--link-icon" width={20} height={20} color="var(--color-neutral-1)" iconType={linkData?.type || linkData?.site_name} background_color={linkData?.background_color} background_scaled_image={linkData?.background_scaled_image} />}
      <div className="link-card-data--content">
        <div className="link-card-data--name">{link?.title || link?.data?.title || link?.url}</div>
        <div className="link-card--date">{dateStr}</div>
      </div>
    </div>
    {unread && <div className="link-card-unread--indicator"/>}
    <div className="link-card--actions">
      <div className="link-card--link" onClick={open}><FontAwesomeIcon icon={faExternalLinkAlt} size="sm" /></div>
      <div className="link-card-actions-pin--container">
        {showPinSpaces &&
          <Popover minWidth={100} onOpen={() => setPopoverIsOpen(true)} onClose={() => setPopoverIsOpen(false)} content={() => (
            <div>
              <div
                onClick={(e: MouseEvent<HTMLDivElement>) => !pinLoading && onPrivatePin && onPrivatePin(e, link)}
                className={cx('document-card-actions-pin-space--button', {disabled: !onPrivatePin})}
              >{PinSpaceToButtonLabel[PinSpaces.PRIVATE]}</div>
              <div
                onClick={(e: MouseEvent<HTMLDivElement>) => !pinLoading && onPublicPin && onPublicPin(e, link)}
                className={cx('document-card-actions-pin-space--button', {disabled: !onPublicPin})}
              >{PinSpaceToButtonLabel[PinSpaces.PUBLIC]}</div>
            </div>
          )}>
            <Button className="link-card-pin-space--button" isLoading={pinLoading}>{PinSpaceToButtonLabel[currentPinSpace]}<ChevronDownIcon marginLeft={4} /></Button>
          </Popover>}
        {shouldShowPinIcon && <div className={cx('link-card--pin', { unpin: !!onUnpin, pinning: pinLoading, 'with-space': showPinSpaces })} onClick={onPinClick}><FontAwesomeIcon icon={faThumbtack} size="sm" /></div>}
      </div>
      {onPinClick &&
        <Dialog
          isShown={showRemovePinModal}
          title="Remove pinned content"
          intent="danger"
          onCloseComplete={() => setShowRemovePinModal(false)}
          onConfirm={() => onPinClick(undefined)}
          confirmLabel="Remove pinned content"
        >Are you sure you want to remove {pinnerName}&rsquo;s pinned content from event?
        </Dialog>
      }
    </div>
    {isTrelloLink && isTrelloNotConnected && hasTrelloConnGate ? 
      <div className="link-card--connect-actions">
        <span className="link-card--connect-text">
          Connect to preview -
        </span>
        <Button className="link-card--connect-button" appearance="minimal" onClick={() => {
          if (context?.event_start_dt) {
            openTrello(encodeURIComponent(context?.event_start_dt));
          } else {
            openTrello(encodeURIComponent(context?.workstream || ''));
          }
        }}>Link account</Button>
        <Popover minWidth={300} content={() => (
          <div className="link-card--info-container">
            <span>Link to your account to preview the link. It just takes a second, and then you’ll be all set.</span>
            <img src={linkAccount} alt="An image describing how to connect account" />
          </div>
        )}> 
          <IconButton className="link-card--connect-info" appearance="minimal" icon={<InfoSignIcon color='#8F95B2' size={10} />} />
        </Popover>
      </div> 
      : null}
    {link && <TagPills tagRelations={link.tags} />}
  </div>;
};


export default LinkCard;