import { createReduxEnhancer } from '@sentry/react';
import { setupListeners } from '@reduxjs/toolkit/query';
import { PayloadAction, configureStore } from '@reduxjs/toolkit';

import { API } from 'api';
import { DEBUG } from 'constants/resources';
import actionItemsReducer from './action_items/slice';
import activitiesReducer from 'store/activities/slice';
import calendarReducer from 'store/calendar/slice';
import connectorsReducer from 'store/connectors/slice';
import contactsReducer from 'store/contacts/slice';
import documentsReducer from 'store/documents/slice';
import linksReducer from 'store/links/slice';
import notesReducer from 'store/notes/slice';
import notificationsReducer from 'store/notifications/slice';
import { queries } from 'api/query';
import readReceiptsSlice from 'store/read_receipts/slice';
import ruleReducer from 'store/rules/slice';
import searchReducer from 'store/search/slice';
import socketReducer from 'store/socket/slice';
import tagsReducer from 'store/tags/slice';
import teamReducer from 'store/teams/slice';
import userReducer from 'store/user/slice';

const loggingMiddleware = () => (next: (action: PayloadAction) => void) => (action: PayloadAction) => {
  if (DEBUG) {
    console.info('[Classify.app] applying action', action);
  }
  next(action);
};

export const store = configureStore({
  reducer: {
    user: userReducer,
    connectors: connectorsReducer,
    documents: documentsReducer,
    notifications: notificationsReducer,
    socket: socketReducer,
    search: searchReducer,
    contacts: contactsReducer,
    calendar: calendarReducer,
    rules: ruleReducer,
    links: linksReducer,
    tags: tagsReducer,
    activities: activitiesReducer,
    notes: notesReducer,
    teams: teamReducer,
    readReceipts: readReceiptsSlice,
    actionItems: actionItemsReducer,
    [queries.reducerPath]: queries.reducer,
  },
  enhancers: [createReduxEnhancer()],
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(queries.middleware, loggingMiddleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

declare global {
  interface Window {
    store: typeof store;
    API: typeof API;
    drift: {
      identify: (id: string, attributes: Record<string, unknown>) => void,
      openChat: () => void,
      load: (clientId: string) => void,
      on: (event: string, func: (api: {openChat: () => void, widget: {show: () => void, hide: () => void}}) => void) => void,
    };
  }
}

if (DEBUG) {
  window.store = store;
  window.API = API;
}