import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent } from 'react';
import cx from 'classnames';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';

import { AppQueryParams } from 'constants/app';
import { useCalendarEvent } from 'hooks';



interface ICalendarEventPillProps {
  calendarEventId: string;
  navigateDate: string;
  className?: string,
  trackingCtx?: string,
}


const CalendarEventPill = ({
  calendarEventId,
  navigateDate,
  className='',
}: ICalendarEventPillProps) => {
  const {calendarEvent} = useCalendarEvent(calendarEventId);

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    const params = new URLSearchParams();
    params.set(AppQueryParams.CALENDAR_VIEW_DATE, navigateDate);
    params.set(AppQueryParams.CALENDAR_FOCUS_EVENT, calendarEventId);
    window.open(`/?${params}`);
  };

  if (!calendarEvent) return null;

  return <div className={cx('document-card-pill--container', className)} onClick={onClick}>
    <div className='document-card-pill-file--container'>
      <FontAwesomeIcon width={14} height={18} color="var(--color-purple-5)" icon={faCalendarDay} size="sm" />
      <div className="document-card-pill-file--name">{calendarEvent.title}</div>
    </div>
  </div>;
};


export default CalendarEventPill;